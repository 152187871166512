import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Col } from 'reactstrap';
import DatePicker from 'react-datepicker';
import Utils from './../../../libs/Utils.js';

import '../../../styles/css/datePicker/style.scss';

class ModalAction extends Component {
  constructor(props, context) {
    super(props);
    this.state = {
      action_date: new Date(),
      action_responsible: '',
      action_description: '',
      action_coachee_id: '',
      maxLength: 1000,
      action_action: 0,
      editing: false,
      statusDate: true,
      selectAllCoachees: false,
    };
    this.utils = new Utils();
    this.saveAction = this.saveAction.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const { editingAction, typeAcao } = nextProps;
    if (editingAction) {
      const {
        date: action_date,
        responsible: action_responsible,
        description: action_description,
        action: action_action,
        date_action: action_date_action,
        coachee_id: action_coachee_id,
      } = editingAction;
      this.setState({
        action_date,
        action_responsible,
        action_description,
        action_action,
        action_date_action,
        action_coachee_id,
        editing: true,
      });
    }
    if (typeAcao !== this.props.typeAcao && !typeAcao) {
      this.setState({
        action_responsible: '',
        action_description: '',
        editing: false,
      });
    }
  }

  async componentDidMount() {
    //Responsavel por adicionar dias em uma data
    let addDate = this.state.action_date;
    let outraData = new Date();
    outraData.setDate(addDate.getDate() + 7);
    await this.setState({ action_date: outraData });

    if (this.props.modal_actions) {
      document.body.style.overflow = 'hidden';
    }
  }
  componentWillUnmount() {
    document.body.style.overflow = 'unset';
  }

  async saveAction() {
    if (
      this.state.action_date !== undefined &&
      this.state.action_date !== null &&
      this.state.action_date !== ''
    ) {
      await this.setState({ statusDate: true });
    } else {
      await this.setState({ statusDate: false });
    }

    if (this.state.statusDate) {
      let action;
      if (!this.props.typeAcao) {
        action = {
          responsible: this.state.action_responsible,
          date: this.utils.resolveSessionDate(this.state.action_date),
          description: this.state.action_description,
          //  alteracao em 8-11-19 Intera RFR  nova linha
          date_action: this.utils.parseDateBR(),
          // alteracao em 8-11-19 Intera RFR linha anterior
          // action: this.state.action_action
        };
      } else {
        action = {
          responsible: this.state.action_responsible,
          date: this.utils.resolveSessionDate(this.state.action_date),
          description: this.state.action_description,
          //  alteracao em 8-11-19 Intera RFR  nova linha
          action: this.state.action_action,
          date_action: this.state.action_date_action,
          coachee_id: this.state.action_coachee_id,
          // alteracao em 8-11-19 Intera RFR linha anterior
          // action: this.state.action_action
        };
      }
      this.props.saveAction(action);
      this.setState({
        action_description: '',
        selectAllCoachees: false,
      });
    }
  }

  isCoacheeSelected = item => {
    return !!this.props.selectedCoachees.find(
      coachee => coachee._id === item._id
    );
  };

  render() {
    return (
      <div
        className={`datepicker-style modal-content ${
          this.props.modal_actions ? ' active' : ''
        }`}
      >
        <div className="modal-box">
          <div className="modal-row-between">
            <div className="box-title">Preencha a ação</div>
            <i
              className="fas fa-times"
              onClick={() => {
                this.setState({ selectAllCoachees: false });
                this.props.toggleModalAction();
              }}
            ></i>
          </div>
          <div className="top-textarea modal-table-box">
            <Col xs={{ size: 12 }} md={{ size: 8, offset: 2 }}>
              <div className="modal-row-triple">
                <div className="form-input w33">
                  <label className="blue-label">Responsável</label>
                  <input
                    type="text"
                    value={this.state.action_responsible}
                    className="blue-input"
                    placeholder="Responsável"
                    onChange={e => {
                      this.setState({ action_responsible: e.target.value });
                    }}
                  />
                </div>
                <div className="form-input w33">
                  <label className="blue-label">Data para conclusão</label>

                  <DatePicker
                    selected={this.utils.resolveSessionDate(
                      this.state.action_date
                    )}
                    onChange={async e => {
                      await this.setState({
                        action_date: e,
                      });
                    }}
                    timeFormat="HH:mm"
                    timeIntervals={30}
                    dateFormat="dd/MM/yyyy"
                    timeCaption="time"
                    className="blue-input"
                  />
                  {!this.state.statusDate && (
                    <span className="campObrigatorio">
                      Campo "Data para conclusão" é obrigatório
                    </span>
                  )}
                </div>
                {this.state.editing && (
                  <div className="form-input w20 disabled">
                    <label className="blue-label">Ação</label>
                    <input
                      type="text"
                      disabled
                      value={this.state.action_action}
                      className="blue-input"
                      placeholder="Ação"
                    />
                  </div>
                )}
              </div>
              <div className="row-form">
                <div className="form-textarea clear-textarea">
                  <label className="blue-label">Descrição</label>
                  <textarea
                    value={this.state.action_description}
                    className="blue-input h55"
                    placeholder="Descrição"
                    maxLength={this.state.maxLength}
                    onChange={e => {
                      this.setState({ action_description: e.target.value });
                    }}
                  ></textarea>
                  <span>
                    {this.state.action_description.length}/
                    {this.state.maxLength}
                  </span>
                </div>
              </div>
            </Col>
          </div>
          {!this.state.editing &&
            this.props.coachees &&
            this.props.coachees.length > 0 && (
              <>
                <div className="modal-row-start">
                  <div className="box-title mtop-10">Adicionar para</div>
                  <label className="container-ckb">
                    <input
                      type="checkbox"
                      checked={this.state.selectAllCoachees}
                      onChange={() => {
                        this.setState(
                          {
                            selectAllCoachees: !this.state.selectAllCoachees,
                          },
                          () => {
                            if (this.state.selectAllCoachees) {
                              this.props.selectAllCoachee();
                            } else {
                              this.props.selectNoneCoachee();
                            }
                          }
                        );
                      }}
                    />
                    <span className="chklbl">Selecionar Todos</span>
                    <span className="checkmark"></span>
                  </label>
                </div>
                <div className="checkbox-columns-container">
                  {this.props.coachees.map(coachee => (
                    <label key={coachee._id} className="container-ckb">
                      <input
                        type="checkbox"
                        checked={this.isCoacheeSelected(coachee)}
                        onChange={() => {
                          this.setState({ selectAllCoachees: false });
                          this.props.toggleCoachee(coachee);
                        }}
                      />
                      <span className="chklbl">{coachee.name}</span>
                      <span className="checkmark"></span>
                    </label>
                  ))}
                </div>
              </>
            )}
          <div className="modal-row-actions">
            <div
              className="bt-blue"
              onClick={() => {
                this.saveAction();
              }}
            >
              <img
                src={require('./../../../assets/images/plus.svg')}
                style={{ marginTop: -3, marginRight: 14 }}
              />
              {this.state.editing ? 'Salvar' : 'Adicionar ação'}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  image: state.simpleReducer.image,
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ModalAction);
