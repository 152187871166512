export const DataProcessingHypotheses = {
  I: 'I',
  II: 'II',
  III: 'III',
  IV: 'IV',
  V: 'V',
  VI: 'VI',
  VII: 'VII',
  VIII: 'VIII',
  IX: 'IX',
  X: 'X',
};

export const getDataProcessingHypothesesText = key =>
  ({
    [DataProcessingHypotheses.I]:
      'I - fornecimento de consentimento pelo titular',
    [DataProcessingHypotheses.II]:
      'II - para o cumprimento de obrigação legal ou regulatória pelo controlador',
    [DataProcessingHypotheses.III]:
      'III -  tratamento e uso compartilhado de dados necessários à execução de políticas públicas',
    [DataProcessingHypotheses.IV]:
      'IV - para a realização de estudos por órgão de pesquisa',
    [DataProcessingHypotheses.V]:
      'V - execução de contrato ou de procedimentos preliminares relacionados a contrato',
    [DataProcessingHypotheses.VI]:
      'VI - para o exercício regular de direitos em processo judicial, administrativo ou arbitral',
    [DataProcessingHypotheses.VII]:
      'VII - para a proteção da vida ou da incolumidade física do titular ou de terceiro',
    [DataProcessingHypotheses.VIII]:
      'VIII - para a tutela da saúde, exclusivamente, em procedimento realizado por profissionais de saúde, serviços de saúde ou autoridade sanitária',
    [DataProcessingHypotheses.IX]:
      'IX - para atender aos interesses legítimos do controlador ou de terceiro',
    [DataProcessingHypotheses.X]: 'X - para a proteção do crédito',
  }[key]);

export const ProcedureStatus = {
  NAO_INICIADO: 'não iniciado',
  EM_ANDAMENTO: 'em andamento',
  ADEQUADO: 'adequado',
};

export const sortProcedures = (a, b) => {
  if (a.meeting === 'session' && b.meeting === 'session')
    return a.procedure - b.procedure;
  if (a.meeting === 'session') return 1;
  if (b.meeting === 'session') return -1;
  return Number(a.meeting) - Number(b.meeting);
};
