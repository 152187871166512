import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import '../../../styles/css/style.css';
import '../../../styles/css/dashboard.css';

import { Col } from 'reactstrap';

import { updateImage, updateAlert } from '../../../actions/SimpleAction';
// import Topbar from '../topbar/Topbar';

import SidebarSessions from './../../sidebar/SidebarSessions';

import ReactLoading from 'react-loading';

import Header from './../../components/Header';
import PrincipalMenu from './../../components/PrincipalMenu';
import Breadcrumbs from './../../components/Breadcrumbs';

import NotAllowed from './../../components/NotAllowed';

import Footer from './../../components/Footer';
import Submenu from './SubmenuSession';

import Service from './../Service.js';
import Utils from './../../../libs/Utils.js';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

class SessionPlanning extends Component {
  constructor(props, context) {
    super(props);

    const initialWidth = window.innerWidth > 0 ? window.innerWidth : 500;

    this.state = {
      isVisible: false,
      sideOpen: false,
      principalOpen: true,
      view: 'programs',
      subview: 'planning',
      side: 'assessments',
      breadcrumbs: [
        { title: 'Dashboard', link: '' },
        { title: 'Programas', link: 'programs' },
        { title: 'Sessão', link: 'programs/assessments' },
        {
          title: 'Planejamento de sessões',
          link: 'programs/assessments/planning',
        },
      ],
      showToolTip: false,
      windowWidth: initialWidth - (window.innerWidth * 40) / 100,
      loading: false,
      isLoading: false,

      loading_coachee: false,
      coachees: [],
      loading_groups: false,
      groups: [],
      session: {
        _id: '',
        its_free: false,
        its_group: false,
        coachee_id: '',
        group_id: '',
        project: '',
      },
      planning: {
        saboteurs: '',
        meetings: [],
      },

      maxLength: 3660,
    };

    this.utils = new Utils();
    this.service = new Service();
    this.handleResize = this.handleResize.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.togglePrincipal = this.togglePrincipal.bind(this);
    this.setSidebarLinks = this.setSidebarLinks.bind(this);
    this.goTo = this.goTo.bind(this);
  }

  toggleMenu() {
    if (this.state.sideOpen) {
      this.setState({ sideOpen: false });
    } else {
      this.setState({ sideOpen: true });
    }
  }

  togglePrincipal() {
    if (this.state.principalOpen) {
      this.setState({ principalOpen: false });
    } else {
      this.setState({ principalOpen: true });
    }
  }

  goTo(page) {
    this.props.history.push(`/${page}`);
  }

  verifyLogin() {
    if (!this.utils.isLogged()) {
      this.goTo('');
    }
  }

  componentDidMount() {
    this.verifyLogin();

    this.setState({ isLoading: true }, () => {
      Promise.all([
        this.loadUser().then(() => this.loadGroups()),
        this.loadCoachees(),
      ])
        .then(() => this.loadSession())
        .then(() => {
          const { session } = this.state;

          if (session.its_free) {
            this.goTo('dashboard/programs/master');
          }
          if (!session._id) {
            this.goTo('dashboard/programs/assessments');
          }
        })
        .finally(() => {
          this.setBreadcrumbs();
          this.setState({ isLoading: false });
        });
    });

    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  setBreadcrumbs = () => {
    const breadcrumbDetail = this.getBreadcrumbDetail();

    this.setState({
      breadcrumbs: [
        { title: 'Dashboard', link: '' },
        { title: 'Programas', link: 'programs' },
        { title: 'Sessão', link: 'programs/assessments' },
        {
          title: 'Planejamento de sessões',
          link: 'programs/assessments/planning',
        },
        ...(breadcrumbDetail ? [{ title: breadcrumbDetail }] : []),
      ],
    });
  };

  getCoacheeName = coacheeId => {
    const coachee = this.state.coachees.find(
      coachee => coachee._id === coacheeId
    );
    return coachee && coachee.name;
  };

  getGroupName = groupId => {
    const group = this.state.groups.find(group => group._id === groupId);
    return group && group.name;
  };

  getBreadcrumbDetail = () => {
    const { session } = this.state;

    if (session.coachee_id) return this.getCoacheeName(session.coachee_id);
    if (session.group_id) return this.getGroupName(session.group_id);
  };

  setSidebarLinks(view) {
    this.setState({ view });
    this.goTo(`dashboard/${view}`);
  }

  handleResize() {
    let _ww =
      window.innerWidth < 890 && window.innerWidth > 570
        ? window.innerWidth - (window.innerWidth * 30) / 100
        : window.innerWidth <= 570
        ? window.innerWidth - (window.innerWidth * 40) / 100
        : window.innerWidth - (window.innerWidth * 40) / 100;

    this.setState({
      windowWidth: _ww,
    });
  }

  logout() {
    this.utils.setLogout();
    this.goTo('');
  }

  async loadUser() {
    let result = await this.service.me();
    if (!result) {
      this.logout();
      return;
    }
    let user = {
      ...result.user,
    };
    if (user.image) {
      this.props.updateImage(user.image);
    }
    this.setState({ user });
  }

  async loadCoachees() {
    this.setState({ loading_coachee: true });
    let result = await this.service.coachees();
    if (result && result.success) {
      this.setState({ coachees: result.coachees });
    }
    this.setState({ loading_coachee: false });
  }

  loadGroups = async () => {
    const { user } = this.state;
    if (!this.userHasGroupSessionRight(user)) return;

    this.setState({ loading_groups: true });
    let result = await this.service.groups();
    if (result && result.success) {
      const { groups } = result;
      this.setState({ groups });
    }
    this.setState({ loading_groups: false });
  };

  mapApiResultToState = result => {
    const { session: stateSession, planning: statePlanning } = this.state;
    const { session } = result;
    const { planning } = session;
    const { master, meetings } = planning;
    this.setState({
      session: {
        ...stateSession,
        _id: session._id,
        its_free: session.its_free,
        its_group: session.its_group,
        coachee_id: session.coachee_id,
        group_id: session.group_id,
        project: session.project,
      },
      planning: {
        ...statePlanning,
        master: {
          ...master,
          date: master.date ? new Date(master.date) : undefined,
        },
        saboteurs: planning.saboteurs,
        meetings: meetings.map(meeting => ({
          ...meeting,
          date: new Date(meeting.date),
        })),
      },
    });
  };

  async loadSession() {
    const result = await this.service.sessionS(this.props.sessionId);
    if (result && result.success) {
      this.mapApiResultToState(result);
    }
  }

  async updatePlanning() {
    const { session, planning } = this.state;
    if (session._id) {
      const result = await this.service.updatePlanning(session._id, {
        saboteurs: planning.saboteurs,
      });
      if (result && result.success) {
        this.mapApiResultToState(result);
      }
    }
  }

  updateMeeting = async (number, payload) => {
    const { session } = this.state;
    if (session._id) {
      const result = await this.service.updateMeeting(
        session._id,
        number,
        payload
      );
      if (result && result.success) {
        this.mapApiResultToState(result);
      }
    }
  };

  changeField = (meeting, field_key, value) => {
    const { planning } = this.state;
    const { meetings } = planning;
    let meetingIndex = meetings.findIndex(m => m.number === meeting.number);
    meetings[meetingIndex].fields[field_key] = value;
    this.setState({ planning: { ...planning, meetings } });
  };

  saveField = async (meeting, field_key, value) => {
    const { planning } = this.state;
    const { meetings } = planning;
    let meetingIndex = meetings.findIndex(m => m.number === meeting.number);
    meetings[meetingIndex].fields[field_key] = value;
    await this.updateMeeting(meeting.number, {
      fields: meetings[meetingIndex].fields,
    });
  };

  addField = async meeting => {
    const { planning } = this.state;
    const { meetings } = planning;
    let meetingIndex = meetings.findIndex(m => m.number === meeting.number);
    meetings[meetingIndex].fields.push('');
    await this.updateMeeting(meeting.number, {
      fields: meetings[meetingIndex].fields,
    });
  };

  addMeeting = async () => {
    const { session } = this.state;
    if (session._id) {
      const result = await this.service.storeMeeting(session._id);
      if (result && result.success) {
        this.mapApiResultToState(result);
      }
    }
  };

  removeField = async (meeting, field_index) => {
    const { planning } = this.state;
    const { meetings } = planning;
    let meetingIndex = meetings.findIndex(m => m.number === meeting.number);
    meetings[meetingIndex].fields.splice(field_index, 1);
    await this.updateMeeting(meeting.number, {
      fields: meetings[meetingIndex].fields,
    });
  };

  removeMeeting = async meeting => {
    const { session } = this.state;
    if (session._id) {
      const result = await this.service.destroyMeeting(
        session._id,
        meeting.number
      );
      if (result && result.success) {
        this.mapApiResultToState(result);
      }
    }
  };

  userHasGroupSessionRight = user => {
    return (
      user &&
      user.plans &&
      user.plans.platform &&
      user.plans.platform.plan.group_sessions
    );
  };

  userHasProgramPlanningRight = user => {
    return (
      user &&
      user.plans &&
      user.plans.platform &&
      user.plans.platform.plan.use_program_planning
    );
  };

  userHasRights = user => {
    const { session } = this.state;
    return (
      this.userHasProgramPlanningRight(user) &&
      (!session.its_group ||
        (session.its_group && this.userHasGroupSessionRight(user)))
    );
  };

  render() {
    const {
      sideOpen,
      side,
      principalOpen,
      view,
      breadcrumbs,
      subview,
      user,
      session,
      planning,
      loading,
      coachees,
      groups,
      maxLength,
      isLoading,
    } = this.state;
    return (
      <div className="main-content">
        <Header
          goTo={this.goTo}
          sideOpen={sideOpen}
          toggleMenu={this.toggleMenu}
        />
        <div className="content-climb user bodyClimby">
          <div
            className={this.props.menu ? 'content-menu closed' : 'content-menu'}
          >
            <SidebarSessions
              goTo={this.goTo}
              side={side}
              setSidebarLinks={this.setSidebarLinks}
            />
          </div>
          <div
            className={this.props.menu ? 'content-dash closed' : 'content-dash'}
          >
            <PrincipalMenu
              principalOpen={principalOpen}
              view={view}
              setSidebarLinks={this.setSidebarLinks}
              togglePrincipal={this.togglePrincipal}
            />

            <div className="dash-content dash-fixed">
              <div className="dash-crumb-filter">
                <Breadcrumbs breadcrumbs={breadcrumbs} />
              </div>
              <div className="dashboard-boxes">
                <Submenu goTo={this.goTo} subview={subview} />
                {isLoading ? (
                  <ReactLoading type="bubbles" color="#B1CD49" />
                ) : !this.userHasRights(user) ? (
                  <NotAllowed go={this.goTo.bind(this)} user={user} />
                ) : (
                  <div className="dashboard-box box100-menu">
                    <div className="box-row-title row-center">
                      <div className="box-title">Planejamento de Sessões</div>
                    </div>

                    <Col
                      md={{ size: 4, offset: 8 }}
                      className="row-info-project"
                    >
                      <div>
                        {session.coachee_id ? (
                          <div className="form-input input-title">
                            <label className="blue-label">Coachee</label>
                            {loading ? (
                              <ReactLoading type="bubbles" color="#B1CD49" />
                            ) : (
                              <select
                                disabled={true}
                                value={session.coachee_id}
                                className="blue-input caret-none"
                              >
                                <option value=""> &nbsp; Coachee</option>
                                {coachees.map(coachee => {
                                  return (
                                    <option
                                      key={String(coachee._id)}
                                      value={coachee._id}
                                    >
                                      {' '}
                                      &nbsp; {coachee.name}
                                    </option>
                                  );
                                })}
                              </select>
                            )}
                          </div>
                        ) : session.group_id ? (
                          <div className="form-input input-title">
                            <label className="blue-label">Área</label>
                            {loading ? (
                              <ReactLoading type="bubbles" color="#B1CD49" />
                            ) : (
                              <select
                                disabled={true}
                                value={session.group_id}
                                className="blue-input caret-none"
                              >
                                <option value=""> &nbsp; Área</option>
                                {groups.map(group => {
                                  return (
                                    <option
                                      key={String(group._id)}
                                      value={group._id}
                                    >
                                      {' '}
                                      &nbsp; {group.name}
                                    </option>
                                  );
                                })}
                              </select>
                            )}
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                      <div>
                        {session ? (
                          <div className="form-input">
                            <label className="blue-label">Projeto</label>
                            <input
                              disabled={true}
                              type="text"
                              value={session.project}
                              className="blue-input"
                              placeholder="Projeto"
                            />
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </Col>

                    <div className="box-content-sessions">
                      {planning.meetings.map(meeting => {
                        return (
                          <Col
                            key={String(meeting.number)}
                            xs={{ size: 12 }}
                            sm={{ size: 12 }}
                            md={{ size: 5 }}
                            lg={{ size: 3 }}
                            className="box-sessions"
                          >
                            <div className="box-session-title">
                              Sessão {meeting.number}
                            </div>

                            <i
                              className="fas fa-times remove-session"
                              onClick={() => {
                                this.removeMeeting(meeting);
                              }}
                            ></i>

                            <div className="form-input planning-date-session">
                              <label className="blue-label special">Data</label>

                              <DatePicker
                                selected={meeting.date}
                                onChange={e => {
                                  this.updateMeeting(meeting.number, {
                                    date: e,
                                  });
                                }}
                                popperModifiers={{
                                  preventOverflow: {
                                    enabled: true,
                                  },
                                }}
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={30}
                                dateFormat="dd/MM/yyyy HH:mm"
                                timeCaption="hora"
                                className="blue-input"
                              />
                            </div>

                            <div className="box-session-content-field">
                              {meeting.fields.map((field, _kk) => {
                                return (
                                  <div style={{ display: 'grid' }} key={_kk}>
                                    <textarea
                                      value={field}
                                      onBlur={e => {
                                        this.saveField(
                                          meeting,
                                          _kk,
                                          e.target.value
                                        );
                                      }}
                                      onChange={e => {
                                        this.changeField(
                                          meeting,
                                          _kk,
                                          e.target.value
                                        );
                                      }}
                                      className="box-session-field"
                                      placeholder="Digite aqui"
                                    ></textarea>
                                    <i
                                      className="fas fa-times remove-session-field"
                                      onClick={() => {
                                        this.removeField(meeting, _kk);
                                      }}
                                    ></i>
                                  </div>
                                );
                              })}
                            </div>
                            <div className="box-session-row-action">
                              <div
                                className="bt-blue"
                                onClick={() => {
                                  this.addField(meeting);
                                }}
                              >
                                <i className="fas fa-plus"></i>
                                Adicionar campo
                              </div>
                            </div>
                          </Col>
                        );
                      })}

                      <Col
                        xs={{ size: 12 }}
                        sm={{ size: 12 }}
                        md={{ size: 5 }}
                        lg={{ size: 3 }}
                        className="box-sessions"
                      >
                        <div
                          className="action-add-session"
                          onClick={() => {
                            this.addMeeting();
                          }}
                        >
                          <i className="fas fa-plus"></i>
                          <p> Adicionar sessão </p>
                        </div>
                      </Col>
                    </div>

                    <Col
                      xs={{ size: 12 }}
                      sm={{ size: 8, offset: 2 }}
                      md={{ size: 6, offset: 3 }}
                      className="mtop-50 mbottom-50"
                    >
                      <div className="row-form top-textarea">
                        <div className="form-textarea clear-textarea">
                          <label className="blue-label">Observações</label>
                          <textarea
                            value={planning.saboteurs}
                            className="blue-input"
                            onBlur={() => {
                              this.updatePlanning();
                            }}
                            placeholder="Observações"
                            maxLength={maxLength}
                            onChange={e => {
                              this.setState({
                                planning: {
                                  ...planning,
                                  saboteurs: e.target.value,
                                },
                              });
                            }}
                          ></textarea>
                          <span>
                            {planning.saboteurs.length}/{maxLength}
                          </span>
                        </div>
                      </div>

                      <div className="row-action mtop-100">
                        {loading ? (
                          <ReactLoading type="bubbles" color="#00838F" />
                        ) : null}
                      </div>
                    </Col>
                  </div>
                )}
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  sessionId: state.simpleReducer.sessionId,
  menu: state.simpleReducer.menu,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateImage, updateAlert }, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SessionPlanning)
);
