import ApiService from '../../services/ApiService';

class Service extends ApiService {
  async oportunities() {
    try {
      let response = await fetch(`${this.endPoint}oportunities`, {
        method: 'GET',
        headers: {
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async oportunity(_id) {
    try {
      let response = await fetch(`${this.endPoint}oportunities/${_id}`, {
        method: 'GET',
        headers: {
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async oportunitiesFree() {
    try {
      let response = await fetch(`${this.endPoint}oportunities/free`, {
        method: 'GET',
        headers: {
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async oportunitiesMaster() {
    try {
      let response = await fetch(`${this.endPoint}oportunities/master`, {
        method: 'GET',
        headers: {
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async saveOportunity(_payload) {
    try {
      let response = await fetch(`${this.endPoint}oportunities`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
        body: this.parsePayload(_payload),
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async updateOportunity(_payload, _id) {
    try {
      let response = await fetch(`${this.endPoint}oportunities/${_id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
        body: this.parsePayload(_payload),
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async removeOportunity(_id) {
    try {
      let response = await fetch(`${this.endPoint}oportunities/${_id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async postFile(fileToUpload, notConvert = false) {
    const formData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    formData.append('notConvert', String(notConvert));
    try {
      let response = await fetch(`${this.endPoint}upload/file`, {
        method: 'POST',
        headers: {
          Authorization: `JWT ${this.getToken()}`,
        },
        body: formData,
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }
  async convertFileDocx(_payload) {
    try {
      let response = await fetch(`${this.endPoint}upload/convert/docx`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
        body: this.parsePayload(_payload),
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async categories() {
    try {
      let response = await fetch(`${this.endPoint}categories`, {
        method: 'GET',
        headers: {
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async saveContent(_payload) {
    try {
      let response = await fetch(`${this.endPoint}contents`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
        body: this.parsePayload(_payload),
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async sessionContent(sessionId, meetingType, contentId) {
    try {
      let response = await fetch(
        `${this.endPoint}acoachee/sessions/${sessionId}/contents/${meetingType}/${contentId}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            Authorization: `JWT ${this.getToken()}`,
          },
        }
      );
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async updateSessionContent(sessionId, meetingType, contentId, payload) {
    try {
      let response = await fetch(
        `${this.endPoint}acoachee/sessions/${sessionId}/contents/${meetingType}/${contentId}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            Authorization: `JWT ${this.getToken()}`,
          },
          body: JSON.stringify(payload),
        }
      );
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async sessionContents(sessionId) {
    try {
      let response = await fetch(
        `${this.endPoint}acoachee/sessions/${sessionId}/contents`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            Authorization: `JWT ${this.getToken()}`,
          },
        }
      );
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async sessionActions(sessionId) {
    try {
      let response = await fetch(
        `${this.endPoint}acoachee/sessions/${sessionId}/actions`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            Authorization: `JWT ${this.getToken()}`,
          },
        }
      );
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async updateSessionAction(sessionId, actionNumber, action) {
    try {
      let response = await fetch(
        `${this.endPoint}acoachee/sessions/${sessionId}/actions/${actionNumber}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            Authorization: `JWT ${this.getToken()}`,
          },
          body: JSON.stringify(action),
        }
      );
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async session() {
    try {
      let response = await fetch(`${this.endPoint}acoachee/session`, {
        method: 'GET',
        headers: {
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async sessions() {
    try {
      let response = await fetch(`${this.endPoint}acoachee/sessions`, {
        method: 'GET',
        headers: {
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async sessionS(id) {
    try {
      let response = await fetch(`${this.endPoint}acoachee/sessions/${id}`, {
        method: 'GET',
        headers: {
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async content(_id) {
    try {
      let response = await fetch(`${this.endPoint}acoachee/contents/${_id}`, {
        method: 'GET',
        headers: {
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async contentsFolder(_payload) {
    try {
      let response = await fetch(`${this.endPoint}contents/relatory/folder`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
        body: this.parsePayload(_payload),
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async contentsRel() {
    try {
      let response = await fetch(`${this.endPoint}contents/relatory`, {
        method: 'GET',
        headers: {
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async contentsClimb() {
    try {
      let response = await fetch(`${this.endPoint}contents/all`, {
        method: 'GET',
        headers: {
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async removeContent(_id) {
    try {
      let response = await fetch(`${this.endPoint}contents/${_id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async session(oportunity_id) {
    try {
      let response = await fetch(
        `${this.endPoint}sessions/oportunity/${oportunity_id}`,
        {
          method: 'GET',
          headers: {
            Authorization: `JWT ${this.getToken()}`,
          },
        }
      );
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }
  async sessionC(oportunity_id) {
    try {
      let response = await fetch(
        `${this.endPoint}sessions/coachee/${oportunity_id}`,
        {
          method: 'GET',
          headers: {
            Authorization: `JWT ${this.getToken()}`,
          },
        }
      );
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async createSession(_payload) {
    try {
      let response = await fetch(`${this.endPoint}sessions`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
        body: JSON.stringify(_payload),
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async updateSession(_payload, _id) {
    try {
      let response = await fetch(`${this.endPoint}sessions/${_id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
        body: this.parsePayload(_payload),
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async completeSessionTask(sessionId, meetingType, coacheeId, contentId) {
    try {
      let response = await fetch(
        `${this.endPoint}acoachee/sessions/${sessionId}/contents/${meetingType}/${coacheeId}/${contentId}/complete`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            Authorization: `JWT ${this.getToken()}`,
          },
        }
      );
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async reopenSessionTask(sessionId, meetingType, coacheeId, contentId) {
    try {
      let response = await fetch(
        `${this.endPoint}acoachee/sessions/${sessionId}/contents/${meetingType}/${coacheeId}/${contentId}/reopen`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            Authorization: `JWT ${this.getToken()}`,
          },
        }
      );
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async answerSessionTask(sessionId, meetingType, coacheeId, contentId) {
    try {
      let response = await fetch(
        `${this.endPoint}acoachee/sessions/${sessionId}/contents/${meetingType}/${coacheeId}/${contentId}/answer`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            Authorization: `JWT ${this.getToken()}`,
          },
        }
      );
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async completeSessionAction(sessionId, coacheeId, actionNumber) {
    try {
      let response = await fetch(
        `${this.endPoint}acoachee/sessions/${sessionId}/actions/${coacheeId}/${actionNumber}/complete`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            Authorization: `JWT ${this.getToken()}`,
          },
        }
      );
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async removeSession(_id) {
    try {
      let response = await fetch(`${this.endPoint}sessions/${_id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async modelProposals() {
    try {
      let response = await fetch(`${this.endPoint}model/proposals`, {
        method: 'GET',
        headers: {
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async calendar() {
    try {
      let response = await fetch(`${this.endPoint}acoachee/calendar`, {
        method: 'GET',
        headers: {
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async proposals() {
    try {
      let response = await fetch(`${this.endPoint}acoachee/proposals`, {
        method: 'GET',
        headers: {
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async proposal(_id) {
    try {
      let response = await fetch(`${this.endPoint}acoachee/proposal/${_id}`, {
        method: 'GET',
        headers: {
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async saveProposal(_payload) {
    try {
      let response = await fetch(`${this.endPoint}proposals`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
        body: this.parsePayload(_payload),
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async updateProposal(_payload, _id) {
    try {
      let response = await fetch(`${this.endPoint}acoachee/proposals/${_id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
        body: this.parsePayload(_payload),
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async removeProposal(_id) {
    try {
      let response = await fetch(`${this.endPoint}proposals/${_id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async search(_payload) {
    try {
      let response = await fetch(`${this.endPoint}search`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
        body: this.parsePayload(_payload),
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async createNewChatByCoachee(payload) {
    try {
      const response = await fetch(`${this.endPoint}chat/create/coachee`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
        body: JSON.stringify(payload),
      });
      const responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async getCoacheeChats() {
    try {
      const response = await fetch(`${this.endPoint}chat/list/coachee`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      const responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async updateChat(payload, _id) {
    try {
      const response = await fetch(
        `${this.endPoint}chat/update/coachee/${_id}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            Authorization: `JWT ${this.getToken()}`,
          },
          body: JSON.stringify(payload),
        }
      );
      const responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async updateReadedChat(_id) {
    try {
      const response = await fetch(
        `${this.endPoint}chat/update/readed/coachee/${_id}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            Authorization: `JWT ${this.getToken()}`,
          },
        }
      );
      const responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async coacheeMessages() {
    try {
      let response = await fetch(`${this.endPoint}acoachee/chat/messages/me`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async dashboard() {
    try {
      let response = await fetch(`${this.endPoint}acoachee/dashboard`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async coacheeLogin(_payload) {
    try {
      let response = await fetch(`${this.endPoint}acoachee/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
        body: this.parsePayload(_payload),
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async readFaqs() {
    try {
      let response = await fetch(`${this.endPoint}faqs`, {
        method: 'GET',
        headers: {},
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async coacheeLoginTermo(_payload) {
    try {
      let response = await fetch(`${this.endPoint}acoachee/login/termo`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
        body: this.parsePayload(_payload),
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }
}

export default Service;
