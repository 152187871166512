import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc';
import { Col, Row } from 'reactstrap';
import DatePicker from 'react-datepicker';
import { updateImage, updateAlert } from '../../../../actions/SimpleAction';
import Sidebar from './../../../sidebar/Sidebar';
import Submenu from '../../Session/SubmenuSession';
import ReactLoading from 'react-loading';
import Header from './../../../components/Header';
import PrincipalMenu from './../../../components/PrincipalMenu';
import Breadcrumbs from './../../../components/Breadcrumbs';
import Footer from './../../../components/Footer';
import Service from './../../Service.js';
import Utils from './../../../../libs/Utils.js';
import '../../../../styles/css/style.css';
import '../../../../styles/css/dashboard.css';

const DragHandle = sortableHandle(({ children }) => children);

const OptionArea = sortableElement(({ itemOption, optionIndex }) => (
  <DragHandle>
    <div key={optionIndex} className="row-center z-index label-drag-handle">
      <span className="form-ranking">{`${optionIndex + 1}º`}</span>
      <div className="form-input">
        <label className="blue-label label-drag-handle">{`${optionIndex +
          1}º Posição`}</label>
        <input
          type="text"
          value={itemOption}
          className="blue-input"
          readOnly={true}
        />
      </div>
    </div>
  </DragHandle>
));

const OptionAreas = sortableContainer(
  ({ question, setQuestionIndex, questionIndex }) => {
    return (
      <Col
        xs={{ size: 12 }}
        md={{ size: 12 }}
        onClick={() => {
          setQuestionIndex(questionIndex);
        }}
      >
        {question.options.map((item, optionIndex) => (
          <OptionArea
            key={`form-option-${optionIndex}`}
            index={optionIndex}
            optionIndex={optionIndex}
            itemOption={item.option}
          />
        ))}
      </Col>
    );
  }
);

class FormEditor extends Component {
  constructor(props, context) {
    super(props);
    const initialWidth = window.innerWidth > 0 ? window.innerWidth : 500;

    this.state = {
      isVisible: false,
      sideOpen: false,
      principalOpen: true,
      view: 'programs',
      subview: 'contents',
      side: 'assessments',
      breadcrumbs: [
        { title: 'Dashboard', link: '' },
        { title: 'Conteúdos', link: 'contents' },
        { title: 'Editor de formulário', link: 'form-editor' },
      ],
      showToolTip: false,
      windowWidth: initialWidth - (window.innerWidth * 40) / 100,
      loading: false,

      formData: [],
      title: '',

      sessionId: '',
      meetingType: '',
      contentId: '',
      setQuestionIndex: null,
      maxLength: 3660,
    };

    this.utils = new Utils();
    this.service = new Service();
    this.handleResize = this.handleResize.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.togglePrincipal = this.togglePrincipal.bind(this);
    this.setSidebarLinks = this.setSidebarLinks.bind(this);
    this.goTo = this.goTo.bind(this);
  }

  toggleMenu() {
    const { sideOpen } = this.state;
    this.setState({ sideOpen: !sideOpen });
  }

  togglePrincipal() {
    const { principalOpen } = this.state;
    this.setState({ principalOpen: !principalOpen });
  }

  goTo(page) {
    this.props.history.push(`/${page}`);
  }

  verifyLogin() {
    if (!this.utils.isLogged()) {
      this.goTo('');
    }
  }

  componentDidMount() {
    this.verifyLogin();
    this.loadUser();
    this.resolveParams();
    window.addEventListener('resize', this.handleResize);
  }

  resolveParams() {
    const {
      match: { params },
    } = this.props;
    const { sessionId, meetingType, contentId } = params;
    if (sessionId && meetingType && contentId) {
      this.loadContent(sessionId, meetingType, contentId);
    } else {
      window.history.back();
    }
  }

  async loadContent(sessionId, meetingType, contentId) {
    this.setState({ loading: true });
    const result = await this.service.sessionContent(
      sessionId,
      meetingType,
      contentId
    );
    if (result && result.success) {
      const { data, title } = result.content;
      this.setState({
        title: title || '',
        formData: [...data],
        sessionId,
        meetingType,
        contentId,
      });
    }
    this.setState({ loading: false });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  setSidebarLinks(view) {
    this.setState({ view });
    this.goTo(`users/dashboard/${view}`);
  }

  handleResize() {
    let _ww =
      window.innerWidth < 890 && window.innerWidth > 570
        ? window.innerWidth - (window.innerWidth * 30) / 100
        : window.innerWidth <= 570
        ? window.innerWidth - (window.innerWidth * 40) / 100
        : window.innerWidth - (window.innerWidth * 40) / 100;

    this.setState({
      windowWidth: _ww,
    });
  }

  logout() {
    this.utils.setLogout();
    this.goTo('');
  }

  async loadUser() {
    let result = await this.service.meCoachee();
    if (!result) {
      this.logout();
      return;
    }
    let user = {
      ...result.user,
    };

    if (user.image) {
      this.props.updateImage(user.image);
    }
  }

  save = async () => {
    const { title, formData, sessionId, meetingType, contentId } = this.state;
    if (!sessionId) return;

    const mandatoryErros = this.validateMandatoryQuastions();
    if (mandatoryErros) {
      this.props.updateAlert(
        'Responda corretamente as questões marcadas como obrigatórias.'
      );
      return;
    }

    this.setState({ loading: true });
    const result = await this.service.updateSessionContent(
      sessionId,
      meetingType,
      contentId,
      {
        title,
        data: [...formData],
      }
    );
    if (result && result.success) {
      const { data, title } = result.content;
      this.setState({
        title: title || '',
        formData: [...data],
      });
      this.props.updateAlert('Salvo com sucesso');
    }
    this.setState({ loading: false });
  };

  validateMandatoryQuastions = () => {
    const { formData } = this.state;
    let error = false;
    formData.forEach(question => {
      if (question.mandatory) {
        switch (question.type) {
          case 'text':
          case 'data':
          case 'evaluation': {
            if (!question.answer) {
              error = true;
            }
            return;
          }
          case 'multiple-choice':
          case 'checkboxes': {
            const checkedOptions = question.options.find(
              option => option.checked
            );
            if (!checkedOptions && question.others && !question.others.answer) {
              error = true;
            }
            return;
          }
          case 'likert': {
            question.likert.rows.forEach(row => {
              if (!row.answer) {
                error = true;
              }
              return;
            });
          }
          default:
            return;
        }
      }
    });
    return error;
  };

  renderEvaluationLevels = evaluationLevels => {
    const levels = [];
    for (let i = 1; i <= parseInt(evaluationLevels); i++) {
      levels.push(i);
    }
    return levels;
  };

  renderResponseTypes(question, questionIndex) {
    switch (question.type) {
      case 'text': {
        return (
          <Col xs={{ size: 12 }} md={{ size: 12 }}>
            <div
              className="form-textarea clear-textarea"
              style={{ maxWidth: 'none', paddingLeft: '0px' }}
            >
              <label className="blue-label">Resposta</label>
              <textarea
                value={question.answer}
                className="blue-input"
                placeholder="Resposta"
                maxLength={this.state.maxLength}
                onChange={e =>
                  this.handleForm('answer', questionIndex, e.target.value)
                }
              />
              <span>
                {question.answer.length}/{this.state.maxLength}
              </span>
            </div>
          </Col>
        );
      }
      case 'date': {
        return (
          <Col xs={{ size: 12 }} md={{ size: 6 }}>
            <div className="form-input date-picker">
              <label className="blue-label">Resposta</label>
              <DatePicker
                selected={question.answer ? new Date(question.answer) : ''}
                onChange={e =>
                  this.handleForm('answer', questionIndex, new Date(e))
                }
                timeIntervals={30}
                dateFormat="dd/MM/yyyy"
                timeCaption="time"
                className="blue-input"
              />
            </div>
          </Col>
        );
      }
      case 'multiple-choice':
      case 'checkboxes': {
        return (
          <Col xs={{ size: 12 }} md={{ size: 12 }}>
            {question.options.map((item, optionIndex) => (
              <div key={optionIndex} className="row-center">
                {question.type === 'multiple-choice' ? (
                  <div className="radio-div">
                    <label className="radio-label">
                      <input
                        className="form-option"
                        type="radio"
                        name={`choices${questionIndex}`}
                        value={item.checked}
                        checked={item.checked}
                        onChange={() =>
                          this.handleRadioOrCheckbox(
                            questionIndex,
                            optionIndex,
                            question.type
                          )
                        }
                      />
                      <span className="radio-span"></span>
                    </label>
                  </div>
                ) : (
                  <div className="checkbox-div">
                    <label className="checkbox-label">
                      <input
                        type="checkbox"
                        value={item.checked}
                        checked={item.checked}
                        onChange={() =>
                          this.handleRadioOrCheckbox(
                            questionIndex,
                            optionIndex,
                            question.type
                          )
                        }
                      />
                      <span className="checkbox-span"></span>
                    </label>
                  </div>
                )}
                <div className="form-input">
                  <label className="blue-label">{`Opção ${optionIndex +
                    1}`}</label>
                  <input
                    type="text"
                    value={item.option}
                    className="blue-input"
                    readOnly={true}
                  />
                </div>
              </div>
            ))}
            {question.others && question.others.actived && (
              <div className="row-center">
                {question.type === 'multiple-choice' ? (
                  <div className="radio-div">
                    <label className="radio-label">
                      <input
                        className="form-option"
                        type="radio"
                        name="choices"
                        value={question.others.checked}
                        checked={question.others.checked}
                        onChange={() =>
                          this.handleOthers(questionIndex, question.type)
                        }
                      />
                      <span className="radio-span"></span>
                    </label>
                  </div>
                ) : (
                  <div className="checkbox-div">
                    <label className="checkbox-label">
                      <input
                        type="checkbox"
                        value={question.others.checked}
                        checked={question.others.checked}
                        onChange={() =>
                          this.handleOthers(questionIndex, question.type)
                        }
                      />
                      <span className="checkbox-span"></span>
                    </label>
                  </div>
                )}

                <div className="form-input">
                  <label className="blue-label">Outros</label>
                  <input
                    type="text"
                    className="blue-input"
                    placeholder="Outros..."
                    value={question.others.answer}
                    disabled={!question.others.checked}
                    readOnly={!question.others.checked}
                    onChange={e =>
                      this.handleOthersAnswer(questionIndex, e.target.value)
                    }
                  />
                </div>
              </div>
            )}
          </Col>
        );
      }
      case 'ranking': {
        return (
          <OptionAreas
            onSortEnd={this.handleSortEnd}
            onSortMove={() => {
              this.setQuestionIndex(questionIndex);
            }}
            setQuestionIndex={this.setQuestionIndex}
            question={question}
            questionIndex={questionIndex}
            useDragHandle
          />
        );
      }
      case 'evaluation': {
        return (
          <Col xs={{ size: 12 }} md={{ size: 12 }}>
            <div className="row-center flex-wrap mb-15">
              {this.renderEvaluationLevels(question.evaluationLevels).map(
                item => (
                  <div key={item} className="form-evaluation-levels-div">
                    <span className="form-evaluation-levels-span">{item}</span>
                    <div className="radio-div mr-0">
                      <label className="radio-label">
                        <input
                          className="form-option"
                          type="radio"
                          name="evaluation"
                          value={question.answer}
                          checked={question.answer === item}
                          onChange={() =>
                            this.handleForm('answer', questionIndex, item)
                          }
                        />
                        <span className="radio-span"></span>
                      </label>
                    </div>
                  </div>
                )
              )}
            </div>
          </Col>
        );
      }
      case 'likert': {
        return (
          <Col xs={{ size: 12 }} md={{ size: 12 }} style={{ margin: '10px 0' }}>
            <div className="form-likert-columns">
              {question.likert.columns.map((column, columnIndex) => {
                return (
                  <span
                    key={columnIndex}
                    className="column-text likert-vertical-text"
                  >
                    {column.text || `Coluna ${columnIndex + 1}`}
                  </span>
                );
              })}
            </div>
            {question.likert.rows.map((row, rowIndex) => {
              return (
                <div
                  key={rowIndex}
                  className={`form-likert-rows ${
                    rowIndex % 2 ? 'bc-gray' : 'bc-light-gray'
                  }`}
                >
                  <span className="row-text likert-vertical-text">
                    {row.text || `Linha ${rowIndex + 1}`}
                  </span>
                  <div className="likert-rows-circle">
                    {question.likert.columns.map((column, columnIndex) => (
                      <div className="radio-div mr-0 mb-0 height-0">
                        <label className="radio-label mb-0 top-3">
                          <input
                            key={`${rowIndex}-${columnIndex}`}
                            className="form-option"
                            type="radio"
                            name={row.text}
                            value={row.answer}
                            checked={row.answer === column.text}
                            onChange={() =>
                              this.handleLikertAnswer(
                                questionIndex,
                                rowIndex,
                                column.text
                              )
                            }
                          />
                          <span className="radio-span"></span>
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              );
            })}
          </Col>
        );
      }
      default:
        return null;
    }
  }

  handleForm = (field, questionIndex, value) => {
    const newFormData = [...this.state.formData];
    newFormData[questionIndex][field] = value;
    this.setState({ formData: newFormData });
  };

  handleRadioOrCheckbox = (questionIndex, optionIndex, questionType) => {
    const newFormData = [...this.state.formData];
    const newValue = !newFormData[questionIndex].options[optionIndex].checked;
    if (questionType === 'multiple-choice') {
      if (newFormData[questionIndex].others) {
        newFormData[questionIndex].others.checked = false;
        newFormData[questionIndex].others.answer = '';
      }
      newFormData[questionIndex].options.forEach((item, index) => {
        if (index !== optionIndex) {
          item.checked = false;
        }
      });
    }
    newFormData[questionIndex].options[optionIndex].checked = newValue;
    this.setState({ formData: newFormData });
  };

  handleOthers = (questionIndex, questionType) => {
    const newFormData = [...this.state.formData];
    const newValue = !newFormData[questionIndex].others.checked;
    if (questionType === 'multiple-choice') {
      newFormData[questionIndex].options.forEach(item => {
        item.checked = false;
      });
    }
    if (!newValue) {
      newFormData[questionIndex].others.answer = '';
    }
    newFormData[questionIndex].others.checked = newValue;
    this.setState({ formData: newFormData });
  };

  handleOthersAnswer = (questionIndex, value) => {
    const newFormData = [...this.state.formData];
    newFormData[questionIndex].others.answer = value;
    this.setState({ formData: newFormData });
  };

  handleLikertAnswer = (questionIndex, rowIndex, columnText) => {
    const newFormData = [...this.state.formData];
    newFormData[questionIndex].likert.rows[rowIndex].answer = columnText;
    this.setState({ formData: newFormData });
  };

  setQuestionIndex = setQuestionIndex => {
    if (setQuestionIndex !== this.state.setQuestionIndex) {
      this.setState({ setQuestionIndex });
    }
  };

  handleSortEnd = ({ oldIndex, newIndex }) => {
    setTimeout(() => {
      const { setQuestionIndex } = this.state;
      if (setQuestionIndex !== null) {
        const newFormData = [...this.state.formData];
        const [option] = newFormData[setQuestionIndex].options.splice(
          oldIndex,
          1
        );
        newFormData[setQuestionIndex].options.splice(newIndex, 0, option);
        this.setState({ formData: newFormData });
      }
    }, 30);
  };

  render() {
    const {
      sideOpen,
      side,
      principalOpen,
      view,
      breadcrumbs,
      subview,
      title,
      formData,
      loading,
    } = this.state;
    return (
      <div className="main-content">
        <Header
          goTo={this.goTo}
          sideOpen={sideOpen}
          toggleMenu={this.toggleMenu}
        />
        <div className="content-climb user bodyClimby">
          <div
            className={this.props.menu ? 'content-menu closed' : 'content-menu'}
          >
            <Sidebar
              side={this.state.side}
              setSidebarLinks={this.setSidebarLinks}
              expanded={!this.state.sideOpen}
            />
          </div>
          <div
            className={this.props.menu ? 'content-dash closed' : 'content-dash'}
          >
            <div className="dash-content">
              <div className="dash-crumb-filter">
                <Breadcrumbs breadcrumbs={breadcrumbs} />
              </div>
              <div className="dashboard-boxes">
                <div className="dashboard-box box100-menu">
                  <Col xs={{ size: 12 }} md={{ size: 12 }}>
                    <div className="box-row-title row-center">
                      <div className="box-title">Editor de Formulário</div>
                    </div>

                    <div className="form-div">
                      <div className="form-input">
                        <label className="blue-label">Título</label>
                        <input
                          type="text"
                          value={title}
                          className="blue-input"
                          placeholder="Título"
                          readOnly={true}
                        />
                      </div>

                      {formData.map((item, index) => (
                        <div key={index} className="form-new-question">
                          <label className="form-question-title">
                            {`${index + 1}. Questão `}
                            {item.mandatory && (
                              <span style={{ color: '#AE1515' }}>*</span>
                            )}
                          </label>
                          <Row>
                            <Col xs={{ size: 12 }} md={{ size: 12 }}>
                              <div className="form-input">
                                <label className="blue-label">Pergunta</label>
                                <input
                                  type="text"
                                  value={item.question}
                                  className="blue-input"
                                  placeholder="Pergunta"
                                  readOnly={true}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>{this.renderResponseTypes(item, index)}</Row>
                        </div>
                      ))}
                    </div>
                  </Col>
                  <Col xs={{ size: 12 }} md={{ size: 10, offset: 1 }}>
                    <div className="row-between mtop-50 mbottom-50">
                      {loading ? (
                        <ReactLoading type="bubbles" color="#00838F" />
                      ) : (
                        <div
                          className="bt-blue"
                          onClick={() => {
                            this.save();
                          }}
                        >
                          Salvar
                        </div>
                      )}
                    </div>
                  </Col>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  menu: state.simpleReducer.menu,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateImage, updateAlert }, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FormEditor)
);
