import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Dropdown, Icon, Nav, Sidenav } from 'rsuite';
import {
  modalStartTestPeriod,
  updateAlert,
  updateImage,
  updateSession,
  updateUser,
} from '../../actions/SimpleAction';
import Utils from '../../libs/Utils';
import Service from '../dashboard/Profile/Service.js';
import sideBarProfile from './ItemsSidebarProfile';

class SidebarProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeKey: '1',
      windowWidth: 0,
      isVisible: false,
      isTestPeriodStarted: props.user.isTestPeriodStarted,
      user: {},
    };
    this.service = new Service();

    this.handleResize = this.handleResize.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  handleSelect(eventKey) {
    if (eventKey === 'logout') {
      this.logout();
    } else {
      this.setState({
        activeKey: eventKey,
      });

      this.props.setSidebarLinks(eventKey);
    }
  }

  handleResize() {
    this.setState({
      windowWidth: window.innerWidth,
    });
  }

  componentDidMount() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
    this.loadUser();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  checkCurrentLink(_menuLink) {
    const { pathname } = window.location;
    if (_menuLink || _menuLink === '') {
      const currentPageLocation = pathname.replace('/dashboard/', '');
      return currentPageLocation === _menuLink;
    }

    return false;
  }

  async loadUser() {
    let result = await this.service.me();
    if (result) {
      this.props.updateUser(result.user);
      this.setState({
        user: result.user,
        isVisible: result.user.profile_active || false,
        isTestPeriodStarted: result.user.isTestPeriodStarted,
      });
    }
  }

  async save(newUser, typeRequest) {
    this.setState({ loading: true });
    let user = { coach_id: this.state.user._id, ...newUser };
    let result = await this.service.save(user);
    if (result && result.success) {
      this.loadUser();
    }
    this.setState({ loading: false });
    if (typeRequest === 'testPeriod' && result && result.success) {
      this.props.updateAlert(
        <div>
          <p>Período de teste iniciado!</p>
        </div>
      );
    }
  }

  logout = () => {
    const utils = new Utils();
    utils.setLogout();
    window.location.reload();
  };

  render() {
    const { expanded } = this.props;
    const { windowWidth } = this.state;

    return (
      <Sidenav
        expanded={windowWidth < 942 ? false : expanded}
        activeKey={this.state.activeKey}
        onSelect={this.handleSelect}
      >
        <Sidenav.Body>
          <Nav>
            {sideBarProfile &&
              sideBarProfile.length > 0 &&
              sideBarProfile.map((el, idx) => {
                return el.menuSubItems && el.menuSubItems.length > 0 ? (
                  <Dropdown
                    key={idx}
                    placement="rightStart"
                    eventKey={el.menuLink}
                    title={el.menuName}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    icon={
                      <Icon
                        style={{
                          fontSize: 24,
                          color: '#003343',
                          top: '10px',
                        }}
                        icon={el.icon}
                      />
                    }
                  >
                    {el.menuSubItems.map((subMenu, idxSub) => (
                      <Dropdown.Item
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                        key={idxSub}
                        eventKey={subMenu.menuLink}
                      >
                        {subMenu.menuName}
                      </Dropdown.Item>
                    ))}
                  </Dropdown>
                ) : (
                  <Nav.Item
                    className={
                      this.checkCurrentLink(el.menuLink)
                        ? 'sideBarItemsActive'
                        : ''
                    }
                    key={idx}
                    eventKey={el.menuLink}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    icon={
                      <Icon
                        className={
                          this.checkCurrentLink(el.menuLink)
                            ? 'sideBarItemsActive-icon'
                            : ''
                        }
                        icon={el.icon}
                        style={{
                          fontSize: 24,
                          color: '#003343',
                          top: '10px',
                        }}
                      />
                    }
                  >
                    {el.menuName}
                  </Nav.Item>
                );
              })}
          </Nav>
        </Sidenav.Body>
      </Sidenav>
    );
  }
}
const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  user: state.simpleReducer.user,
  sessionId: state.simpleReducer.sessionId,
  showModalStartTestPeriod: state.simpleReducer.showModalStartTestPeriod,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateImage,
      updateUser,
      updateAlert,
      modalStartTestPeriod,
      updateSession,
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SidebarProfile)
);
