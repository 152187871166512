export default [
  {
    menuName: 'Dashboard',
    menuLink: '',
    icon: 'dashboard',
  },
  {
    menuName: 'Meu Perfil',
    menuLink: 'profile',
    icon: 'user',
  },
  {
    menuName: 'Termos de Uso',
    menuLink: 'terms',
    icon: 'calendar',
  },
  {
    menuName: 'Alterar Senha',
    menuLink: 'profile/changepassword',
    icon: 'comment',
  },
  {
    menuName: 'Sair',
    icon: 'sign-out',
    menuLink: 'logout',
  },
];
