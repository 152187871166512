import React, { Component } from 'react';
import ReactLoading from 'react-loading';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { updateImage, updateSession } from '../../../actions/SimpleAction';
import '../../../styles/css/dashboard.css';
import '../../../styles/css/style.css';
import CardGenericTable from '../../components/CardGenericTable';
import Utils from './../../../libs/Utils.js';
import Breadcrumbs from './../../components/Breadcrumbs';
import Footer from './../../components/Footer';
import Header from './../../components/Header';
import ModalActionDelete from './../../components/NewModalCoacheeDelete';
import NotAllowed from './../../components/NotAllowed';
import Sidebar from './../../sidebar/Sidebar';
import Service from './../Service.js';
import ModalActions from './ModalActions';
import { sortActions } from '../../../consts/action.js';

class ActionsRegister extends Component {
  constructor(props, context) {
    super(props);

    const initialWidth = window.innerWidth > 0 ? window.innerWidth : 500;

    this.state = {
      isVisible: false,
      sideOpen: this.props.menu,
      principalOpen: true,
      view: 'programs',
      subview: 'actions',
      side: 'assessments',
      breadcrumbs: [
        { title: 'Dashboard', link: '' },
        { title: 'Programas', link: 'programs' },
        { title: 'Registro de ações', link: 'programs/assessments/actions' },
      ],
      showToolTip: false,
      windowWidth: initialWidth - (window.innerWidth * 40) / 100,
      loading: false,

      rows: [],
      tableTitles: [
        { title: 'Data ação', key: 'date_action' },
        { title: 'Ação', key: '_action' },
        { title: 'Descrição', key: 'description' },
        { title: 'Responsável', key: 'responsible' },
        { title: 'Data prevista', key: 'udate' },
        { title: 'Conclusão', key: 'completed_at' },
        { title: 'Status', key: 'action_status' },
        { title: 'Opções', key: 'options' },
        { title: 'Resposta', key: '_response_status' },
      ],
      tableActions: [
        {
          title: 'Visualizar',
          icon: 'fas fa-eye',
          action: this.edit.bind(this),
        },
        {
          title: 'Deletar',
          icon: 'fas fa-trash',
          action: this.removeAction.bind(this),
        },
      ],
      isLoading: false,

      loading_coachee: false,
      coachees: [],

      loading_groups: false,
      groups: [],

      session: {
        project: '',
        coachee_id: '',
        group_id: '',
        its_group: false,
        _id: '',
      },
      sessionActions: [],

      showModalActions: false,
      responseActions: [{ action: this.taskYes.bind(this) }],
      isEditingAction: false,
      showModalActionDelete: false,
      actionToDelete: null,
      editingAction: null,
      selectedCoachees: [],
    };

    this.utils = new Utils();
    this.service = new Service();
    this.handleResize = this.handleResize.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.togglePrincipal = this.togglePrincipal.bind(this);
    this.setSidebarLinks = this.setSidebarLinks.bind(this);
    this.goTo = this.goTo.bind(this);

    this.toggleModalAction = this.toggleModalAction.bind(this);
    this.saveAction = this.saveAction.bind(this);
    this.removeAction = this.removeAction.bind(this);
  }

  toggleCoachee = item => {
    let selectedCoachees = [...this.state.selectedCoachees];
    const isCoacheeSelected = selectedCoachees.find(
      coachee => coachee._id === item._id
    );
    if (isCoacheeSelected) {
      selectedCoachees = selectedCoachees.filter(
        coachee => coachee._id !== item._id
      );
    } else {
      selectedCoachees.push(item);
    }
    this.setState({ selectedCoachees });
  };

  toggleModalAction() {
    const { showModalActions } = this.state;
    this.setState({
      showModalActions: !showModalActions,
      selectedCoachees: [],
    });
  }

  async saveAction(action) {
    const { sessionId } = this.props;
    const { selectedCoachees, session, editingAction } = this.state;

    if (sessionId) {
      const isEditing = editingAction;
      let result;

      const sendTo = [];
      if (session.its_group) {
        selectedCoachees.forEach(coachee => sendTo.push(coachee._id));
      } else if (session.coachee_id) {
        sendTo.push(session.coachee_id);
      }
      if (isEditing) {
        const { coachee_id: coacheeId, action: actionNumber } = action;

        result = await this.service.updateSessionAction(
          sessionId,
          coacheeId,
          actionNumber,
          action
        );
      } else if (sendTo.length > 0) {
        result = await this.service.storeSessionActions(
          sessionId,
          'session',
          sendTo,
          action
        );
      }

      if (result && result.success) {
        await this.loadSessionActions(sessionId);
      }
    }

    this.toggleModalAction();
  }

  async edit(action) {
    this.setState(
      {
        isEditingAction: true,
        editingAction: action,
      },
      () => {
        this.toggleModalAction();
      }
    );
  }

  async removeAction(action) {
    this.setState(
      {
        actionToDelete: action,
      },
      () => {
        this.toggleModalDelete();
      }
    );
  }

  deleteAction = async () => {
    const { sessionId } = this.props;
    const { actionToDelete } = this.state;
    if (sessionId) {
      const { coachee_id: coacheeId, action: actionNumber } = actionToDelete;

      const result = await this.service.destroySessionAction(
        sessionId,
        coacheeId,
        actionNumber
      );
      if (result && result.success) {
        await this.loadSessionActions(sessionId);
      }
    }

    this.toggleModalDelete();
  };

  toggleModalDelete() {
    const { showModalActionDelete } = this.state;
    this.setState({
      showModalActionDelete: !showModalActionDelete,
    });
  }

  toggleMenu() {
    const { sideOpen } = this.state;
    this.setState({ sideOpen: !sideOpen });
  }

  togglePrincipal() {
    const { principalOpen } = this.state;
    this.setState({ principalOpen: !principalOpen });
  }

  goTo(page) {
    this.props.history.push(`/${page}`);
  }

  verifyLogin() {
    if (!this.utils.isLogged()) {
      this.goTo('');
    }
  }

  componentDidMount() {
    this.verifyLogin();

    this.setState({ isLoading: true }, () => {
      Promise.all([
        this.loadUser().then(() => this.loadGroups()),
        this.resolveParams(),
        this.loadCoachees(),
      ]).finally(() => {
        this.setBreadcrumbs();
        this.setState({ isLoading: false });
      });
    });
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  setBreadcrumbs = () => {
    const breadcrumbDetail = this.getBreadcrumbDetail();

    this.setState({
      breadcrumbs: [
        { title: 'Dashboard', link: '' },
        { title: 'Programas', link: 'programs' },
        { title: 'Registro de ações', link: 'programs/assessments/actions' },
        ...(breadcrumbDetail ? [{ title: breadcrumbDetail }] : []),
      ],
    });
  };

  getCoacheeName = coacheeId => {
    const coachee = this.state.coachees.find(
      coachee => coachee._id === coacheeId
    );
    return coachee && coachee.name;
  };

  getGroupName = groupId => {
    const group = this.state.groups.find(group => group._id === groupId);
    return group && group.name;
  };

  getBreadcrumbDetail = () => {
    const { session } = this.state;

    if (session.coachee_id) return this.getCoacheeName(session.coachee_id);
    if (session.group_id) return this.getGroupName(session.group_id);
  };

  resolveParams = async () => {
    const { session } = this.state;
    const { sessionId } = this.props;

    const __sessionID = session._id || sessionId;

    if (__sessionID) {
      const result = await this.service.sessionG(__sessionID);

      if (result && result.success) {
        this.props.updateSession(__sessionID);
      }

      this.loadSession(__sessionID);
      this.loadSessionActions(__sessionID);
    }
  };

  setSidebarLinks(view) {
    this.setState({ view });
    this.goTo(`dashboard/${view}`);
  }

  handleResize() {
    let _ww =
      window.innerWidth < 890 && window.innerWidth > 570
        ? window.innerWidth - (window.innerWidth * 30) / 100
        : window.innerWidth <= 570
        ? window.innerWidth - (window.innerWidth * 40) / 100
        : window.innerWidth - (window.innerWidth * 40) / 100;

    this.setState({
      windowWidth: _ww,
    });
  }

  logout() {
    this.utils.setLogout();
    this.goTo('');
  }

  parseSession = session => {
    const { coachee_id, project, group_id, its_group, _id } = session;
    return { coachee_id, project, group_id, its_group, _id };
  };

  loadSession = async sessionId => {
    // if (!sessionId) {
    //   this.goTo('dashboard/programs/assessments');
    //   return;
    // }

    this.setState({ loading: true });
    const result = await this.service.sessionG(sessionId);
    if (result && result.success && result.session) {
      this.setState({
        session: this.parseSession(result.session),
      });
    }
    this.setState({ loading: false });
  };

  async loadUser() {
    let result = await this.service.me();
    if (!result) {
      this.logout();
      return;
    }
    let user = {
      ...result.user,
    };
    if (user.image) {
      this.props.updateImage(user.image);
    }

    this.setState({ user });
  }

  async loadCoachees() {
    this.setState({ loading_coachee: true });
    let result = await this.service.coachees();
    if (result && result.success) {
      await this.setState({ coachees: result.coachees });
    }
    this.setState({ loading_coachee: false });
  }

  loadGroups = async () => {
    const { user } = this.state;
    if (!this.userHasGroupSessionRight(user)) return;

    this.setState({ loading_groups: true });
    let result = await this.service.groups();
    if (result && result.success) {
      const { groups } = result;
      this.setState({ groups });
    }
    this.setState({ loading_groups: false });
  };

  async loadSessionActions(sessionId) {
    this.setState({ loading: true });
    let result = await this.service.sessionActions(sessionId);
    if (result && result.success) {
      this.setState({ sessionActions: result.actions });
    }
    this.setState({ loading: false });
  }

  async taskYes(action) {
    const { sessionId } = this.props;
    if (sessionId) {
      const { coachee_id: coacheeId, action: actionNumber } = action;

      const result = await this.service.completeSessionAction(
        sessionId,
        coacheeId,
        actionNumber
      );

      if (result && result.success) {
        this.loadSessionActions(sessionId);
      }
    }
  }

  adicionarNovaAcao = () => {
    this.setState({ isEditingAction: false, editingAction: null }, () => {
      this.toggleModalAction();
    });
  };

  getGroupCoachees = () => {
    const { session, groups } = this.state;
    const group =
      session.group_id && groups.find(group => group._id === session.group_id);
    return group ? group.coachees : [];
  };

  userHasGroupSessionRight = user => {
    return (
      user &&
      user.plans &&
      user.plans.platform &&
      user.plans.platform.plan.group_sessions
    );
  };

  userHasRights = user => {
    const { session } = this.state;
    return (
      !session.its_group ||
      (session.its_group && this.userHasGroupSessionRight(user))
    );
  };

  loadSessionByGroupId = async group_id => {
    if (!group_id) return;
    const result = await this.service.sessionG(group_id);
    if (result && result.success) {
      const { session } = result;

      if (session) {
        this.setState({
          session: this.parseSession(result.session),
        });

        this.setState({ isLoading: true }, () => {
          Promise.all([this.resolveParams(), this.loadCoachees()]).finally(
            () => {
              this.setBreadcrumbs();
              this.setState({ isLoading: false });
            }
          );
        });
      } else if (group_id) {
        const updateState = {
          group_id: group_id,
          coachee_id: '',
          oportunity_id: '',
        };
        this.setState(
          {
            session: { ...this.state.session, ...updateState },
          },
          () => {
            this.createSession();
          }
        );
      }
    }
  };

  createSession = async () => {
    this.setState({ loading: true });
    const {
      coachee_id,
      oportunity_id,
      group_id,
      its_group,
    } = this.state.session;

    const result = await this.service.createSession({
      coachee_id,
      oportunity_id,
      its_free: false,
      group_id,
      its_group,
    });

    if (result && result.success) {
      const { session } = result;

      this.setState(
        {
          session: this.parseSession(session),
        },
        async () => {
          this.setBreadcrumbs();
        }
      );
    }
    this.setState({ loading: false });
  };

  changeGroup = e => {
    this.loadSessionByGroupId(e.target.value);
  };

  render() {
    const {
      sideOpen,
      side,
      breadcrumbs,
      session,
      loading,
      tableActions,
      responseActions,
      tableTitles,
      sessionActions,
      showModalActions,
      showModalActionDelete,
      editingAction,
      isEditingAction,
      groups,
      selectedCoachees,
      user,
      isLoading,
    } = this.state;
    return (
      <div className="main-content">
        <Header
          goTo={this.goTo}
          sideOpen={sideOpen}
          toggleMenu={this.toggleMenu}
        />
        <div className="content-climb user bodyClimby">
          <div
            className={this.props.menu ? 'content-menu closed' : 'content-menu'}
          >
            <Sidebar
              side={this.state.side}
              setSidebarLinks={this.setSidebarLinks}
              expanded={!this.state.sideOpen}
            />
          </div>
          <div
            className={this.props.menu ? 'content-dash closed' : 'content-dash'}
          >
            <div className="dash-content">
              <div className="dash-crumb-filter">
                <Breadcrumbs breadcrumbs={breadcrumbs} />
              </div>
              {isLoading ? (
                <ReactLoading type="bubbles" color="#B1CD49" />
              ) : !this.userHasRights(user) ? (
                <NotAllowed go={this.goTo.bind(this)} user={user} />
              ) : (
                <div className="dashboard-boxes">
                  <div className="dashboard-box box100 align-select-group">
                    <div style={{ display: 'flex' }}>
                      <span
                        className="box-title row-center"
                        style={{
                          alignItems: 'center',
                          width: '100%',
                        }}
                      >
                        Registro de Ações
                      </span>

                      <div
                        className="form-input"
                        style={{
                          width: '45%',
                          padding: '0 20px',
                        }}
                      >
                        <label className="blue-label">Área</label>
                        {this.state.loading_groups ? (
                          <ReactLoading type="bubbles" color="#B1CD49" />
                        ) : (
                          <select
                            value={session.group_id}
                            className="blue-input"
                            onChange={e => {
                              this.changeGroup(e);
                            }}
                          >
                            <option value=""> &nbsp; Área</option>
                            {groups.map((group, key) => {
                              return (
                                <option key={group._id} value={group._id}>
                                  {' '}
                                  &nbsp; {group.name}
                                </option>
                              );
                            })}
                          </select>
                        )}
                      </div>
                    </div>

                    {session._id && (
                      <CardGenericTable
                        noBox
                        id="session-actions-1"
                        title="REGISTRO DE AÇÕES"
                        response_actions={responseActions}
                        actions={tableActions}
                        title_rows={[
                          ...(session.its_group
                            ? [
                                {
                                  title: 'Membro',
                                  key: 'coacheeName',
                                },
                              ]
                            : []),
                          ...tableTitles,
                        ]}
                        rows={
                          sessionActions && sessionActions.length > 0
                            ? sessionActions
                                .map(action => {
                                  return {
                                    ...action,
                                    coacheeName: action.coachee
                                      ? action.coachee.name
                                      : '',
                                  };
                                })
                                .sort(sortActions)
                            : []
                        }
                      />
                    )}
                  </div>

                  {session._id && (
                    <div className="row-button" style={{ padding: '7px 16px' }}>
                      {loading ? (
                        <ReactLoading type="bubbles" color="#00838F" />
                      ) : (
                        <div
                          className="bt-blue noMargin"
                          onClick={() => {
                            this.adicionarNovaAcao();
                          }}
                        >
                          <img
                            src={require('./../../../assets/images/plus.svg')}
                            style={{ marginTop: -3, marginRight: 14 }}
                          />
                          Adicionar Ação
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}

              <Footer />
            </div>
          </div>
        </div>

        <ModalActions
          toggleModalAction={this.toggleModalAction}
          saveAction={this.saveAction}
          modal_actions={showModalActions}
          typeAcao={isEditingAction}
          editingAction={editingAction}
          coachees={this.getGroupCoachees()}
          selectedCoachees={selectedCoachees}
          toggleCoachee={this.toggleCoachee}
          selectAllCoachee={() =>
            this.setState({ selectedCoachees: [...this.getGroupCoachees()] })
          }
          selectNoneCoachee={() => this.setState({ selectedCoachees: [] })}
        />

        <ModalActionDelete
          close={() => {
            this.setState({ showModalActionDelete: false });
          }}
          open={showModalActionDelete}
          deleteContent={this.deleteAction}
          title={`Confirmar deleção?`}
        />
      </div>
    );
  }
}
const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  sessionId: state.simpleReducer.sessionId,
  menu: state.simpleReducer.menu,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateImage, updateSession }, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ActionsRegister)
);
