import ApiService from '../../services/ApiService';

class Service extends ApiService {
  async oportunities() {
    try {
      let response = await fetch(`${this.endPoint}oportunities`, {
        method: 'GET',
        headers: {
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async oportunity(_id) {
    try {
      let response = await fetch(`${this.endPoint}oportunities/${_id}`, {
        method: 'GET',
        headers: {
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async oportunitiesFree() {
    try {
      let response = await fetch(`${this.endPoint}oportunities/free`, {
        method: 'GET',
        headers: {
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async oportunitiesMaster() {
    try {
      let response = await fetch(`${this.endPoint}oportunities/master`, {
        method: 'GET',
        headers: {
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async saveOportunity(_payload) {
    try {
      let response = await fetch(`${this.endPoint}oportunities`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
        body: this.parsePayload(_payload),
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async updateOportunity(_payload, _id) {
    try {
      let response = await fetch(`${this.endPoint}oportunities/${_id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
        body: this.parsePayload(_payload),
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async removeOportunity(_id) {
    try {
      let response = await fetch(`${this.endPoint}oportunities/${_id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async postFile(fileToUpload, notConvert = false) {
    const formData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    formData.append('notConvert', String(notConvert));
    try {
      let response = await fetch(`${this.endPoint}upload/file`, {
        method: 'POST',
        headers: {
          Authorization: `JWT ${this.getToken()}`,
        },
        body: formData,
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }
  async convertFileDocx(_payload) {
    try {
      let response = await fetch(`${this.endPoint}upload/convert/docx`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
        body: this.parsePayload(_payload),
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async categories() {
    try {
      let response = await fetch(`${this.endPoint}categories`, {
        method: 'GET',
        headers: {
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async saveContent(_payload) {
    try {
      let response = await fetch(`${this.endPoint}admin/contents`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
        body: this.parsePayload(_payload),
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async updateContent(_payload, _id) {
    try {
      let response = await fetch(`${this.endPoint}admin/contents/${_id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
        body: this.parsePayload(_payload),
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async contents() {
    try {
      let response = await fetch(`${this.endPoint}admin/contents`, {
        method: 'GET',
        headers: {
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async actions() {
    try {
      let response = await fetch(`${this.endPoint}admin/actions`, {
        method: 'GET',
        headers: {
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async saveAction(_payload) {
    try {
      let response = await fetch(`${this.endPoint}admin/actions`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
        body: this.parsePayload(_payload),
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async sessions() {
    try {
      let response = await fetch(`${this.endPoint}acoachee/sessions`, {
        method: 'GET',
        headers: {
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async content(_id) {
    try {
      let response = await fetch(`${this.endPoint}admin/contents/${_id}`, {
        method: 'GET',
        headers: {
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async contentsFolder(_payload) {
    try {
      let response = await fetch(`${this.endPoint}contents/relatory/folder`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
        body: this.parsePayload(_payload),
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async contentsRel() {
    try {
      let response = await fetch(`${this.endPoint}contents/relatory`, {
        method: 'GET',
        headers: {
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async contentsClimb() {
    try {
      let response = await fetch(`${this.endPoint}contents/all`, {
        method: 'GET',
        headers: {
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async removeContent(_id) {
    try {
      let response = await fetch(`${this.endPoint}contents/${_id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async session(oportunity_id) {
    try {
      let response = await fetch(
        `${this.endPoint}sessions/oportunity/${oportunity_id}`,
        {
          method: 'GET',
          headers: {
            Authorization: `JWT ${this.getToken()}`,
          },
        }
      );
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }
  async sessionC(oportunity_id) {
    try {
      let response = await fetch(
        `${this.endPoint}sessions/coachee/${oportunity_id}`,
        {
          method: 'GET',
          headers: {
            Authorization: `JWT ${this.getToken()}`,
          },
        }
      );
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async createSession(_payload) {
    try {
      let response = await fetch(`${this.endPoint}sessions`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
        body: JSON.stringify(_payload),
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async updateSession(_payload, _id) {
    try {
      let response = await fetch(`${this.endPoint}sessions/${_id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
        body: this.parsePayload(_payload),
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async removeSession(_id) {
    try {
      let response = await fetch(`${this.endPoint}sessions/${_id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async modelProposals() {
    try {
      let response = await fetch(`${this.endPoint}model/proposals`, {
        method: 'GET',
        headers: {
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async calendar() {
    try {
      let response = await fetch(`${this.endPoint}acoachee/calendar`, {
        method: 'GET',
        headers: {
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async proposals() {
    try {
      let response = await fetch(`${this.endPoint}acoachee/proposals`, {
        method: 'GET',
        headers: {
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async proposal(_id) {
    try {
      let response = await fetch(`${this.endPoint}acoachee/proposal/${_id}`, {
        method: 'GET',
        headers: {
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async saveProposal(_payload) {
    try {
      let response = await fetch(`${this.endPoint}proposals`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
        body: this.parsePayload(_payload),
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async updateProposal(_payload, _id) {
    try {
      let response = await fetch(`${this.endPoint}proposals/${_id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
        body: this.parsePayload(_payload),
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async removeProposal(_id) {
    try {
      let response = await fetch(`${this.endPoint}proposals/${_id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async search(_payload) {
    try {
      let response = await fetch(`${this.endPoint}search`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
        body: this.parsePayload(_payload),
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async coacheeCreateMessages(_pay) {
    try {
      let response = await fetch(
        `${this.endPoint}acoachee/chat/message/coachee`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
            Authorization: `JWT ${this.getToken()}`,
          },
          body: this.parsePayload(_pay),
        }
      );
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async coacheeMessages() {
    try {
      let response = await fetch(`${this.endPoint}acoachee/chat/messages/me`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async dashboard() {
    try {
      let response = await fetch(`${this.endPoint}admin/dashboard`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async loginAdmin(_payload) {
    try {
      let response = await fetch(`${this.endPoint}admins/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
        body: this.parsePayload(_payload),
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async updateCoach(_payload) {
    try {
      let response = await fetch(`${this.endPoint}admin/update/coach`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
        body: JSON.stringify(_payload),
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }
}

export default Service;
