import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import logo from '../../logo.svg';
import '../../styles/css/style.css';
import '../../styles/css/dashboard.css';

import { Container, Row, Col, Nav, NavItem, NavLink } from 'reactstrap';

import { simpleAction, updateImage } from '../../actions/SimpleAction';
// import Topbar from '../topbar/Topbar';

import Sidebar from '../sidebar/Sidebar';

import Header from './../components/Header';
import Breadcrumbs from './../components/Breadcrumbs';
import PeriodiceFilter from './../components/PeriodiceFilter';

import CardGenericTable from './../../screens_dashboard/components/CardGenericTable';

import Footer from './../components/Footer';

import Service from './Service.js';
import Utils from './../../libs/Utils.js';

import { AreaChart, BarChart } from 'react-easy-chart';

class Dashboard extends Component {
  constructor(props, context) {
    super(props);

    const initialWidth = window.innerWidth > 0 ? window.innerWidth : 500;

    this.state = {
      isVisible: false,
      sideOpen: false,
      principalOpen: true,
      view: '',
      side: 'dash',
      breadcrumbs: [{ title: 'Dashboard', link: '' }],
      showToolTip: false,
      windowWidth: initialWidth - (window.innerWidth * 40) / 100,

      user: {},

      rows: [],
      titles: [
        { title: 'Projeto', key: '_coachee' },
        { title: 'Data', key: 'date' },
        { title: 'Título', key: 'title' },
        //{ title: "Status", key: "taks_status" }
        // { title: "Concluir", key: "_response_status" }
        // { title: "Ações", key: 'options' }
      ],
      actions: [
        {
          title: 'Visualizar',
          icon: 'fas fa-eye',
          action: this.see.bind(this),
        },
      ],

      rows1: [],
      titles1: [
        { title: 'Data', key: 'start' },
        { title: 'Assunto', key: 'title' },
      ],

      rows2: [],
      titles2: [
        { title: 'Enviado de', key: '_from' },
        { title: 'Data', key: 'date' },
        { title: 'Enviado a', key: '_to' },
        { title: 'Assunto', key: 'message' },
      ],

      rows3: [],
      titles3: [
        { title: 'Membro', key: '_coachee' },
        { title: 'Data Limite', key: 'udate' },
        { title: 'Ação', key: 'title' },
      ],

      response_actions2: [{ action: this.taskYes.bind(this) }],
    };

    this.utils = new Utils();
    this.service = new Service();
    this.handleResize = this.handleResize.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.togglePrincipal = this.togglePrincipal.bind(this);
    this.setSidebarLinks = this.setSidebarLinks.bind(this);
    this.goTo = this.goTo.bind(this);
  }

  toggleMenu() {
    if (this.state.sideOpen) {
      this.setState({ sideOpen: false });
    } else {
      this.setState({ sideOpen: true });
    }
  }

  togglePrincipal() {
    if (this.state.principalOpen) {
      this.setState({ principalOpen: false });
    } else {
      this.setState({ principalOpen: true });
    }
  }

  goTo(page) {
    this.props.history.push(`/${page}`);
  }

  verifyLogin() {
    if (!this.utils.isLogged()) {
      this.goTo('');
    }
  }

  componentDidMount() {
    this.verifyLogin();
    this.loadUser();
    this.loadDash();
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  setSidebarLinks(view) {
    this.setState({ view });
    this.goTo(`users/dashboard/${view}`);
  }

  handleResize() {
    let _ww =
      window.innerWidth < 890 && window.innerWidth > 570
        ? window.innerWidth - (window.innerWidth * 30) / 100
        : window.innerWidth <= 570
        ? window.innerWidth - (window.innerWidth * 40) / 100
        : window.innerWidth - (window.innerWidth * 40) / 100;

    this.setState({
      windowWidth: _ww,
    });
  }

  logout() {
    this.utils.setLogout();
    this.goTo('');
  }

  async loadUser() {
    let result = await this.service.meCoachee();
    if (!result) {
      this.logout();
      return;
    }
    let user = {
      ...result.user,
    };
    if (user.image) {
      this.props.updateImage(user.image);
    }
    this.setState({ user });
  }

  async loadDash() {
    let result = await this.service.dashboard();
    if (result && result.success) {
      let tasks = [];
      await result.dash.tasks.map(t => {
        if (t.status === 'true') {
          tasks.push(t);
        }
      });
      await this.setState({
        rows: tasks,
        rows1: result.dash.week,
        rows2: result.dash.messages,
        rows3: result.dash.last,
      });
    }
  }

  async taskYes(item) {
    let result = await this.service.updateTask({
      key: item.key,
      type: item.from,
      status: 'complete',
      session_id: item.session_id,
    });

    if (result && result.success) {
      this.loadDash();
    }
  }

  see(item) {
    let url;
    if (item.doc_format.toLowerCase() === 'link') {
      url = item.doc_source;
    } else {
      url = this.utils.resolveURL(item.doc_source, this.service.getServer());
    }
    window.open(url);
  }

  openTasks(content) {
    this.goTo(`users/dashboard/programs/contents/${content.session_id}`);
  }

  render() {
    return (
      <div className="main-content">
        <Header
          goTo={this.goTo}
          sideOpen={this.state.sideOpen}
          toggleMenu={this.toggleMenu}
        />
        <div className="content-climb user bodyClimby">
          <div
            className={this.props.menu ? 'content-menu closed' : 'content-menu'}
          >
            <Sidebar
              side={this.state.side}
              setSidebarLinks={this.setSidebarLinks}
              expanded={!this.state.sideOpen}
            />
          </div>
          <div
            className={this.props.menu ? 'content-dash closed' : 'content-dash'}
          >
            <div className="dash-content">
              <div className="dash-crumb-filter">
                <Breadcrumbs breadcrumbs={this.state.breadcrumbs} />
                {/*<PeriodiceFilter filtred={() => {  }} filter={ "Semestral" } />*/}
              </div>
              <div className="dashboard-boxes" style={{ paddingRight: 20 }}>
                <Col md={{ size: '12' }} lg={{ size: '7' }}>
                  <div className="m--15 normalize-card">
                    <CardGenericTable
                      id="coachees-dashboard-dashboard-5"
                      title="Últimas ações"
                      className="scrollOp"
                      state={this.state}
                      title_rows={this.state.titles3}
                      rows={this.state.rows3}
                    />
                  </div>
                </Col>

                <Col md={{ size: '12' }} lg={{ size: '5' }}>
                  <div className="m--15 normalize-card">
                    <CardGenericTable
                      id="coachees-dashboard-dashboard-3"
                      title="Agenda da semana"
                      className="scrollOp2"
                      state={this.state}
                      title_rows={this.state.titles1}
                      rows={this.state.rows1}
                    />
                  </div>
                </Col>

                <Col md={{ size: '12' }} lg={{ size: '7' }}>
                  <div className="m--15 normalize-card">
                    <CardGenericTable
                      id="coachees-dashboard-dashboard-2"
                      title="Conteúdo compartilhado"
                      className="scrollOp"
                      state={this.state}
                      response_actions={this.state.response_actions2}
                      actions={this.state.actions}
                      title_rows={this.state.titles}
                      rows={this.state.rows}
                      row_action={this.openTasks.bind(this)}
                    />
                  </div>
                </Col>

                {/* TODO: habilitar quando tiver mensagens */}
                {/* <Col md={{ size: '12' }} lg={{ size: '5' }}>
                  <div className="m--15 normalize-card">
                    <CardGenericTable
                      id="coachees-dashboard-dashboard-4"
                      title="Mensagens"
                      state={this.state}
                      title_rows={this.state.titles2}
                      rows={this.state.rows2}
                    />
                  </div>
                </Col> */}

                {/*<CardTasks tasks={ this.state.tasks } />
                <CardWeekCalendar week_calendar={ this.state.week_calendar } />
                <CardMessages messages={ this.state.messages } />
                <CardLastActions last_actions={ this.state.last_actions } /> */}
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  menu: state.simpleReducer.menu,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateImage }, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Dashboard)
);
