export const AdequacyStatus = {
  NAO_INICIADO: 'não iniciado',
  EM_ANDAMENTO: 'em andamento',
  ADEQUADO: 'adequado',
  NAO_APLICAVEL: 'não aplicável',
};

export const sortArticles = (a, b) => {
  if (
    a.status === AdequacyStatus.NAO_APLICAVEL &&
    b.status === AdequacyStatus.NAO_APLICAVEL
  )
    return 0;
  if (
    b.status === AdequacyStatus.NAO_APLICAVEL &&
    a.status !== AdequacyStatus.NAO_APLICAVEL
  )
    return -1;
  return 1;
};
