import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Container, Row, Col, Nav, NavItem, NavLink } from 'reactstrap';

import { simpleAction } from '../../actions/SimpleAction';
// import Avatar from './components/avatar/Avatar';
// import Logo from './components/logo/Logo';

class Sidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isVisible: false,
    };
  }

  render() {
    return (
      <ul className="side-menu menuLateral">
        <li
          className={this.props.side == 'dash' ? 'active' : ''}
          onClick={() => {
            this.props.setSidebarLinks('');
          }}
        >
          <img
            src={require('./../../assets/images/menu/icon-0.svg')}
            width="28"
          />
          <img
            src={require('./../../assets/images/menu/icon-0w.svg')}
            width="28"
            className="image-over"
          />
          <span>Dashboard</span>
        </li>
        <li
          className={this.props.side == 'admins' ? 'active' : ''}
          onClick={() => {
            this.props.setSidebarLinks('admins');
          }}
        >
          <img
            src={require('./../../assets/images/menu/icon-9.svg')}
            width="28"
          />
          <img
            src={require('./../../assets/images/menu/icon-9w.svg')}
            width="28"
            className="image-over"
          />
          <span>Administradores</span>
        </li>
        <li
          className={this.props.side == 'coachs' ? 'active' : ''}
          onClick={() => {
            this.props.setSidebarLinks('coachs');
          }}
        >
          <img
            src={require('./../../assets/images/menu/icon-8.svg')}
            width="28"
          />
          <img
            src={require('./../../assets/images/menu/icon-8w.svg')}
            width="28"
            className="image-over"
          />
          <span>Coaches</span>
        </li>
        <li
          className={this.props.side == 'coachees' ? 'active' : ''}
          onClick={() => {
            this.props.setSidebarLinks('coachees');
          }}
        >
          <img
            src={require('./../../assets/images/menu/icon-1.svg')}
            width="28"
          />
          <img
            src={require('./../../assets/images/menu/icon-1w.svg')}
            width="28"
            className="image-over"
          />
          <span>Coachees</span>
        </li>
        <li
          className={this.props.side == 'projects' ? 'active' : ''}
          onClick={() => {
            this.props.setSidebarLinks('projects');
          }}
        >
          <img
            src={require('./../../assets/images/menu/icon-8.svg')}
            width="28"
          />
          <img
            src={require('./../../assets/images/menu/icon-8w.svg')}
            width="28"
            className="image-over"
          />
          <span>Projetos</span>
        </li>
        <li
          className={this.props.side == 'plans' ? 'active' : ''}
          onClick={() => {
            this.props.setSidebarLinks('plans');
          }}
        >
          <img
            src={require('./../../assets/images/menu/icon-10.svg')}
            width="28"
          />
          <img
            src={require('./../../assets/images/menu/icon-10w.svg')}
            width="28"
            className="image-over"
          />
          <span>Planos</span>
        </li>
        <li
          className={this.props.side == 'combos' ? 'active' : ''}
          onClick={() => {
            this.props.setSidebarLinks('combos');
          }}
        >
          <img
            src={require('./../../assets/images/menu/icon-10.svg')}
            width="28"
          />
          <img
            src={require('./../../assets/images/menu/icon-10w.svg')}
            width="28"
            className="image-over"
          />
          <span>Combos de Planos</span>
        </li>
        <li
          className={this.props.side == 'coupons' ? 'active' : ''}
          onClick={() => {
            this.props.setSidebarLinks('coupons');
          }}
        >
          <img
            src={require('./../../assets/images/finances/sub-2.svg')}
            width="28"
          />
          <img
            src={require('./../../assets/images/finances/sub-2w.svg')}
            width="28"
            className="image-over"
          />
          <span>Cupons de Desconto</span>
        </li>
        <li
          className={this.props.side == 'categories' ? 'active' : ''}
          onClick={() => {
            this.props.setSidebarLinks('categories');
          }}
        >
          <img
            src={require('./../../assets/images/finances/sub-2.svg')}
            width="28"
          />
          <img
            src={require('./../../assets/images/finances/sub-2w.svg')}
            width="28"
            className="image-over"
          />
          <span>Categorias</span>
        </li>

        <li
          className={this.props.side == 'institutions' ? 'active' : ''}
          onClick={() => {
            this.props.setSidebarLinks('institutions');
          }}
        >
          <img
            src={require('./../../assets/images/finances/sub-2.svg')}
            width="28"
          />
          <img
            src={require('./../../assets/images/finances/sub-2w.svg')}
            width="28"
            className="image-over"
          />
          <span>Instituições Parceiras</span>
        </li>

        <li
          className={this.props.side == 'contents' ? 'active' : ''}
          onClick={() => {
            this.props.setSidebarLinks('contents');
          }}
        >
          <img
            src={require('./../../assets/images/contents/icon-1.svg')}
            width="28"
          />
          <img
            src={require('./../../assets/images/contents/icon-1w.svg')}
            width="28"
            className="image-over"
          />
          <span>Conteúdo Climby</span>
        </li>
        <li
          className={this.props.side == 'notifications' ? 'active' : ''}
          onClick={() => {
            this.props.setSidebarLinks('notifications');
          }}
        >
          <img
            src={require('./../../assets/images/menu/icon-3.svg')}
            width="28"
          />
          <img
            src={require('./../../assets/images/menu/icon-3w.svg')}
            width="28"
            className="image-over"
          />
          <span>Notificações</span>
        </li>
        {/*<li className={ this.props.side == 'faqs' ? "active" : "" }
            onClick={ () => { this.props.setSidebarLinks('faqs'); } } >
          <img src={ require('./../../assets/images/menu/icon-7.svg') } width="28" /> 
          <img src={ require('./../../assets/images/menu/icon-7w.svg') } width="28" className="image-over"  /> 
          <span>Central de ajuda</span>
        </li>*/}
        <li
          className={this.props.side == 'configs' ? 'active' : ''}
          onClick={() => {
            this.props.setSidebarLinks('configs');
          }}
        >
          <img
            src={require('./../../assets/images/menu/icon-6.svg')}
            width="28"
          />
          <img
            src={require('./../../assets/images/menu/icon-6w.svg')}
            width="28"
            className="image-over"
          />
          <span>Geral</span>
        </li>
      </ul>
    );
  }
}
const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch => ({
  simpleAction: () => dispatch(simpleAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
