import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import logo from '../../logo.svg';

import '../../styles/css/style.css';
import '../../styles/css/dashboard.css';

import { Container, Row, Col, Nav, NavItem, NavLink } from 'reactstrap';

import {
  simpleAction,
  updateImage,
  updateAlert,
} from '../../actions/SimpleAction';

import ReactLoading from 'react-loading';

import 'jodit';
import 'jodit/build/jodit.min.css';
import JoditEditor from 'jodit-react';

// import { composeDecorators } from 'draft-js-plugins-editor';

// import createResizeablePlugin from 'draft-js-resizeable-plugin';
// import createFocusPlugin from 'draft-js-focus-plugin';
// import createBlockDndPlugin from 'draft-js-drag-n-drop-plugin';

// // import createColorBlockPlugin from './colorBlockPlugin';

// import { Editor } from 'react-draft-wysiwyg';

// import {  CompositeDecorator,
//           ContentBlock,
//           ContentState,
//           EditorState ,
//           convertFromHTML,
//           convertFromRaw,
//           convertToRaw
//         } from 'draft-js';

// import { convertToHTML } from 'draft-convert';

// import './../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
// import './../../../node_modules/draft-js-resizeable-plugin/lib/plugin.css';

import Service from './../dashboard/Service.js';
import Utils from './../../libs/Utils.js';

// const focusPlugin = createFocusPlugin();
// const resizeablePlugin = createResizeablePlugin();

// const decorator = composeDecorators(
//   resizeablePlugin.decorator,
//   focusPlugin.decorator,
//   createBlockDndPlugin.decorator,
// );

// const colorBlockPlugin = createColorBlockPlugin({ decorator });
// const plugins = [ focusPlugin, resizeablePlugin, createBlockDndPlugin ];

const service = new Service();

class CustomEditor extends Component {
  constructor(props, context) {
    super(props);

    this.state = { content: 'content' };

    this.utils = new Utils();
  }

  componentDidMount() {}
  componentWillUnmount() {}

  onEditorStateChange(editorState) {
    this.props.setState({
      editorState,
    });
  }

  updateContent = value => {
    this.setState({ content: value });
  };

  uploadImageCallBack(file) {
    return new Promise(async (resolve, reject) => {
      let result = await service.postFile(file);
      resolve({
        data: {
          link: this.utils.resolveURL(result.source),
          type: 'image/png',
        },
      });
    });
  }

  /**
   * @property Jodit jodit instance of native Jodit
   */
  jodit;
  setRef = jodit => (this.jodit = jodit);

  config = {
    ...this.props.config,
    enableDragAndDropFileToEditor: true,
    uploader: {
      insertImageAsBase64URI: true,
    },
    minHeight: 550,
    height:
      this.props.state &&
      this.props.state.height !== undefined && this.props.state.height !== null
        ? this.props.state.height
        : 'auto',
    language: 'pt_br',
    enter: 'P',
    defaultMode: '1',
    toolbarStickyOffset: null,
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
    readonly: false,
    width: this.props.width ? this.props.width : null,
    minWidth: this.props.minWidth ? this.props.minWidth : null,
  };
  /* <Editor
          state={this.state}
          editorState={this.props.state.editorState}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName"
          toolbar={{
              image: { uploadCallback: this.uploadImageCallBack.bind(this), alt: { present: false, mandatory: false } , previewImage:true },
            }}
            plugins={plugins}
          onEditorStateChange={this.onEditorStateChange.bind(this)} /> */

  render() {
    return (
      <JoditEditor
        editorRef={this.setRef}
        value={this.props.state.editorState}
        config={this.config}
        onChange={this.onEditorStateChange.bind(this)}
      />
    );
  }
}

const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  menu: state.simpleReducer.menu,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateImage, updateAlert }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CustomEditor);
