import { SIMPLE_ACTION } from '../actions/SimpleAction';
import {
  SW_INIT,
  SW_UPDATE,
  UPDATE_CHRONOMETER,
  UPDATE_CHRONOMETER_SESSIONS,
  UPDATE_ACTIVE_MEETING_CHRONOMETER,
  UPDATE_URL_VIDEO_SESSION,
  UPDATE_COUNTRIES,
} from '../consts/reduxTypes';

const initialState = {
  menu: false,
  _from: '',
  alert: '',
  image: '',
  periodicy: 'Semestral',
  user: {},
  sessionId: undefined,
  videoSession: {
    isActive: false,
    type: '',
    roomName: '',
    userName: '',
    role: '',
    subject: '',
    userType: '',
    lobbyMessage: '',
  },
  showModalStartTestPeriod: false,
  serviceWorkerInitialized: false,
  serviceWorkerUpdated: false,
  serviceWorkerRegistration: null,
  chronometer: {},
  chronometerSession: {},
  activeMeetingChronometer: null,
  urlVideoSession: undefined,
  countries: [],
  projectId: '',
};

export const simpleReducer = (state = initialState, action) => {
  switch (action.type) {
    case SIMPLE_ACTION:
      return {
        result: action.payload,
      };
    case 'UPDATE_IMAGE':
      return {
        ...state,
        image: action.value,
      };
    case 'UPDATE_USER':
      return {
        ...state,
        user: action.value,
      };
    case 'UPDATE_SESSION':
      return {
        ...state,
        sessionId: action.value,
      };
    case 'UPDATE_MENU':
      return {
        ...state,
        menu: action.value,
      };
    case 'UPDATE_FROM':
      return {
        ...state,
        _from: action.value,
      };
    case 'UPDATE_ALERT':
      return {
        ...state,
        alert: action.value,
      };

    case 'MODAL_TUTORIAL_STATUS':
      return {
        ...state,
        tutorial_status: action.value,
      };

    case 'MODAL_TUTORIAL_POSICAO':
      return {
        ...state,
        tutorial_posicao: action.value,
      };

    case 'UPDATE_PERIODICY':
      return {
        ...state,
        periodicy: action.value,
      };

    case 'VIDEO_SESSION':
      return {
        ...state,
        videoSession: action.value,
      };

    case 'MODAL_START_TEST_PERIOD':
      return {
        ...state,
        showModalStartTestPeriod: action.value,
      };

    case SW_INIT:
      return {
        ...state,
        serviceWorkerInitialized: !state.serviceWorkerInitialized,
      };

    case SW_UPDATE:
      return {
        ...state,
        serviceWorkerUpdated: !state.serviceWorkerUpdated,
        serviceWorkerRegistration: action.payload,
      };

    case UPDATE_CHRONOMETER:
      return {
        ...state,
        chronometer: action.value,
      };

    case UPDATE_CHRONOMETER_SESSIONS:
      return {
        ...state,
        chronometerSession: action.value,
      };

    case UPDATE_ACTIVE_MEETING_CHRONOMETER:
      return {
        ...state,
        activeMeetingChronometer: action.value,
      };

    case UPDATE_URL_VIDEO_SESSION:
      return {
        ...state,
        urlVideoSession: action.value,
      };

    case UPDATE_COUNTRIES:
      return {
        ...state,
        countries: action.value,
      };

    case 'UPDATE_PROJECT_ID':
      return {
        ...state,
        projectId: action.value,
      };

    default:
      return state;
  }
};
