import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import '../../styles/css/dashboard.css';
import '../../styles/css/style.css';
import BoxOptions from './BoxOptions';

const CardGenericChartDoughnut = props => {
  const [visible, setVisible] = useState(true);
  const [widthChartDoughnut, setWidthChartDoughnut] = useState(0);
  const [widthScreen, setWidthScreen] = useState(0);

  function toggleVisibility() {
    if (!visible) {
      setClosed(`${props.id}`, '');
    } else {
      setClosed(`${props.id}`, 'closed');
    }
    setVisible(!visible);
  }

  function setClosed(_k, t) {
    let key = `climby::${_k}`;
    try {
      return localStorage.setItem(key, t);
    } catch (e) {
      return sessionStorage.setItem(key, t);
    }
  }

  function isClosed(_k) {
    let key = `climby::${_k}`;
    try {
      return (
        localStorage.getItem(key) !== null &&
        localStorage.getItem(key) !== 'null' &&
        localStorage.getItem(key) !== ''
      );
    } catch (e) {
      return (
        sessionStorage.getItem(key) !== null &&
        sessionStorage.getItem(key) !== 'null' &&
        sessionStorage.getItem(key) !== ''
      );
    }
  }

  function calcTotalWidthChart() {
    const _container = document.getElementById(`containerChartsDoughnut`);
    setWidthScreen(window.innerWidth);

    if (_container) {
      if (props.numberOfGraphsPerLine && window.innerWidth >= 1218) {
        const _w =
          Math.floor(_container.offsetWidth / props.numberOfGraphsPerLine) - 10;

        setWidthChartDoughnut(_w > 300 ? 300 : _w);
        return;
      } else if (window.innerWidth < 1218) {
        setWidthChartDoughnut(400);
      }
    }

    setWidthChartDoughnut(200);
  }

  useEffect(() => {
    calcTotalWidthChart();
    window.addEventListener('resize', calcTotalWidthChart);
    if (isClosed(`${props.id}`)) {
      setVisible(false);
    }

    return () => window.removeEventListener('resize', calcTotalWidthChart);
  }, []);

  return (
    <div className="dashboard-box box100">
      <div className="box-row-title">
        <div className="box-title">{props.title}</div>
        <BoxOptions visible={visible} toggleVisibility={toggleVisibility} />
      </div>
      {visible ? (
        <div
          className="containerMainChartDoughnut"
          style={{
            flexWrap: `${
              props.flexWrap || widthScreen < 1218 ? 'wrap' : 'nowarp'
            }`,
            justifyContent: `${
              props.numberOfGraphsPerLine <= props.dataChartDoughnut.length ||
              widthScreen < 1218
                ? 'center'
                : 'flex-start'
            }`,
          }}
        >
          {props.dataChartDoughnut &&
            props.dataChartDoughnut.length > 0 &&
            props.dataChartDoughnut.map(data => (
              <div
                key={data.titleChart}
                style={{
                  width:
                    widthScreen < 1218
                      ? widthChartDoughnut
                      : widthChartDoughnut / 1.2,
                  textAlign: 'center',
                }}
              >
                {(props.legendPosition === 'top' ||
                  props.legendPosition === '' ||
                  props.legendPosition === undefined) && (
                  <span className="titleChartDoughnut">{data.titleChart}</span>
                )}

                <div>
                  <Doughnut
                    width={widthChartDoughnut}
                    height={widthChartDoughnut}
                    data={data.dataChart}
                    legend={{
                      fullWidth: true,
                      labels: {
                        fontSize: 11,
                        fontColor: '#003343',
                        boxWidth: 20,
                      },
                    }}
                    options={{
                      cutoutPercentage: 50,
                      maintainAspectRatio: true,
                      responsive: true,
                      plugins: {
                        datalabels: {
                          formatter: val => val + '%',
                        },
                      },
                    }}
                  />
                </div>

                {data.subTitleChart && (
                  <span className="titleChartDoughnut">
                    {data.subTitleChart}
                  </span>
                )}

                {props.legendPosition === 'bottom' && (
                  <span
                    className="titleChartDoughnut"
                    style={{
                      display: 'flex',
                      marginTop: '20px',
                      justifyContent: 'center',
                      fontSize: '20px',
                    }}
                  >
                    {data.titleChart}
                  </span>
                )}
              </div>
            ))}
        </div>
      ) : null}
    </div>
  );
};

export default CardGenericChartDoughnut;

// class CardGenericChartDoughnut extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       visible: true,
//       widthChartDoughnut: 0,
//     };
//     this.utils = new Utils();
//   }

//   componentDidMount() {
//     this.calcTotalWidthChart();
//     window.addEventListener('resize', this.calcTotalWidthChart);
//     if (this.isClosed(`${this.props.id}`)) {
//       this.setState({ visible: false });
//     }
//   }

//   componentWillUnmount() {
//     window.removeEventListener('resize', this.calcTotalWidthChart);
//   }

//   toggleVisibility() {
//     if (!this.state.visible) {
//       this.setClosed(`${this.props.id}`, '');
//     } else {
//       this.setClosed(`${this.props.id}`, 'closed');
//     }
//     this.setState({ visible: !this.state.visible });
//   }

//   isClosed(_k) {
//     let key = `climby::${_k}`;
//     try {
//       return (
//         localStorage.getItem(key) !== null &&
//         localStorage.getItem(key) !== 'null' &&
//         localStorage.getItem(key) !== ''
//       );
//     } catch (e) {
//       return (
//         sessionStorage.getItem(key) !== null &&
//         sessionStorage.getItem(key) !== 'null' &&
//         sessionStorage.getItem(key) !== ''
//       );
//     }
//   }

//   setClosed(_k, t) {
//     let key = `climby::${_k}`;
//     try {
//       return localStorage.setItem(key, t);
//     } catch (e) {
//       return sessionStorage.setItem(key, t);
//     }
//   }

//   calcTotalWidthChart() {
//     console.log('passou aqui');
//     const _container = document.getElementById(`containerChartsDoughnut`);

//     console.log(_container.offsetWidth, this.state);

//     if (_container) {
//       if (this.props.numberOfGraphsPerLine) {
//         this.setState({
//           widthChartDoughnut:
//             Math.floor(
//               _container.offsetWidth / this.props.numberOfGraphsPerLine
//             ) - 10,
//         });

//         return;
//       }
//     }

//     this.setState({
//       widthChartDoughnut: 200,
//     });
//   }

//   render() {
//     const { dataChartDoughnut, legendPosition, flexWrap } = this.props;

//     return (
//       <div
//         className="dashboard-box box100"
//         // id="containerChartsDoughnut"
//         style={{ border: '1px solid red' }}
//       >
//         <div className="box-row-title">
//           <div className="box-title">{this.props.title}</div>
//           <BoxOptions
//             visible={this.state.visible}
//             toggleVisibility={this.toggleVisibility.bind(this)}
//           />
//         </div>
//         {this.state.visible ? (
//           <div
//             className="containerMainChartDoughnut"
//             style={{
//               flexWrap: `${flexWrap ? 'wrap' : 'nowarp'}`,
//             }}
//           >
//             {dataChartDoughnut &&
//               dataChartDoughnut.length > 0 &&
//               dataChartDoughnut.map(data => (
//                 <div
//                   key={data.titleChart}
//                   style={{
//                     width: this.state.widthChartDoughnut,
//                     textAlign: 'center',
//                   }}
//                 >
//                   {(legendPosition === 'top' ||
//                     legendPosition === '' ||
//                     legendPosition === undefined) && (
//                     <span className="titleChartDoughnut">
//                       {data.titleChart}
//                     </span>
//                   )}

//                   <div>
//                     <Doughnut
//                       height={this.state.widthChartDoughnut / 1.5}
//                       width={this.state.widthChartDoughnut / 1.5}
//                       data={data.dataChart}
//                       legend={{
//                         fullWidth: true,
//                         labels: {
//                           fontSize: 10,
//                           fontColor: '#003343',
//                           boxWidth: 20,
//                         },
//                       }}
//                       options={{
//                         maintainAspectRatio: false,
//                         responsive: true,
//                       }}
//                     />
//                   </div>

//                   {legendPosition === 'bottom' && (
//                     <span
//                       className="titleChartDoughnut"
//                       style={{
//                         display: 'flex',
//                         marginTop: '20px',
//                         justifyContent: 'center',
//                         fontSize: '20px',
//                       }}
//                     >
//                       {data.titleChart}
//                     </span>
//                   )}
//                 </div>
//               ))}
//           </div>
//         ) : null}
//       </div>
//     );
//   }
// }

// const mapStateToProps = state => ({
//   ...state,
// });

// const mapDispatchToProps = dispatch => ({
//   simpleAction: () => dispatch(simpleAction()),
// });

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(CardGenericChartDoughnut);
