import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import logo from '../../../logo.svg';
import '../../../styles/css/style.css';
import '../../../styles/css/dashboard.css';

import InputMask from 'react-input-mask';

import { Container, Row, Col, Nav, NavItem, NavLink } from 'reactstrap';

import {
  simpleAction,
  updateImage,
  updateAlert,
} from '../../../actions/SimpleAction';
// import Topbar from '../topbar/Topbar';

import Sidebar from './../../sidebar/Sidebar';

import ReactLoading from 'react-loading';

import Header from './../../components/Header';
import PrincipalMenu from './../../components/PrincipalMenu';
import Breadcrumbs from './../../components/Breadcrumbs';
import PeriodiceFilter from './../../components/PeriodiceFilter';

import RowStats from './../../components/RowStats';

import CardGenericArea from './../../components/CardGenericArea';

import Footer from './../../components/Footer';
import ClimbyEditor from './../../components/Editor';

import { Editor } from 'react-draft-wysiwyg';
import {
  CompositeDecorator,
  ContentBlock,
  ContentState,
  EditorState,
  convertFromHTML,
  convertFromRaw,
  convertToRaw,
} from 'draft-js';

import { convertToHTML } from 'draft-convert';

import './../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import Service from './../Service.js';
import Utils from './../../../libs/Utils.js';

import CurrencyInput from 'react-currency-input';

import { AreaChart, BarChart } from 'react-easy-chart';

class TemplateEditor extends Component {
  constructor(props, context) {
    super(props);

    const initialWidth = window.innerWidth > 0 ? window.innerWidth : 500;

    this.state = {
      isVisible: false,
      sideOpen: false,
      principalOpen: true,
      view: 'reports',
      side: 'my',
      breadcrumbs: [
        { title: 'Dashboard', link: '' },
        { title: 'Relatórios', link: 'reports' },
        { title: 'Editor de Relatórios', link: 'report-editor' },
      ],
      showToolTip: false,
      windowWidth: initialWidth - (window.innerWidth * 40) / 100,
      loading: false,

      loading_coachee: false,
      coachees: [],

      loading_contents: false,
      list_contents: [],

      contents: [],

      editorState: '',
      contentState: {},
      title: '',
      value: '',
      tag: '',
      maxLength: 3660,
      editing: false,
      editing_id: '',
      height: 20,
    };

    this.utils = new Utils();
    this.service = new Service();
    this.handleResize = this.handleResize.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.togglePrincipal = this.togglePrincipal.bind(this);
    this.setSidebarLinks = this.setSidebarLinks.bind(this);
    this.goTo = this.goTo.bind(this);
    this.save = this.save.bind(this);
  }

  toggleMenu() {
    if (this.state.sideOpen) {
      this.setState({ sideOpen: false });
    } else {
      this.setState({ sideOpen: true });
    }
  }

  togglePrincipal() {
    if (this.state.principalOpen) {
      this.setState({ principalOpen: false });
    } else {
      this.setState({ principalOpen: true });
    }
  }

  goTo(page) {
    this.props.history.push(`/${page}`);
  }

  verifyLogin() {
    if (!this.utils.isLogged()) {
      this.goTo('');
    }
  }

  componentDidMount() {
    this.verifyLogin();
    this.loadUser();
    this.resolveParams();
    window.addEventListener('resize', this.handleResize);
  }

  resolveParams() {
    const {
      match: { params },
    } = this.props;
    if (params.id) {
      let editing_id = params.id;
      this.setState({
        editing: true,
        editing_id,
      });
      this.load(editing_id);
    }
  }

  async load(_id) {
    this.setState({ loading: true });
    let result = await this.service.content(_id);
    if (result && result.success) {
      let _html =
        result.content && result.content.html ? result.content.html : '';
      // const editorState = this.utils.convertFromHtml(_html)
      if (result.content) {
        this.setState({
          title: result.content.title,
          editorState: _html,
          docFormat: result.content.doc_format,
          docSource: result.content.doc_source,
        });
      }
    }
    this.setState({ loading: false });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  setSidebarLinks(view) {
    this.setState({ view });
    this.goTo(`dashboard/${view}`);
  }

  handleResize() {
    let _ww =
      window.innerWidth < 890 && window.innerWidth > 570
        ? window.innerWidth - (window.innerWidth * 30) / 100
        : window.innerWidth <= 570
        ? window.innerWidth - (window.innerWidth * 40) / 100
        : window.innerWidth - (window.innerWidth * 40) / 100;

    this.setState({
      windowWidth: _ww,
    });
  }

  logout() {
    this.utils.setLogout();
    this.goTo('');
  }

  async loadUser() {
    let result = await this.service.me();
    if (!result) {
      this.logout();
      return;
    }
    let user = {
      ...result.user,
    };
    if (user.image) {
      this.props.updateImage(user.image);
    }
  }

  async save() {
    this.setState({ loading: true });
    // const html = this.utils.convertToHTML(convertToRaw(this.state.editorState.getCurrentContent()));
    let _pay = {
      html: this.state.editorState,
      title: this.state.title != undefined ? this.state.title : '',
      doc_format: this.state.docFormat ? this.state.docFormat : '',
      doc_source: this.state.docSource ? this.state.docSource : '',
    };
    let result = this.state.editing
      ? await this.service.updateContent(_pay, this.state.editing_id)
      : await this.service.saveContent(_pay);
    if (result && result.success) {
      this.props.updateAlert('Salvo com sucesso');
    }
    this.setState({ loading: false });
  }

  showPDF() {
    window.open(
      `${this.service.getServer()}pdf/contents/${this.state.editing_id}`
    );
  }

  render() {
    return (
      <div className="main-content">
        <Header
          goTo={this.goTo}
          sideOpen={this.state.sideOpen}
          toggleMenu={this.toggleMenu}
        />
        <div className="content-climb zIndex user bodyClimby">
          <div
            className={this.props.menu ? 'content-menu closed' : 'content-menu'}
          >
            <Sidebar
              side={this.state.side}
              setSidebarLinks={this.setSidebarLinks}
              expanded={!this.state.sideOpen}
            />
          </div>
          <div
            className={this.props.menu ? 'content-dash closed' : 'content-dash'}
          >
            <div className="dash-content zIndex">
              <div className="dash-crumb-filter">
                <Breadcrumbs breadcrumbs={this.state.breadcrumbs} />
              </div>
              <div className="dashboard-boxes">
                <div className="dashboard-box box100">
                  <Col xs={{ size: 12 }} md={{ size: 6, offset: 3 }}>
                    <div className="form-input input-title">
                      <label className="blue-label">Título</label>
                      <input
                        type="text"
                        value={this.state.title}
                        className="blue-input"
                        placeholder="Título"
                        onChange={e => {
                          this.setState({ title: e.target.value });
                        }}
                      />
                    </div>

                    <div className="box-row-title row-center">
                      <div className="box-title">Editor de Relatórios</div>
                    </div>
                  </Col>
                  <Col xs={{ size: 12 }} md={{ size: 10, offset: 1 }}>
                    <ClimbyEditor
                      setState={this.setState.bind(this)}
                      state={this.state}
                    />

                    <div className="row-center mtop-50 mbottom-50">
                      {this.state.loading ? (
                        <ReactLoading type="bubbles" color="#00838F" />
                      ) : (
                        <div style={{ display: 'flex' }}>
                          <div
                            className={true ? 'bt-blue' : 'bt-blue bt-disabled'}
                            onClick={() => {
                              this.save();
                            }}
                          >
                            {this.state.editing ? 'Salvar ' : 'Salvar'}
                          </div>

                          <div
                            style={{ marginLeft: 10 }}
                            className="bt-blue"
                            onClick={() => {
                              this.showPDF();
                            }}
                          >
                            Ver PDF
                          </div>
                        </div>
                      )}
                    </div>
                  </Col>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  menu: state.simpleReducer.menu,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateImage, updateAlert }, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TemplateEditor)
);
