import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import logo from '../../../logo.svg';
import '../../../styles/css/style.css';
import '../../../styles/css/dashboard.css';

import InputMask from 'react-input-mask';

import { Container, Row, Col, Nav, NavItem, NavLink } from 'reactstrap';

import {
  simpleAction,
  updateImage,
  updateSession,
} from '../../../actions/SimpleAction';
// import Topbar from '../topbar/Topbar';

import SidebarSessions from './../../sidebar/SidebarSessions';

import ReactLoading from 'react-loading';

import Header from './../../components/Header';
import PrincipalMenu from './../../components/PrincipalMenu';
import Breadcrumbs from './../../components/Breadcrumbs';
import PeriodiceFilter from './../../components/PeriodiceFilter';

import RowStats from './../../components/RowStats';

import CardGenericArea from './../../components/CardGenericArea';

import Footer from './../../components/Footer';
import ClimbyEditor from './../../components/Editor';
import Submenu from './SubmenuSession';

import { Editor } from 'react-draft-wysiwyg';
import {
  CompositeDecorator,
  ContentBlock,
  ContentState,
  EditorState,
  convertFromHTML,
  convertFromRaw,
  convertToRaw,
} from 'draft-js';

import { convertToHTML } from 'draft-convert';

import './../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import Service from './../Service.js';
import Utils from './../../../libs/Utils.js';

import { AreaChart, BarChart } from 'react-easy-chart';

class RelatoryEditor extends Component {
  constructor(props, context) {
    super(props);

    const initialWidth = window.innerWidth > 0 ? window.innerWidth : 500;

    this.state = {
      isVisible: false,
      sideOpen: false,
      principalOpen: true,
      view: 'programs',
      subview: 'relatory',
      side: 'assessments',
      breadcrumbs: [
        { title: 'Dashboard', link: '' },
        { title: 'Programas', link: 'programs' },
        { title: 'Sessão', link: 'programs/assessments' },
        { title: 'Relatório', link: 'programs/assessments/report-view' },
      ],
      showToolTip: false,
      windowWidth: initialWidth - (window.innerWidth * 40) / 100,
      loading: false,

      editing: false,
      editing_id: '',

      loading_coachee: false,
      coachees: [],

      loading_contents: false,
      list_contents: [],
      list_contents_rel: [],
      content_id: '',
      opened_toggle: '',

      categories: [],

      title: '',
      category: 'Relatórios de Coachees',
      tag: '',
      editorState: '',
      contentState: {},
      contents: [],

      maxLength: 3660,
      session_master: [],
    };

    this.utils = new Utils();
    this.service = new Service();

    this.handleResize = this.handleResize.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.togglePrincipal = this.togglePrincipal.bind(this);
    this.setSidebarLinks = this.setSidebarLinks.bind(this);
    this.goTo = this.goTo.bind(this);
  }

  toggleMenu() {
    if (this.state.sideOpen) {
      this.setState({ sideOpen: false });
    } else {
      this.setState({ sideOpen: true });
    }
  }

  togglePrincipal() {
    if (this.state.principalOpen) {
      this.setState({ principalOpen: false });
    } else {
      this.setState({ principalOpen: true });
    }
  }

  goTo(page) {
    this.props.history.push(`/${page}`);
  }

  verifyLogin() {
    if (!this.utils.isLogged()) {
      this.goTo('');
    }
  }

  componentDidMount() {
    this.verifyLogin();
    this.loadUser();

    this.resolveParams();
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  resolveParams() {
    const {
      match: { params },
    } = this.props;
    if (params.id) {
      this.changeContent(params.id);
    }
  }

  setSidebarLinks(view) {
    this.setState({ view });
    this.goTo(`dashboard/${view}`);
  }

  handleResize() {
    let _ww =
      window.innerWidth < 890 && window.innerWidth > 570
        ? window.innerWidth - (window.innerWidth * 30) / 100
        : window.innerWidth <= 570
        ? window.innerWidth - (window.innerWidth * 40) / 100
        : window.innerWidth - (window.innerWidth * 40) / 100;

    this.setState({
      windowWidth: _ww,
    });
  }

  logout() {
    this.utils.setLogout();
    this.goTo('');
  }

  async loadUser() {
    let result = await this.service.me();
    if (!result) {
      this.logout();
      return;
    }
    let user = {
      ...result.user,
    };
    if (user.image) {
      this.props.updateImage(user.image);
    }
  }

  async loadRelatory(rel_id) {
    this.setState({ loading: true });
    let result = await this.service.content(rel_id);
    if (result && result.success && result.content) {
      let source =
        result.content.doc_format.toLowerCase() == 'docx'
          ? result.content.html
          : result.content.doc_source;

      // Session master
      if (
        result.content.contents.filter(_content => {
          return _content.text;
        }).length > 0
      ) {
        source += this.htmlSpace();
        source += this.htmlH1('Reunião Inaugural');
        for (let _content of result.content.contents.filter(_content => {
          return _content.text;
        })) {
          source += _content.text ? this.htmlSessionItem(_content) : '';
        }
      }
      // Action
      if (
        result.content.contents.filter(_content => {
          return _content.action;
        }).length > 0
      ) {
        source += this.htmlSpace();
        source += this.htmlH1('Ações');
        for (let _content of result.content.contents.filter(_content => {
          return _content.action;
        })) {
          source += _content.action ? this.htmlAction(_content) : '';
        }
      }

      // Conteúdos
      if (
        result.content.contents.filter(_content => {
          return _content.doc_format;
        }).length > 0
      ) {
        source += this.htmlSpace();
        source += this.htmlH1('Conteudos');
        for (let _content of result.content.contents.filter(_content => {
          return _content.doc_format;
        })) {
          source +=
            _content.doc_format && _content.doc_format == 'LINK'
              ? this.htmlLink(_content)
              : _content.html
              ? this.htmlP(_content)
              : this.htmlContent(_content);
        }
      }

      const editorState = source;

      this.setState({
        title: result.content.title,
        tag: result.content.tag,
        editorState,
        contents: result.content.contents,
      });
    }
    this.setState({ loading: false });
  }

  changeContent(content_id) {
    this.setState({ content_id });
    this.loadRelatory(content_id);
  }

  htmlLink(item) {
    return `<p><a href="${item.doc_source}" target="new">${item.title}</a></p>`;
  }

  htmlSessionItem(item) {
    return `<p><b>${item.title}</b></p><p>${item.text}</p>`;
  }

  htmlP(item) {
    return `<p>${item.html}</p>`;
  }

  htmlAction(item) {
    return `<p><b>${this.utils.parseDateUSA(item.date)}</b></p><p>${
      item.description
    }</p>`;
  }

  htmlContent(item) {
    return `<p><a href="${this.utils.resolveURL(
      item.doc_source
    )}" target="new">${item.title}</a></p>`;
  }

  htmlH1(item) {
    return `<h2>${item}</h2>`;
  }

  htmlSpace() {
    return `<p>&nbsp;</p>`;
  }

  openPDF() {
    window.open(
      `${this.service.getServer()}generate/session/${this.state.content_id}`
    );
  }

  render() {
    return (
      <div className="main-content">
        <Header
          goTo={this.goTo}
          sideOpen={this.state.sideOpen}
          toggleMenu={this.toggleMenu}
        />
        <div className="content-climb zIndex user bodyClimby">
          <div
            className={this.props.menu ? 'content-menu closed' : 'content-menu'}
          >
            <SidebarSessions
              goTo={this.goTo}
              side={this.state.side}
              setSidebarLinks={this.setSidebarLinks}
            />
          </div>
          <div
            className={this.props.menu ? 'content-dash closed' : 'content-dash'}
          >
            <PrincipalMenu
              principalOpen={this.state.principalOpen}
              view={this.state.view}
              setSidebarLinks={this.setSidebarLinks}
              togglePrincipal={this.togglePrincipal}
            />

            <div className="dash-content zIndex dash-fixed">
              <div className="dash-crumb-filter">
                <Breadcrumbs breadcrumbs={this.state.breadcrumbs} />
              </div>
              <div className="dashboard-boxes">
                <div className="dashboard-box box100">
                  <Col xs={{ size: 12 }} sm={{ size: 12 }} md={{ size: 12 }}>
                    <div className="box-row-title row-center">
                      <div className="box-title">Relatório</div>
                    </div>
                    <ClimbyEditor
                      config={{ toolbar: false, readonly: true }}
                      setState={this.setState.bind(this)}
                      state={this.state}
                    />
                  </Col>

                  <Col
                    xs={{ size: 12 }}
                    sm={{ size: 12 }}
                    md={{ size: 5, offset: 2 }}
                  >
                    <div className="row-between mtop-50 mbottom-50">
                      <div
                        className="bt-blue"
                        onClick={() => {
                          this.openPDF();
                        }}
                      >
                        Gerar PDF
                      </div>
                    </div>
                  </Col>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  session: state.simpleReducer.session,
  menu: state.simpleReducer.menu,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateImage, updateSession }, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RelatoryEditor)
);
