export default [
  {
    menuName: 'Dashboard',
    menuLink: '',
    icon: 'dashboard',
  },
  {
    menuName: 'Cadastros',
    menuLink: 'users',
    icon: 'user',
    menuSubItems: [
      {
        menuName: 'Cadastro de Membros',
        menuLink: 'users',
        icon: 'user',
      },
      {
        menuName: 'Cadastro de Áreas',
        menuLink: 'groups',
        icon: 'group',
      },
    ],
  },
  {
    menuName: 'Agenda',
    menuLink: 'calendar',
    icon: 'calendar',
  },
  // TODO: Habilitar quando o chat estiver pronto
  // {
  //   menuName: 'Central de Mensagens',
  //   menuLink: 'chat',
  //   icon: 'comment',
  //   menuSubItems: [
  //     {
  //       menuName: 'Chat',
  //       menuLink: 'chat',
  //       icon: 'comment',
  //     },
  //     {
  //       menuName: 'Mensagens Personalizadas',
  //       menuLink: 'messages',
  //       icon: 'comments',
  //     },
  //   ],
  // },
  {
    menuName: 'Conteúdos',
    menuLink: 'contents',
    icon: 'detail',
    menuSubItems: [
      {
        menuName: 'Conteúdo',
        menuLink: 'contents',
        icon: 'id-info',
      },
      {
        menuName: 'Conteúdo Personalizado',
        menuLink: 'contents/custom',
        icon: 'id-info',
      },
    ],
  },
  {
    menuName: 'Programa',
    menuLink: 'programs',
    icon: 'tasks',
    menuSubItems: [
      {
        menuName: 'Reunião Inaugural',
        menuLink: 'programs/master',
        icon: 'order-form',
      },
      {
        menuName: 'Registro de Sessões',
        menuLink: 'programs/assessments/register',
        icon: 'user-analysis',
      },
      {
        menuName: 'Lista de Ações',
        menuLink: 'programs/assessments/actions',
        icon: 'list',
      },
      {
        menuName: 'Conteúdos Compartilhados',
        menuLink: 'programs/assessments/contents',
        icon: 'share-square-o',
      },
      {
        menuName: 'Lista de Processos',
        menuLink: 'programs/assessments/process-list',
        icon: 'list',
      },
      {
        menuName: 'Status Adequação',
        menuLink: 'adequacy-status',
        icon: 'twinkle-star',
      },
    ],
  },
  {
    menuName: 'Relatório',
    menuLink: 'reports',
    icon: 'file-text',
  },
  {
    menuName: 'Sair',
    icon: 'sign-out',
    menuLink: 'logout',
  },
];
