import React, { Component } from 'react';
import ReactLoading from 'react-loading';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { updateImage, updateSession } from '../../../actions/SimpleAction';
import '../../../styles/css/dashboard.css';
import '../../../styles/css/style.css';
import Utils from './../../../libs/Utils.js';
import Breadcrumbs from './../../components/Breadcrumbs';
import CardGenericTable from './../../components/CardGenericTable';
import Footer from './../../components/Footer';
import Header from './../../components/Header';
import ModalActionDelete from './../../components/NewModalCoacheeDelete';
import NotAllowed from './../../components/NotAllowed';
import Sidebar from './../../sidebar/Sidebar';
import Service from './../Service.js';
import ModalActions from './ModalActions';
import ModalProcess from './ModalProcess';
import {
  getDataProcessingHypothesesText,
  sortProcedures,
} from '../../../consts/procedure.js';

class ProcessList extends Component {
  constructor(props, context) {
    super(props);

    const initialWidth = window.innerWidth > 0 ? window.innerWidth : 500;

    this.state = {
      isVisible: false,
      sideOpen: this.props.menu,
      principalOpen: true,
      view: 'programs',
      subview: 'actions',
      side: 'assessments',
      breadcrumbs: [
        { title: 'Dashboard', link: '' },
        { title: 'Programas', link: 'programs' },
        {
          title: 'Lista de Processos',
          link: 'programs/assessments/process-list',
        },
      ],
      showToolTip: false,
      windowWidth: initialWidth - (window.innerWidth * 40) / 100,
      loading: false,

      rows: [],
      tableTitles: [
        { title: 'Processo', key: '' },
        { title: 'Arigo da Lei', key: '' },
        { title: 'Comentário', key: '' },
        { title: 'Status', key: '' },
        { title: 'Opções', key: 'options' },
      ],
      tableActions: [
        {
          title: 'Visualizar',
          icon: 'fas fa-eye',
          action: this.viewProcedure.bind(this),
        },
        {
          title: 'Deletar',
          icon: 'fas fa-trash',
          action: this.openModalDeleteProcedure.bind(this),
        },
      ],
      titleRows: [
        { title: 'Processo', key: 'process_description' },
        {
          title: 'Hipótese de Tratamento de Dados',
          key: 'data_processing_hypotheses_label',
        },
        { title: 'Comentário', key: 'comments' },
        { title: 'Status', key: 'process_status' },
        { title: 'Opções', key: 'options' },
      ],
      isLoading: false,

      loading_coachee: false,
      coachees: [],

      loading_groups: false,
      groups: [],

      session: {
        project: '',
        coachee_id: '',
        group_id: '',
        its_group: false,
        _id: '',
      },
      sessionActions: [],
      sessionProcess: [],

      responseActions: [{ action: this.taskYes.bind(this) }],
      isEditingAction: false,
      showModalProcessDelete: false,
      actionToDelete: null,
      editingAction: null,
      selectedCoachees: [],
      objEditProcess: {},

      showModalProcess: false,
      sendModalProcessToBackgroundAndOpenModalAction: false,
      showModalActions: false,
      processToDelete: {},
    };

    this.utils = new Utils();
    this.service = new Service();
    this.handleResize = this.handleResize.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.togglePrincipal = this.togglePrincipal.bind(this);
    this.setSidebarLinks = this.setSidebarLinks.bind(this);
    this.goTo = this.goTo.bind(this);
  }

  viewProcedure(event) {
    this.setState(
      {
        objEditProcess: event,
      },
      () => this.toggleModalProcess()
    );
  }

  openModalDeleteProcedure(event) {
    this.setState({ processToDelete: event }, () => {
      this.toggleModalDelete();
    });
  }

  deleteProcedure = async () => {
    const { processToDelete, session } = this.state;

    if (Object.values(processToDelete).length > 0) {
      const result = await this.service.deleteProcedures(
        session._id,
        processToDelete.procedure
      );

      if (result && result.success) {
        this.setState(
          {
            processToDelete: {},
          },
          async () => {
            await this.getAllProceduresBySession(session._id);
            this.toggleModalDelete();
          }
        );
      }
    }
  };

  toggleCoachee = item => {
    let selectedCoachees = [...this.state.selectedCoachees];
    const isCoacheeSelected = selectedCoachees.find(
      coachee => coachee._id === item._id
    );
    if (isCoacheeSelected) {
      selectedCoachees = selectedCoachees.filter(
        coachee => coachee._id !== item._id
      );
    } else {
      selectedCoachees.push(item);
    }
    this.setState({ selectedCoachees });
  };

  toggleModalProcess = () => {
    const { showModalProcess } = this.state;
    this.setState({
      showModalProcess: !showModalProcess,
    });
  };

  saveProcess = async process => {
    const { session } = this.state;
    const body = {
      procedure: {
        ...process,
      },
    };

    if (session) {
      const result = await this.service.saveProcedures(session._id, body);

      if (result && result.success) {
        this.getAllProceduresBySession(session._id);
        this.toggleModalProcess();
      }
    }
  };

  updateProcess = async process => {
    const { session } = this.state;

    const _id = process.id;

    delete process.id;
    delete process.meeting;

    const body = {
      ...process,
    };

    if (session) {
      const result = await this.service.updateProcedures(
        session._id,
        _id,
        body
      );

      if (result && result.success) {
        await this.getAllProceduresBySession(session._id);
        this.toggleModalProcess();
      }
    }
  };

  async edit(action) {
    this.setState(
      {
        isEditingAction: true,
        editingAction: action,
      },
      () => {
        this.toggleModalAction();
      }
    );
  }

  toggleModalDelete() {
    const { showModalProcessDelete } = this.state;
    this.setState({
      showModalProcessDelete: !showModalProcessDelete,
    });
  }

  toggleMenu() {
    const { sideOpen } = this.state;
    this.setState({ sideOpen: !sideOpen });
  }

  togglePrincipal() {
    const { principalOpen } = this.state;
    this.setState({ principalOpen: !principalOpen });
  }

  goTo(page) {
    this.props.history.push(`/${page}`);
  }

  verifyLogin() {
    if (!this.utils.isLogged()) {
      this.goTo('');
    }
  }

  componentDidMount() {
    this.verifyLogin();

    this.setState({ isLoading: true }, () => {
      Promise.all([
        this.loadUser().then(() => this.loadGroups()),
        this.resolveParams(),
        this.loadCoachees(),
      ]).finally(() => {
        this.setBreadcrumbs();
        this.setState({ isLoading: false });
      });
    });
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  setBreadcrumbs = () => {
    const breadcrumbDetail = this.getBreadcrumbDetail();

    this.setState({
      breadcrumbs: [
        { title: 'Dashboard', link: '' },
        { title: 'Programas', link: 'programs' },
        {
          title: 'Lista de Processos',
          link: 'programs/assessments/process-list',
        },
        ...(breadcrumbDetail ? [{ title: breadcrumbDetail }] : []),
      ],
    });
  };

  getCoacheeName = coacheeId => {
    const coachee = this.state.coachees.find(
      coachee => coachee._id === coacheeId
    );
    return coachee && coachee.name;
  };

  getGroupName = groupId => {
    const group = this.state.groups.find(group => group._id === groupId);
    return group && group.name;
  };

  getBreadcrumbDetail = () => {
    const { session } = this.state;

    if (session.coachee_id) return this.getCoacheeName(session.coachee_id);
    if (session.group_id) return this.getGroupName(session.group_id);
  };

  resolveParams = async () => {
    const { session } = this.state;
    // const { sessionId } = this.props;

    if (session._id) {
      const result = await this.service.sessionS(session._id);

      if (result && result.success) {
        this.props.updateSession(session._id);
      }

      this.loadSession(session._id);
      this.loadSessionActions(session._id);
    }
  };

  setSidebarLinks(view) {
    this.setState({ view });
    this.goTo(`dashboard/${view}`);
  }

  handleResize() {
    let _ww =
      window.innerWidth < 890 && window.innerWidth > 570
        ? window.innerWidth - (window.innerWidth * 30) / 100
        : window.innerWidth <= 570
        ? window.innerWidth - (window.innerWidth * 40) / 100
        : window.innerWidth - (window.innerWidth * 40) / 100;

    this.setState({
      windowWidth: _ww,
    });
  }

  logout() {
    this.utils.setLogout();
    this.goTo('');
  }

  parseSession = session => {
    const { coachee_id, project, group_id, its_group, _id } = session;
    return { coachee_id, project, group_id, its_group, _id };
  };

  loadSession = async sessionId => {
    // if (!sessionId) {
    //   this.goTo('dashboard/programs/assessments');
    //   return;
    // }

    this.setState({ loading: true });
    const result = await this.service.sessionS(sessionId);
    if (result && result.success && result.session) {
      if (result.session.its_free) {
        this.goTo('dashboard/programs/master');
        return;
      }

      this.setState({
        session: this.parseSession(result.session),
      });
    }
    this.setState({ loading: false });
  };

  async loadUser() {
    let result = await this.service.me();
    if (!result) {
      this.logout();
      return;
    }
    let user = {
      ...result.user,
    };
    if (user.image) {
      this.props.updateImage(user.image);
    }

    this.setState({ user });
  }

  async loadCoachees() {
    this.setState({ loading_coachee: true });
    let result = await this.service.coachees();
    if (result && result.success) {
      await this.setState({ coachees: result.coachees });
    }
    this.setState({ loading_coachee: false });
  }

  loadGroups = async () => {
    const { user } = this.state;
    if (!this.userHasGroupSessionRight(user)) return;

    this.setState({ loading_groups: true });
    let result = await this.service.groups();
    if (result && result.success) {
      const { groups } = result;
      this.setState({ groups });
    }
    this.setState({ loading_groups: false });
  };

  async loadSessionActions(sessionId) {
    this.setState({ loading: true });
    let result = await this.service.sessionActions(sessionId);
    if (result && result.success) {
      this.setState({ sessionActions: result.actions });
    }
    this.setState({ loading: false });
  }

  async taskYes(action) {
    const { session } = this.state;

    if (session._id) {
      const { coachee_id: coacheeId, action: actionNumber } = action;

      const result = await this.service.completeSessionAction(
        session._id,
        coacheeId,
        actionNumber
      );

      if (result && result.success) {
        this.loadSessionActions(session._id);
      }
    }
  }

  adicionarNovoProcesso = () => {
    this.setState({ objEditProcess: {} }, () => {
      this.toggleModalProcess();
    });
  };

  getGroupCoachees = () => {
    const { session, groups } = this.state;
    const group =
      session.group_id && groups.find(group => group._id === session.group_id);
    return group ? group.coachees : [];
  };

  userHasGroupSessionRight = user => {
    return (
      user &&
      user.plans &&
      user.plans.platform &&
      user.plans.platform.plan.group_sessions
    );
  };

  userHasRights = user => {
    const { session } = this.state;
    return (
      !session.its_group ||
      (session.its_group && this.userHasGroupSessionRight(user))
    );
  };

  loadSessionByGroupId = async group_id => {
    if (!group_id) return;
    const result = await this.service.sessionG(group_id);
    if (result && result.success) {
      const { session } = result;

      if (session) {
        this.setState({
          session: this.parseSession(result.session),
        });

        this.setState({ isLoading: true }, () => {
          Promise.all([
            this.resolveParams(),
            this.loadCoachees(),
            this.getAllProceduresBySession(session._id),
          ]).finally(() => {
            this.setBreadcrumbs();
            this.setState({ isLoading: false });
          });
        });
      } else if (group_id) {
        const updateState = {
          group_id: group_id,
          coachee_id: '',
          oportunity_id: '',
        };
        this.setState(
          {
            session: { ...this.state.session, ...updateState },
          },
          () => {
            this.createSession();
          }
        );
      }
    }
  };

  async getAllProceduresBySession(sessionID) {
    const result = await this.service.getAllProceduresBySession(sessionID);

    if (result) {
      this.setState({
        sessionProcess: result,
      });
    }
  }

  createSession = async () => {
    this.setState({ loading: true });
    const {
      coachee_id,
      oportunity_id,
      group_id,
      its_group,
    } = this.state.session;

    const result = await this.service.createSession({
      coachee_id,
      oportunity_id,
      its_free: false,
      group_id,
      its_group,
    });

    if (result && result.success) {
      const { session } = result;

      this.setState(
        {
          session: this.parseSession(session),
        },
        async () => {
          this.setBreadcrumbs();
        }
      );
    }
    this.setState({ loading: false });
  };

  changeGroup = e => {
    this.loadSessionByGroupId(e.target.value);
  };

  toggleModalAction = () => {
    const { showModalActions, showModalProcess } = this.state;
    this.setState({
      showModalActions: !showModalActions,
      selectedCoachees: [],
      sendModalProcessToBackgroundAndOpenModalAction:
        !showModalActions && showModalProcess,
    });
  };

  getSessionID = () => {
    const { session } = this.state;
    return session._id;
  };

  saveAction = async action => {
    const __sessionID = this.getSessionID();

    const { selectedCoachees, session, objEditProcess } = this.state;
    if (__sessionID) {
      const sendTo = [];
      if (session.its_group) {
        selectedCoachees.forEach(coachee => sendTo.push(coachee._id));
      } else if (session.coachee_id) {
        sendTo.push(session.coachee_id);
      }

      if (sendTo.length > 0) {
        await this.service.storeSessionActions(
          __sessionID,
          objEditProcess.meeting || 'session',
          sendTo,
          action
        );
      }
    }
    this.toggleModalAction();
  };

  render() {
    const {
      sideOpen,
      side,
      breadcrumbs,
      session,
      loading,
      tableActions,
      responseActions,
      tableTitles,
      sessionActions,
      showModalActions,
      sendModalProcessToBackgroundAndOpenModalAction,
      showModalProcessDelete,
      editingAction,
      isEditingAction,
      groups,
      selectedCoachees,
      user,
      isLoading,
      titleRows,
      showModalProcess,
      objEditProcess,
      sessionProcess,
    } = this.state;

    return (
      <div className="main-content">
        <Header
          goTo={this.goTo}
          sideOpen={sideOpen}
          toggleMenu={this.toggleMenu}
        />
        <div className="content-climb user bodyClimby">
          <div
            className={this.props.menu ? 'content-menu closed' : 'content-menu'}
          >
            <Sidebar
              side={this.state.side}
              setSidebarLinks={this.setSidebarLinks}
              expanded={!this.state.sideOpen}
            />
          </div>
          <div
            className={this.props.menu ? 'content-dash closed' : 'content-dash'}
          >
            <div className="dash-content">
              <div className="dash-crumb-filter">
                <Breadcrumbs breadcrumbs={breadcrumbs} />
              </div>
              {isLoading ? (
                <ReactLoading type="bubbles" color="#B1CD49" />
              ) : !this.userHasRights(user) ? (
                <NotAllowed go={this.goTo.bind(this)} user={user} />
              ) : (
                <div className="dashboard-boxes">
                  <div className="dashboard-box box100 align-select-group">
                    <div style={{ display: 'flex' }}>
                      <span
                        className="box-title row-center"
                        style={{
                          alignItems: 'center',
                          width: '100%',
                        }}
                      >
                        Lista de Processos
                      </span>

                      <div
                        className="form-input"
                        style={{
                          width: '45%',
                          padding: '0 20px',
                        }}
                      >
                        <label className="blue-label">Área</label>
                        {this.state.loading_groups ? (
                          <ReactLoading type="bubbles" color="#B1CD49" />
                        ) : (
                          <select
                            value={session.group_id}
                            className="blue-input"
                            onChange={e => {
                              this.changeGroup(e);
                            }}
                          >
                            <option value=""> &nbsp; Área</option>
                            {groups.map((group, key) => {
                              return (
                                <option key={group._id} value={group._id}>
                                  {' '}
                                  &nbsp; {group.name}
                                </option>
                              );
                            })}
                          </select>
                        )}
                      </div>
                    </div>

                    {session._id && (
                      <CardGenericTable //Só deverá mostrar se houver uma sessão
                        noBox
                        id="session-actions-1"
                        title="Lista de Processos"
                        response_actions={responseActions}
                        actions={tableActions}
                        title_rows={titleRows}
                        rows={
                          sessionProcess && sessionProcess.length > 0
                            ? sessionProcess
                                .map(proc => {
                                  return {
                                    ...proc,
                                    process_status: proc.status,
                                    data_processing_hypotheses_label:
                                      getDataProcessingHypothesesText(
                                        proc.data_processing_hypotheses
                                      ) || '',
                                  };
                                })
                                .sort(sortProcedures)
                            : []
                        }
                      />
                    )}
                  </div>

                  {session._id && (
                    <div className="row-button" style={{ padding: '7px 16px' }}>
                      {loading ? (
                        <ReactLoading type="bubbles" color="#00838F" />
                      ) : (
                        <div
                          className="bt-blue noMargin"
                          onClick={() => {
                            this.adicionarNovoProcesso();
                          }}
                        >
                          <img
                            src={require('./../../../assets/images/plus.svg')}
                            style={{ marginTop: -3, marginRight: 14 }}
                          />
                          Adicionar Processo
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}

              <Footer />
            </div>
          </div>
        </div>

        {(showModalActions ||
          sendModalProcessToBackgroundAndOpenModalAction) && (
          <ModalActions
            toggleModalAction={this.toggleModalAction}
            saveAction={this.saveAction}
            modal_actions={
              showModalActions || sendModalProcessToBackgroundAndOpenModalAction
            }
            coachees={this.getGroupCoachees()}
            selectedCoachees={selectedCoachees}
            toggleCoachee={this.toggleCoachee}
            selectAllCoachee={() =>
              this.setState({ selectedCoachees: [...this.getGroupCoachees()] })
            }
            selectNoneCoachee={() => this.setState({ selectedCoachees: [] })}
          />
        )}

        {(showModalProcess ||
          sendModalProcessToBackgroundAndOpenModalAction) && (
          <ModalProcess
            toggleModalProcess={this.toggleModalProcess}
            saveProcess={this.saveProcess}
            updateProcess={this.updateProcess}
            modal_process={
              showModalProcess &&
              !sendModalProcessToBackgroundAndOpenModalAction
            }
            objEditProcess={objEditProcess}
            toggleModalAction={this.toggleModalAction}
          />
        )}

        {showModalProcessDelete && (
          <ModalActionDelete
            close={() => {
              this.setState({ showModalProcessDelete: false });
            }}
            open={showModalProcessDelete}
            deleteContent={this.deleteProcedure}
            title={`Confirmar deleção?`}
          />
        )}
      </div>
    );
  }
}
const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  sessionId: state.simpleReducer.sessionId,
  menu: state.simpleReducer.menu,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateImage, updateSession }, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProcessList)
);
