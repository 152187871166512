import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import '../../../styles/css/style.css';
import '../../../styles/css/dashboard.css';
import { Col } from 'reactstrap';
import { updateImage } from '../../../actions/SimpleAction';
import SidebarProposes from './../../sidebar/SidebarProposes';
import Header from './../../components/Header';
import PrincipalMenu from './../../components/PrincipalMenu';
import Breadcrumbs from './../../components/Breadcrumbs';
import Footer from './../../components/Footer';
import { Editor } from 'react-draft-wysiwyg';
import './../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Service from './../Service.js';
import Utils from './../../../libs/Utils.js';
import CurrencyInput from 'react-currency-input';

class ProposalCreate extends Component {
  constructor(props, context) {
    super(props);

    const initialWidth = window.innerWidth > 0 ? window.innerWidth : 500;

    this.state = {
      isVisible: false,
      sideOpen: false,
      principalOpen: true,
      view: 'proposals',
      side: 'generate',
      breadcrumbs: [
        { title: 'Dashboard', link: '' },
        { title: 'Propostas', link: 'proposals' },
        { title: 'Visualizar Proposta', link: 'proposals/proposal/register' },
      ],
      showToolTip: false,
      windowWidth: initialWidth - (window.innerWidth * 40) / 100,
      loading: false,

      loading_coachee: false,
      coachees: [],

      loading_contents: false,
      list_contents: [],

      contents: [],

      editorState: '',
      contentState: {},
      title: '',
      value: '',
      tag: '',
      maxLength: 3660,
      editing: false,
      editing_id: '',
    };

    this.utils = new Utils();
    this.service = new Service();
    this.handleResize = this.handleResize.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.togglePrincipal = this.togglePrincipal.bind(this);
    this.setSidebarLinks = this.setSidebarLinks.bind(this);
    this.goTo = this.goTo.bind(this);
  }

  toggleMenu() {
    if (this.state.sideOpen) {
      this.setState({ sideOpen: false });
    } else {
      this.setState({ sideOpen: true });
    }
  }

  togglePrincipal() {
    if (this.state.principalOpen) {
      this.setState({ principalOpen: false });
    } else {
      this.setState({ principalOpen: true });
    }
  }

  goTo(page) {
    this.props.history.push(`/${page}`);
  }

  verifyLogin() {
    if (!this.utils.isLogged()) {
      this.goTo('');
    }
  }

  componentDidMount() {
    this.verifyLogin();
    this.loadUser();
    this.resolveParams();
    window.addEventListener('resize', this.handleResize);
  }

  resolveParams() {
    const {
      match: { params },
    } = this.props;
    if (params.id) {
      let editing_id = params.id;
      this.setState({
        editing: true,
        editing_id,
      });
      this.load(editing_id);
    }
  }

  async load(_id) {
    this.setState({ loading: true });
    let result = await this.service.proposal(_id);
    if (result && result.success) {
      let _text = result.proposal.text ? result.proposal.text : '';
      const editorState = _text;

      this.setState({
        value: result.proposal.value,
        title: result.proposal.title,
        tag: result.proposal.tag,
        editorState,
      });
    }
    this.setState({ loading: false });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  setSidebarLinks(view) {
    this.setState({ view });
    this.goTo(`users/dashboard/${view}`);
  }

  handleResize() {
    let _ww =
      window.innerWidth < 890 && window.innerWidth > 570
        ? window.innerWidth - (window.innerWidth * 30) / 100
        : window.innerWidth <= 570
        ? window.innerWidth - (window.innerWidth * 40) / 100
        : window.innerWidth - (window.innerWidth * 40) / 100;

    this.setState({
      windowWidth: _ww,
    });
  }

  logout() {
    this.utils.setLogout();
    this.goTo('');
  }

  async loadUser() {
    let result = await this.service.meCoachee();
    if (!result) {
      this.logout();
      return;
    }
    let user = {
      ...result.user,
    };
    if (user.image) {
      this.props.updateImage(user.image);
    }
  }

  render() {
    return (
      <div className="main-content">
        <Header
          goTo={this.goTo}
          sideOpen={this.state.sideOpen}
          toggleMenu={this.toggleMenu}
        />
        <div className="content-climb user bodyClimby">
          <div
            className={this.props.menu ? 'content-menu closed' : 'content-menu'}
          >
            <SidebarProposes
              goTo={this.goTo}
              side={this.state.side}
              setSidebarLinks={this.setSidebarLinks}
            />
          </div>
          <div
            className={this.props.menu ? 'content-dash closed' : 'content-dash'}
          >
            <PrincipalMenu
              principalOpen={this.state.principalOpen}
              view={this.state.view}
              setSidebarLinks={this.setSidebarLinks}
              togglePrincipal={this.togglePrincipal}
            />

            <div className="dash-content dash-fixed">
              <div className="dash-crumb-filter">
                <Breadcrumbs breadcrumbs={this.state.breadcrumbs} />
              </div>
              <div className="dashboard-boxes">
                <div className="dashboard-box box100">
                  <Col xs={{ size: 12 }} md={{ size: 6, offset: 3 }}>
                    <div className="form-input input-title">
                      <label className="blue-label">Título</label>
                      <input
                        type="text"
                        value={this.state.title}
                        className="blue-input"
                        placeholder="Título"
                        onChange={e => {
                          this.setState({ title: e.target.value });
                        }}
                      />
                    </div>

                    <div className="box-row-title row-center">
                      <div className="box-title">Proposta</div>
                    </div>
                  </Col>
                  <Col xs={{ size: 12 }} md={{ size: 10, offset: 1 }}>
                    <Editor
                      state={this.state}
                      editorState={this.state.editorState}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                      onEditorStateChange={this.onEditorStateChange.bind(this)}
                      initialContentState={this.state.contentState}
                      toolbar={{
                        image: {
                          uploadCallback: this.uploadImageCallBack.bind(this),
                          alt: { present: true, mandatory: true },
                        },
                      }}
                      onContentStateChange={this.onContentStateChange.bind(
                        this
                      )}
                    />

                    <div className="form-input  max-320">
                      <label className="blue-label">Valor da proposta</label>
                      <CurrencyInput
                        value={this.state.value}
                        className="blue-input"
                        onChangeEvent={e => {
                          this.setState({ value: e.target.value });
                        }}
                        decimalSeparator=","
                        thousandSeparator="."
                        prefix="R$ "
                        placeholder="Valor da proposta"
                      />
                    </div>

                    <div className="form-input max-320">
                      <label className="blue-label">Modelo</label>
                      <input
                        type="text"
                        value={this.state.tag}
                        className="blue-input"
                        placeholder="Modelo"
                        onChange={e => {
                          this.setState({ tag: e.target.value });
                        }}
                      />
                    </div>
                  </Col>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  menu: state.simpleReducer.menu,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateImage }, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProposalCreate)
);
