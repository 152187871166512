import 'bootstrap/dist/css/bootstrap.min.css';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';

import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';

import { Provider, useSelector } from 'react-redux';
import configureStore from './store';

import './styles/css/style.css';

import ReactLoading from 'react-loading';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';
import { LinkedInPopUp } from 'react-linkedin-login-oauth2';

import Login from './screens/Login/';
import Register from './screens/Register/';
import RegisterSuccess from './screens/RegisterSuccess/';
import NotFound from './screens/NotFound/';
import Forgot from './screens/Forgot/';
import ForgotSuccess from './screens/ForgotSuccess/';
import NewPass from './screens/NewPass/';
import VerifyEmail from './screens/VerifyEmail/';
import ProposalResponse from './screens/ProposalResponse/';
import Terms from './screens/Terms/';
import Policity from './screens/Policity/';

import VideoSessionWrapper from './components/VideoSessionWrapper';
import SWUpdate from './components/SWUpdate';

import Contents from './screens_dashboard/dashboard/Contents';
import ContentsClimb from './screens_dashboard/dashboard/ContentsClimb';
import Dashboard from './screens_dashboard/dashboard/Dashboard';
import Finances from './screens_dashboard/dashboard/Finances';
import Programs from './screens_dashboard/dashboard/Programs';
import Proposals from './screens_dashboard/dashboard/Proposals';
import Reports from './screens_dashboard/dashboard/Reports';
import Profile from './screens_dashboard/dashboard/Profile/Profile';
import Users from './screens_dashboard/dashboard/Users';
import Calendar from './screens_dashboard/dashboard/Calendar';
import Messages from './screens_dashboard/dashboard/Messages';
import HelpCenterSearcher from './screens_dashboard/dashboard/HelpCenterSearcher';
import Groups from './screens_dashboard/dashboard/Groups';
import RegisterCompletion from './screens_dashboard/dashboard/RegisterCompletion';

import ProfileProfessional from './screens_dashboard/dashboard/Profile/ProfileProfessional';
import ProfileProfessionalArea from './screens_dashboard/dashboard/Profile/ProfileProfessionalArea';
import ProfileProfessionalDescription from './screens_dashboard/dashboard/Profile/ProfileProfessionalDescription';
import ProfileProfessionalSession from './screens_dashboard/dashboard/Profile/ProfileProfessionalSession';
import ProfileProfessionalValue from './screens_dashboard/dashboard/Profile/ProfileProfessionalValue';
import MyProfile from './screens_dashboard/dashboard/Profile/MyProfile';

import ChangePassword from './screens_dashboard/dashboard/Profile/ChangePassword';
import MyTerms from './screens_dashboard/dashboard/Profile/Terms';
import MyPlans from './screens_dashboard/dashboard/Profile/MyPlans';
import CreditCard from './screens_dashboard/dashboard/Profile/CreditCard';
import Plans from './screens_dashboard/dashboard/Profile/Plans';

import OportunityCreate from './screens_dashboard/dashboard/Create/OportunityCreate';
import UserCreate from './screens_dashboard/dashboard/Create/UserCreate';
import ContentCreate from './screens_dashboard/dashboard/Create/ContentCreate';
import ProposalCreate from './screens_dashboard/dashboard/Create/ProposalCreate';
import ProposalGenerate from './screens_dashboard/dashboard/Create/ProposalGenerate';

import TemplateEditor from './screens_dashboard/dashboard/Create/TemplateEditor';
import WheelEditor from './screens_dashboard/dashboard/Create/WheelEditor';
import WheelViewer from './screens_dashboard/dashboard/Create/WheelViewer';
import WheelPreview from './screens_dashboard/dashboard/Create/WheelPreview';
import ResponseViewer from './screens_dashboard/dashboard/Create/ResponseViewer';
import ReportEditor from './screens_dashboard/dashboard/Create/ReportEditor';
import FormEditor from './screens_dashboard/dashboard/Create/FormEditor';
import FormViewer from './screens_dashboard/dashboard/Create/FormViewer';
import FormPreview from './screens_dashboard/dashboard/Create/FormPreview';

import SessionMaster from './screens_dashboard/dashboard/Session/SessionMaster';
import SessionPlanning from './screens_dashboard/dashboard/Session/SessionPlanning';
import SessionRegister from './screens_dashboard/dashboard/Session/SessionRegister';
import ActionsRegister from './screens_dashboard/dashboard/Session/ActionsRegister';
import ContentsRegister from './screens_dashboard/dashboard/Session/ContentsRegister';
import RelatoryEditor from './screens_dashboard/dashboard/Session/RelatoryEditor';
import RelatoryView from './screens_dashboard/dashboard/Session/RelatoryView';

import ProvidersRegister from './screens_dashboard/dashboard/Finance/ProvidersRegister';
import ServicesRegister from './screens_dashboard/dashboard/Finance/ServicesRegister';
import CategoriesRegister from './screens_dashboard/dashboard/Finance/CategoriesRegister';

import CategoryCreate from './screens_dashboard/dashboard/Finance/CategoryCreate';
import ProviderCreate from './screens_dashboard/dashboard/Finance/ProviderCreate';
import ServiceCreate from './screens_dashboard/dashboard/Finance/ServiceCreate';

import BalanceIn from './screens_dashboard/dashboard/Finance/BalanceIn';
import BalanceOut from './screens_dashboard/dashboard/Finance/BalanceOut';
import BalanceInOut from './screens_dashboard/dashboard/Finance/BalanceInOut';
import BalanceCreate from './screens_dashboard/dashboard/Finance/BalanceCreate';

import MarketingSite from './screens_dashboard/dashboard/Marketing/Site';
import MarketingMessages from './screens_dashboard/dashboard/Marketing/Messages';
import MarketingLandpage from './screens_dashboard/dashboard/Marketing/Landpage';

import * as registerServiceWorker from './registerServiceWorker';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useLocation,
  useHistory,
} from 'react-router-dom';

import CoacheeAuth from './screens_coachees/dashboard/Auth';
import CoacheeDashboard from './screens_coachees/dashboard/Dashboard';
import CoacheeCalendar from './screens_coachees/dashboard/Calendar';
import CoacheeMessages from './screens_coachees/dashboard/Messages';

import CoacheePrograms from './screens_coachees/dashboard/Programs';
import CoacheeProposals from './screens_coachees/dashboard/Proposals';
import CoacheeTemplateEditor from './screens_coachees/dashboard/Create/TemplateEditor';
import CoacheeWheelEditor from './screens_coachees/dashboard/Create/WheelEditor';
import CoacheeFormEditor from './screens_coachees/dashboard/Create/FormEditor';

import CoacheeActionsRegister from './screens_coachees/dashboard/Session/ActionsRegister';
import CoacheeContentsRegister from './screens_coachees/dashboard/Session/ContentsRegister';
import CoacheeProposalGenerate from './screens_coachees/dashboard/Create/ProposalGenerate';

import CoacheeProfile from './screens_coachees/dashboard/Profile/Profile';
import CoacheeMyTerms from './screens_coachees/dashboard/Profile/Terms';
import CoacheeHelpCenter from './screens_coachees/dashboard/HelpCenter';

import AdminAuth from './screens_admin/dashboard/Auth';
import AdminDashboard from './screens_admin/dashboard/Dashboard';
import AdminProfile from './screens_admin/dashboard/Profile/Profile';
import AdminMyTerms from './screens_admin/dashboard/Profile/Terms';
import AdminChangePassword from './screens_admin/dashboard/Profile/ChangePassword';

import AdminAdmins from './screens_admin/dashboard/Admins/List';
import AdminAdminsForm from './screens_admin/dashboard/Admins/Form';

import AdminCoachees from './screens_admin/dashboard/Coachees/List';
import AdminCoacheesForm from './screens_admin/dashboard/Coachees/Form';

import AdminCoachs from './screens_admin/dashboard/Coachs/List';
import AdminAnalytics from './screens_admin/dashboard/Coachs/Analytics';
import AdminCoachsInfos from './screens_admin/dashboard/Coachs/Infos';
import AdminCoachsForm from './screens_admin/dashboard/Coachs/Form';

import AdminPlans from './screens_admin/dashboard/Plans/List';
import AdminPlansForm from './screens_admin/dashboard/Plans/Form';

import AdminCombos from './screens_admin/dashboard/Combos/List';
import AdminCombosForm from './screens_admin/dashboard/Combos/Form';

import AdminCoupons from './screens_admin/dashboard/Coupons/List';
import AdminCouponsForm from './screens_admin/dashboard/Coupons/Form';

import AdminCategories from './screens_admin/dashboard/Categories/List';
import AdminCategoriesForm from './screens_admin/dashboard/Categories/Form';

import AdminPartnerInstitutions from './screens_admin/dashboard/PartnerInstitutions/List';
import AdminPartnerInstitutionsForm from './screens_admin/dashboard/PartnerInstitutions/Form';

import AdminFaqs from './screens_admin/dashboard/Faqs/List';
import AdminFaqsForm from './screens_admin/dashboard/Faqs/Form';

import AdminConfigs from './screens_admin/dashboard/Configs/List';
import AdminConfigsForm from './screens_admin/dashboard/Configs/Form';

import AdminContents from './screens_admin/dashboard/Contents/List';
import AdminContentsForm from './screens_admin/dashboard/Contents/Form';
import AdminTemplateEditor from './screens_admin/dashboard/Create/TemplateEditor';
import AdminWheelEditor from './screens_admin/dashboard/Create/WheelEditor';
import AdminWheelPreview from './screens_admin/dashboard/Create/WheelPreview';
import AdminFormEditor from './screens_admin/dashboard/Create/FormEditor';
import AdminFormPreview from './screens_admin/dashboard/Create/FormPreview';
import AdminProjects from './screens_admin/dashboard/Projects/List';
import AdminProjectsForm from './screens_admin/dashboard/Projects/Form';

import AdminNotifications from './screens_admin/dashboard/Notifications/List';
import AdminNotificationsForm from './screens_admin/dashboard/Notifications/Form';
import CoacheeCreateBatch from './screens_dashboard/dashboard/Create/CoacheeCreateBatch';
import OportunityCreateBatch from './screens_dashboard/dashboard/Create/OportunityCreateBatch';
import GroupCreate from './screens_dashboard/dashboard/Create/GroupCreate';

import Utils, { getSitePath } from './libs/Utils';

import LoginService from './screens/Login/Service';

import { SW_INIT, SW_UPDATE } from './consts/reduxTypes';
import {
  updateServiceWorkerInitialized,
  updateServiceWorkerUpdated,
} from './actions/SimpleAction';
import ProcessList from './screens_dashboard/dashboard/Session/ProcessList';
import AdequacyStatus from './screens_dashboard/dashboard/Session/AdequacyStatus';

registerLocale('ptBR', ptBR);
setDefaultLocale('ptBR');

const utils = new Utils();
const store = configureStore();

const CoachRoutes = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const [user, setUser] = useState();
  const [forceRefresh, setForceRefresh] = useState(false);
  const projectId = useSelector(state => state.simpleReducer.projectId);

  useEffect(() => {
    async function loadUser() {
      const service = new LoginService();
      const result = await service.me();
      if (!result) {
        utils.setLogout();
        history.push('/');
        return;
      }
      if (result.user) setUser(result.user);
    }

    loadUser();
    setForceRefresh(false);
  }, [pathname, forceRefresh]);

  useEffect(() => {
    setUser(undefined);
    setForceRefresh(true);
  }, [projectId]);

  return user ? (
    // Disable register completion
    //user.isRegisterComplete ? (
    true ? (
      <Switch>
        <Route path="/dashboard" exact={true} component={Dashboard} />
        <Route path="/dashboard/contents" exact={true} component={Contents} />
        <Route
          path="/dashboard/contents/custom"
          exact={true}
          component={ContentsClimb}
        />
        <Route
          path="/dashboard/contents/create"
          exact={true}
          component={ContentCreate}
        />
        <Route
          path="/dashboard/contents/create/:id"
          exact={true}
          component={ContentCreate}
        />
        <Route
          path="/dashboard/template-editor/:id"
          exact={true}
          component={TemplateEditor}
        />
        <Route
          path="/dashboard/wheel-editor/:id?"
          exact={true}
          component={WheelEditor}
        />
        <Route
          path="/dashboard/wheel-preview/:id"
          exact={true}
          component={WheelPreview}
        />
        <Route
          path="/dashboard/response-viewer/:sessionId/:meetingType/:coacheeId/:contentId"
          exact={true}
          component={ResponseViewer}
        />
        <Route
          path="/dashboard/wheel-viewer/:sessionId/:meetingType/:coacheeId/:contentId"
          exact={true}
          component={WheelViewer}
        />
        <Route
          path="/dashboard/report-editor/:id"
          exact={true}
          component={ReportEditor}
        />
        <Route
          path="/dashboard/form-editor/:id?"
          exact={true}
          component={FormEditor}
        />
        <Route
          path="/dashboard/form-viewer/:sessionId/:meetingType/:coacheeId/:contentId"
          exact={true}
          component={FormViewer}
        />
        <Route
          path="/dashboard/form-preview/:id"
          exact={true}
          component={FormPreview}
        />

        <Route path="/dashboard/finances" exact={true} component={Finances} />
        <Route path="/dashboard/programs" exact={true} component={Dashboard} />
        <Route path="/dashboard/proposals" exact={true} component={Proposals} />
        <Route path="/dashboard/reports" exact={true} component={Reports} />
        <Route path="/dashboard/users" exact={true} component={Users} />
        <Route
          path="/dashboard/users/create"
          exact={true}
          component={UserCreate}
        />
        <Route
          path="/dashboard/users/create/:id"
          exact={true}
          component={UserCreate}
        />
        <Route
          path="/dashboard/users/create_batch"
          exact={true}
          component={CoacheeCreateBatch}
        />
        <Route path="/dashboard/groups" exact={true} component={Groups} />
        <Route
          path="/dashboard/groups/create"
          exact={true}
          component={GroupCreate}
        />
        <Route
          path="/dashboard/groups/create/:id"
          exact={true}
          component={GroupCreate}
        />
        <Route path="/dashboard/profile" exact={true} component={MyProfile} />

        {/* <Route
          path="/dashboard/profile/professional"
          exact={true}
          component={ProfileProfessional}
        />
        <Route
          path="/dashboard/profile/professional/area"
          exact={true}
          component={ProfileProfessionalArea}
        />
        <Route
          path="/dashboard/profile/professional/description"
          exact={true}
          component={ProfileProfessionalDescription}
        />
        <Route
          path="/dashboard/profile/professional/session"
          exact={true}
          component={ProfileProfessionalSession}
        />
        <Route
          path="/dashboard/profile/professional/value"
          exact={true}
          component={ProfileProfessionalValue}
        /> */}
        <Route
          path="/dashboard/profile/changepassword"
          exact={true}
          component={ChangePassword}
        />
        <Route path="/dashboard/profile/plans" exact={true} component={Plans} />
        <Route
          path="/dashboard/profile/myplans"
          exact={true}
          component={MyPlans}
        />
        <Route
          path="/dashboard/profile/creditcard"
          exact={true}
          component={CreditCard}
        />
        <Route path="/dashboard/terms" exact={true} component={MyTerms} />

        <Route
          path="/dashboard/proposals/oportunity/register"
          exact={true}
          component={OportunityCreate}
        />
        <Route
          path="/dashboard/proposals/oportunity/register/:id"
          exact={true}
          component={OportunityCreate}
        />
        <Route
          path="/dashboard/proposals/proposal/register"
          exact={true}
          component={ProposalCreate}
        />
        <Route
          path="/dashboard/proposals/proposal/register/:id"
          exact={true}
          component={ProposalGenerate}
        />
        <Route
          path="/dashboard/proposals/oportunity/create_batch"
          exact={true}
          component={OportunityCreateBatch}
        />

        <Route
          path="/dashboard/programs/session"
          exact={true}
          component={SessionMaster}
        />

        <Route
          path="/dashboard/programs/:session_type"
          exact={true}
          component={SessionMaster}
        />
        <Route
          path="/dashboard/programs/:session_type/edit/:sessionId"
          exact={true}
          component={SessionMaster}
        />
        <Route
          path="/dashboard/programs/:session_type/edit/:sessionId/:session"
          exact={true}
          component={SessionMaster}
        />
        <Route
          path="/dashboard/programs/assessments/planning"
          exact={true}
          component={SessionPlanning}
        />
        <Route
          path="/dashboard/programs/assessments/register"
          exact={true}
          component={SessionRegister}
        />
        <Route
          path="/dashboard/programs/assessments/register/:group"
          exact={true}
          component={SessionRegister}
        />
        <Route
          path="/dashboard/programs/assessments/register/:session/:meeting"
          exact={true}
          component={SessionRegister}
        />
        <Route
          path="/dashboard/programs/assessments/actions/:sessionId"
          exact={true}
          component={ActionsRegister}
        />
        <Route
          path="/dashboard/programs/assessments/actions"
          exact={true}
          component={ActionsRegister}
        />
        <Route
          path="/dashboard/programs/assessments/contents"
          exact={true}
          component={ContentsRegister}
        />
        <Route
          path="/dashboard/programs/assessments/process-list"
          exact={true}
          component={ProcessList}
        />
        <Route
          path="/dashboard/adequacy-status"
          exact={true}
          component={AdequacyStatus}
        />
        <Route
          path="/dashboard/programs/assessments/relatory"
          exact={true}
          component={RelatoryEditor}
        />
        <Route
          path="/dashboard/programs/assessments/relatory-view/:id"
          exact={true}
          component={RelatoryView}
        />

        <Route
          path="/dashboard/finances/register/provider"
          exact={true}
          component={ProvidersRegister}
        />
        <Route
          path="/dashboard/finances/register/service"
          exact={true}
          component={ServicesRegister}
        />
        <Route
          path="/dashboard/finances/register/category"
          exact={true}
          component={CategoriesRegister}
        />

        <Route
          path="/dashboard/finances/register/category/create"
          exact={true}
          component={CategoryCreate}
        />
        <Route
          path="/dashboard/finances/register/category/create/:id"
          exact={true}
          component={CategoryCreate}
        />
        <Route
          path="/dashboard/finances/register/provider/create"
          exact={true}
          component={ProviderCreate}
        />
        <Route
          path="/dashboard/finances/register/provider/create/:id"
          exact={true}
          component={ProviderCreate}
        />
        <Route
          path="/dashboard/finances/register/service/create"
          exact={true}
          component={ServiceCreate}
        />
        <Route
          path="/dashboard/finances/register/service/create/:id"
          exact={true}
          component={ServiceCreate}
        />

        <Route
          path="/dashboard/finances/balance/in"
          exact={true}
          component={BalanceIn}
        />
        <Route
          path="/dashboard/finances/balance/out"
          exact={true}
          component={BalanceOut}
        />
        <Route
          path="/dashboard/finances/balance/inout"
          exact={true}
          component={BalanceInOut}
        />
        <Route
          path="/dashboard/finances/balance/:type/create/"
          exact={true}
          component={BalanceCreate}
        />
        <Route
          path="/dashboard/finances/balance/:type/create/:id"
          exact={true}
          component={BalanceCreate}
        />

        <Route
          path="/dashboard/marketing/site"
          exact={true}
          component={MarketingSite}
        />
        <Route
          path="/dashboard/marketing/messages"
          exact={true}
          component={MarketingMessages}
        />

        <Route path="/dashboard/calendar" exact={true} component={Calendar} />
        <Route
          path="/dashboard/messages"
          exact={true}
          component={MarketingMessages}
        />
        <Route path="/dashboard/chat" exact={true} component={Messages} />
        <Route
          path="/dashboard/chat/:chat_id"
          exact={true}
          component={Messages}
        />
        <Route
          path="/dashboard/search/:expression"
          exact={true}
          component={HelpCenterSearcher}
        />
        <Route path="*" component={NotFound} />
      </Switch>
    ) : (
      <RegisterCompletion setForceRefresh={setForceRefresh} />
    )
  ) : (
    <ReactLoading type="bubbles" color="#B1CD49" />
  );
};

ReactDOM.render(
  <Provider store={store}>
    <>
      <VideoSessionWrapper />
      <SWUpdate />
      <Router basename={getSitePath() || '/'}>
        <Switch>
          <Route path="/" exact={true} component={Login} />
          <Route path="/login" component={Login} />
          <Route path="/register" component={Register} />
          <Route path="/register-success" component={RegisterSuccess} />
          <Route path="/forgot" component={Forgot} />
          <Route path="/forgot-success" component={ForgotSuccess} />
          <Route path="/new-password" component={NewPass} />
          <Route path="/verify-email" component={VerifyEmail} />
          <Route path="/respond-proposal" component={ProposalResponse} />
          <Route path="/terms" component={Terms} />
          <Route path="/policity" component={Policity} />
          <Route path="/climby/:slug" component={MarketingLandpage} />
          <Route
            path="/climby-preview/:slug/:preview"
            component={MarketingLandpage}
          />

          <Route path="/dashboard" component={CoachRoutes} />

          <Route
            path="/users/dashboard"
            exact={true}
            component={CoacheeDashboard}
          />
          <Route
            path="/users/dashboard/auth"
            exact={true}
            component={CoacheeAuth}
          />
          <Route
            path="/users/dashboard/calendar"
            exact={true}
            component={CoacheeCalendar}
          />
          <Route
            path="/users/dashboard/messages/:chat_id"
            exact={true}
            component={CoacheeMessages}
          />
          <Route
            path="/users/dashboard/messages"
            exact={true}
            component={CoacheeMessages}
          />
          <Route
            path="/users/dashboard/proposals"
            exact={true}
            component={CoacheeProposals}
          />
          <Route
            path="/users/dashboard/programs/actions/:sessionId?"
            exact={true}
            component={CoacheeActionsRegister}
          />
          <Route
            path="/users/dashboard/programs/contents/:sessionId?"
            exact={true}
            component={CoacheeContentsRegister}
          />
          <Route
            path="/users/dashboard/programs/:sessionId?"
            exact={true}
            component={CoacheePrograms}
          />
          <Route
            path="/users/dashboard/profile"
            exact={true}
            component={CoacheeProfile}
          />
          <Route
            path="/users/dashboard/search/:expression"
            exact={true}
            component={CoacheeHelpCenter}
          />
          <Route
            path="/users/dashboard/terms"
            exact={true}
            component={CoacheeMyTerms}
          />
          <Route
            path="/users/dashboard/proposals/proposal/:id"
            exact={true}
            component={CoacheeProposalGenerate}
          />

          <Route
            path="/users/response-editor/:sessionId/:meetingType/:contentId"
            exact={true}
            component={CoacheeTemplateEditor}
          />
          <Route
            path="/users/wheel-editor/:sessionId/:meetingType/:contentId"
            exact={true}
            component={CoacheeWheelEditor}
          />
          <Route
            path="/users/form-editor/:sessionId/:meetingType/:contentId"
            exact={true}
            component={CoacheeFormEditor}
          />

          <Route path="/admin" exact={true} component={AdminAuth} />
          <Route
            path="/admin/dashboard"
            exact={true}
            component={AdminDashboard}
          />
          <Route
            path="/admin/dashboard/profile"
            exact={true}
            component={AdminProfile}
          />
          <Route
            path="/admin/dashboard/terms"
            exact={true}
            component={AdminMyTerms}
          />
          <Route
            path="/admin/dashboard/profile/changepassword"
            exact={true}
            component={AdminChangePassword}
          />
          <Route
            path="/admin/template-editor/:id"
            exact={true}
            component={AdminTemplateEditor}
          />
          <Route
            path="/admin/wheel-editor/:id?"
            exact={true}
            component={AdminWheelEditor}
          />
          <Route
            path="/admin/wheel-preview/:id"
            exact={true}
            component={AdminWheelPreview}
          />
          <Route
            path="/admin/form-editor/:id?"
            exact={true}
            component={AdminFormEditor}
          />
          <Route
            path="/admin/form-preview/:id"
            exact={true}
            component={AdminFormPreview}
          />

          <Route
            path="/admin/dashboard/admins"
            exact={true}
            component={AdminAdmins}
          />
          <Route
            path="/admin/dashboard/admins/form"
            exact={true}
            component={AdminAdminsForm}
          />
          <Route
            path="/admin/dashboard/admins/form/:id"
            exact={true}
            component={AdminAdminsForm}
          />

          <Route
            path="/admin/dashboard/coachees"
            exact={true}
            component={AdminCoachees}
          />
          <Route
            path="/admin/dashboard/coachees/coach/:id"
            exact={true}
            component={AdminCoachees}
          />
          <Route
            path="/admin/dashboard/coachees/form"
            exact={true}
            component={AdminCoacheesForm}
          />
          <Route
            path="/admin/dashboard/coachees/form/:id"
            exact={true}
            component={AdminCoacheesForm}
          />

          <Route
            path="/admin/dashboard/coachs"
            exact={true}
            component={AdminCoachs}
          />
          <Route
            path="/admin/dashboard/analytics"
            exact={true}
            component={AdminAnalytics}
          />
          <Route
            path="/admin/dashboard/coachs/infos/:id"
            exact={true}
            component={AdminCoachsInfos}
          />
          <Route
            path="/admin/dashboard/coachs/form"
            exact={true}
            component={AdminCoachsForm}
          />
          <Route
            path="/admin/dashboard/coachs/form/:id"
            exact={true}
            component={AdminCoachsForm}
          />

          <Route
            path="/admin/dashboard/plans"
            exact={true}
            component={AdminPlans}
          />
          <Route
            path="/admin/dashboard/plans/form"
            exact={true}
            component={AdminPlansForm}
          />
          <Route
            path="/admin/dashboard/plans/form/:id"
            exact={true}
            component={AdminPlansForm}
          />

          <Route
            path="/admin/dashboard/combos"
            exact={true}
            component={AdminCombos}
          />
          <Route
            path="/admin/dashboard/combos/form"
            exact={true}
            component={AdminCombosForm}
          />
          <Route
            path="/admin/dashboard/combos/form/:id"
            exact={true}
            component={AdminCombosForm}
          />

          <Route
            path="/admin/dashboard/coupons"
            exact={true}
            component={AdminCoupons}
          />
          <Route
            path="/admin/dashboard/coupons/form/:id?"
            exact={true}
            component={AdminCouponsForm}
          />

          <Route
            path="/admin/dashboard/categories"
            exact={true}
            component={AdminCategories}
          />
          <Route
            path="/admin/dashboard/categories/form"
            exact={true}
            component={AdminCategoriesForm}
          />
          <Route
            path="/admin/dashboard/categories/form/:id"
            exact={true}
            component={AdminCategoriesForm}
          />

          <Route
            path="/admin/dashboard/institutions"
            exact={true}
            component={AdminPartnerInstitutions}
          />
          <Route
            path="/admin/dashboard/institutions/form"
            exact={true}
            component={AdminPartnerInstitutionsForm}
          />
          <Route
            path="/admin/dashboard/institutions/form/:id"
            exact={true}
            component={AdminPartnerInstitutionsForm}
          />

          <Route
            path="/admin/dashboard/faqs"
            exact={true}
            component={AdminFaqs}
          />
          <Route
            path="/admin/dashboard/faqs/form"
            exact={true}
            component={AdminFaqsForm}
          />
          <Route
            path="/admin/dashboard/faqs/form/:id"
            exact={true}
            component={AdminFaqsForm}
          />

          <Route
            path="/admin/dashboard/configs"
            exact={true}
            component={AdminConfigs}
          />
          <Route
            path="/admin/dashboard/configs/form"
            exact={true}
            component={AdminConfigsForm}
          />
          <Route
            path="/admin/dashboard/configs/form/:id"
            exact={true}
            component={AdminConfigsForm}
          />

          <Route
            path="/admin/dashboard/contents"
            exact={true}
            component={AdminContents}
          />
          <Route
            path="/admin/dashboard/contents/coach/:id"
            exact={true}
            component={AdminContents}
          />
          <Route
            path="/admin/dashboard/contents/form"
            exact={true}
            component={AdminContentsForm}
          />
          <Route
            path="/admin/dashboard/contents/form/:id"
            exact={true}
            component={AdminContentsForm}
          />

          <Route
            path="/admin/dashboard/notifications"
            exact={true}
            component={AdminNotifications}
          />
          <Route
            path="/admin/dashboard/notifications/form"
            exact={true}
            component={AdminNotificationsForm}
          />
          <Route
            path="/admin/dashboard/notifications/form/:id"
            exact={true}
            component={AdminNotificationsForm}
          />

          <Route
            path="/admin/dashboard/projects"
            exact={true}
            component={AdminProjects}
          />
          <Route
            path="/admin/dashboard/projects/form"
            exact={true}
            component={AdminProjectsForm}
          />
          <Route
            path="/admin/dashboard/projects/form/:id"
            exact={true}
            component={AdminProjectsForm}
          />

          <Route exact path="/linkedin" component={LinkedInPopUp} />
          <Route path="*" component={NotFound} />
        </Switch>
      </Router>
    </>
  </Provider>,
  document.getElementById('root')
);

registerServiceWorker.register({
  onSuccess: () =>
    store.dispatch(
      updateServiceWorkerInitialized({
        type: SW_INIT,
        serviceWorkerInitialized: true,
      })
    ),
  onUpdate: registration =>
    store.dispatch(
      updateServiceWorkerUpdated({
        type: SW_UPDATE,
        serviceWorkerUpdated: true,
        payload: registration,
      })
    ),
});
