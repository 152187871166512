//libs
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter, Prompt } from 'react-router-dom';

import Switch from 'react-switch';
import ReactLoading from 'react-loading';
import InputMask from 'react-input-mask';
import DatePicker from 'react-datepicker';
import { startOfToday, subYears } from 'date-fns';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import ProgressBar from '@ramonak/react-progress-bar';
import CurrencyInput from 'react-currency-input';
import _ from 'lodash';

//compomentes do sistema
import Service from './Service.js';
import {
  updateImage,
  updateUser,
  updateAlert,
  updateCountries,
} from '../../../actions/SimpleAction';
import SidebarProfile from '../../sidebar/SidebarProfile';
import Header from './../../components/Header';
import Breadcrumbs from './../../components/Breadcrumbs';
import Footer from './../../components/Footer';
import UploadImage from './UploadImage';
import Utils from './../../../libs/Utils.js';
import UploadFile from './../Create/NewUploadFile';
import UploadCert from './../Create/UploadCert';
import TextRecommend from '../../components/text_recommend/TextRecommend';
import BannerEngage from '../../components/banner_engage/BannerEngage';
import CalculateBasics from '../../../services/CalculateBasics';
import {
  TagPickerWrapper,
  CheckboxWrapper,
} from '../../components/rsuite_wrapper/index';
import AddNewBadgesICF from '../../components/AddNewBadgesICF';

//CSS
import '../../../styles/css/style.css';
import '../../../styles/css/profile.css';
import '../../../styles/css/datePicker/style.scss';
import './css/MyProfile.css';
import './scss/MyProfile.scss';

const Typeahead = require('react-typeahead').Typeahead;

const InputError = ({ error, children }) => {
  return (
    <>
      {children}
      {error && <span className="campObrigatorio">{error}</span>}
    </>
  );
};

const TEXT_VALUE_SPECIFC = 'Valor específico';
const PRICES_OPTIONS = [
  'até R$150',
  'de R$151 a até R$300',
  'de R$301 a até R$500',
  'mais de R$500',
  'Sob consulta',
  'Valor específico',
];
const WEEK_OPTIONS = [
  { day: 'SEG', hours: [] },
  { day: 'TER', hours: [] },
  { day: 'QUA', hours: [] },
  { day: 'QUI', hours: [] },
  { day: 'SEX', hours: [] },
  { day: 'SÁB', hours: [] },
  { day: 'DOM', hours: [] },
];
const HOUR_OPTIONS = [
  '08:00',
  '09:00',
  '10:00',
  '11:00',
  '12:00',
  '13:00',
  '14:00',
  '15:00',
  '16:00',
  '17:00',
  '18:00',
];

class MyProfile extends Component {
  constructor(props) {
    super(props);

    const initialWidth = window.innerWidth > 0 ? window.innerWidth : 500;
    this.allowExitPage = false;
    this.state = {
      isVisible: false,
      sideOpen: false,
      principalOpen: true,
      loading: false,
      view: 'myprofile',
      breadcrumbs: [
        { title: 'Dashboard', link: '' },
        { title: 'Meu Perfil', link: '' },
      ],
      showToolTip: false,
      windowWidth: initialWidth - (window.innerWidth * 40) / 100,
      user: {},

      countries: [],
      states: [],
      cities: [],
      loading_state: false,
      loading_cities: false,

      open_event: false,
      loadingModal: false,
      typeTela:
        localStorage.getItem('typeTela') !== undefined &&
        localStorage.getItem('typeTela') !== null
          ? parseInt(localStorage.getItem('typeTela'))
          : 1,
      validateEmail: true,
      birthdayError: '',
      isTestPeriodStarted: props.user.isTestPeriodStarted,
      menuView: 'dados_pessoais',
      professional: [],
      professional_areas_original: [],
      life_areas_original: [],
      selected_expertise_areas: [],
      loading_professional: false,
      presential: false,
      online: false,
      citiesAreas: '',
      maxLength: 3660,
      master: false,
      week: WEEK_OPTIONS,
      hours: HOUR_OPTIONS,
      prices: PRICES_OPTIONS,
      currency: 'R$',
      price: 'Sob consulta',
      specif_value: '',
      loading_currencies: false,
      currencies: [],

      professionalDescription: '',
      experienceDescription: '',
      methodologyDescription: '',
      professional_video: '',
      uploading: false,
      canUploadPresentation: false,
      doc_source: '',
      doc_format: '',
      certificates: [],
      upload: '',
      showBannerEngage: false,
      loading_institutes: false,
      institutes: [],
      institute: '',
      _institute: '',
      year: '',
      graduate: '',
      fieldUrlCredly: '',
      loadingBadgesICF: false,
      badgesICF: [
        {
          IcfPublicURL: '',
          acceptedTermsICF: false,
          badgesSrc: '',
        },
      ],
      instituteHasBadge: false,
      errorbadgesSrc: false,
      errorMessage: '',
      cert_source: '',
      cert_format: '',
      cert_original_name: '',
      percentage_fields_filled: 0,
      phoneError: '',
      ddiList: [],
      dataHasChanged: false,
      shouldBlockNavigation: false,
      shouldBlockNavigationEngage: false,
      isLoadComplete: false,
      isEditingCertificate: false,
      keyCertificate: '',
      removedCertificates: [],
    };

    this.utils = new Utils();
    this.service = new Service();
    this.handleResize = this.handleResize.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.togglePrincipal = this.togglePrincipal.bind(this);
    this.setSidebarLinks = this.setSidebarLinks.bind(this);
    this.goTo = this.goTo.bind(this);
    this.logout = this.logout.bind(this);
    this.loadUser = this.loadUser.bind(this);
    this.loadCities = this.loadCities.bind(this);
    this.loadCurrencySymbols = this.loadCurrencySymbols.bind(this);
    this.toggleSwitch = this.toggleSwitch.bind(this);
    this.calculateBasic = this.calculateBasic.bind(this);
    this.toggleMaster = this.toggleMaster.bind(this);
    this.handleSelectPrice = this.handleSelectPrice.bind(this);
    this.handleBannerIgnore = this.handleBannerIgnore.bind(this);
    this.verifyCanVideo = this.verifyCanVideo.bind(this);
    this.handleEngagePublication = this.handleEngagePublication.bind(this);
    this.saveCuration = this.saveCuration.bind(this);
    this.refPersonalData = React.createRef();
    this.refExpertiseAreas = React.createRef();
    this.refSessions = React.createRef();
    this.refProfessionalData = React.createRef();
    this.refFormation = React.createRef();
    this.refURLPublic = React.createRef();
    this.calculateBasics = new CalculateBasics();
    this.refYear = React.createRef();
    this.refCurseName = React.createRef();
    this.addBadgesICF = this.addBadgesICF.bind(this);
    this.executeAddBadgesICF = this.executeAddBadgesICF.bind(this);
    this.deleteBadgesICF = this.deleteBadgesICF.bind(this);
    this.AddCertificate = this.AddCertificate.bind(this);
  }

  async loadDdis() {
    const result = await this.service.ddis();
    if (result && result.success) {
      const ddis = result.ddis.map(item => {
        return item.ddi;
      });
      this.setState({ ddiList: ddis });
    }
  }

  async loadInstitutes(instituteSaved) {
    let institu = [];
    this.setState({ loading_institutes: true });
    try {
      const result = await this.service.institutes();
      if (result && result.success) {
        result.options.forEach(inst => {
          if (inst.partner) {
            institu.push(inst);
          }
        });
        if (instituteSaved) institu.push({ name: instituteSaved });
        this.setState({
          institutes: institu,
          institute: instituteSaved ? instituteSaved : '',
        });
      }
    } finally {
      this.cancelLoadingInstitutes();
    }
  }

  async createInstitute(value) {
    this.setState({ loading_institutes: true });
    try {
      if (this.state.institutes.filter(el => el.name === value).length === 0) {
        await this.service.saveInstitute({
          name: value,
        });
        this.loadInstitutes(value);
      }
    } finally {
      this.cancelLoadingInstitutes();
    }
  }

  async deleteBadgesICF(urlDelete) {
    this.setState({
      loadingBadgesICF: true,
    });
    const newBadges = this.state.badgesICF.filter(
      el => el.IcfPublicURL !== urlDelete
    );

    await this.updateBadges(newBadges);
    this.setState({
      badgesICF: newBadges,
      instituteHasBadge: newBadges.length > 0,
    });
    this.cancelLoadingBadges();
  }

  async executeAddBadgesICF() {
    const allBadges = this.state.badgesICF;

    const validUrlDuplicated = this.state.badgesICF.filter(
      el => el.IcfPublicURL === this.state.fieldUrlCredly
    );

    if (validUrlDuplicated && validUrlDuplicated.length > 0) {
      this.cancelLoadingBadges();
      this.setState({
        errorbadgesSrc: true,
        errorMessage:
          'A URL pública do Badge do ICF está duplicada, verifique.',
      });
    } else {
      if (allBadges.length === 4) {
        this.cancelLoadingBadges();
        this.setState({
          errorbadgesSrc: true,
          errorMessage:
            'Não será possível carregar mais do que 4 Badges, verifique.',
        });
        return;
      }

      const src = await this.getBadgesSRC(this.state.fieldUrlCredly);

      if (src && src.success) {
        if (
          allBadges &&
          allBadges.length === 1 &&
          allBadges[0].IcfPublicURL === ''
        ) {
          allBadges[0].IcfPublicURL = this.state.fieldUrlCredly;
          allBadges[0].acceptedTermsICF = true;
          allBadges[0].badgesSrc = src.badgesSrc;
        } else {
          allBadges.push({
            IcfPublicURL: this.state.fieldUrlCredly,
            acceptedTermsICF: true,
            badgesSrc: src.badgesSrc,
          });
        }

        const response = await this.updateBadges(allBadges);

        if (response && !response.success) {
          this.setState({
            errorbadgesSrc: true,
            errorMessage: response.message,
          });
        } else {
          this.setState({ fieldUrlCredly: '', badgesICF: allBadges });
          if (this.refProps && this.refProps.current) {
            this.refProps.current.focus();
          }
        }
        this.cancelLoadingBadges();
      } else if (src && !src.success) {
        this.cancelLoadingBadges();
        this.setState({
          errorbadgesSrc: true,
          errorMessage: 'Não foi possível carregar o Badge, tente novamente.',
        });
        return;
      } else {
        this.cancelLoadingBadges();
        return;
      }
    }
  }

  addBadgesICF() {
    this.setState({
      errorbadgesSrc: false,
      errorMessage: '',
      loadingBadgesICF: true,
    });

    const someNotAccepted = this.state.badgesICF.some(
      el => el.acceptedTermsICF === false || el.IcfPublicURL === ''
    );

    if (
      this.state.instituteHasBadge &&
      (this.state.badgesICF.length === 0 || someNotAccepted)
    ) {
      this.PopAlertICFTermAccept();
    } else {
      this.executeAddBadgesICF();
    }
  }

  async AddCertificate() {
    if (this.state.institute !== '' && this.state.year !== '') {
      this.resolveAdd();
    } else {
      if (this.state._institute !== '' && this.state.year !== '') {
        await this.createInstitute(this.state._institute);
        this.setState({ institute: this.state._institute });
        this.resolveAdd();
      }
    }
  }

  async resolveAdd() {
    this.setState({ loading_institutes: true });

    try {
      const certificates = this.state.certificates;
      const certificate = {
        institute: this.state.institute,
        year: this.state.year,
        graduate: this.state.graduate,
        certificate: this.state.cert_source,
        certificateURL: this.state.cert_source
          ? this.utils.resolveURL(
              this.state.cert_source,
              this.service.getServer()
            )
          : '',
      };
      if (this.state.isEditingCertificate && this.state.keyCertificate !== '') {
        certificates[this.state.keyCertificate] = certificate;
      } else {
        certificates.push(certificate);
      }

      const result = await this.service.updateCertificates({
        certificate: certificates,
      });

      if (result && !result.success) {
        certificates.pop(certificate);
        return;
      }

      await this.removeCertificateFromServer();

      this.setState({
        certificates,
        institute: '',
        _institute: '',
        year: '',
        graduate: '',
        cert_source: '',
        cert_format: '',
        cert_original_name: '',
        isEditingCertificate: false,
        keyCertificate: '',
        removedCertificates: [],
      });

      if (this.refCurseName && this.refCurseName.current) {
        this.refCurseName.current.focus();
      }
    } finally {
      this.cancelLoadingInstitutes();
    }
  }

  cancelEditingCertificate() {
    this.setState({
      loading_institutes: true,
      institute: '',
      _institute: '',
      year: '',
      graduate: '',
      cert_source: '',
      cert_format: '',
      cert_original_name: '',
      isEditingCertificate: false,
      keyCertificate: '',
      removedCertificates: [],
    });
    this.cancelLoadingInstitutes();
  }

  cancelLoadingInstitutes = () => {
    setTimeout(() => {
      this.setState({ loading_institutes: false });
    }, 200);
  };

  cancelLoadingBadges = () => {
    setTimeout(() => {
      this.setState({ loadingBadgesICF: false });
    }, 200);
  };

  async removeCertificateFromServer() {
    if (this.state.removedCertificates.length > 0) {
      await this.service.removeCert(this.state.removedCertificates);
      this.setState({ removedCertificates: [] });
    }
  }

  formValidCertificate() {
    const { institute, year, graduate, _institute } = this.state;

    const allFieldsFilled = (institute || _institute) && year && graduate;

    return allFieldsFilled;
  }

  handleBannerIgnore = () => {
    this.setState({ showBannerEngage: !this.state.showBannerEngage });
  };

  parseYoutubeID(value) {
    if (value.indexOf('?v=') !== -1) {
      let _begin = value.indexOf('?v=') + 3;
      let _end = value.indexOf('?v=') + 3 + 11;
      return value.substring(_begin, _end);
    }
    if (value.indexOf('youtu.be/') !== -1) {
      let _begin = value.indexOf('youtu.be/') + 9;
      let _end = value.indexOf('youtu.be/') + 9 + 11;
      return value.substring(_begin, _end);
    }
    if (value.indexOf('embed/') !== -1) {
      let _begin = value.indexOf('embed/') + 6;
      let _end = value.indexOf('embed/') + 6 + 11;
      return value.substring(_begin, _end);
    }
    return false;
  }

  async loadUserProfessionalDescription(result) {
    this.setState({ loading_professional: true });
    if (result && result.professional_description !== undefined) {
      this.setState({
        experienceDescription: result.professional_description.experience,
        professionalDescription: result.professional_description.professional,
        methodologyDescription: result.professional_description.methodology,
        upload: result.professional_description.upload,
        certificates: result.professional_description.certificate
          ? result.professional_description.certificate
          : [],
        professional_video: result.professional_video || '',
        uploading: this.videoSlideshowRule(),
        badgesICF: result.professional_description.badgesICF || [],
        instituteHasBadge:
          result.professional_description.badgesICF &&
          result.professional_description.badgesICF.length > 0,
      });

      const certificates = this.state.certificates.map(certificate => ({
        ...certificate,
        certificateURL: certificate.certificate
          ? this.utils.resolveURL(
              certificate.certificate,
              this.service.getServer()
            )
          : '',
      }));

      this.setState({ certificates });

      if (result.professional_present) {
        const professional_present_data = JSON.parse(
          result.professional_present
        );
        this.setState({
          doc_format: professional_present_data.doc_format,
          doc_source: professional_present_data.doc_source,
          original_name: professional_present_data.original_name,
          canUploadPresentation: this.videoSlideshowRule(),
        });
      }
    }
    this.setState({ loading_professional: false });
  }

  handleSelectPrice = value => {
    const currencySelect = value;

    if (currencySelect === 'R$') {
      this.setState({
        currency: currencySelect,
        prices: PRICES_OPTIONS,
      });
    } else {
      this.setState({
        currency: currencySelect,
        prices: ['Sob consulta', 'Valor específico'],
      });
    }
  };

  async loadUserProfessionalValue(result) {
    this.setState({ loading_professional: true });
    if (result && result.professional_value !== undefined) {
      const currecyResult = result.professional_value.currency || 'R$';
      this.setState({
        currency: currecyResult,
        price: result.professional_value.price,
        specif_value: result.professional_value.specif_value,
      });
      this.handleSelectPrice(currecyResult);
    }
    this.setState({ loading_professional: false });
  }

  async loadCurrencySymbols() {
    this.setState({ loading_currencies: true });
    const result = await this.service.currencies();
    if (result && result.success) {
      this.setState({
        loading_currencies: false,
        currencies: result.data,
      });
    }
    this.setState({ loading_currencies: false });
  }

  async loadProfessional_Life() {
    this.setState({ loading_professional: true });
    let result = await this.service.options_professional();
    let resultLife = await this.service.options_life();
    if (result || resultLife) {
      const professionalAreas = result.options.map(prof => {
        return {
          label: prof.option,
          value: prof.option,
          role: 'Professional',
        };
      });

      const professionalLife = resultLife.options.map(life => {
        return {
          label: life.option,
          value: life.option,
          role: 'Life',
        };
      });
      this.setState({
        loading_professional: false,
        professional: [...professionalAreas, ...professionalLife],
        professional_areas_original: [...result.options],
        life_areas_original: [...resultLife.options],
      });
    }
  }

  toggleSwitch() {
    if (!this.formValid()) {
      this.props.updateAlert('Preencha todos os campos obrigatórios');
      return;
    }
    const isVisible = !this.state.isVisible;
    if (isVisible) {
      if (this.props.user.curation && this.props.user.curation.active) {
        this.save({ profile_active: isVisible }, 'engage');
        this.engageAlert();
      } else {
        this.curationAlert({ profile_active: isVisible });
      }
    } else {
      this.save({ profile_active: isVisible }, 'engage');
      this.engageAlert();
    }
    this.setState({ isVisible });
  }

  checkEngageFieldsRecommend = () => {
    let result = true;
    // const validationProfessional = this.professionalDescriptionCompleted();
    // result = validationProfessional.isFilled;

    if (this.state.user)
      if (
        this.state.user.image === null ||
        this.state.user.image === undefined ||
        !this.state.user.image
      ) {
        result = false;
      }

    // if (
    //   this.state.selected_expertise_areas === undefined ||
    //   this.state.selected_expertise_areas === null ||
    //   !this.state.selected_expertise_areas ||
    //   this.state.selected_expertise_areas.length === 0
    // ) {
    //   result = false;
    // }

    // if (!this.state.presential && !this.state.online) {
    //   result = false;
    // }

    return result;
  };

  handleEngagePublication = () => {
    if (!this.state.isVisible && !this.checkEngageFieldsRecommend()) {
      this.engagePopUpAlert();
    } else {
      this.toggleSwitch();
    }
  };

  PopAlertICFTermAccept = () => {
    this.props.updateAlert(
      <div>
        <p className="text-modal-big">
          Eu lí e estou de acordo com o código de ética do ICF.
        </p>
        <a
          className="text-modal-big highlight"
          target={'_blank'}
          href="https://coachingfederation.org/ethics/code-of-ethics"
          style={{ color: '#00838f' }}
        >
          Ler o Código de Ética
        </a>

        <div
          className="btn-footer-modal"
          style={{ marginTop: '10px', flexDirection: 'column' }}
        >
          <div
            className="bt-blue"
            style={{ marginBottom: '5px', marginLeft: 0 }}
            onClick={() => {
              this.setState({ acceptedTermsICF: true });
              this.props.updateAlert('');
              this.executeAddBadgesICF();
            }}
          >
            Aceitar
          </div>
          <div
            className="bt-blue-outline"
            onClick={() => {
              this.setState({
                acceptedTermsICF: false,
                loadingBadgesICF: false,
              });
              this.props.updateAlert('');
            }}
          >
            Não Aceitar
          </div>
        </div>
      </div>
    );
  };

  engagePopUpAlert = (isButtonSave, saveFunction) => {
    this.props.updateAlert(
      <div>
        <p className="text-modal-big">
          Alguns campos recomendados ainda não foram preenchidos.
        </p>
        <p className="text-modal-medium">
          Sugerimos o preenchimento desses campos antes de publicar o perfil no{' '}
          <span style={{ color: '#a6ce39' }}>engage.</span>
        </p>
        <div className="btn-footer-modal" style={{ flexDirection: 'column' }}>
          <div
            className="bt-blue"
            style={{ marginBottom: '5px', marginLeft: 0 }}
            onClick={() => this.props.updateAlert('')}
          >
            Continuar preenchendo
          </div>
          <div
            className="bt-blue-outline"
            onClick={() => {
              this.props.updateAlert('');
              if (isButtonSave) {
                saveFunction();
              } else {
                this.toggleSwitch();
              }
            }}
          >
            {isButtonSave ? 'Seguir mesmo assim' : 'Publicar mesmo assim'}
          </div>
        </div>
      </div>
    );
  };

  engagePopUpAlertIncomplete = ({ isButtonSave, saveFunction, location }) => {
    this.props.updateAlert(
      <div>
        <p className="text-modal-big">
          Seu perfil ainda não está pronto para ser publicado no&nbsp;
          <span style={{ color: '#a6ce39' }}>engage.</span>
        </p>
        <p className="text-modal-medium">
          Seus potenciais clientes ainda não poderão ver seu perfil.
        </p>
        <div className="btn-footer-modal" style={{ flexDirection: 'column' }}>
          <div
            className="bt-blue"
            style={{ marginBottom: '5px', marginLeft: 0 }}
            onClick={() => this.props.updateAlert('')}
          >
            Continuar preenchendo
          </div>
          <div
            className="bt-blue-outline"
            onClick={() => {
              this.props.updateAlert('');
              if (isButtonSave) {
                if (_.isFunction(saveFunction)) saveFunction();
              } else if (location) {
                if (this.props.location.pathname !== location.pathname) {
                  this.allowExitPage = true;
                  const locationGoTo = location.pathname.replace('/', '');
                  this.goTo(locationGoTo);
                }
              }
            }}
          >
            Seguir sem publicar agora
          </div>
        </div>
      </div>
    );
  };

  engagePopUpAlertComplete = ({ location }) => {
    this.props.updateAlert(
      <div>
        <p className="text-modal-big">
          Seu perfil está pronto para ser publicado no&nbsp;
          <span style={{ color: '#a6ce39' }}>engage.</span>
        </p>
        <p className="text-modal-medium">
          E fique disponível agora mesmo para novas oportunidades.
        </p>
        <div className="btn-footer-modal" style={{ flexDirection: 'column' }}>
          <div
            className="bt-blue"
            style={{ marginBottom: '5px', marginLeft: 0 }}
            onClick={async () => {
              if (!this.formValid()) {
                this.props.updateAlert('Preencha todos os campos obrigatórios');
                return;
              }
              const isVisible = !this.state.isVisible;
              this.save({ profile_active: isVisible }, 'engage');

              this.props.updateAlert('');
              this.allowExitPage = true;
              const locationGoTo = location.pathname.replace('/', '');
              if (this.props.location.pathname !== location.pathname) {
                this.goTo(locationGoTo);
              }
            }}
          >
            Publicar agora
          </div>
          <div
            className="bt-blue-outline"
            onClick={() => {
              this.props.updateAlert('');
              this.allowExitPage = true;
              const locationGoTo = location.pathname.replace('/', '');
              if (this.props.location.pathname !== location.pathname) {
                this.goTo(locationGoTo);
              }
            }}
          >
            Não publicar ainda
          </div>
        </div>
      </div>
    );
  };

  engageAlert = () => {
    this.props.updateAlert(
      <div>
        <p>Seu perfil já foi atualizado no engage, o ambiente de negócios</p>
        <div className="btn-footer-modal">
          <div className="bt-blue" onClick={() => this.props.updateAlert('')}>
            OK
          </div>
        </div>
      </div>
    );
  };

  curationAlert(paramSave) {
    this.props.updateAlert(
      <div className="postition-relative">
        <div className="mb-10">
          Gostaria de passar pela curadoria
          <i className="info-curation fas fa-info-circle">
            <div className="curation-hint">
              Curadoria climby é uma validação das informações apresentadas no
              seu perfil. Seu perfil receberá um selo de validação ao ser
              aprovado no processo.
            </div>
          </i>
          e receber o selo de validação de dados?
        </div>

        <div className="btn-footer-modal">
          <div
            className="bt-blue-outline"
            style={{ margin: '0 5px' }}
            onClick={() => this.save(paramSave)}
          >
            Não
          </div>
          <div
            className="bt-blue"
            style={{ margin: '0 5px' }}
            onClick={() => this.handleCuration()}
          >
            Sim
          </div>
        </div>
      </div>
    );
  }

  newCurationProcessAlert = () => {
    this.props.updateAlert(
      <div>
        <p className="mb-20">
          Para manter sua condição de perfil verificado, você aceita passar por
          um novo processo de curadoria?
        </p>

        <div className="btn-footer-modal">
          <div
            className="bt-blue-outline"
            style={{ margin: '0 5px' }}
            onClick={() => this.newCurationProcess(false)}
          >
            Não aceito
          </div>
          <div
            className="bt-blue"
            style={{ margin: '0 5px' }}
            onClick={() => this.newCurationProcess(true)}
          >
            Aceito
          </div>
        </div>
      </div>
    );
  };

  handleValidationProfessionalDataForCuration(validationProfessional) {
    if (validationProfessional.isDescriptionEmpty) {
      this.executeScroll(this.refProfessionalData);
      return this.props.updateAlert(
        'É necessário preencher seus dados profissionais para participar do processo de curadoria.'
      );
    }

    if (validationProfessional.isCertificateEmpty) {
      this.executeScroll(this.refFormation);
      return this.props.updateAlert(
        'É necessário preencher seus certificados profissionais para participar do processo de curadoria.'
      );
    }
  }

  newCurationProcess = async curationValue => {
    const validationProfessional = this.professionalDescriptionCompleted();
    if (curationValue && !validationProfessional.isFilled) {
      return this.handleValidationProfessionalDataForCuration(
        validationProfessional
      );
    }
    const data = {
      active: curationValue,
      date: curationValue ? new Date() : '',
      status: curationValue ? 'Solicitado' : '',
      review: curationValue,
    };
    const result = await this.service.save({ curation: JSON.stringify(data) });
    if (result && result.success) {
      this.save();
    }
  };

  async saveCuration() {
    if (!this.isPhoneValid(this.state.user.phone))
      this.setState({
        phoneError:
          'Telefone com tamanho inválido deve ser vazio ou entre 4 e 15 dígitos.',
      });
    else
      this.setState({
        phoneError: '',
      });
    if (!this.formValid()) {
      this.props.updateAlert('Preencha todos os campos obrigatórios');
      return;
    }

    if (this.state.isVisible) {
      const { user } = this.props;
      if (!user.curation || !user.curation.active) {
        this.curationAlert();
      } else if (user.curation && user.curation.status === 'Aprovado') {
        this.newCurationProcessAlert();
      } else if (user.curation && user.curation.status === 'Solicitado') {
        const validationProfessional = this.professionalDescriptionCompleted();
        if (!validationProfessional.isFilled) {
          return this.handleValidationProfessionalDataForCuration(
            validationProfessional
          );
        }
        this.save();
      }
    } else {
      this.save();
    }
  }

  handleCuration = async () => {
    if (!this.formValid()) {
      this.props.updateAlert('Preencha todos os campos obrigatórios');
      return;
    }

    const activeCuration = this.props.user.curation
      ? !this.props.user.curation.active
      : true;
    const validationProfessional = this.professionalDescriptionCompleted();
    if (activeCuration && !validationProfessional.isFilled) {
      return this.handleValidationProfessionalDataForCuration(
        validationProfessional
      );
    }
    this.setState({ loading: true });
    const data = {
      active: activeCuration,
      date: activeCuration ? new Date() : '',
      status: activeCuration ? 'Solicitado' : '',
      review: false,
    };
    const result = await this.service.save({
      curation: JSON.stringify(data),
    });
    if (result && result.success) {
      this.loadUser();
      this.save({}, 'engage');
      this.setState({ activeCuration });
      if (activeCuration) {
        this.props.updateAlert(
          <div>
            <p>
              Você autorizou a curadoria a avaliar seu perfil. Caso tenhamos
              alguma dúvida entraremos em contato. Ao ser aprovado seu perfil
              receberá o selo climby de validação.
            </p>
            <div className="btn-footer-modal">
              <div
                className="bt-blue"
                onClick={() => this.props.updateAlert('')}
              >
                OK
              </div>
            </div>
          </div>
        );
      }
    }
    this.setState({ loading: false });
  };

  professionalDescriptionCompleted = () => {
    const {
      experienceDescription,
      professionalDescription,
      methodologyDescription,
      certificates,
    } = this.state;
    let isFilled = true;
    if (
      !experienceDescription ||
      !professionalDescription ||
      !methodologyDescription ||
      certificates.length === 0
    ) {
      isFilled = false;
    }

    return {
      isFilled,
      isDescriptionEmpty:
        !experienceDescription ||
        !professionalDescription ||
        !methodologyDescription,
      isCertificateEmpty: certificates.length === 0,
    };
  };

  toggleMenu() {
    if (this.state.sideOpen) {
      this.setState({ sideOpen: false });
    } else {
      this.setState({ sideOpen: true });
    }
  }

  togglePrincipal() {
    if (this.state.principalOpen) {
      this.setState({ principalOpen: false });
    } else {
      this.setState({ principalOpen: true });
    }
  }

  toggleMaster() {
    this.setState({ master: !this.state.master });
  }

  goTo(page) {
    this.props.history.push(`/${page}`);
  }

  verifyLogin() {
    if (!this.utils.isLogged()) {
      this.goTo('');
    }
  }

  logout() {
    window.location.reload();
    this.utils.setLogout();
    this.props.updateImage('');
    this.goTo('');
  }

  async componentDidMount() {
    this.verifyLogin();
    let user = this.utils.getUser();
    this.setState({ user });
    window.addEventListener('resize', this.handleResize);
    this.loadDdis();
    this.loadCountries();
    this.loadInstitutes();
    await this.loadUser();
    this.calculateBasic();
  }

  componentDidUpdate(prevProps, prevStates) {
    if (prevProps.user !== this.props.user) {
      this.calculateBasic();
    }
    this.verifyIfDataHasChanged(prevProps);

    if (
      prevStates.showBannerEngage !== undefined &&
      prevProps.menu === this.props.menu
    ) {
      if (
        !prevStates.showBannerEngage &&
        !this.state.showBannerEngage &&
        !prevStates.isVisible &&
        !this.state.isVisible
      ) {
        if (this.checkEngageFieldsRecommend()) {
          this.setState({ showBannerEngage: true });
        }
      } else if (
        prevStates.showBannerEngage &&
        this.state.showBannerEngage &&
        !this.checkEngageFieldsRecommend()
      ) {
        this.setState({ showBannerEngage: false });
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  verifyIfDataHasChanged(prevProps) {
    if (prevProps.user.name && this.state.user) {
      if (!this.state.shouldBlockNavigation && this.state.isLoadComplete) {
        const birthDayUpdated = this.state.user.birthday
          ? this.utils.parseDateUSA(this.state.user.birthday)
          : '';

        let lengthProfessionalAreasLife = 0;
        let lengthProfessionalAreasProfessional = 0;
        if (
          prevProps.user.professional_area &&
          prevProps.user.professional_area.life &&
          prevProps.user.professional_area.life.length
        ) {
          lengthProfessionalAreasLife =
            prevProps.user.professional_area.life.length;
        }

        if (
          prevProps.user.professional_area &&
          prevProps.user.professional_area.professional &&
          prevProps.user.professional_area.professional.length
        ) {
          lengthProfessionalAreasProfessional =
            prevProps.user.professional_area.professional.length;
        }

        const lengthProfessionalAreas =
          lengthProfessionalAreasLife + lengthProfessionalAreasProfessional;

        let professional_presentParsed = { doc_source: '' };
        if (prevProps.user.professional_present) {
          professional_presentParsed = JSON.parse(
            prevProps.user.professional_present
          );
        }

        if (
          prevProps.user.name !== this.state.user.name ||
          prevProps.user.surname !== this.state.user.surname ||
          prevProps.user.birthday !== birthDayUpdated ||
          prevProps.user.email !== this.state.user.email ||
          prevProps.user.engage_name !== this.state.user.engage_name ||
          prevProps.user.location[0].country !== this.state.user.country ||
          prevProps.user.location[0].state !== this.state.user.state ||
          prevProps.user.location[0].city !== this.state.user.city ||
          prevProps.user.contact[0].ddi !== this.state.user.ddi ||
          prevProps.user.contact[0].phone !== this.state.user.phone ||
          prevProps.user.contact[0].site !== this.state.user.site ||
          lengthProfessionalAreas !==
            this.state.selected_expertise_areas.length ||
          (prevProps.user.professional_area &&
            (prevProps.user.professional_area.presential !==
              this.state.presential ||
              prevProps.user.professional_area.online !== this.state.online ||
              prevProps.user.professional_area.cities !==
                this.state.citiesAreas)) ||
          (prevProps.user.professional_session &&
            prevProps.user.professional_session.master !== this.state.master) ||
          (prevProps.user.professional_value &&
            (prevProps.user.professional_value.currency !==
              this.state.currency ||
              prevProps.user.professional_value.price !== this.state.price ||
              prevProps.user.professional_value.specif_value !==
                this.state.specif_value)) ||
          (prevProps.user.professional_description &&
            (prevProps.user.professional_description.experience !==
              this.state.experienceDescription ||
              prevProps.user.professional_description.professional !==
                this.state.professionalDescription ||
              prevProps.user.professional_description.methodology !==
                this.state.methodologyDescription ||
              prevProps.user.professional_description.certificate.length !==
                this.state.certificates.length)) ||
          professional_presentParsed.doc_source !== this.state.doc_source ||
          prevProps.user.professional_video !== this.state.professional_video
        ) {
          this.setState({ shouldBlockNavigation: true, dataHasChanged: true });
        }
      }
      if (
        !this.state.shouldBlockNavigationEngage &&
        this.state.isLoadComplete
      ) {
        if (!this.state.isVisible && this.checkEngageFieldsRecommend()) {
          this.setState({ shouldBlockNavigationEngage: true });
        } else if (
          !this.state.isVisible &&
          !this.checkEngageFieldsRecommend()
        ) {
          this.setState({ shouldBlockNavigationEngage: true });
        }
      }
    }
  }

  dataNotSavedAlert = location => {
    const locationGoTo = location.pathname.replace('/', '');
    this.props.updateAlert(
      <div>
        <p>
          Algumas informações alteradas não foram salvas. Deseja mesmo sair sem
          salvar?
        </p>
        <div className="btn-footer-modal" style={{ flexDirection: 'column' }}>
          <div
            className="bt-blue"
            style={{ marginBottom: '5px', marginLeft: 0 }}
            onClick={() => {
              this.allowExitPage = true;
              this.props.updateAlert('');
              if (this.props.location.pathname !== location.pathname) {
                this.goTo(locationGoTo);
              }
            }}
          >
            Sair sem salvar
          </div>
          <div
            className="bt-blue-outline"
            onClick={async () => {
              if (this.formValid()) {
                await this.save();
                this.allowExitPage = true;
                this.props.updateAlert('');
                if (this.props.location.pathname !== location.pathname) {
                  this.goTo(locationGoTo);
                }
              } else {
                this.props.updateAlert('Preencha todos os campos obrigatórios');
              }
            }}
          >
            Salvar e sair
          </div>
        </div>
      </div>
    );
  };

  setSidebarLinks(view) {
    this.setState({ view });
    this.goTo(`dashboard/${view}`);
  }

  handleResize() {
    let _ww =
      window.innerWidth < 890 && window.innerWidth > 570
        ? window.innerWidth - (window.innerWidth * 30) / 100
        : window.innerWidth <= 570
        ? window.innerWidth - (window.innerWidth * 40) / 100
        : window.innerWidth - (window.innerWidth * 40) / 100;

    this.setState({
      windowWidth: _ww,
    });
  }

  loadCountries = async () => {
    this.setState({ loading_state: true });
    let result;
    if (this.props.countries && this.props.countries.length === 0) {
      const response = await this.service.countries();
      result = response.countries;
      this.props.updateCountries(result);
    } else {
      result = this.props.countries;
    }

    if (result) {
      this.setState({ loading_state: false, countries: result });
    } else {
      this.setState({ loading_state: false });
    }
  };

  loadStates = async country => {
    this.setState({ loading_state: true });
    if (country === 'Brasil') {
      let result = await this.service.states();
      if (result)
        this.setState({ loading_state: false, states: result.states });
    } else {
      const param = encodeURI(country);
      let result = await this.service.statesForeign(param);
      if (result && result.success) {
        this.setState({
          loading_state: false,
          states: result.data[0].states ? result.data[0].states : [],
          cities: [],
        });
      } else {
        this.setState({
          loading_state: false,
        });
      }
    }
  };

  loadCities = async (state, country) => {
    this.setState({
      user: { ...this.state.user, state, city: '' },
      cities: [],
    });

    if (country === 'Brasil' || country === undefined) {
      if (!state) return;
      this.setState({ loading_cities: true });
      let result = await this.service.cities(state);
      if (result)
        this.setState({ loading_cities: false, cities: result.cities });
    } else {
      this.setState({ loading_cities: true });
      const citiesData = this.state.states.filter(item => item.name === state);
      this.setState({
        loading_cities: false,
        cities:
          citiesData.length > 0 && citiesData[0].cities
            ? citiesData[0].cities
            : [],
      });
    }
  };

  loadDdi = async country => {
    let ddiCountry = '55';
    if (country) {
      const countryFiltered = this.state.countries.filter(
        item => item.name === country
      );
      if (countryFiltered[0]) {
        ddiCountry = countryFiltered[0].ddi[0]
          ? countryFiltered[0].ddi[0]
          : '55';
      }
    }
    return ddiCountry;
  };

  async loadUser() {
    this.setState({ isLoadComplete: false });
    let result = await this.service.me();
    if (!result) {
      this.logout();
      return;
    }

    this.props.updateUser(result.user);

    let user = {
      ...result.user,
      birthday: result.user.birthday
        ? new Date(this.utils.resolveSessionDate(result.user.birthday))
        : '',
      engage_name:
        result.user.engage_name ||
        `${result.user.name || ''} ${result.user.surname || ''}`,
    };
    delete user.location;
    delete user.contact;
    if (result.user.contact && result.user.contact.length > 0) {
      user['phone'] = result.user.contact[0].phone;
      user['site'] = result.user.contact[0].site;
      user['ddi'] = result.user.contact[0].ddi;
    }

    if (result.user.location && result.user.location.length > 0) {
      user['country'] = result.user.location[0].country || 'Brasil';
      user['state'] = result.user.location[0].state;
      user['city'] = result.user.location[0].city;
      await this.loadStates(user.country);
      this.loadCities(user.state, user.country);
      user['ddi'] =
        user['ddi'] === '' || user['ddi'] === undefined
          ? await this.loadDdi(user['country'])
          : user['ddi'];
    }

    const isVisible = result.user.profile_active || false;

    await this.loadProfessional_Life();

    this.loadCurrencySymbols();

    this.loadUserProfessionalArea(result.user);

    this.loadUserProfessionalSession(result.user);

    this.loadUserProfessionalValue(result.user);

    this.loadUserProfessionalDescription(result.user);

    if (user.image) {
      this.props.updateImage(user.image);
    }

    if (isVisible) {
      this.setState({ showBannerEngage: false });
    }

    this.setState({ user, isVisible, isLoadComplete: true });
  }

  async loadUserProfessionalSession(result) {
    if (result && result.professional_session !== undefined) {
      this.setState({
        week: result.professional_session.week,
        master: result.professional_session.master,
      });
    }
  }

  loadUserProfessionalArea = async resultProfissional => {
    if (resultProfissional && resultProfissional.professional_area) {
      const life_areas_loaded = resultProfissional.professional_area.life.map(
        lifeId => {
          const nameLife = this.state.life_areas_original.filter(
            item => item.id === lifeId
          );
          return nameLife[0].option;
        }
      );

      const professional_areas_loaded = resultProfissional.professional_area.professional.map(
        professionalId => {
          const nameProfessional = this.state.professional_areas_original.filter(
            item => item.id === professionalId
          );
          return nameProfessional[0].option;
        }
      );

      this.setState({
        selected_expertise_areas: [
          ...professional_areas_loaded,
          ...life_areas_loaded,
        ],
        citiesAreas: resultProfissional.professional_area.cities,
        presential: resultProfissional.professional_area.presential,
        online: resultProfissional.professional_area.online,
      });
    }
  };

  saveProfessional_area = async () => {
    const selected_professional = this.state.selected_expertise_areas
      .map(area => {
        const professionalFiltered = this.state.professional_areas_original.filter(
          value => {
            return value.option === area;
          }
        );
        return professionalFiltered[0] ? professionalFiltered[0].id : '';
      })
      .filter(item => item !== '');

    const selected_life = this.state.selected_expertise_areas
      .map(area => {
        const lifeFiltered = this.state.life_areas_original.filter(
          value => value.option === area
        );
        return lifeFiltered[0] ? lifeFiltered[0].id : '';
      })
      .filter(item => item !== '');

    let professional_area = {
      professional: selected_professional,
      life: selected_life,
      presential: this.state.presential,
      online: this.state.online,
      cities: this.state.citiesAreas,
    };
    return professional_area;
  };

  save = async (newUser, typeRequest) => {
    const {
      name,
      email,
      surname,
      engage_name,
      country,
      city,
      state,
      birthday,
      phone,
      site,
      ddi,
    } = this.state.user;

    if (this.formValid()) {
      this.setState({ loading: true });
      await this.validateEmail(email);

      if (this.state.validateEmail) {
        const phoneOk = phone ? phone.replace(/_/g, '').trim() : '';
        const professional_area = await this.saveProfessional_area();
        const professional_session = {
          week: this.state.week,
          master: this.state.master,
        };

        const professional_value = {
          currency: this.state.currency,
          price: this.state.price,
          specif_value:
            this.state.price === TEXT_VALUE_SPECIFC
              ? this.state.specif_value
              : '',
        };

        const professional_description = {
          experience: this.state.experienceDescription,
          professional: this.state.professionalDescription,
          methodology: this.state.methodologyDescription,
          certificate: this.state.certificates,
          upload:
            this.state.upload && this.state.upload.length > 0
              ? this.state.upload
              : '',
          badgesICF: this.state.badgesICF,
        };

        const professional_present = {
          doc_format: this.state.doc_format,
          doc_source: this.state.doc_source,
          original_name: this.state.original_name,
        };

        let user = {
          name,
          email,
          surname,
          engage_name: engage_name || '',
          location: JSON.stringify([{ country, state, city }]),
          contact: JSON.stringify([{ ddi, phone: phoneOk, site }]),
          birthday: birthday ? this.utils.parseDateUSA(birthday) : '',
          professional_area: JSON.stringify(professional_area),
          professional_session: JSON.stringify(professional_session),
          professional_value: JSON.stringify(professional_value),
          professional_description: JSON.stringify(professional_description),
          professional_video:
            this.state.professional_video.length > 0
              ? this.state.professional_video
              : ' ',
          professional_present: JSON.stringify(professional_present),
          ...newUser,
        };
        let result = await this.service.save(user);
        if (result.success) {
          if (typeRequest !== 'engage') {
            this.props.updateAlert('Salvo com sucesso');
          }
          this.loadUser();
        }
        if (result.status === 'Error') {
          this.props.updateAlert(result.messages[0]);
        }
      }

      this.setState({
        loading: false,
        shouldBlockNavigation: false,
        shouldBlockNavigationEngage: false,
        dataHasChanged: false,
        loading_institutes: true,
        isEditingCertificate: false,
        keyCertificate: '',
        institute: '',
        _institute: '',
        year: '',
        graduate: '',
        cert_source: '',
        cert_format: '',
        cert_original_name: '',
      });
      this.cancelLoadingInstitutes();
    } else {
      const allFieldsFilled =
        name && email && surname && email && country && city && state;

      if (!allFieldsFilled) {
        this.props.updateAlert('Preencha todos os campos obrigatórios');
        return;
      }

      const isBirthdayValid = this.validateDate(birthday);

      if (!isBirthdayValid)
        this.setState({ birthdayError: 'Data de Nascimento Inválida' });

      if (!this.isPhoneValid(phone))
        this.setState({
          phoneError:
            'Telefone com tamanho inválido deve ser vazio ou entre 4 e 15 dígitos.',
        });
    }
  };

  validateDate = date => {
    if (date) {
      const parsedDate = new Date(
        this.utils.resolveSessionDate(this.utils.parseDateUSA(date))
      );

      return parsedDate instanceof Date && !isNaN(parsedDate);
    } else {
      return true;
    }
  };

  formValid() {
    const {
      name,
      surname,
      birthday,
      city,
      state,
      engage_name,
      country,
      email,
      phone,
    } = this.state.user;

    const allFieldsFilled =
      name && surname && email && country && city && state && engage_name;

    if (!allFieldsFilled) return false;

    const isBirthdayValid = this.validateDate(birthday);

    return isBirthdayValid && this.isPhoneValid(phone);
  }

  isPhoneValid(phone) {
    const phoneOk = phone ? phone.replace(/_/g, '').trim() : '';

    const isPhoneValid =
      phoneOk.length === 0 ||
      (phoneOk.length > 0 && phoneOk.length > 4 && phoneOk.length <= 15);

    return isPhoneValid;
  }

  validateEmail = async email => {
    if (this.utils.validateEmail(email)) {
      await this.setState({ validateEmail: true });
    } else {
      await this.setState({ validateEmail: false });
    }
  };

  handleFocus = event => {
    event.target.placeholder = '';
    event.target.select();
  };

  userIsOnTheTestPlan() {
    const {
      user: { isTestPeriodStarted, testPeriod },
    } = this.props;
    if (this.userHasPlataformPlan() && isTestPeriodStarted && testPeriod) {
      return true;
    }
    return false;
  }

  isLoadedUserPlans() {
    if (this.props.user && this.props.user.plans) {
      return true;
    }
    return false;
  }

  isTestPeriodExpired() {
    const {
      user: { isTestPeriodStarted, testPeriod },
    } = this.props;
    if (!this.userHasPlataformPlan() && isTestPeriodStarted && !testPeriod) {
      return true;
    }
    return false;
  }

  userHasPlataformPlan() {
    if (
      this.props.user &&
      this.props.user.plans &&
      this.props.user.plans.platform
    ) {
      return true;
    }
    return false;
  }

  getDaysRemaining() {
    if (this.props.user && this.props.user.expiresTest) {
      const expiresTest = moment(this.props.user.expiresTest, 'DD/MM/YYYY');
      const daysRemaining = expiresTest.diff(moment().startOf('day'), 'days');
      return daysRemaining.toString();
    }
  }

  calculateBasic() {
    const total = this.calculateBasics.execute(this.props.user);
    this.setState({ percentage_fields_filled: total });
  }

  verifyCanVideo(e) {
    if (this.videoSlideshowRule()) {
      this.setState({ uploading: true });
    } else {
      e.target.blur();
      this.props.updateAlert(
        <div>
          <p>Aumente seu plano para continuar..</p>
          <br />
          <div
            className="bt-blue"
            style={{ margin: '0 auto' }}
            onClick={() => {
              this.goTo('dashboard/profile/plans');
            }}
          >
            Alterar plano
          </div>
        </div>
      );
    }
  }

  verifyCanUpload() {
    if (this.videoSlideshowRule()) {
      this.setState({ canUploadPresentation: true });
    } else {
      this.props.updateAlert(
        <div>
          <p>Aumente seu plano para continuar..</p>
          <br />
          <div
            className="bt-blue"
            style={{ margin: '0 auto' }}
            onClick={() => {
              this.goTo('dashboard/profile/plans');
            }}
          >
            Alterar plano
          </div>
        </div>
      );
    }
  }

  videoSlideshowRule() {
    return (
      this.props.user &&
      this.props.user.plans &&
      this.props.user.plans.seacher &&
      this.props.user.plans.seacher &&
      this.props.user.plans.seacher.plan &&
      this.props.user.plans.seacher.plan.title.toLowerCase() === 'premium'
    );
  }

  executeScroll = ref => {
    if (ref === undefined || ref === null) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  menuProfileView = () => (
    <ul className="myprofile-menu">
      <li
        className={
          this.state.menuView === 'dados_pessoais'
            ? 'active row-between-center flex1 content-pop'
            : 'row-between-center flex1 content-pop'
        }
        onClick={() => {
          this.setState({ menuView: 'dados_pessoais' });
          this.executeScroll(this.refPersonalData);
        }}
      >
        <img
          src={require('./../../../assets/images/menu/profile/Pessoa.svg')}
          width="28"
          alt="profile"
        />
        <div className="row-between-center flex1">
          <span>Dados Pessoais</span>
        </div>
      </li>

      <li
        className={
          this.state.menuView === 'area_atuacao'
            ? 'active row-between-center flex1 content-pop'
            : 'row-between-center flex1 content-pop'
        }
        onClick={() => {
          this.setState({ menuView: 'area_atuacao' });
          this.executeScroll(this.refExpertiseAreas);
        }}
      >
        <img
          src={require('./../../../assets/images/menu/profile/Maleta.svg')}
          width="28"
          alt="profile"
        />
        <div className="row-between-center flex1">
          <span>Área de Atuação</span>
        </div>
      </li>

      <li
        className={
          this.state.menuView === 'sessoes'
            ? 'active row-between-center flex1 content-pop'
            : 'row-between-center flex1 content-pop'
        }
        onClick={() => {
          this.setState({ menuView: 'sessoes' });
          this.executeScroll(this.refSessions);
        }}
      >
        <img
          src={require('./../../../assets/images/menu/profile/Megafone.svg')}
          width="28"
          alt="profile"
        />
        <div className="row-between-center flex1">
          <span>Sessões</span>
        </div>
      </li>

      <li
        className={
          this.state.menuView === 'dados_profissionais'
            ? 'active row-between-center flex1 content-pop'
            : 'row-between-center flex1 content-pop'
        }
        onClick={() => {
          this.setState({ menuView: 'dados_profissionais' });
          this.executeScroll(this.refProfessionalData);
        }}
      >
        <img
          src={require('./../../../assets/images/menu/profile/Edificio.svg')}
          width="28"
          alt="profile"
        />
        <div className="row-between-center flex1">
          <span>Dados Profissionais</span>
        </div>
      </li>

      <li
        className={
          this.state.menuView === 'formacao'
            ? 'active row-between-center flex1 content-pop'
            : 'row-between-center flex1 content-pop'
        }
        onClick={() => {
          this.setState({ menuView: 'formacao' });
          this.executeScroll(this.refFormation);
        }}
      >
        <img
          src={require('./../../../assets/images/menu/profile/Formacao.svg')}
          width="28"
          alt="profile"
        />
        <div className="row-between-center flex1">
          <span>Formação</span>
        </div>
      </li>
    </ul>
  );

  handleDeleteCertificate = async (key, certificate) => {
    const removedCertificates = [];
    removedCertificates.push(certificate.certificate);

    const newCertificates = this.state.certificates;
    newCertificates.splice(key, 1);

    await this.service.updateCertificates({
      certificate: newCertificates,
    });

    await this.service.removeCert(removedCertificates);

    this.setState({ certificates: newCertificates });
    if (this.state.isEditingCertificate) this.cancelEditingCertificate();
  };

  getBadgesSRC = async _url => {
    return await this.service.getBadgesSrc({
      IcfPublicURL: _url,
    });
  };

  updateBadges = async obj => {
    return await this.service.updateBadges({
      badgesICF: [...obj],
    });
  };

  handleDownloadCertificate = key => {
    const cert = this.state.certificates[key];
    if (cert) {
      if (cert.certificate) window.open(cert.certificate);
    }
  };

  handleEditCertificate = key => {
    this.setState({ loading_institutes: true });
    try {
      const cert = this.state.certificates[key];
      if (cert) {
        const { graduate, institute, year, certificate, certificateURL } = cert;
        const institutes = this.state.institutes;
        const intFound = institutes.filter(item => item.name === institute);
        if (intFound && intFound.length === 0) {
          const inst = { name: institute };
          institutes.push(inst);
        }
        const cert_original_name = this.utils.fileNameFromUrl(certificateURL);
        const cert_format = this.utils.getExtensionFile(cert_original_name);
        this.setState({
          isEditingCertificate: true,
          keyCertificate: key,
          graduate,
          institute,
          _institute: institute,
          year,
          certificate,
          institutes,
          cert_original_name,
          cert_format,
          cert_source: certificateURL,
        });
      }
    } finally {
      this.cancelLoadingInstitutes();
    }
  };

  itemCertificateGridView = (certificate, key) => {
    return (
      <div className="table-certificates-items" key={key}>
        <span>{`${certificate.graduate} ${certificate.institute}/${certificate.year}`}</span>
        <div className="table-certificates-icons">
          <i
            className="fas fa-edit"
            data-tip
            data-for="tooltip-edit"
            onClick={() => this.handleEditCertificate(key)}
          />
          <ReactTooltip
            id="tooltip-edit"
            type="light"
            effect="solid"
            backgroundColor="#757575"
            textColor="white"
            borderColor="#B1B1B1"
            border={true}
          >
            <p className="myprofile-tootip-style-text">Editar</p>
          </ReactTooltip>
          <i
            className="fas fa-download"
            data-tip
            data-for="tooltip-download"
            onClick={() => this.handleDownloadCertificate(key)}
          />
          <ReactTooltip
            id="tooltip-download"
            type="light"
            effect="solid"
            backgroundColor="#757575"
            textColor="white"
            borderColor="#B1B1B1"
            border={true}
          >
            <p className="myprofile-tootip-style-text">Download</p>
          </ReactTooltip>
          <i
            className={`fas fa-times`}
            data-tip
            data-for="tooltip-delete"
            onClick={() => {
              this.handleDeleteCertificate(key, certificate);
            }}
          />
          <ReactTooltip
            id="tooltip-delete"
            type="light"
            effect="solid"
            backgroundColor="#757575"
            textColor="white"
            borderColor="#B1B1B1"
            border={true}
          >
            <p className="myprofile-tootip-style-text">Excluir</p>
          </ReactTooltip>
        </div>
      </div>
    );
  };

  itemsBadgesICFGridView = (IcfPublicURL, key) => {
    return (
      <div className="table-certificates-items" key={key}>
        <span style={{ wordBreak: 'break-all' }}>{`${IcfPublicURL}`}</span>
        <div className="table-certificates-icons">
          <i
            className="fas fa-download"
            data-tip
            data-for="tooltip-download"
            onClick={() => {
              var win = window.open(IcfPublicURL, '_blank');
              win.focus();
            }}
          />
          <ReactTooltip
            id="tooltip-download"
            type="light"
            effect="solid"
            backgroundColor="#757575"
            textColor="white"
            borderColor="#B1B1B1"
            border={true}
          >
            <p className="myprofile-tootip-style-text">Acessar o link</p>
          </ReactTooltip>
          <i
            className={`fas fa-times`}
            data-tip
            data-for="tooltip-delete"
            onClick={() => {
              this.deleteBadgesICF(IcfPublicURL);
            }}
          />
          <ReactTooltip
            id="tooltip-delete"
            type="light"
            effect="solid"
            backgroundColor="#757575"
            textColor="white"
            borderColor="#B1B1B1"
            border={true}
          >
            <p className="myprofile-tootip-style-text">Excluir</p>
          </ReactTooltip>
        </div>
      </div>
    );
  };

  buttonSaveView = () => {
    const closed = this.props.menu ? 'closed' : '';
    const viewButton =
      this.state.user && this.state.user != null ? (
        <div className={`button-save-my-profile-sticky mobile ${closed}`}>
          {this.state.loading ? (
            <ReactLoading type="bubbles" color="#B1CD49" />
          ) : (
            <div
              onClick={() => {
                this.handleSaveOnClickButton();
              }}
              className={
                !this.formValid()
                  ? 'bt-save my-profile hover-shadow'
                  : 'bt-save my-profile hover-shadow active'
              }
            >
              Salvar Alterações
            </div>
          )}
        </div>
      ) : null;
    return viewButton;
  };

  handleSaveOnClickButton = async () => {
    // if (this.state.isVisible && !this.checkEngageFieldsRecommend()) {
    //   this.engagePopUpAlert(true, this.saveCuration);
    // } else

    // if (!this.checkEngageFieldsRecommend()) {
    //   this.engagePopUpAlertIncomplete({
    //     isButtonSave: true,
    //     saveFunction: this.saveCuration,
    //   });
    // }
    // else {
    //   this.saveCuration();
    // }

    this.saveCuration();
  };

  handleDisabledCurationClick = () => {
    if (!this.state.isVisible) {
      this.props.updateAlert(
        'É necessário ativar seu perfil no engage para participar do processo de curadoria.'
      );
      return;
    }
  };

  showValidationField(fieldName, stateValidation) {
    if (!stateValidation && this.state.isLoadComplete)
      return (
        <span className="campObrigatorio">{`Campo "${fieldName}" é obrigatório`}</span>
      );
  }

  render() {
    const {
      user,
      ddiList,
      shouldBlockNavigation,
      dataHasChanged,
      shouldBlockNavigationEngage,
    } = this.state;
    return (
      <div className="datepicker-style main-content" ref={this.refPersonalData}>
        {/* <Prompt
          when={shouldBlockNavigation || shouldBlockNavigationEngage}
          message={(location, action) => {
            if (dataHasChanged) {
              this.dataNotSavedAlert(location);
            } else if (
              !this.state.isVisible &&
              !this.checkEngageFieldsRecommend()
            ) {
              this.engagePopUpAlertIncomplete({
                isButtonSave: false,
                saveFunction: null,
                location,
              });
            } else if (
              !this.state.isVisible &&
              this.checkEngageFieldsRecommend()
            ) {
              this.engagePopUpAlertComplete({
                location,
              });
            } else {
              this.dataNotSavedAlert(location);
            }
            return this.allowExitPage;
          }}
        /> */}
        <Header
          goTo={this.goTo}
          sideOpen={this.state.sideOpen}
          toggleMenu={this.toggleMenu}
        />
        <div className="content-climb user bodyClimby">
          <div
            className={this.props.menu ? 'content-menu closed' : 'content-menu'}
          >
            <SidebarProfile
              side={this.state.side}
              setSidebarLinks={this.setSidebarLinks}
              expanded={!this.state.sideOpen}
            />
          </div>
          <div
            className={this.props.menu ? 'content-dash closed' : 'content-dash'}
          >
            <div className="hide-when-desktop">{this.buttonSaveView()}</div>

            <div className="dash-content">
              {/* <BannerEngage
                show={this.state.showBannerEngage}
                onClickIgnore={this.handleBannerIgnore}
                onClickPublish={this.handleEngagePublication}
              /> */}
              <div className="dash-crumb-filter crumb-my-profile">
                <Breadcrumbs breadcrumbs={this.state.breadcrumbs} />
              </div>
              {this.state.user && this.state.user != null ? (
                <div className="dashboard-box box100 container-menu-my-profile">
                  <div className="mpf-menu-myperfil">
                    <div className="box-row-title mpf-padding-left">
                      <div className="box-title box-align-row">
                        Foto
                        {/* <TextRecommend
                          style={{ marginLeft: '10px' }}
                          classText="text-recommend-medium"
                          iconSize="16px"
                        /> */}
                      </div>
                    </div>
                    <div className="box-row-title mobile-column">
                      <UploadImage
                        className="up-image-resp-myprofile"
                        user={this.state.user}
                        uuser={this.loadUser}
                        newOption={true}
                        classNameEmptyImg="up-image-myprofile hover-shadow"
                      />
                    </div>

                    {/* <div className="progress-container">
                      <span className="progress-percent-text">
                        {this.state.percentage_fields_filled}%{' '}
                      </span>
                      <ProgressBar
                        completed={this.state.percentage_fields_filled}
                        bgColor="#00838f"
                        isLabelVisible={false}
                        borderRadius="4px"
                        height="16px"
                      />
                    </div> */}

                    {/* {this.menuProfileView()} */}

                    <div className="hide-when-mobile">
                      {this.buttonSaveView()}
                    </div>

                    {/* <div className="switch-myprofile">
                      <div className="container-switch-span">
                        <span className="switch-span-my-profile mobile">
                          Perfil ativo no{' '}
                          <span style={{ color: '#a6ce39' }}>engage</span>
                        </span>
                      </div>

                      <div className="container-switch-icon">
                        <div
                          className="info-icon-my-profile"
                          data-tip
                          data-for="tooltip-engage"
                        >
                          <i className="info fas fa-info-circle"></i>
                          <ReactTooltip
                            id="tooltip-engage"
                            type="light"
                            effect="solid"
                            textColor="#757575"
                            borderColor="#B1B1B1"
                            border={true}
                          >
                            <p className="myprofile-tootip-style-text">
                              engage é o ambiente de negócios onde o seu perfil
                              estará disponível para o mercado.
                            </p>
                          </ReactTooltip>
                        </div>
                        <Switch
                          onChange={() => {
                            this.handleEngagePublication();
                          }}
                          id={`toggle-visibility`}
                          checkedIcon={false}
                          uncheckedIcon={false}
                          offColor={'#D8D8D8'}
                          offHandleColor={'#9F9F9F'}
                          onColor={'#96CBCF'}
                          onHandleColor={'#00838F'}
                          handleDiameter={20}
                          height={13}
                          width={33}
                          checked={this.state.isVisible}
                        />
                      </div>
                    </div> */}

                    {/* <div
                      className={
                        !this.state.isVisible
                          ? 'switch-myprofile disable-element'
                          : 'switch-myprofile'
                      }
                    >
                      <div className="container-switch-span">
                        <span className="switch-span-my-profile mobile">
                          Solicitar curadoria
                        </span>
                      </div>

                      <div className="container-switch-icon">
                        <div
                          className="info-icon-my-profile"
                          data-tip
                          data-for="tooltip-curation"
                        >
                          <i className="info fas fa-info-circle"></i>
                          <ReactTooltip
                            id="tooltip-curation"
                            type="light"
                            effect="solid"
                            textColor="#757575"
                            borderColor="#B1B1B1"
                            border={true}
                          >
                            <p className="myprofile-tootip-style-text">
                              Curadoria climby é uma validação das informações
                              apresentadas no seu perfil. Seu perfil receberá um
                              selo de validação ao ser aprovado no processo.
                            </p>
                          </ReactTooltip>
                        </div>
                        <div
                          style={{ pointerEvents: 'all' }}
                          onClick={() => {
                            this.handleDisabledCurationClick();
                          }}
                        >
                          <Switch
                            disabled={!this.state.isVisible}
                            onChange={this.handleCuration}
                            id={`toggle-visibility`}
                            checkedIcon={false}
                            uncheckedIcon={false}
                            offColor={'#D8D8D8'}
                            offHandleColor={'#9F9F9F'}
                            onColor={'#96CBCF'}
                            onHandleColor={'#00838F'}
                            handleDiameter={20}
                            height={13}
                            width={33}
                            checked={
                              this.props.user.curation
                                ? this.props.user.curation.active
                                : false
                            }
                          />
                        </div>
                      </div>
                    </div> */}

                    {/* {this.props.user.curation &&
                      this.props.user.curation.active && (
                        <span className="curation-status-date">{`${
                          this.props.user.curation.status
                        } em ${moment(this.props.user.curation.date).format(
                          'DD/MM/YYYY'
                        )}`}</span>
                      )}

                    <TextRecommend classText="text-recommend-big" />
                    <p>
                      Recomendamos o preenchimento desses campos para melhorar
                      seus resultados no&nbsp;
                      <span style={{ color: '#a6ce39' }}>engage.</span>
                    </p> */}
                  </div>
                </div>
              ) : null}

              {this.state.user && this.state.user != null ? (
                <div
                  className="dashboard-box box100 box-my-profile"
                  style={{
                    padding: '10px 10px 17px 10px',
                  }}
                >
                  <div className="mpf-principal-myperfil">
                    <div className="mpf-content-myperfil">
                      <div className="box-row-title mpf-padding-left">
                        <div className="box-title">Dados pessoais</div>
                      </div>
                      <div className="content-form my-profile">
                        <div className="form-input">
                          <label className="blue-label">
                            Nome
                            <span className="required-field">*</span>
                          </label>
                          <input
                            type="text"
                            value={this.state.user.name || ''}
                            className="blue-input"
                            onChange={e => {
                              this.setState({
                                user: {
                                  ...this.state.user,
                                  name: e.target.value,
                                },
                              });
                            }}
                          />
                          {this.showValidationField(
                            'Nome',
                            this.state.user.name
                          )}
                        </div>
                        <div className="form-input">
                          <label className="blue-label">
                            Sobrenome
                            <span className="required-field">*</span>
                          </label>
                          <input
                            type="text"
                            value={this.state.user.surname || ''}
                            className="blue-input"
                            onChange={e => {
                              this.setState({
                                user: {
                                  ...this.state.user,
                                  surname: e.target.value,
                                },
                              });
                            }}
                          />
                          {this.showValidationField(
                            'Sobrenome',
                            this.state.user.surname
                          )}
                        </div>
                        {/* <div className="form-input">
                          <InputError error={this.state.birthdayError}>
                            <label className="blue-label">
                              Data de Nascimento
                            </label>
                            <DatePicker
                              selected={this.state.user.birthday}
                              minDate={subYears(startOfToday(), 150)}
                              maxDate={startOfToday()}
                              onChange={e => {
                                this.setState({
                                  user: { ...this.state.user, birthday: e },
                                  birthdayError: '',
                                });
                              }}
                              onChangeRaw={event => {
                                if (
                                  event.target.value &&
                                  event.target.value === '__/__/____'
                                ) {
                                  this.setState({
                                    user: {
                                      ...this.state.user,
                                      birthday: '',
                                    },
                                    birthdayError: '',
                                  });
                                }
                              }}
                              dateFormat="dd/MM/yyyy"
                              className="blue-input"
                              customInput={<InputMask mask="99/99/9999" />}
                            />
                          </InputError>
                        </div> */}
                        <div className="form-input">
                          <label className="blue-label">
                            Email
                            <span className="required-field">*</span>
                          </label>
                          <input
                            type="text"
                            value={this.state.user.email || ''}
                            className="blue-input"
                            onChange={e => {
                              this.setState({
                                user: {
                                  ...this.state.user,
                                  email: e.target.value,
                                },
                              });
                              this.validateEmail(e.target.value);
                            }}
                          />
                          {this.showValidationField(
                            'Email',
                            this.state.validateEmail
                          )}
                        </div>
                        {/* 
                        <div className="form-input">
                          <label className="blue-label">
                            Como quer que seu nome apareça no engage?
                            <span className="required-field">*</span>
                          </label>
                          <input
                            type="text"
                            value={this.state.user.engage_name || ''}
                            placeholder="Informe o nome que irá aparecer no engage"
                            className="blue-input"
                            onFocus={this.handleFocus}
                            onBlur={e =>
                              (e.target.placeholder =
                                'Informe o nome que irá aparecer no engage')
                            }
                            onChange={e => {
                              this.setState({
                                user: {
                                  ...this.state.user,
                                  engage_name: e.target.value,
                                },
                              });
                            }}
                          />
                          {this.showValidationField(
                            'Como quer que seu nome apareça no engage?',
                            this.state.user.engage_name
                          )}
                        </div> */}
                      </div>

                      {/* <div className="box-row-title mpf-padding-left">
                        <div className="box-title">Localidade</div>
                      </div>
                      <div className="content-form my-profile">
                        <div className="form-input">
                          {this.state.loading_state ? (
                            <ReactLoading type="bubbles" color="#B1CD49" />
                          ) : (
                            <div className="form-input">
                              <label className="blue-label">
                                País
                                <span className="required-field">*</span>
                              </label>
                              <select
                                value={this.state.user.country}
                                className="blue-input"
                                onChange={async e => {
                                  const countryChange = e.target.value;
                                  this.loadStates(countryChange);
                                  const ddi = await this.loadDdi(countryChange);
                                  this.setState({
                                    user: {
                                      ...user,
                                      country: countryChange,
                                      state: '',
                                      city: '',
                                      ddi,
                                    },
                                  });
                                }}
                              >
                                <option value=""> &nbsp; País</option>
                                {this.state.countries.map(
                                  (countryItem, key) => {
                                    return (
                                      <option
                                        key={key}
                                        value={countryItem.name}
                                      >
                                        {' '}
                                        &nbsp; {countryItem.name}
                                      </option>
                                    );
                                  }
                                )}
                              </select>
                              {this.showValidationField(
                                'País',
                                this.state.user.country
                              )}
                            </div>
                          )}
                        </div>
                        <div className="form-input">
                          {this.state.loading_state ? (
                            <ReactLoading type="bubbles" color="#B1CD49" />
                          ) : (
                            <div className="form-input">
                              <label className="blue-label">
                                {this.state.user.country === 'Brasil'
                                  ? 'Estado'
                                  : 'Estado/Província'}
                                <span className="required-field">*</span>
                              </label>
                              <select
                                value={this.state.user.state}
                                className="blue-input"
                                onChange={e => {
                                  this.loadCities(
                                    e.target.value,
                                    this.state.user.country
                                  );
                                }}
                              >
                                <option value=""> &nbsp; Estado</option>
                                {this.state.states.map((item, key) => {
                                  return (
                                    <option
                                      key={key}
                                      value={
                                        item.sigla_uf
                                          ? item.sigla_uf
                                          : item.name
                                      }
                                    >
                                      &nbsp;{' '}
                                      {item.nome_uf ? item.nome_uf : item.name}
                                    </option>
                                  );
                                })}
                              </select>
                              {this.showValidationField(
                                'Estado',
                                this.state.user.state
                              )}
                            </div>
                          )}
                        </div>
                        <div className="form-input">
                          {this.state.loading_cities ? (
                            <ReactLoading type="bubbles" color="#B1CD49" />
                          ) : (
                            <div className="form-input">
                              <label className="blue-label">
                                Cidade
                                <span className="required-field">*</span>
                              </label>
                              <select
                                value={this.state.user.city}
                                className="blue-input"
                                onChange={e => {
                                  this.setState({
                                    user: {
                                      ...this.state.user,
                                      city: e.target.value,
                                    },
                                  });
                                }}
                              >
                                <option value="">&nbsp; Cidade</option>
                                {this.state.cities.map((item, key) => {
                                  return (
                                    <option
                                      key={key}
                                      value={
                                        item.nome_municipio
                                          ? item.nome_municipio
                                          : item.name
                                      }
                                    >
                                      {' '}
                                      &nbsp;{' '}
                                      {item.nome_municipio
                                        ? item.nome_municipio
                                        : item.name}
                                    </option>
                                  );
                                })}
                              </select>
                              {this.showValidationField(
                                'Cidade',
                                this.state.user.city
                              )}
                            </div>
                          )}
                        </div>
                      </div> */}

                      {/* <div className="box-row-title mpf-padding-left">
                        <div className="box-title">Contato</div>
                      </div>
                      <div className="content-form my-profile">
                        <div className="container-default-row">
                          <div className="form-input ddi">
                            <label className="blue-label">DDI +</label>
                            <select
                              value={this.state.user.ddi}
                              className="blue-input"
                              onChange={e => {
                                this.setState({
                                  user: {
                                    ...this.state.user,
                                    ddi: e.target.value,
                                  },
                                });
                              }}
                            >
                              {ddiList.map(ddi => {
                                return (
                                  <option value={ddi} key={ddi}>
                                    &nbsp;{ddi}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                          <div className="form-input">
                            <label className="blue-label">Telefone</label>
                            <InputError error={this.state.phoneError}>
                              <InputMask
                                {...this.props}
                                mask={
                                  this.state.user.ddi === '55'
                                    ? '(99) 999999999'
                                    : '999999999999999'
                                }
                                value={this.state.user.phone || ''}
                                className="blue-input"
                                placeholder="Telefone"
                                onChange={e => {
                                  this.setState({
                                    user: {
                                      ...this.state.user,
                                      phone: e.target.value,
                                    },
                                  });
                                }}
                              />
                            </InputError>
                          </div>
                        </div>
                        <div
                          className="form-input"
                          ref={this.refExpertiseAreas}
                        >
                          <label className="blue-label">Site</label>
                          <input
                            type="url"
                            value={this.state.user.site || ''}
                            className="blue-input"
                            placeholder="Complete aqui com seu website caso você já possua um"
                            onChange={e => {
                              this.setState({
                                user: {
                                  ...this.state.user,
                                  site: e.target.value,
                                },
                              });
                            }}
                          />
                        </div>
                      </div> */}
                      {/* <div className="box-row-title mpf-padding-left">
                        <div className="box-title box-align-row">
                          Área de Atuação
                          <TextRecommend
                            style={{ marginLeft: '10px' }}
                            classText="text-recommend-medium"
                            iconSize="16px"
                          />
                        </div>
                      </div>
                      <div
                        className="content-form my-profile"
                        ref={this.refSessions}
                      >
                        <TagPickerWrapper
                          onChange={e => {
                            this.setState({ selected_expertise_areas: e });
                          }}
                          value={this.state.selected_expertise_areas}
                          placeholder="Áreas"
                          data={this.state.professional}
                          cleanable={false}
                        />
                      </div>

                      <div className="box-row-title mpf-padding-left">
                        <div className="box-title box-align-row">
                          Tipo de Atendimento
                          <TextRecommend
                            style={{ marginLeft: '10px' }}
                            classText="text-recommend-medium"
                            iconSize="16px"
                          />
                        </div>
                      </div>
                      <div className="container-options-professional">
                        <div className="content-check-container">
                          <div
                            className={
                              this.state.presential
                                ? 'content-check-square active'
                                : 'content-check-square'
                            }
                          >
                            <div
                              className={
                                this.state.presential
                                  ? 'option-type-professional active'
                                  : 'option-type-professional'
                              }
                              onClick={() => {
                                this.setState({
                                  presential: !this.state.presential,
                                });
                              }}
                            >
                              <img
                                src={
                                  this.state.presential
                                    ? require('./../../../assets/images/menu/personal/icon-5b.svg')
                                    : require('./../../../assets/images/menu/personal/icon-5.svg')
                                }
                                alt="presencial"
                              />
                              <CheckboxWrapper checked={this.state.presential}>
                                Presencial
                              </CheckboxWrapper>
                            </div>
                          </div>

                          <div
                            className={
                              this.state.online
                                ? 'content-check-square active'
                                : 'content-check-square'
                            }
                          >
                            <div
                              className={
                                this.state.online
                                  ? 'option-type-professional active'
                                  : 'option-type-professional'
                              }
                              onClick={() => {
                                this.setState({ online: !this.state.online });
                              }}
                            >
                              <img
                                src={
                                  this.state.online
                                    ? require('./../../../assets/images/menu/personal/icon-6b.svg')
                                    : require('./../../../assets/images/menu/personal/icon-6.svg')
                                }
                                width="55"
                                alt="online"
                              />
                              <CheckboxWrapper checked={this.state.online}>
                                Online
                              </CheckboxWrapper>
                            </div>
                          </div>
                        </div>
                      </div> */}

                      {/* <div className="content-form my-profile">
                        <div className="form-textarea-my-profile ">
                          <label className="blue-label">Cidades que atua</label>
                          <textarea
                            value={this.state.citiesAreas}
                            className="blue-input"
                            placeholder="Cidades que atua"
                            maxLength={this.state.maxLength}
                            onChange={e => {
                              this.setState({ citiesAreas: e.target.value });
                            }}
                          ></textarea>
                          <span>
                            {this.state.citiesAreas.length}/
                            {this.state.maxLength}
                          </span>
                        </div>
                      </div> */}

                      {/* <div className="box-row-title mpf-padding-left">
                        <div className="box-title">Sessão Gratuita</div>
                      </div>
                      <div className="content-form my-profile">
                        <div className="container-default-row">
                          <div data-tip data-for="tooltip-master">
                            <Switch
                              onChange={this.toggleMaster}
                              id={`toggle-visibility`}
                              checkedIcon={false}
                              uncheckedIcon={false}
                              offColor={'#D8D8D8'}
                              offHandleColor={'#9F9F9F'}
                              onColor={'#96CBCF'}
                              onHandleColor={'#00838F'}
                              handleDiameter={20}
                              height={13}
                              width={33}
                              checked={this.state.master}
                            />
                          </div>

                          <span>&nbsp;&nbsp;Sessão master gratuita</span>
                        </div>
                        <p className="text-description-free-session description-responsive">
                          A realização de uma Sessão Gratuita pode contribuir
                          para uma interação com o potencial Coachee além de
                          permitir esclarecer o que é e o que nao é Coaching. Ao
                          selecionar este item você estará informando que está
                          aberto a realização de Sessões gratuitas com seus
                          potenciais clientes. Em nenhum momento é um
                          compromisso assumido, apenas uma sinalizacão no
                          engage. Recomendamos habilitá-la por ser uma boa
                          prática.
                        </p>
                        <ReactTooltip
                          id="tooltip-master"
                          type="light"
                          effect="solid"
                          textColor="#757575"
                          borderColor="#B1B1B1"
                          border={true}
                        >
                          <p className="myprofile-tootip-style-text">
                            Ao selecionar este item será informado no engage que
                            você realiza Sessões iniciais.
                          </p>
                        </ReactTooltip>
                      </div> */}

                      {/* <div className="box-row-title mpf-padding-left">
                        <div className="box-title">Valor da Sessão</div>
                      </div>
                      <div className="content-form my-profile">
                        <div className="form-input">
                          <label className="blue-label">Moeda</label>
                          <select
                            value={this.state.currency}
                            className="blue-input"
                            onChange={e => {
                              this.handleSelectPrice(e.target.value);
                            }}
                          >
                            {this.state.currencies.map((value, key) => {
                              return (
                                <option key={key} value={value}>
                                  &nbsp;{value}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        <div className="form-input">
                          <label className="blue-label">
                            Escolha uma opção
                          </label>
                          <select
                            value={this.state.price}
                            className="blue-input"
                            onChange={e => {
                              this.setState({ price: e.target.value });
                            }}
                          >
                            {this.state.prices.map((value, key) => {
                              return (
                                <option key={key} value={value}>
                                  &nbsp;{value}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        <div
                          className={`form-input ${
                            TEXT_VALUE_SPECIFC !== this.state.price
                              ? 'disabled'
                              : ''
                          }`}
                          ref={this.refProfessionalData}
                        >
                          <label className="blue-label">Valor da sessão</label>
                          <CurrencyInput
                            readOnly={TEXT_VALUE_SPECIFC !== this.state.price}
                            value={this.state.specif_value}
                            className="blue-input"
                            onChangeEvent={e => {
                              this.setState({ specif_value: e.target.value });
                            }}
                            decimalSeparator=","
                            thousandSeparator="."
                            prefix=""
                          />
                        </div>
                      </div> */}

                      {/* <div className="box-row-title mpf-padding-left">
                        <div className="box-title box-align-row">
                          Dados Profissionais
                          <TextRecommend
                            style={{ marginLeft: '10px' }}
                            classText="text-recommend-medium"
                            iconSize="16px"
                          />
                        </div>
                      </div>
                      <div className="content-form my-profile">
                        <div className="form-input">
                          <div className="form-textarea-my-profile ">
                            <label className="blue-label">
                              Breve descrição profissional
                            </label>
                            <textarea
                              value={this.state.professionalDescription}
                              className="blue-input mobile"
                              placeholder="Este texto estará disponível na área principal do seu perfil no ambiente de negócios. Fale brevemente sobre você e seus principais pontos e nichos de atuação. Torne seu perfil atrativo para seu potencial cliente."
                              onChange={e => {
                                this.setState({
                                  professionalDescription: e.target.value,
                                });
                              }}
                            ></textarea>
                            <span>
                              {this.state.professionalDescription.length}/
                              {this.state.maxLength}
                            </span>
                          </div>
                        </div>

                        <div className="form-input">
                          <div className="form-textarea-my-profile">
                            <label className="blue-label">
                              Experiência como coach
                            </label>
                            <textarea
                              value={this.state.experienceDescription}
                              className="blue-input mobile"
                              placeholder="Descreva sua experiência com o coaching. Mostre para seu potencial cliente que você está habilitado e possui experiência para apoiá-lo. Caso se sinta confortável, e tenha autorização, fique a vontade em incluir referências."
                              onChange={e => {
                                this.setState({
                                  experienceDescription: e.target.value,
                                });
                              }}
                            ></textarea>
                            <span>
                              {this.state.experienceDescription.length}/
                              {this.state.maxLength}
                            </span>
                          </div>
                        </div>

                        <div className="form-input">
                          <div
                            className="form-textarea-my-profile"
                            ref={this.refFormation}
                          >
                            <label className="blue-label">
                              Descrição da metodologia
                            </label>
                            <textarea
                              value={this.state.methodologyDescription}
                              className="blue-input mobile"
                              placeholder="Aqui nesta área explique para seu potencial cliente como você trabalha de uma forma geral. Se você utiliza uma metodologia de alguma escola de formação ou metodologia própria, faça uma breve descrição da mesma para que seu potencial cliente possa entender um pouco sobre como será o trabalho."
                              onChange={e => {
                                this.setState({
                                  methodologyDescription: e.target.value,
                                });
                              }}
                            ></textarea>
                            <span>
                              {this.state.methodologyDescription.length}/
                              {this.state.maxLength}
                            </span>
                          </div>
                        </div>
                      </div> */}

                      {/* <div className="box-row-title mpf-padding-left">
                        <div
                          className="box-title box-align-row"
                          style={{ display: 'flex' }}
                        >
                          <div data-tip data-for="tooltip-certificate">
                            Certificações&nbsp;
                            <i className="fas fa-info-circle table-icon"></i>
                            <ReactTooltip
                              id="tooltip-certificate"
                              type="light"
                              effect="solid"
                              textColor="#757575"
                              borderColor="#B1B1B1"
                              border={true}
                            >
                              <p className="myprofile-tootip-style-text">
                                Mostre para seus potenciais clientes sua
                                formação e conquiste ainda mais credibilidade.
                                Ao incluir seu certificado, nossa equipe de
                                curadoria poderá aprovar de forma mais rápida
                                sua participação no engage, seu ambiente de
                                negócios.
                              </p>
                            </ReactTooltip>
                          </div>
                          <TextRecommend
                            style={{ marginLeft: '10px' }}
                            classText="text-recommend-medium"
                            iconSize="16px"
                          />
                        </div>
                      </div>
                      {this.state.loading_institutes ? (
                        <ReactLoading type="bubbles" color="#B1CD49" />
                      ) : (
                        <div className="content-form my-profile">
                          <div className="form-input">
                            <label className="blue-label">Nome do curso</label>
                            <input
                              ref={this.refCurseName}
                              type="text"
                              value={this.state.graduate}
                              placeholder="Nome do Curso"
                              className="blue-input"
                              onChange={e => {
                                this.setState({ graduate: e.target.value });
                              }}
                            />
                          </div>

                          <div className="form-doubleinput">
                            <div
                              className="form-input"
                              style={{ marginRight: '10px' }}
                            >
                              <label className="blue-label">Instituição</label>

                              <Typeahead
                                value={this.state.institute}
                                defaultValue={this.state.institute}
                                filterOption="name"
                                displayOption="name"
                                placeholder="Nome da Instituição"
                                options={this.state.institutes}
                                onKeyUp={e => {
                                  if (e.key === 'Enter') {
                                    const name = e.target.value;
                                    this.createInstitute(name);

                                    this.refYear.current.focus();
                                    this.setState(state => ({
                                      ...state,
                                      institute: name,
                                      _institute: '',
                                    }));
                                  } else {
                                    this.setState({
                                      _institute: e.target.value,
                                    });
                                  }
                                }}
                                onOptionSelected={e => {
                                  this.setState(state => ({
                                    ...state,
                                    institute: e.name,
                                    _institute: '',
                                  }));
                                }}
                                customClasses={{
                                  input: 'blue-input',
                                  results: 'list-messages listahead',
                                }}
                              />
                            </div>

                            <div className="form-input">
                              <label className="blue-label">
                                Ano de conclusão
                              </label>
                              <input
                                ref={this.refYear}
                                type="number"
                                value={this.state.year}
                                placeholder="Ano de conclusão"
                                className="blue-input"
                                onKeyPress={e => {
                                  if (
                                    e.key === 'Enter' &&
                                    !this.state.instituteHasBadge
                                  ) {
                                    this.AddCertificate();
                                  }
                                }}
                                onChange={e => {
                                  this.setState({ year: e.target.value });
                                }}
                              />
                            </div>
                          </div>

                          <div
                            className="container-default-row"
                            style={{ justifyContent: 'space-between' }}
                          >
                            <UploadCert
                              notConvert={true}
                              setState={this.setState.bind(this)}
                              cert_format={this.state.cert_format}
                              cert_source={this.state.cert_source}
                              cert_original_name={this.state.cert_original_name}
                              removedCertificates={
                                this.state.removedCertificates
                              }
                              state={this.state}
                              typeUploadCss="btnCertif"
                            />

                            <div
                              className={
                                this.formValidCertificate()
                                  ? 'bt-blue my-profile-certificate'
                                  : 'bt-blue my-profile-certificate disable-element disable-background'
                              }
                              onClick={e => {
                                this.AddCertificate();
                              }}
                            >
                              {this.state.isEditingCertificate
                                ? 'Salvar'
                                : 'Adicionar'}
                            </div>
                            {this.state.isEditingCertificate && (
                              <div
                                className="bt-blue my-profile-certificate"
                                onClick={e => {
                                  this.cancelEditingCertificate();
                                }}
                              >
                                Cancelar
                              </div>
                            )}
                          </div>

                          <div className="table-certificates">
                            {this.state.certificates.map((certificate, key) => {
                              return this.itemCertificateGridView(
                                certificate,
                                key
                              );
                            })}
                          </div>
                        </div>
                      )}

                      <div className="content-form my-profile">
                        <div
                          className="bt-blue"
                          onClick={() => {
                            this.setState(prev => ({
                              ...prev,
                              instituteHasBadge: true,
                            }));
                          }}
                          style={{
                            marginLeft: 0,
                            width: '100%',
                            marginBottom: '10px',
                          }}
                        >
                          <i className="fas fa-plus" />
                          Adicionar Badges Digital ICF
                        </div>

                        {this.state.instituteHasBadge && (
                          <>
                            <AddNewBadgesICF
                              state={this.state}
                              setState={this.setState.bind(this)}
                              fnAddNewBadge={this.addBadgesICF}
                              refProps={this.refURLPublic}
                            />

                            {this.state.loadingBadgesICF ? (
                              <ReactLoading type="bubbles" color="#B1CD49" />
                            ) : (
                              this.state.badgesICF.map(badge => {
                                if (badge.IcfPublicURL !== '') {
                                  return (
                                    <div
                                      key={badge.IcfPublicURL}
                                      className="table-certificates"
                                    >
                                      {this.itemsBadgesICFGridView(
                                        badge.IcfPublicURL
                                      )}
                                    </div>
                                  );
                                }
                              })
                            )}
                          </>
                        )}
                      </div> */}

                      {/* <div className="box-row-title mpf-padding-left">
                        <div className="box-title">Apresentação</div>
                      </div>
                      <div className="content-form my-profile">
                        {!this.state.canUploadPresentation ? (
                          <div className="row-form" style={{ width: '100%' }}>
                            <div
                              data-tip
                              data-for="tooltip-presentation"
                              className="bt-blue"
                              onClick={() => {
                                this.verifyCanUpload();
                              }}
                              style={{
                                marginLeft: 0,
                                width: '100%',
                                marginBottom: '10px',
                              }}
                            >
                              <div>
                                <i className="fas fa-upload"></i>
                                Enviar Apresentação
                                <ReactTooltip
                                  className="myprofile-tootip-style-apresentation"
                                  style={{ fontSize: '16px' }}
                                  id="tooltip-presentation"
                                  type="light"
                                  effect="solid"
                                  textColor="#757575"
                                  borderColor="#B1B1B1"
                                  border={true}
                                >
                                  <p className="myprofile-tootip-style-text">
                                    Ao assinar o pacote Premium você pode
                                    incluir um vídeo e uma apresentação para se
                                    diferenciar dos demais Coaches.
                                  </p>
                                </ReactTooltip>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <>
                            <UploadFile
                              notConvert={true}
                              setState={this.setState.bind(this)}
                              doc_format={this.state.doc_format}
                              doc_source={this.state.doc_source}
                              state={this.state}
                              acceptTypes={'application/pdf'}
                            />
                          </>
                        )}

                        {this.state.professional_video ? (
                          this.parseYoutubeID(this.state.professional_video) ? (
                            <div className="video-container">
                              <iframe
                                title="professional"
                                width="560"
                                height="315"
                                src={`https://www.youtube.com/embed/${this.parseYoutubeID(
                                  this.state.professional_video
                                )}`}
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                frameborder="0"
                                allowfullscreen
                              ></iframe>
                            </div>
                          ) : (
                            <div className="box-subtitle">
                              {' '}
                              Url inválida, informe um url do youtube válida{' '}
                            </div>
                          )
                        ) : null}

                        <p>&nbsp;</p>
                        <div
                          className="form-input form-video"
                          data-tip
                          data-for={
                            !this.state.uploading ? 'tooltip-video' : ''
                          }
                        >
                          <label className="blue-label">Vídeo</label>
                          <input
                            type="text"
                            readOnly={!this.state.uploading}
                            value={this.state.professional_video}
                            placeholder="URL do vídeo"
                            className="blue-input"
                            onChange={e => {
                              this.setState({
                                professional_video: e.target.value,
                              });
                            }}
                            onFocus={e => {
                              this.verifyCanVideo(e);
                            }}
                          />
                          {!this.state.uploading ? (
                            <ReactTooltip
                              className="myprofile-tootip-style"
                              id="tooltip-video"
                              type="light"
                              effect="solid"
                              textColor="#757575"
                              borderColor="#B1B1B1"
                              border={true}
                            >
                              <p className="myprofile-tootip-style-text">
                                Ao assinar o pacote Premium você pode incluir um
                                vídeo e uma apresentação para se diferenciar dos
                                demais Coaches.
                              </p>
                            </ReactTooltip>
                          ) : null}
                        </div>
                      </div> */}
                      <div className="content-form my-profile">
                        <span
                          className="myprofile-backToTop mobile"
                          onClick={() => this.executeScroll()}
                        >
                          Voltar ao topo
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <ReactLoading type="bubbles" color="#B1CD49" />
              )}
              <Footer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  menu: state.simpleReducer.menu ? 1 : 0,
  user: state.simpleReducer.user,
  countries: state.simpleReducer.countries,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateImage,
      updateUser,
      updateAlert,
      updateCountries,
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MyProfile)
);
