import ApiService from '../../../services/ApiService';

class Service extends ApiService {
  async states() {
    try {
      let response = await fetch(`${this.endPoint}location/state`, {
        method: 'GET',
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async cities(state) {
    try {
      let response = await fetch(`${this.endPoint}location/${state}/cities`, {
        method: 'GET',
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async save(_payload) {
    try {
      let response = await fetch(`${this.endPoint}users`, {
        method: 'PUT',
        headers: {
          Authorization: `JWT ${this.getToken()}`,
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
        body: this.parsePayload(_payload),
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async saveInstitute(_payload) {
    try {
      let response = await fetch(`${this.endPoint}options/institutes`, {
        method: 'POST',
        headers: {
          ...this.dpoDefaultHeaders,
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
        body: this.parsePayload(_payload),
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async canDowngrade(_payload) {
    try {
      let response = await fetch(`${this.endPoint}can/downgrade`, {
        method: 'POST',
        headers: {
          ...this.dpoDefaultHeaders,
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
        body: this.parsePayload(_payload),
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async postImage(fileToUpload) {
    const formData = new FormData();
    formData.append('image', fileToUpload, fileToUpload.name);
    try {
      let response = await fetch(`${this.endPoint}upload/image`, {
        method: 'POST',
        headers: {
          ...this.dpoDefaultHeaders,
        },
        body: formData,
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async institutes() {
    try {
      let response = await fetch(`${this.endPoint}options/institutes`, {
        method: 'GET',
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async options_professional() {
    try {
      let response = await fetch(`${this.endPoint}options/professional`, {
        method: 'GET',
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async options_life() {
    try {
      let response = await fetch(`${this.endPoint}options/life`, {
        method: 'GET',
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async professional_area() {
    try {
      let response = await fetch(`${this.endPoint}user/me/professional/area`, {
        method: 'GET',
        headers: {
          ...this.dpoDefaultHeaders,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async professional_description() {
    try {
      let response = await fetch(
        `${this.endPoint}user/me/professional/description`,
        {
          method: 'GET',
          headers: {
            ...this.dpoDefaultHeaders,
          },
        }
      );
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async professional_session() {
    try {
      let response = await fetch(
        `${this.endPoint}user/me/professional/session`,
        {
          method: 'GET',
          headers: {
            ...this.dpoDefaultHeaders,
          },
        }
      );
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async professional_value() {
    try {
      let response = await fetch(`${this.endPoint}user/me/professional/value`, {
        method: 'GET',
        headers: {
          ...this.dpoDefaultHeaders,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async plans() {
    try {
      let response = await fetch(`${this.endPoint}plans`, {
        method: 'GET',
        headers: {
          ...this.dpoDefaultHeaders,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async currentPlans() {
    try {
      let response = await fetch(`${this.endPoint}current/buy`, {
        method: 'GET',
        headers: {
          ...this.dpoDefaultHeaders,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async buy(_pay) {
    try {
      let response = await fetch(`${this.endPoint}buy`, {
        method: 'PUT',
        headers: {
          ...this.dpoDefaultHeaders,
          'Content-Type': 'application/json; charset=UTF-8',
        },
        body: JSON.stringify(_pay),
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async validateCoupon({ couponCode, planPlatformId, planSearcherId }) {
    try {
      let response = await fetch(`${this.endPoint}coupons/validate`, {
        method: 'POST',
        headers: {
          ...this.dpoDefaultHeaders,
          'Content-Type': 'application/json; charset=UTF-8',
        },
        body: JSON.stringify({ couponCode, planPlatformId, planSearcherId }),
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async cards() {
    try {
      let response = await fetch(`${this.endPoint}cards`, {
        method: 'GET',
        headers: {
          ...this.dpoDefaultHeaders,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async myCard() {
    try {
      let response = await fetch(`${this.endPoint}mycard`, {
        method: 'GET',
        headers: {
          ...this.dpoDefaultHeaders,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async card(_id) {
    try {
      let response = await fetch(`${this.endPoint}cards/${_id}`, {
        method: 'GET',
        headers: {
          ...this.dpoDefaultHeaders,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async saveCard(_payload) {
    try {
      let response = await fetch(`${this.endPoint}cards`, {
        method: 'POST',
        headers: {
          ...this.dpoDefaultHeaders,
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
        body: this.parsePayload(_payload),
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async updateCard(_payload, _id) {
    try {
      let response = await fetch(`${this.endPoint}cards/${_id}`, {
        method: 'PUT',
        headers: {
          ...this.dpoDefaultHeaders,
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
        body: this.parsePayload(_payload),
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async removeCard(_id) {
    try {
      let response = await fetch(`${this.endPoint}cards/${_id}`, {
        method: 'DELETE',
        headers: {
          ...this.dpoDefaultHeaders,
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async removeImage(idUser) {
    try {
      let response = await fetch(`${this.endPoint}upload/image/${idUser}`, {
        method: 'DELETE',
        headers: {
          ...this.dpoDefaultHeaders,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async combos() {
    try {
      let response = await fetch(`${this.endPoint}combos`, {
        method: 'GET',
        headers: {
          ...this.dpoDefaultHeaders,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async suspendSubscription(id) {
    try {
      let response = await fetch(`${this.endPoint}buy`, {
        method: 'DELETE',
        headers: {
          ...this.dpoDefaultHeaders,
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async countries() {
    try {
      let response = await fetch(`${this.endPoint}countries`, {
        method: 'GET',
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async statesForeign(countryName) {
    try {
      let response = await fetch(
        `${this.endPoint}countries/${countryName}/states`,
        {
          method: 'GET',
        }
      );
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async currencies() {
    try {
      let response = await fetch(`${this.endPoint}countries/currencies`, {
        method: 'GET',
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async ddis() {
    try {
      let response = await fetch(`${this.endPoint}ddis`, {
        method: 'GET',
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async updateCertificates(_pay) {
    try {
      let response = await fetch(`${this.endPoint}users/certificates`, {
        method: 'PUT',
        headers: {
          Authorization: `JWT ${this.getToken()}`,
          'Content-Type': 'application/json; charset=UTF-8',
        },
        body: JSON.stringify(_pay),
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async getBadgesSrc(_urlPublic) {
    try {
      let response = await fetch(`${this.endPoint}badges/src`, {
        method: 'POST',
        headers: {
          ...this.dpoDefaultHeaders,
          'Content-Type': 'application/json; charset=UTF-8',
        },
        body: JSON.stringify(_urlPublic),
      });

      return await response.json();
    } catch (error) {
      console.error(error);
    }
  }

  async updateBadges(objAllBadges) {
    try {
      let response = await fetch(`${this.endPoint}users/badges`, {
        method: 'PUT',
        headers: {
          Authorization: `JWT ${this.getToken()}`,
          'Content-Type': 'application/json; charset=UTF-8',
        },
        body: JSON.stringify(objAllBadges),
      });

      return await response.json();
    } catch (error) {
      console.error(error);
    }
  }
}

export default Service;
