import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ReactLoading from 'react-loading';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Switch from 'react-switch';
import { Col, Row } from 'reactstrap';
import { bindActionCreators } from 'redux';
import {
  updateActiveMeetingChronometer,
  updateAlert,
  updateChronometerSessions,
  updateImage,
  updateVideoSession,
} from '../../../actions/SimpleAction';
import '../../../styles/css/dashboard.css';
import '../../../styles/css/sessaoMasterDate/style-register.scss';
import '../../../styles/css/style.css';
import RichText from './../../../components/RichText';
import Utils from './../../../libs/Utils.js';
import Breadcrumbs from './../../components/Breadcrumbs';
import Chronometer, {
  chronometerInitialState,
  normalizeChronometerFromApi,
} from './../../components/Chronometer';
import Footer from './../../components/Footer';
import Header from './../../components/Header';
import ModalAlertCustom from './../../components/ModalAlertCustom';
import NotAllowed from './../../components/NotAllowed';
import Sidebar from './../../sidebar/Sidebar';
import Service from './../Service.js';
import ContentUploadConfirmation from './ContentUploadConfirmation';
import ModalActions from './ModalActions';
import ModalContentUpload from './ModalContentUpload';
import ModalContents from './ModalContents';
import userTypes from '../../../consts/userTypes';
import videoRoomTypes, {
  roles as videoRoomRoles,
} from '../../../consts/videoRoomTypes';
import TableAdd from './TableAdd';
import ModalProcess from './ModalProcess';
import {
  getDataProcessingHypothesesText,
  sortProcedures,
} from '../../../consts/procedure.js';
import { sortActions } from '../../../consts/action.js';

const Typeahead = require('react-typeahead').Typeahead;

class SessionRegister extends Component {
  constructor(props, context) {
    super(props);
    this._typeahead = React.createRef();

    const initialWidth = window.innerWidth > 0 ? window.innerWidth : 500;

    this.state = {
      isVisible: false,
      sideOpen: this.props.menu,
      principalOpen: true,
      view: 'programs',
      subview: 'register',
      side: 'assessments',
      breadcrumbs: [
        { title: 'Dashboard', link: '' },
        { title: 'Programas', link: 'programs' },
        { title: 'Registro de sessões', link: 'programs/assessments/register' },
      ],
      showToolTip: false,
      windowWidth: initialWidth - (window.innerWidth * 40) / 100,
      isLoading: false,
      loading: false,
      loading_coachee: false,
      coachees: [],
      loading_contents: false,
      contentList: [],
      loading_groups: false,
      groups: [],
      selectedContents: [],
      selectedCoachees: [],
      session: {
        project: '',
        coachee_id: '',
        group_id: '',
        its_group: false,
        _id: '',
      },
      activeMeeting: 'master',
      meetings: {},
      showModalContents: false,
      showModalActions: false,
      sendModalProcessToBackgroundAndOpenModalAction: false,
      showModalContentUpload: false,
      maxLength: 3660,
      sortField: '',
      hasNoMeetings: false,
      alert: '',
      chronometer: '',
      openPop: null,
      openPopInsert: false,
      coacheeSelected: '',
      viewPopoverVideo: false,
      showModalProcess: false,
      loadingSendMinute: false,
    };

    this.utils = new Utils();
    this.service = new Service();
    this.handleResize = this.handleResize.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.togglePrincipal = this.togglePrincipal.bind(this);
    this.setSidebarLinks = this.setSidebarLinks.bind(this);
    this.goTo = this.goTo.bind(this);
  }

  getSessionID = () => {
    const { session } = this.state;
    return session._id;
  };

  notify = async () => {
    const { session, activeMeeting } = this.state;

    if (session && session._id) {
      const obj = {
        meetingType: 'meetings',
        meetingId: activeMeeting.toString(),
      };

      this.setState(
        {
          loadingSendMinute: true,
        },
        () => {
          this.service
            .sendMinutes(session._id, obj)
            .then(result => {
              if (result && result.success) {
                this.props.updateAlert('Compartilhado com sucesso');
              }
            })
            .finally(() => {
              this.setState({
                loadingSendMinute: false,
              });
            });
        }
      );
    }
  };

  parseSession = session => {
    const { coachee_id, project, group_id, its_group, _id } = session;
    return { coachee_id, project, group_id, its_group, _id };
  };

  parseMeetings = session => {
    const arrayMeetings = [...session.planning.meetings];
    const meetings = {};

    arrayMeetings.forEach(meeting => {
      meeting.date = meeting.date ? new Date(meeting.date) : undefined;
      meeting.chronometer = meeting.chronometer
        ? normalizeChronometerFromApi(meeting.chronometer)
        : chronometerInitialState();
      meeting.coachee = meeting.coachee || [];
      meeting.procedures =
        meeting.number === arrayMeetings.length
          ? session.procedures.sort(sortProcedures)
          : session.procedures.filter(
              procedure =>
                procedure.meeting !== 'session' &&
                Number(procedure.meeting) <= meeting.number
            );
      meeting.actions =
        meeting.number === arrayMeetings.length
          ? session.actions.sort(sortActions)
          : session.actions.filter(
              action =>
                action.meeting !== 'session' &&
                Number(action.meeting) <= meeting.number
            );

      meetings[meeting.number] = meeting;
    });

    return meetings;
  };

  toggleModalContent = async (saving = false, content = []) => {
    const __sessionID = this.getSessionID();

    const {
      selectedContents,
      selectedCoachees,
      session,
      activeMeeting,
      contentList,
    } = this.state;

    const hasContent = contentList.length > 0;
    if (!hasContent) {
      this.props.updateAlert(
        <ContentUploadConfirmation
          cancel={() => this.props.updateAlert('')}
          upload={() => {
            this.props.updateAlert('');
            this.setState({ showModalContentUpload: true });
          }}
        />
      );
      return;
    }

    const contentIds = selectedContents.map(content => content._id);
    if (saving && contentIds.length > 0 && __sessionID) {
      const sendTo = [];
      if (session.its_group) {
        selectedCoachees.forEach(coachee => sendTo.push(coachee._id));
      } else if (session.coachee_id) {
        sendTo.push(session.coachee_id);
      }

      if (sendTo.length > 0) {
        const result = await this.service.storeSessionContents(
          __sessionID,
          activeMeeting,
          sendTo,
          contentIds
        );

        if (result && result.success) {
          const { session } = result;
          const meetings = this.parseMeetings(session);
          this.setState({
            session: this.parseSession(session),
            meetings,
          });
        }
      }
    }
    this.setState({
      showModalContents: !this.state.showModalContents,
      selectedContents: [...content],
      selectedCoachees: [],
    });
  };

  handleHtmlAlert() {
    return (
      <div>
        <p>
          Você ainda não tem nenhuma sessão planejada. Para planejar uma sessão,
          acesse o Planejamento de Sessões.
        </p>
        <br />
        <div className="btn-footer-modal">
          <div
            className="bt-blue"
            style={{ margin: '0 5px' }}
            onClick={() => {
              this.goTo('dashboard/programs/assessments/planning');
            }}
          >
            Planejamento de sessões
          </div>
        </div>
      </div>
    );
  }

  handleNoMeeting() {
    return (
      <ModalAlertCustom
        showCloseIcon={false}
        alert={this.state.alert}
        close={() => {
          this.setState({ alert: '' });
        }}
      />
    );
  }

  toggleModalAction = () => {
    const { showModalActions, showModalProcess } = this.state;
    this.setState({
      showModalActions: !showModalActions,
      selectedCoachees: [],
      sendModalProcessToBackgroundAndOpenModalAction:
        !showModalActions && showModalProcess,
    });
  };

  toggleContent = async item => {
    let selectedContents = [...this.state.selectedContents];
    const isContentSelected = selectedContents.find(
      content => content._id === item._id
    );
    if (isContentSelected) {
      selectedContents = selectedContents.filter(
        content => content._id !== item._id
      );
    } else {
      selectedContents.push(item);
    }
    this.setState({ selectedContents });
  };

  toggleModalContentUpload = async isSaved => {
    const { showModalContentUpload } = this.state;
    this.setState({ showModalContentUpload: !showModalContentUpload });
    if (isSaved) await this.loadContents(true);
  };

  toggleCoachee = item => {
    let selectedCoachees = [...this.state.selectedCoachees];
    const isCoacheeSelected = selectedCoachees.find(
      coachee => coachee._id === item._id
    );
    if (isCoacheeSelected) {
      selectedCoachees = selectedCoachees.filter(
        coachee => coachee._id !== item._id
      );
    } else {
      selectedCoachees.push(item);
    }
    this.setState({ selectedCoachees });
  };

  removeContent = async item => {
    const __sessionID = this.getSessionID();
    const { activeMeeting } = this.state;
    if (__sessionID) {
      const result = await this.service.destroySessionContent(
        __sessionID,
        activeMeeting,
        item.coachee_id,
        item._id
      );
      if (result && result.success) {
        const { session } = result;
        this.setState({
          session: this.parseSession(session),
          meetings: this.parseMeetings(session),
        });
      }
    }
  };

  saveAction = async action => {
    const __sessionID = this.getSessionID();

    const { selectedCoachees, session, activeMeeting } = this.state;
    if (__sessionID) {
      const sendTo = [];
      if (session.its_group) {
        selectedCoachees.forEach(coachee => sendTo.push(coachee._id));
      } else if (session.coachee_id) {
        sendTo.push(session.coachee_id);
      }

      if (sendTo.length > 0) {
        const result = await this.service.storeSessionActions(
          __sessionID,
          activeMeeting,
          sendTo,
          action
        );

        if (result && result.success) {
          const { session } = result;
          this.setState({
            session: this.parseSession(session),
            meetings: this.parseMeetings(session),
          });
        }
      }
    }
    this.toggleModalAction();
  };

  removeAction = async action => {
    const __sessionID = this.getSessionID();

    if (__sessionID) {
      const result = await this.service.destroySessionAction(
        __sessionID,
        action.coachee_id,
        action.action
      );
      if (result && result.success) {
        const { session } = result;
        this.setState({
          session: this.parseSession(session),
          meetings: this.parseMeetings(session),
        });
      }
    }
  };

  toggleMenu() {
    const { sideOpen } = this.state;
    this.setState({ sideOpen: !sideOpen });
  }

  togglePrincipal() {
    const { principalOpen } = this.state;
    this.setState({ principalOpen: !principalOpen });
  }

  goTo(page) {
    this.props.history.push(`/${page}`);
  }

  verifyLogin() {
    if (!this.utils.isLogged()) {
      this.goTo('');
    }
  }

  componentDidMount() {
    this.verifyLogin();

    this.setState({ isLoading: true }, () => {
      Promise.all([
        this.loadUser().then(() => this.loadGroups()),
        this.loadCoachees(),
        this.loadContents(),
      ])
        .then(() => this.resolveParams())
        .finally(() => {
          this.setBreadcrumbs();
          this.setState({ isLoading: false });
        });
    });

    window.addEventListener('resize', this.handleResize);
  }

  setBreadcrumbs = () => {
    const breadcrumbDetail = this.getBreadcrumbDetail();

    this.setState({
      breadcrumbs: [
        { title: 'Dashboard', link: '' },
        { title: 'Programas', link: 'programs' },
        { title: 'Registro de sessões', link: 'programs/assessments/register' },
        ...(breadcrumbDetail ? [{ title: breadcrumbDetail }] : []),
      ],
    });
  };

  getCoacheeName = coacheeId => {
    const coachee = this.state.coachees.find(
      coachee => coachee._id === coacheeId
    );
    return coachee && coachee.name;
  };

  getGroupName = groupId => {
    const group = this.state.groups.find(group => group._id === groupId);
    return group && group.name;
  };

  getBreadcrumbDetail = () => {
    const { session } = this.state;

    if (session.coachee_id) return this.getCoacheeName(session.coachee_id);
    if (session.group_id) return this.getGroupName(session.group_id);
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  async resolveParams() {
    const {
      match: { params },
    } = this.props;

    let _groupID;
    let __sessionID = this.getSessionID();

    if (params && params.group) {
      _groupID = params.group;
      __sessionID = null;

      const resultSessionByGroup = await this.service.sessionG(_groupID);

      if (
        resultSessionByGroup &&
        resultSessionByGroup.success &&
        !resultSessionByGroup.session
      ) {
        //Não existe sessão para o grupo. Criar uma sessão e uma reunião.
        this.setState(
          {
            session: {
              ...this.state.session,
              group_id: _groupID,
              selectedGroupId: _groupID,
            },
          },
          async () => {
            await this.createSession();
          }
        );
      } else if (resultSessionByGroup && resultSessionByGroup.success) {
        __sessionID = resultSessionByGroup.session._id;
      }
    } else if (params && params.session) {
      __sessionID = params.session;
    }

    if (__sessionID) {
      this.setState({ loading: true });

      const result = await this.service.sessionS(__sessionID);

      if (result && result.success && result.session) {
        const parsedMeetings = this.parseMeetings(result.session);

        if (Object.keys(parsedMeetings).length === 0) {
          this.setState({
            loading: false,
            hasNoMeetings: true,
          });
        } else {
          const _keyMeetings = Object.keys(parsedMeetings).sort(
            this.sortMeetings
          );
          const _activeMeeting =
            params && params.meeting
              ? params.meeting
              : _keyMeetings[_keyMeetings.length - 1];

          this.setState(
            {
              session: this.parseSession(result.session),
              meetings: parsedMeetings,
              activeMeeting: _activeMeeting,
            },
            () => {
              if (!this.props.activeMeetingChronometer) {
                this.changeMeeting(_activeMeeting);
              } else {
                this.setChronometerActiveMeeting(
                  this.props.activeMeetingChronometer
                );
              }
            }
          );
        }
      }

      this.setState({ loading: false });
    }
  }

  setSidebarLinks(view) {
    this.setState({ view });
    this.goTo(`dashboard/${view}`);
  }

  handleResize() {
    let _ww =
      window.innerWidth < 890 && window.innerWidth > 570
        ? window.innerWidth - (window.innerWidth * 30) / 100
        : window.innerWidth <= 570
        ? window.innerWidth - (window.innerWidth * 40) / 100
        : window.innerWidth - (window.innerWidth * 40) / 100;

    this.setState({
      windowWidth: _ww,
    });
  }

  logout() {
    this.utils.setLogout();
    this.goTo('');
  }

  async loadUser() {
    let result = await this.service.me();
    if (!result) {
      this.logout();
      return;
    }

    let user = {
      ...result.user,
    };
    if (user.image) {
      this.props.updateImage(user.image);
    }
    this.setState({ user });
  }

  async loadCoachees() {
    this.setState({ loading_coachee: true });
    let result = await this.service.coachees();
    if (result && result.success) {
      this.setState({ coachees: result.coachees });
    }
    this.setState({ loading_coachee: false });
  }

  async loadContents(openModalAfterLoad = false) {
    this.setState({ loading_contents: true });
    let result = await this.service.contents();
    if (result && result.success) {
      this.setState({ contentList: result.contents }, () => {
        if (openModalAfterLoad) {
          const { contentList } = this.state;
          const [newContent] = contentList;
          if (newContent) this.toggleModalContent(false, [newContent]);
        }
      });
    }
    this.setState({ loading_contents: false });
  }

  loadGroups = async () => {
    const { user } = this.state;
    if (!this.userHasGroupSessionRight(user)) return;

    this.setState({ loading_groups: true });
    let result = await this.service.groups();
    if (result && result.success) {
      const { groups } = result;
      this.setState({ groups });
    }
    this.setState({ loading_groups: false });
  };

  changeMeeting = meetingNumber => {
    this.setState({ activeMeeting: meetingNumber, selectedCoachees: [] });
    this.setChronometerActiveMeeting(meetingNumber);
  };

  updateMeeting = async payload => {
    const __sessionID = this.getSessionID();

    const { activeMeeting } = this.state;
    if (__sessionID) {
      const result = await this.service.updateMeeting(
        __sessionID,
        activeMeeting,
        payload
      );
      if (result && result.success) {
        const { session } = result;
        this.setState({
          session: this.parseSession(session),
          meetings: this.parseMeetings(session),
        });
      }
    }
  };

  sort(key) {
    let order =
      this.state.sortField == '' || this.state.sortField == key ? '-down' : '';

    let contentList = this.state.contentList.sort((a, b) => {
      if (a[key] > b[key]) {
        return order.indexOf('down') !== -1 ? 1 : -1;
      }
      if (a[key] < b[key]) {
        return order.indexOf('down') !== -1 ? -1 : 1;
      }
      return 0;
    });

    this.setState({ sortField: `${key}${order}`, contentList });
  }

  getActiveMeetingValue = fieldname => {
    const { meetings, activeMeeting } = this.state;

    if (!meetings[activeMeeting]) return;
    return meetings[activeMeeting][fieldname];
  };

  setActiveMeetingValue = payload => {
    const { meetings, activeMeeting } = this.state;

    this.setState({
      meetings: {
        ...meetings,
        [activeMeeting]: {
          ...meetings[activeMeeting],
          ...payload,
        },
      },
    });
  };

  sortMeetings = (a, b) => {
    return a.number - b.number;
  };

  getGroupCoachees = () => {
    const { session, groups } = this.state;
    const group =
      session.group_id && groups.find(group => group._id === session.group_id);
    return group ? group.coachees : [];
  };

  userHasGroupSessionRight = user => {
    return (
      user &&
      user.plans &&
      user.plans.platform &&
      user.plans.platform.plan.group_sessions
    );
  };

  userHasProgramPlanningRight = user => {
    return (
      user &&
      user.plans &&
      user.plans.platform &&
      user.plans.platform.plan.use_program_planning
    );
  };

  userHasRights = user => {
    const { session } = this.state;
    return (
      this.userHasProgramPlanningRight(user) &&
      (!session.its_group ||
        (session.its_group && this.userHasGroupSessionRight(user)))
    );
  };

  handleChronometer = chronometer => {
    const { meetings, activeMeeting } = this.state;
    this.props.updateChronometerSessions(chronometer);
    this.setState({
      meetings: {
        ...meetings,
        [activeMeeting]: {
          ...meetings[activeMeeting],
          ...chronometer,
        },
      },
    });
  };

  getChronometerFromActiveMeeting = activeMeeting => {
    const { meetings } = this.state;

    if (!meetings[activeMeeting]) return;
    return meetings[activeMeeting]['chronometer'];
  };

  setChronometerActiveMeeting = activeMeeting => {
    const chronometer = this.getChronometerFromActiveMeeting(activeMeeting);

    if (
      this.props.chronometerSession &&
      (!this.props.chronometerSession.startTime ||
        this.props.chronometerSession.startTime === null)
    )
      this.props.updateChronometerSessions(chronometer);
  };

  updateMeetingChronometer = async chronometer => {
    const __sessionID = this.getSessionID();

    if (__sessionID) {
      const result = await this.service.updateMeeting(
        __sessionID,
        this.props.activeMeetingChronometer,
        chronometer
      );
      if (result && result.success) {
        const { session } = result;
        this.setState({
          session: this.parseSession(session),
          meetings: this.parseMeetings(session),
        });
      }
    }
  };

  renderChronometer = (activeMeeting, chronometer) => {
    return (
      <Chronometer
        activeMeeting={activeMeeting}
        chronometer={chronometer}
        handleChronometer={this.handleChronometer}
        onSave={() => {
          if (activeMeeting !== this.state.activeMeeting)
            this.updateMeetingChronometer({
              chronometer: chronometer,
            });
          else
            this.updateMeeting({
              chronometer: chronometer,
            });
        }}
      />
    );
  };

  addZero = n => (n < 10 ? `0${n}` : n);

  renderTimeSession = activeMeeting => {
    const chronometer = this.getChronometerFromActiveMeeting(activeMeeting);
    const diff = chronometer.diff;
    const hours = diff ? chronometer.diff.hours() : 0;
    const minutes = diff ? chronometer.diff.minutes() : 0;
    const seconds = diff ? chronometer.diff.second() : 0;
    const hundredths = diff
      ? Math.round(chronometer.diff.millisecond() / 10)
      : 0;
    return (
      <div className="timesession-div">
        <label className="blue-label">Tempo da sessão </label>
        <div className="timesession-info">
          <span>
            {this.addZero(hours)}:{this.addZero(minutes)}:
            {this.addZero(seconds)}:{this.addZero(hundredths)}
          </span>
        </div>
      </div>
    );
  };

  removeProcess = async process => {
    const { session } = this.state;

    if (session._id) {
      const result = await this.service.destroySessionProceduresMeetings(
        session._id,
        process.procedure
      );
      if (result && result.success) {
        const { session } = result;
        this.setState({
          session: this.parseSession(session),
          meetings: this.parseMeetings(session),
        });
      }
    }
  };

  saveProcess = async process => {
    const { session, activeMeeting } = this.state;
    const body = {
      procedure: {
        ...process,
      },
    };

    if (session) {
      const result = await this.service.saveProceduresSessionMeetings(
        session._id,
        activeMeeting,
        body
      );

      if (result && result.success) {
        this.setState(
          {
            session: this.parseSession(result.session),
            meetings: this.parseMeetings(result.session),
          },
          () => this.toggleModalProcess()
        );
      }
    }
  };

  toggleModalProcess = () => {
    const { showModalProcess } = this.state;
    this.setState({
      showModalProcess: !showModalProcess,
    });
  };

  loadSessionByGroupId = async group_id => {
    if (!group_id) return;
    const result = await this.service.sessionG(group_id);
    if (result && result.success) {
      const { session } = result;

      if (session) {
        let _meetings = this.parseMeetings(session);

        if (Object.values(_meetings).length === 0) {
          await this.addMeeting(session._id);
        } else {
          this.setState({
            session: this.parseSession(session),
            meetings: this.parseMeetings(session),
          });

          this.changeMeeting(Object.values(_meetings).length);
        }

        this.setState({ isLoading: true }, () => {
          Promise.all([this.resolveParams(), this.loadCoachees()]).finally(
            () => {
              this.setBreadcrumbs();
              this.setState({ isLoading: false });
            }
          );
        });
      } else if (group_id) {
        const updateState = {
          group_id: group_id,
          coachee_id: '',
          oportunity_id: '',
        };
        this.setState(
          {
            session: { ...this.state.session, ...updateState },
          },
          () => {
            this.createSession();
          }
        );
      }
    }
  };

  createSession = async () => {
    this.setState({ loading: true });
    const {
      coachee_id,
      oportunity_id,
      group_id,
      its_group,
    } = this.state.session;

    const result = await this.service.createSession({
      coachee_id,
      oportunity_id,
      its_free: false,
      group_id,
      its_group,
    });

    if (result && result.success) {
      const { session } = result;

      this.setState(
        {
          session: this.parseSession(session),
          meetings: this.parseMeetings(session),
        },
        async () => {
          await this.addMeeting();
          this.changeMeeting(1);
          this.setBreadcrumbs();
        }
      );
    }
    this.setState({ loading: false });
  };

  changeGroup = e => {
    this.setState({
      selectedGroupId: e.target.value,
    });
    this.loadSessionByGroupId(e.target.value);
  };

  removeMeeting = async meeting => {
    const __sessionID = this.getSessionID();

    if (__sessionID) {
      const result = await this.service.destroyMeeting(
        __sessionID,
        meeting.number
      );
      if (result && result.success) {
        this.mapApiResultToState(result);
      }
    }
  };

  addMeeting = async sessionID => {
    const __sessionID = sessionID || this.getSessionID();

    if (__sessionID) {
      const result = await this.service.storeMeeting(__sessionID);

      if (result && result.success) {
        const resultSessionS = await this.service.sessionS(__sessionID);

        this.mapApiResultToState(resultSessionS);
      }
    }
  };

  mapApiResultToState = result => {
    const { session: stateSession, planning: statePlanning } = this.state;

    const { session } = result;

    const _meetings = this.parseMeetings(session);

    this.setState({
      session: {
        ...stateSession,
        _id: session._id,
        its_group: session.its_group,
        coachee_id: session.coachee_id,
        group_id: session.group_id,
        project: session.project,
      },
      meetings: _meetings,
      hasNoMeetings: false,
      activeMeeting: Object.values(_meetings).length,
    });
  };

  calculateTotalMeetings = () => {
    const { meetings } = this.state;

    if (meetings) {
      return Object.values(meetings).length;
    }

    return 0;
  };

  getSearchCoachees = coachees => `${coachees.name} - ${coachees.email}`;

  handleCoacheeSelected = coachee => {
    this.setState({ coacheeSelected: coachee });
  };

  handleSelectedCoachees = () => {
    const { meetings, activeMeeting, coacheeSelected } = this.state;

    if (
      activeMeeting &&
      coacheeSelected &&
      (!meetings[activeMeeting].coachees ||
        !meetings[activeMeeting].coachees.find(
          item => item._id === coacheeSelected._id
        ))
    ) {
      meetings[activeMeeting].coachees.push(coacheeSelected);

      this.setState({ meetings: meetings, coacheeSelected: '' }, () =>
        this.updateMeeting(meetings[activeMeeting])
      );
    }

    if (this._typeahead && this._typeahead.current)
      this._typeahead.current.setEntryText('');
  };

  removeSelectedCoachee = coacheeId => {
    const { meetings, activeMeeting } = this.state;

    if (
      activeMeeting &&
      (meetings[activeMeeting].coachees ||
        meetings[activeMeeting].coachees.find(item => item._id === coacheeId))
    ) {
      meetings[activeMeeting].coachees = meetings[
        activeMeeting
      ].coachees.filter(item => item._id !== coacheeId);

      this.setState({ meetings: meetings, coacheeSelected: '' }, () =>
        this.updateMeeting(meetings[activeMeeting])
      );
    }

    if (this._typeahead && this._typeahead.current)
      this._typeahead.current.setEntryText('');
  };

  render() {
    const {
      sideOpen,
      side,
      principalOpen,
      view,
      breadcrumbs,
      subview,
      user,
      loading,
      session,
      coachees,
      groups,
      selectedGroupId,
      meetings,
      activeMeeting,
      maxLength,
      sortField,
      showModalContents,
      showModalContentUpload,
      contentList,
      selectedContents,
      selectedCoachees,
      showModalActions,
      isLoading,
      hasNoMeetings,
      viewPopoverVideo,
      showModalProcess,
      loadingSendMinute,
      sendModalProcessToBackgroundAndOpenModalAction,
    } = this.state;

    return (
      <div className="sessao-master-date-style-register main-content">
        <Header
          style={hasNoMeetings ? { zIndex: 0 } : {}}
          goTo={this.goTo}
          sideOpen={sideOpen}
          toggleMenu={this.toggleMenu}
        />
        <div className="content-climb zIndex user bodyClimby">
          <div
            className={this.props.menu ? 'content-menu closed' : 'content-menu'}
          >
            <Sidebar
              side={this.state.side}
              setSidebarLinks={this.setSidebarLinks}
              expanded={!this.state.sideOpen}
            />
          </div>
          <div
            className={this.props.menu ? 'content-dash closed' : 'content-dash'}
          >
            <div className="dash-content zIndex">
              <div className="dash-crumb-filter">
                <Breadcrumbs breadcrumbs={breadcrumbs} />
              </div>
              <div className="dashboard-boxes">
                {isLoading ? (
                  <ReactLoading type="bubbles" color="#B1CD49" />
                ) : !this.userHasRights(user) ? (
                  <NotAllowed go={this.goTo.bind(this)} user={user} />
                ) : loading ? (
                  <ReactLoading type="bubbles" color="#B1CD49" />
                ) : (
                  <div className="dashboard-box box100">
                    {!hasNoMeetings &&
                      session._id &&
                      this.renderChronometer(
                        this.props.activeMeetingChronometer ||
                          this.state.activeMeeting,
                        this.props.chronometerSession
                      )}

                    <div style={{ display: 'flex' }}>
                      <span
                        className="box-title row-center"
                        style={{
                          alignItems: 'center',
                          width: '100%',
                          paddingLeft: `${
                            !hasNoMeetings && session._id ? '160px' : '0'
                          }`,
                        }}
                      >
                        Registro de Sessões
                      </span>

                      <div
                        className="form-input"
                        style={{
                          width: '45%',
                          padding: '0 20px',
                        }}
                      >
                        <label className="blue-label">Área</label>
                        {this.state.loading_groups ? (
                          <ReactLoading type="bubbles" color="#B1CD49" />
                        ) : (
                          <select
                            value={session.group_id || selectedGroupId}
                            className="blue-input"
                            onChange={e => {
                              this.changeGroup(e);
                            }}
                          >
                            <option value=""> &nbsp; Área</option>
                            {groups.map(group => {
                              return (
                                <option key={group._id} value={group._id}>
                                  {' '}
                                  &nbsp; {group.name}
                                </option>
                              );
                            })}
                          </select>
                        )}
                      </div>
                    </div>

                    {!hasNoMeetings && session._id ? (
                      <>
                        <div
                          style={{
                            display: 'flex',
                            margin: '15px 0',
                            justifyContent: 'center',
                          }}
                        >
                          <ul className="list-sessions">
                            {Object.values(meetings)
                              .sort(this.sortMeetings)
                              .map((meeting, key) => {
                                return (
                                  <span
                                    key={String(meeting.number)}
                                    className={
                                      String(meeting.number) ===
                                      String(activeMeeting)
                                        ? 'active'
                                        : ''
                                    }
                                  >
                                    {key !== 0 &&
                                    Object.values(meetings) &&
                                    Object.values(meetings).length > 1 ? (
                                      <li className="line-sessions" />
                                    ) : null}
                                    <li
                                      onClick={() => {
                                        this.changeMeeting(meeting.number);
                                      }}
                                    >
                                      Sessão {meeting.number}
                                    </li>

                                    {key ===
                                    this.calculateTotalMeetings() - 1 ? (
                                      <>
                                        <li className="line-sessions" />
                                        <li
                                          onClick={() => {
                                            this.addMeeting();
                                          }}
                                          className="AddSessions"
                                        >
                                          <i
                                            style={{ marginRight: '5px' }}
                                            className="fas fa-plus"
                                          />
                                          Adicionar sessão
                                        </li>
                                      </>
                                    ) : null}
                                  </span>
                                );
                              })}
                          </ul>
                        </div>

                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                        >
                          <Col xs={{ size: 12 }} md={{ size: 7, offset: 2.5 }}>
                            <div>
                              {/* {this.renderTimeSession(activeMeeting)} */}
                              <div
                                className="new-check-content noMargin"
                                style={{
                                  justifyContent: 'flex-start',
                                }}
                              >
                                <div
                                  className="form-input"
                                  style={{ width: 'calc(100% - 143px - 46px)' }}
                                >
                                  <label className="blue-label">
                                    Tema da sessão
                                  </label>
                                  <input
                                    type="text"
                                    value={this.getActiveMeetingValue(
                                      'session_theme'
                                    )}
                                    className="blue-input"
                                    placeholder="Tema da sessão"
                                    onBlur={e => {
                                      this.updateMeeting({
                                        session_theme: e.target.value,
                                      });
                                    }}
                                    onChange={e =>
                                      this.setActiveMeetingValue({
                                        session_theme: e.target.value,
                                      })
                                    }
                                  />
                                </div>
                                <div
                                  className="new-form-input"
                                  style={{ marginLeft: 5, width: '138px' }}
                                >
                                  <label className="blue-label">Data</label>

                                  <DatePicker
                                    selected={this.getActiveMeetingValue(
                                      'date'
                                    )}
                                    onChange={e =>
                                      this.updateMeeting({ date: e })
                                    }
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={30}
                                    dateFormat="dd/MM/yyyy HH:mm"
                                    timeCaption="hora"
                                    className="blue-input"
                                    popperModifiers={{
                                      preventOverflow: {
                                        enabled: true,
                                      },
                                    }}
                                  />
                                </div>
                                {/* TODO: Implementar video-chamada */}
                                {/* <div
                                  onMouseEnter={() =>
                                    this.setState({ viewPopoverVideo: true })
                                  }
                                  onMouseLeave={() =>
                                    this.setState({ viewPopoverVideo: false })
                                  }
                                  className="bt-blueicon container-pop bt-blueicon-video-session"
                                  onClick={() =>
                                    this.props.updateVideoSession({
                                      isActive: true,
                                      roomName: this.getSessionID(),
                                      type: videoRoomTypes.SESSION,
                                      role: videoRoomRoles.moderator,
                                      subject: this.getActiveMeetingValue(
                                        'session_theme'
                                      ),
                                      userType: userTypes.coach,
                                    })
                                  }
                                >
                                  <i className="fas fa-video" />
                                  {viewPopoverVideo && (
                                    <div className="popover-container">
                                      <div className="popover-content">
                                        Vídeo-chamada
                                      </div>
                                    </div>
                                  )}
                                </div> */}
                              </div>

                              <div className="form-input">
                                <label className="blue-label">Membro</label>
                                <div
                                  className="search-content"
                                  style={{ width: '100%' }}
                                >
                                  <i className="fas fa-search message-search-icon"></i>
                                  <Typeahead
                                    ref={this._typeahead}
                                    maxVisible={10}
                                    filterOption="name"
                                    defaultValue=""
                                    displayOption={this.getSearchCoachees}
                                    placeholder="Membro"
                                    options={this.state.coachees}
                                    onOptionSelected={
                                      this.handleCoacheeSelected
                                    }
                                    customClasses={{
                                      input: 'messages-search',
                                      results: 'list-messages listahead',
                                    }}
                                  />
                                  <div className="button-save">
                                    {this.state.openPopInsert && (
                                      <div className="insert-hint">Inserir</div>
                                    )}

                                    <img
                                      onMouseOver={() =>
                                        this.setState({ openPopInsert: true })
                                      }
                                      onMouseOut={() =>
                                        this.setState({ openPopInsert: false })
                                      }
                                      onClick={this.handleSelectedCoachees}
                                      className="table-icon disable svg-image"
                                      src={require('./../../../assets/images/tables/add_coachee.svg')}
                                      style={{ width: 20 }}
                                      alt="Inserir"
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="selected-coachees-div">
                                {meetings &&
                                activeMeeting !== 'master' &&
                                meetings[activeMeeting] &&
                                meetings[activeMeeting].coachees &&
                                meetings[activeMeeting].coachees.length > 0 ? (
                                  meetings[activeMeeting].coachees.map(item => (
                                    <Row
                                      key={item._id}
                                      className="selected-coachees-rows"
                                    >
                                      <div className="list-group-name">
                                        <span>{item.name}</span>
                                      </div>
                                      <div className="list-group-email">
                                        <span>{item.email}</span>
                                      </div>

                                      <div>
                                        {this.state.openPop === item._id && (
                                          <div className="delete-hint">
                                            Deletar
                                          </div>
                                        )}
                                        <img
                                          onMouseOver={() =>
                                            this.setState({ openPop: item._id })
                                          }
                                          onMouseOut={() =>
                                            this.setState({ openPop: null })
                                          }
                                          onClick={() =>
                                            this.removeSelectedCoachee(item._id)
                                          }
                                          className="table-icon svg-image"
                                          src={require('./../../../assets/images/tables/trash.svg')}
                                          style={{ width: 10 }}
                                          alt="Excluir"
                                        />
                                      </div>
                                    </Row>
                                  ))
                                ) : (
                                  <div className="selected-coachees-empty">
                                    <i
                                      className="fas fa-exclamation-triangle"
                                      style={{ marginRight: 5 }}
                                    ></i>
                                    Nenhum membro selecionado
                                  </div>
                                )}
                              </div>

                              <div className="row-form top-textarea">
                                <div className="form-textarea maxWidth clear-textarea">
                                  <label className="blue-label">
                                    Registro de Sessão
                                  </label>
                                  <div className="textarea">
                                    <RichText
                                      setState={this.setState.bind(this)}
                                      state={this.state}
                                      value={this.getActiveMeetingValue(
                                        'comment_private'
                                      )}
                                      showToolbar={true}
                                      minHeight={260}
                                      height={260}
                                      maxLength={maxLength}
                                      placeholder="Registro de Sessão"
                                      onBlur={e =>
                                        this.updateMeeting({
                                          comment_private: e,
                                        })
                                      }
                                      onChange={e =>
                                        this.setActiveMeetingValue({
                                          comment_private: e,
                                        })
                                      }
                                    />
                                  </div>
                                  <span>
                                    {this.getActiveMeetingValue(
                                      'comment_private'
                                    )
                                      ? this.utils.getLengthStringOfHtml(
                                          this.getActiveMeetingValue(
                                            'comment_private'
                                          )
                                        )
                                      : '0'}
                                    /{maxLength}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </div>

                        <Col
                          className="mtop-100"
                          xs={{ size: 12 }}
                          md={{ size: 10, offset: 1 }}
                        >
                          <TableAdd
                            title="Definições de Processos"
                            rows={[
                              {
                                title: 'Título do Processo',
                                key: 'process_description',
                              },
                              {
                                title: 'Hipótese de Tratamento de Dados',
                                key: 'data_processing_hypotheses_label',
                              },
                              {
                                title: 'Status',
                                key: 'status',
                              },
                              {
                                title: 'Remover',
                                key: 'remove',
                              },
                            ]}
                            remove={this.removeProcess}
                            toggle={this.toggleModalProcess}
                            button_label="Adicionar Processo"
                            button_icon="fas fa-plus"
                            values={
                              activeMeeting !== 'master' &&
                              this.getActiveMeetingValue('procedures')
                                ? this.getActiveMeetingValue('procedures').map(
                                    p => ({
                                      ...p,
                                      data_processing_hypotheses_label:
                                        getDataProcessingHypothesesText(
                                          p.data_processing_hypotheses
                                        ) || '',
                                    })
                                  )
                                : []
                            }
                          />
                        </Col>

                        <Col
                          className="mtop-100"
                          xs={{ size: 12 }}
                          md={{ size: 10, offset: 1 }}
                        >
                          <TableAdd
                            title="Tabela de conteúdos"
                            linked={'content'}
                            rows={[
                              ...(session.its_group
                                ? [
                                    {
                                      title: 'Membro',
                                      key: 'coacheeName',
                                    },
                                  ]
                                : []),
                              {
                                title: 'Título',
                                key: 'title',
                              },
                              {
                                title: 'Formato',
                                key: 'doc_format',
                              },
                              {
                                title: 'Categoria',
                                key: 'category',
                              },
                              {
                                title: 'Modelo',
                                key: 'tag',
                              },
                              {
                                title: 'Remover',
                                key: 'remove',
                              },
                            ]}
                            remove={this.removeContent}
                            toggle={this.toggleModalContent}
                            button_label="Adicionar Conteúdo"
                            button_icon="fas fa-paperclip"
                            values={
                              activeMeeting !== 'master' &&
                              this.getActiveMeetingValue('contents')
                                ? this.getActiveMeetingValue('contents').map(
                                    content => ({
                                      ...content,
                                      coacheeName: content.coachee.name,
                                    })
                                  )
                                : []
                            }
                          />
                        </Col>

                        <Col
                          className="mtop-100"
                          xs={{ size: 12 }}
                          md={{ size: 10, offset: 1 }}
                        >
                          <TableAdd
                            title="Tabela de ações"
                            rows={[
                              ...(session.its_group
                                ? [
                                    {
                                      title: 'Membro',
                                      key: 'coacheeName',
                                    },
                                  ]
                                : []),
                              {
                                title: 'Ação',
                                key: 'action',
                              },
                              {
                                title: 'Descrição',
                                key: 'description',
                              },
                              {
                                title: 'Responsável',
                                key: 'responsible',
                              },
                              {
                                title: 'Data para conclusão',
                                key: 'date',
                              },
                              {
                                title: 'Remover',
                                key: 'remove',
                              },
                            ]}
                            remove={this.removeAction}
                            toggle={this.toggleModalAction}
                            button_label="Adicionar uma Ação"
                            button_icon="fas fa-plus"
                            values={
                              activeMeeting !== 'master' &&
                              this.getActiveMeetingValue('actions')
                                ? this.getActiveMeetingValue('actions').map(
                                    action => ({
                                      ...action,
                                      coacheeName: action.coachee
                                        ? action.coachee.name
                                        : '',
                                    })
                                  )
                                : []
                            }
                          />
                        </Col>

                        <Col xs={{ size: 12 }} md={{ size: 8, offset: 2 }}>
                          <div className="row-between mtop-50 mbottom-50">
                            {session.coachee_id ||
                              (session.group_id && (
                                <div
                                  className="bt-blue"
                                  onClick={() => {
                                    this.notify();
                                  }}
                                >
                                  {loadingSendMinute ? (
                                    <ReactLoading
                                      type="bubbles"
                                      color="#B1CD49"
                                    />
                                  ) : (
                                    'Enviar ATA'
                                  )}
                                </div>
                              ))}
                          </div>
                        </Col>
                      </>
                    ) : (
                      hasNoMeetings &&
                      session._id && (
                        <div className="containerAddNewSession">
                          <p>
                            Você ainda não tem nenhuma sessão planejada.
                            Adicione a primeira para começar.
                          </p>

                          <span
                            onClick={() => {
                              this.addMeeting();
                            }}
                            className="AddSessions"
                            style={{
                              padding: '15px',
                            }}
                          >
                            <i
                              style={{ marginRight: '5px' }}
                              className="fas fa-plus"
                            />
                            Adicionar sessão
                          </span>
                        </div>
                      )
                    )}
                  </div>
                )}
              </div>
              <Footer />
            </div>
          </div>
        </div>

        <ModalContents
          sort={this.sort.bind(this)}
          sortField={sortField}
          show={showModalContents}
          contentList={contentList}
          selectedContents={selectedContents}
          toggleContent={this.toggleContent}
          toggleModalContent={this.toggleModalContent}
          coachees={this.getGroupCoachees()}
          selectedCoachees={selectedCoachees}
          toggleCoachee={this.toggleCoachee}
          selectAllCoachee={() =>
            this.setState({ selectedCoachees: [...this.getGroupCoachees()] })
          }
          selectNoneCoachee={() => this.setState({ selectedCoachees: [] })}
        />

        {(showModalActions ||
          sendModalProcessToBackgroundAndOpenModalAction) && (
          <ModalActions
            toggleModalAction={this.toggleModalAction}
            saveAction={this.saveAction}
            modal_actions={
              showModalActions || sendModalProcessToBackgroundAndOpenModalAction
            }
            coachees={this.getGroupCoachees()}
            selectedCoachees={selectedCoachees}
            toggleCoachee={this.toggleCoachee}
            selectAllCoachee={() =>
              this.setState({ selectedCoachees: [...this.getGroupCoachees()] })
            }
            selectNoneCoachee={() => this.setState({ selectedCoachees: [] })}
          />
        )}

        {(showModalProcess ||
          sendModalProcessToBackgroundAndOpenModalAction) && (
          <ModalProcess
            toggleModalProcess={this.toggleModalProcess}
            saveProcess={this.saveProcess}
            modal_process={
              showModalProcess &&
              !sendModalProcessToBackgroundAndOpenModalAction
            }
            toggleModalAction={this.toggleModalAction}
          />
        )}

        <ModalContentUpload
          show={showModalContentUpload}
          toggle={this.toggleModalContentUpload}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  sessionId: state.simpleReducer.sessionId,
  menu: state.simpleReducer.menu,
  chronometerSession: state.simpleReducer.chronometerSession,
  activeMeetingChronometer: state.simpleReducer.activeMeetingChronometer,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateImage,
      updateAlert,
      updateVideoSession,
      updateChronometerSessions,
      updateActiveMeetingChronometer,
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SessionRegister)
);
