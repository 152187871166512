export default [
  {
    menuName: 'Dashboard',
    menuLink: '',
    icon: 'dashboard',
  },
  {
    menuName: 'Agenda',
    menuLink: 'calendar',
    icon: 'calendar',
  },
  // TODO: Habilitar quando o chat estiver pronto
  // {
  //   menuName: 'Central de Mensagens',
  //   menuLink: 'chat',
  //   icon: 'comment',
  //   menuSubItems: [
  //     {
  //       menuName: 'Chat',
  //       menuLink: 'chat',
  //       icon: 'comment',
  //     },
  //     {
  //       menuName: 'Mensagens Personalizadas',
  //       menuLink: 'messages',
  //       icon: 'comments',
  //     },
  //   ],
  // },
  {
    menuName: 'Programa',
    menuLink: 'programs',
    icon: 'tasks',
    menuSubItems: [
      {
        menuName: 'Sessões',
        menuLink: 'programs',
        icon: 'order-form',
      },
      {
        menuName: 'Lista de Ações',
        menuLink: 'programs/actions',
        icon: 'list',
      },
      {
        menuName: 'Conteúdos Compartilhados',
        menuLink: 'programs/contents',
        icon: 'share-square-o',
      },
    ],
  },
];
