import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import '../../../styles/css/style.css';
import '../../../styles/css/dashboard.css';
import {
  updateImage,
  updateSession,
  updateAlert,
} from '../../../actions/SimpleAction';
import SidebarFinances from './../../sidebar/SidebarFinances';
import CardGenericTable from './../../components/CardGenericTable';
import ReactLoading from 'react-loading';
import Header from './../../components/Header';
import PrincipalMenu from './../../components/PrincipalMenu';
import Breadcrumbs from './../../components/Breadcrumbs';
import NotAllowed from './../../components/NotAllowed';
import Footer from './../../components/Footer';
import Submenu from './SubmenuBalance';
import Service from './../Service.js';
import Utils from './../../../libs/Utils.js';
import NewModalCoacheeDelete from './../../components/NewModalCoacheeDelete';

class BalanceIn extends Component {
  constructor(props, context) {
    super(props);

    const initialWidth = window.innerWidth > 0 ? window.innerWidth : 500;

    this.state = {
      isVisible: false,
      sideOpen: false,
      principalOpen: true,
      view: 'finances',
      subview: 'in',
      side: 'balance',
      breadcrumbs: [
        { title: 'Dashboard', link: '' },
        { title: 'Financeiro', link: 'finances' },
        { title: 'Balanço', link: 'finances/balance/in' },
        { title: 'Recebimentos', link: 'finances/balance/in' },
      ],
      showToolTip: false,
      windowWidth: initialWidth - (window.innerWidth * 40) / 100,
      loading: false,

      loading_coachee: false,
      coachees: [],

      loading_contents: false,
      list_contents: [],

      originalRows: [],
      rows: [],
      title_rows: [
        { title: 'Data de vencimento', key: '_date' },
        { title: 'Nº da proposta', key: 'number' },
        { title: 'Coachee/Grupo', key: 'coachee_or_group' },
        { title: 'Categoria', key: 'category' },
        { title: 'Valor', key: 'value' },
        { title: 'Status', key: 'finance_status' },
        { title: 'Ações', key: 'options' },
      ],
      table_actions: [
        /*
        {
          title: "Boleto",
          svg: {
            icon: require("./../../../assets/images/tables/boleto.svg"),
            width: 24
          },
          action: this.boleto.bind(this)
        },
        */
        {
          title: 'Editar',
          svg: {
            icon: require('./../../../assets/images/tables/edit.svg'),
            width: 18,
          },
          action: this.edit.bind(this),
        },
        {
          title: 'Relatorios',
          svg: {
            icon: require('./../../../assets/images/tables/doc_money.svg'),
            width: 18,
          },
          action: this.report.bind(this),
        },
        {
          title: 'Deletar',
          svg: {
            icon: require('./../../../assets/images/tables/trash.svg'),
            width: 15,
          },
          action: this.remove.bind(this),
        },
      ],

      view_rows: '',
      oportunity_id: '',
      coachee_id: '',
      group_id: '',
      forGroups: false,
      its_free: false,
      project: '',
      expected_date: '',
      coachee_objectives: '',
      success_evidence: '',
      motivators: '',
      saboteurs: '',
      values: '',
      strategies: '',
      necessary_resources: '',
      contents: [],
      actions: [],

      modal_contents: false,
      modal_actions: false,
      action_date: '',
      action_responsible: '',
      action_description: '',
      sort1: '',

      maxLength: 3660,

      coachee_objectives_active: false,
      success_evidence_active: false,
      motivators_active: false,
      saboteurs_active: false,
      values_active: false,
      strategies_active: false,
      necessary_resources_active: false,

      new_modal_coachee_delete: false,
      content_share: [],
      nameCoachee: '',
    };

    this.utils = new Utils();
    this.service = new Service();

    this.handleResize = this.handleResize.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.togglePrincipal = this.togglePrincipal.bind(this);
    this.setSidebarLinks = this.setSidebarLinks.bind(this);
    this.goTo = this.goTo.bind(this);
    this.changeRows = this.changeRows.bind(this);
  }

  toggleMenu() {
    if (this.state.sideOpen) {
      this.setState({ sideOpen: false });
    } else {
      this.setState({ sideOpen: true });
    }
  }

  togglePrincipal() {
    if (this.state.principalOpen) {
      this.setState({ principalOpen: false });
    } else {
      this.setState({ principalOpen: true });
    }
  }

  goTo(page) {
    this.props.history.push(`/${page}`);
  }

  verifyLogin() {
    if (!this.utils.isLogged()) {
      this.goTo('');
    }
  }

  componentDidMount() {
    this.verifyLogin();
    this.loadUser();

    this.loadContents();
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  setSidebarLinks(view) {
    this.setState({ view });
    this.goTo(`dashboard/${view}`);
  }

  handleResize() {
    let _ww =
      window.innerWidth < 890 && window.innerWidth > 570
        ? window.innerWidth - (window.innerWidth * 30) / 100
        : window.innerWidth <= 570
        ? window.innerWidth - (window.innerWidth * 40) / 100
        : window.innerWidth - (window.innerWidth * 40) / 100;

    this.setState({
      windowWidth: _ww,
    });
  }

  logout() {
    this.utils.setLogout();
    this.goTo('');
  }

  async loadUser() {
    let result = await this.service.me();
    if (!result) {
      this.logout();
      return;
    }
    let user = {
      ...result.user,
    };
    delete user.location;
    delete user.contact;
    if (result.user.contact && result.user.contact.length > 0) {
      user['phone'] = result.user.contact[0].phone;
      user['site'] = result.user.contact[0].site;
    }
    if (result.user.location && result.user.location.length > 0) {
      user['state'] = result.user.location[0].state;
      user['city'] = result.user.location[0].city;
    }
    if (user.image) {
      this.props.updateImage(user.image);
    }
    this.setState({ user });
  }

  async loadContents() {
    this.setState({ loading_contents: true });
    let result = await this.service.balancesIn();
    if (result && result.success) {
      let contents = result.balances.map(result => ({
        ...result,
        date: result.date.includes('T')
          ? this.utils.parseDateUSA(result.date)
          : result.date,
      }));
      this.setState({
        contents,
        originalRows: result.balances,
      });
    }
    this.setState({ loading_contents: false });
  }

  async remove(item) {
    await this.setState({
      content_share: item,
      nameCoachee: item.coachee ? item.coachee : '',
    });
    this.toggleModalDelete();
  }

  deleteContent = async () => {
    this.setState({ loading_contents: true });
    let result = await this.service.removeBalance(this.state.content_share._id);
    if (result && result.success) {
      this.loadContents();
    }
    this.setState({ loading_contents: false });
    this.toggleModalDelete();
  };

  toggleModalDelete() {
    this.setState({
      new_modal_coachee_delete: !this.state.new_modal_coachee_delete,
    });
  }

  edit(item) {
    this.setSidebarLinks(`finances/balance/${item.type}/create/${item._id}`);
  }

  async toggleSwitch(key) {
    let contents = this.state.contents;
    contents[key].status =
      contents[key].quotas_observation[contents[key].quota_key].status == 'paid'
        ? 'unpaid'
        : 'paid';
    this.setState({ contents });
    let result = await this.service.updateBalance(
      {
        coachee_id: contents[key].coachee_id || '',
        group_id: contents[key].group_id || '',
        status: contents[key].status,
        quota_key: contents[key].quota_key,
        quotas_observation: JSON.stringify(contents[key].quotas_observation),
      },
      contents[key]._id
    );
    if (result && result.success) {
      this.setState({ view_rows: 'all' });
      this.loadContents();
    }
  }

  changeRows(type) {
    if (type == 'all') {
      let contents = this.state.originalRows;
      this.setState({ contents });
    }
    if (type == 'received') {
      let contents = this.state.originalRows.filter(item => {
        return item.status == 'paid';
      });
      this.setState({ contents });
    }
    if (type == 'unreceived') {
      let contents = this.state.originalRows.filter(item => {
        return item.status != 'paid' && item.passed;
      });
      this.setState({ contents });
    }
    if (type == 'toreceive') {
      let contents = this.state.originalRows.filter(item => {
        return item.status != 'paid' && !item.passed;
      });
      this.setState({ contents });
    }
    this.setState({ view_rows: type });
  }

  boleto(item) {
    this.service.invoiceGenerate({
      coachee_id: item.coachee_id,
      value: item.total,
    });

    this.props.updateAlert('Boleto');
  }

  report(item) {
    this.goTo('dashboard/reports');
  }

  sort1(key) {
    let order =
      this.state.sort1 == '' || this.state.sort1 == key ? '-down' : '';

    let contents = this.state.contents.sort((a, b) => {
      if (a[key] > b[key]) {
        return order.indexOf('down') !== -1 ? 1 : -1;
      }
      if (a[key] < b[key]) {
        return order.indexOf('down') !== -1 ? -1 : 1;
      }
      return 0;
    });
    // console.log(key, this.state.sort)
    this.setState({ sort1: `${key}${order}`, contents });
  }

  render() {
    return (
      <div className="main-content">
        <Header
          goTo={this.goTo}
          sideOpen={this.state.sideOpen}
          toggleMenu={this.toggleMenu}
        />
        <div className="content-climb user bodyClimby">
          <div
            className={this.props.menu ? 'content-menu closed' : 'content-menu'}
          >
            <SidebarFinances
              goTo={this.goTo}
              setSidebarLinks={this.setSidebarLinks}
              side={this.state.side}
            />
          </div>
          <div
            className={this.props.menu ? 'content-dash closed' : 'content-dash'}
          >
            <PrincipalMenu
              principalOpen={this.state.principalOpen}
              view={this.state.view}
              setSidebarLinks={this.setSidebarLinks}
              togglePrincipal={this.togglePrincipal}
            />

            <div className="dash-content dash-fixed">
              <div className="dash-crumb-filter">
                <Breadcrumbs breadcrumbs={this.state.breadcrumbs} />
              </div>

              {(this.state.user &&
                this.state.user.plans &&
                !this.state.user.plans.platform) ||
              (this.state.user &&
                this.state.user.plans &&
                this.state.user.plans.platform &&
                !this.state.user.plans.platform.plan.use_finance) ? (
                <NotAllowed go={this.goTo.bind(this)} user={this.state.user} />
              ) : (
                <div className="dashboard-boxes">
                  <Submenu goTo={this.goTo} subview={this.state.subview} />

                  <div className="box100-menu">
                    <div className="m-15">
                      <CardGenericTable
                        title="Recebimentos"
                        id="balance-in-1"
                        finance={'finance'}
                        type={'in'}
                        changeRows={this.changeRows}
                        viewRows={this.state.view_rows}
                        toggleSwitch={this.toggleSwitch.bind(this)}
                        state={this.state}
                        actions={this.state.table_actions}
                        title_rows={this.state.title_rows}
                        sort={this.sort1.bind(this)}
                        _sort={this.state.sort1}
                        rows={this.state.contents}
                      />
                    </div>

                    <div className="mleft-40  mbottom-50">
                      {this.state.loading ? (
                        <ReactLoading type="bubbles" color="#00838F" />
                      ) : (
                        <div
                          className="bt-blue"
                          onClick={() => {
                            this.setSidebarLinks('finances/balance/in/create');
                          }}
                        >
                          <img
                            src={require('./../../../assets/images/plus.svg')}
                            style={{ marginTop: -3, marginRight: 14 }}
                          />
                          Novo Recebimento
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              <Footer />
            </div>
          </div>
        </div>

        <NewModalCoacheeDelete
          close={() => {
            this.setState({ new_modal_coachee_delete: false });
          }}
          state={this.state}
          open={this.state.new_modal_coachee_delete}
          deleteContent={this.deleteContent.bind(this)}
          title={`Confirmar deleção?`}
        />
      </div>
    );
  }
}
const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  session: state.simpleReducer.session,
  menu: state.simpleReducer.menu,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateImage, updateSession, updateAlert }, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BalanceIn)
);
