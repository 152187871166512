import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import '../../../styles/css/style.css';
import '../../../styles/css/profile.css';
import '../../../styles/css/datePicker/style.scss';
import ReactLoading from 'react-loading';
import InputMask from 'react-input-mask';
import StarRatingComponent from 'react-star-rating-component';
import Service from './Service.js';
import {
  updateImage,
  updateUser,
  updateAlert,
  updateSession,
} from '../../../actions/SimpleAction';
import SidebarProfile from '../../sidebar/SidebarProfile';
import Header from './../../components/Header';
import Breadcrumbs from './../../components/Breadcrumbs';
import Footer from './../../components/Footer';
import UploadImage from './UploadImage';
import Utils from './../../../libs/Utils.js';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

class Profile extends Component {
  constructor(props, context) {
    super(props);

    const initialWidth = window.innerWidth > 0 ? window.innerWidth : 500;

    this.state = {
      isVisible: false,
      sideOpen: false,
      principalOpen: true,
      loading: false,
      view: 'profile',
      breadcrumbs: [
        { title: 'Dashboard', link: '' },
        { title: 'Perfil', link: '' },
        { title: 'Dados básicos', link: '' },
      ],
      showToolTip: false,
      windowWidth: initialWidth - (window.innerWidth * 40) / 100,
      user: {},

      states: [],
      cities: [],
      loading_state: false,
      loading_cities: false,
      loading_rate: false,
      rate: 0,

      statusDate: true,
      validateEmail: true,
    };

    this.utils = new Utils();
    this.service = new Service();
    this.handleResize = this.handleResize.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.togglePrincipal = this.togglePrincipal.bind(this);
    this.setSidebarLinks = this.setSidebarLinks.bind(this);
    this.goTo = this.goTo.bind(this);
    this.logout = this.logout.bind(this);
    this.loadUser = this.loadUser.bind(this);
    this.loadCities = this.loadCities.bind(this);
  }

  toggleMenu() {
    if (this.state.sideOpen) {
      this.setState({ sideOpen: false });
    } else {
      this.setState({ sideOpen: true });
    }
  }

  togglePrincipal() {
    if (this.state.principalOpen) {
      this.setState({ principalOpen: false });
    } else {
      this.setState({ principalOpen: true });
    }
  }

  goTo(page) {
    this.props.history.push(`/${page}`);
  }

  verifyLogin() {
    if (!this.utils.isLogged()) {
      this.goTo('');
    }
  }

  logout() {
    this.utils.setLogout();
    this.props.updateImage('');
    this.props.updateSession(undefined);
    this.goTo('');
  }

  componentDidMount() {
    this.verifyLogin();
    let user = this.utils.getUser();
    this.setState({ user });
    window.addEventListener('resize', this.handleResize);
    this.loadUser();
    this.loadStates();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  setSidebarLinks(view) {
    this.setState({ view });
    this.goTo(`users/dashboard/${view}`);
  }

  handleResize() {
    let _ww =
      window.innerWidth < 890 && window.innerWidth > 570
        ? window.innerWidth - (window.innerWidth * 30) / 100
        : window.innerWidth <= 570
        ? window.innerWidth - (window.innerWidth * 40) / 100
        : window.innerWidth - (window.innerWidth * 40) / 100;

    this.setState({
      windowWidth: _ww,
    });
  }

  async loadStates() {
    this.setState({ loading_state: true });
    let result = await this.service.states();
    if (result) this.setState({ loading_state: false, states: result.states });
  }

  async loadCities(state) {
    this.setState({ user: { ...this.state.user, state } });
    this.setState({ loading_cities: true });
    let result = await this.service.cities(state);
    if (result) this.setState({ loading_cities: false, cities: result.cities });
  }

  async loadUser() {
    this.setState({ loading: true });
    let result = await this.service.meCoachee();
    if (!result) {
      this.logout();
      return;
    }
    this.props.updateUser(result.user);

    let dataUser = [];
    dataUser.push(result.user);

    const users = dataUser.map(result => ({
      ...result,
      birthday: result.birthday.trim(),
    }));

    let user = users[0];

    this.setState({ loading: false, user, rate: result.rate });
    if (user.image) {
      this.props.updateImage(user.image);
    }
  }

  async save() {
    if (this.formValid()) {
      this.setState({ loading: true });
      await this.validateEmail(this.state.user.email);
      if (
        this.state.user.birthday !== undefined &&
        this.state.user.birthday !== null &&
        this.state.user.birthday !== ''
      ) {
        await this.setState({ statusDate: true });
      } else {
        await this.setState({ statusDate: false });
      }

      if (this.state.statusDate && this.state.validateEmail) {
        let user = {
          name: this.state.user.name,
          email: this.state.user.email,
          address: this.state.user.address,
          phone: this.state.user.phone,
          birthday: this.state.user.birthday,
        };
        let result = await this.service.save(user);
        if (result.success) {
          this.props.updateAlert('Salvo com sucesso');
          this.loadUser();
        }
      }
      this.setState({ loading: false });
    } else {
      this.props.updateAlert('Preencha todos os campos');
    }
  }

  formValid() {
    return true;

    // if( this.state.user.email
    // &&  this.state.user.email.length > 0
    // &&  this.state.user.name
    // &&  this.state.user.name.length > 0
    // &&  this.state.user.surname
    // &&  this.state.user.surname.length > 0
    // &&  this.state.user.state
    // &&  this.state.user.state.length > 0
    // &&  this.state.user.city
    // &&  this.state.user.city.length > 0
    // &&  this.state.user.phone
    // &&  this.state.user.phone.length > 0
    // &&  this.state.user.site
    // &&  this.state.user.site.length > 0 ){
    //   return true
    // }
    // return false;
  }

  async setRate(rate) {
    this.setState({ loading_rate: true, rate });
    let result = await this.service.addRating({ rate });
    if (result && result.success) {
      this.props.updateAlert('Avaliação enviada com sucesso');
    }
    this.setState({ loading_rate: false });
  }

  statusDate = async () => {
    if (
      this.state.birthday !== undefined &&
      this.state.birthday !== null &&
      this.state.birthday !== ''
    ) {
      await this.setState({ statusDate: true });
    } else {
      await this.setState({ statusDate: false });
    }
  };

  validateEmail = async email => {
    if (this.utils.validateEmail(email)) {
      await this.setState({ validateEmail: true });
    } else {
      await this.setState({ validateEmail: false });
    }
  };

  render() {
    return (
      <div className="datepicker-style main-content">
        <Header
          goTo={this.goTo}
          sideOpen={this.state.sideOpen}
          toggleMenu={this.toggleMenu}
        />
        <div className="content-climb user bodyClimby">
          <div
            className={this.props.menu ? 'content-menu closed' : 'content-menu'}
          >
            <SidebarProfile
              goTo={this.goTo}
              view={this.state.view}
              logout={this.logout}
              setSidebarLinks={this.setSidebarLinks}
            />
          </div>
          <div
            className={this.props.menu ? 'content-dash closed' : 'content-dash'}
          >
            <div className="dash-content">
              <div className="dash-crumb-filter">
                <Breadcrumbs breadcrumbs={this.state.breadcrumbs} />
              </div>

              <div className="dashboard-box box100">
                <div className="box-row-title mobile-column">
                  <div className="box-title">Dados pessoais</div>
                  <UploadImage
                    user={this.state.user}
                    uuser={this.loadUser}
                    newOption={true}
                  />
                </div>
                <div className="content-form">
                  <div className="form-input">
                    <label className="blue-label">Nome</label>
                    <input
                      type="text"
                      value={this.state.user ? this.state.user.name : ''}
                      className="blue-input"
                      name="name"
                      onChange={e => {
                        this.setState({
                          user: { ...this.state.user, name: e.target.value },
                        });
                      }}
                    />
                  </div>
                  <div className="form-input">
                    <label className="blue-label">Email</label>
                    <input
                      type="text"
                      value={this.state.user ? this.state.user.email : ''}
                      className="blue-input"
                      name="email"
                      onChange={e => {
                        this.setState({
                          user: { ...this.state.user, email: e.target.value },
                        });
                        this.validateEmail(e.target.value);
                      }}
                    />
                    {!this.state.validateEmail && (
                      <span className="campObrigatorio">
                        Campo "Email" é obrigatório e precisa ser válido
                      </span>
                    )}
                  </div>
                  <div className="form-input">
                    <label className="blue-label">Telefone</label>
                    <InputMask
                      {...this.props}
                      mask="(99) 999999999"
                      value={this.state.user ? this.state.user.phone : ''}
                      className="blue-input"
                      onChange={e => {
                        this.setState({
                          user: { ...this.state.user, phone: e.target.value },
                        });
                      }}
                    />
                  </div>
                  <div className="form-input">
                    <label className="blue-label">Endereço</label>
                    <input
                      type="text"
                      value={this.state.user ? this.state.user.address : ''}
                      className="blue-input"
                      name="address"
                      onChange={e => {
                        this.setState({
                          user: { ...this.state.user, address: e.target.value },
                        });
                      }}
                    />
                  </div>
                  <div className="form-input-data">
                    <label className="blue-label">Data de Nascimento</label>
                    <DatePicker
                      selected={
                        this.state.user !== null &&
                        this.state.user.birthday &&
                        this.state.user.birthday !== ''
                          ? new Date(
                              this.utils.resolveSessionDate(
                                this.state.user.birthday
                              )
                            )
                          : ''
                      }
                      onChange={async e => {
                        await this.setState({
                          user: {
                            ...this.state.user,
                            birthday: this.utils.parseDateUSA(e),
                          },
                        });
                      }}
                      timeIntervals={30}
                      dateFormat="dd/MM/yyyy"
                      timeCaption="time"
                      className="blue-input"
                    />
                    {!this.state.statusDate && (
                      <span className="campObrigatorio">
                        Campo "Data de Nascimento" obrigatório
                      </span>
                    )}
                  </div>

                  {this.state.loading ? (
                    <ReactLoading type="bubbles" color="#B1CD49" />
                  ) : (
                    <div
                      onClick={() => {
                        this.save();
                      }}
                      className={
                        !this.formValid()
                          ? 'bt-save hover-shadow'
                          : 'bt-save hover-shadow active'
                      }
                    >
                      Salvar Alterações
                    </div>
                  )}
                </div>
              </div>

              <Footer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  menu: state.simpleReducer.menu,
  updateSession: state.simpleReducer.updateSession,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { updateImage, updateUser, updateAlert, updateSession },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Profile)
);
