import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import logo from '../../logo.svg';
import '../../styles/css/style.css';
import '../../styles/css/dashboard.css';

import { Container, Row, Col, Nav, NavItem, NavLink } from 'reactstrap';

import { simpleAction, updateImage } from '../../actions/SimpleAction';
// import Topbar from '../topbar/Topbar';

import SidebarFinances from '../sidebar/SidebarFinances';

import Header from './../components/Header';
import PrincipalMenu from './../components/PrincipalMenu';
import Breadcrumbs from './../components/Breadcrumbs';
import PeriodiceFilter from './../components/PeriodiceFilter';

import RowStats from './../components/RowStats';

import CardPerformance from './../components/CardPerformance';
import CardFinance from './../components/CardFinance';
import CardCoachees from './../components/CardCoachees';
import CardTasks from './../components/CardTasks';
import CardWeekCalendar from './../components/CardWeekCalendar';
import CardMessages from './../components/CardMessages';
import CardLastActions from './../components/CardLastActions';
import NotAllowed from './../components/NotAllowed';

import Footer from './../components/Footer';

import Service from './Service.js';
import Utils from './../../libs/Utils.js';

import { LineChart, BarChart } from 'react-easy-chart';

import Highcharts from 'highcharts';
import {
  HighchartsChart,
  Chart,
  withHighcharts,
  XAxis,
  YAxis,
  Title,
  Subtitle,
  Legend,
  LineSeries,
} from 'react-jsx-highcharts';

class Finances extends Component {
  constructor(props, context) {
    super(props);

    const initialWidth = window.innerWidth > 0 ? window.innerWidth : 500;

    this.state = {
      isVisible: false,
      sideOpen: false,
      principalOpen: true,
      view: 'finances',
      side: 'general',
      breadcrumbs: [
        { title: 'Dashboard', link: '' },
        { title: 'Financeiro', link: 'finances' },
      ],
      showToolTip: false,
      windowWidth: initialWidth - (window.innerWidth * 40) / 100,
      dash: {},
      _ins: [],
      _outs: [],
      _bals: [],
      xAxis: {
        categories: [],
      },
      lineChart: true,
    };

    this.utils = new Utils();
    this.service = new Service();
    this.handleResize = this.handleResize.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.togglePrincipal = this.togglePrincipal.bind(this);
    this.setSidebarLinks = this.setSidebarLinks.bind(this);
    this.goTo = this.goTo.bind(this);
  }

  toggleMenu() {
    if (this.state.sideOpen) {
      this.setState({ sideOpen: false });
    } else {
      this.setState({ sideOpen: true });
    }
  }

  togglePrincipal() {
    if (this.state.principalOpen) {
      this.setState({ principalOpen: false });
    } else {
      this.setState({ principalOpen: true });
    }
  }

  goTo(page) {
    this.props.history.push(`/${page}`);
  }

  verifyLogin() {
    if (!this.utils.isLogged()) {
      this.goTo('');
    }
  }

  componentDidMount() {
    this.verifyLogin();
    this.loadUser();
    this.loadDash();
    this.loadChart();
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  setSidebarLinks(view) {
    this.setState({ view });
    this.goTo(`dashboard/${view}`);
  }

  handleResize() {
    let _ww =
      window.innerWidth < 890 && window.innerWidth > 570
        ? window.innerWidth - (window.innerWidth * 30) / 100
        : window.innerWidth <= 570
        ? window.innerWidth - (window.innerWidth * 40) / 100
        : window.innerWidth - (window.innerWidth * 40) / 100;

    this.setState({
      windowWidth: _ww,
    });
  }

  logout() {
    this.utils.setLogout();
    this.goTo('');
  }

  async loadUser() {
    let result = await this.service.me();
    if (!result) {
      this.logout();
      return;
    }
    let user = {
      ...result.user,
    };
    if (user.image) {
      this.props.updateImage(user.image);
    }
    this.setState({ user });
  }

  async loadDash() {
    let periodicy = this.utils.getStorage('periodicy-filter')
      ? this.utils.getStorage('periodicy-filter')
      : this.props.periodicy;
    let result = await this.service.balanceDashboard(periodicy);
    if (result && result.success) {
      this.setState({ dash: result.data });
    }
  }

  async loadChart() {
    let result = await this.service.balanceChart();
    if (result && result.success) {
      this.setState({
        _ins: result._ins,
        _outs: result._outs,
        _bals: result._bals,
        xAxis: {
          categories: result._days,
        },
        lineChart: false,
      });
      setTimeout(() => {
        this.setState({ lineChart: true });
      }, 500);
    }
  }

  render() {
    return (
      <div className="main-content">
        <Header
          goTo={this.goTo}
          sideOpen={this.state.sideOpen}
          toggleMenu={this.toggleMenu}
        />
        <div className="content-climb user bodyClimby">
          <div
            className={this.props.menu ? 'content-menu closed' : 'content-menu'}
          >
            <SidebarFinances
              goTo={this.goTo}
              setSidebarLinks={this.setSidebarLinks}
              side={this.state.side}
            />
          </div>
          <div
            className={this.props.menu ? 'content-dash closed' : 'content-dash'}
          >
            <PrincipalMenu
              principalOpen={this.state.principalOpen}
              view={this.state.view}
              setSidebarLinks={this.setSidebarLinks}
              togglePrincipal={this.togglePrincipal}
            />

            <div className="dash-content dash-fixed">
              <div className="dash-crumb-filter">
                <Breadcrumbs
                  breadcrumbs={this.state.breadcrumbs}
                  customClass={'dash-breadcrumbs custom'}
                />
                <PeriodiceFilter
                  filtred={() => {
                    this.loadUser();
                    this.loadDash();
                    this.loadChart();
                  }}
                  filter={'Semestral'}
                />
              </div>

              {(this.state.user &&
                this.state.user.plans &&
                !this.state.user.plans.platform) ||
              (this.state.user &&
                this.state.user.plans &&
                this.state.user.plans.platform &&
                !this.state.user.plans.platform.plan.use_finance) ? (
                <NotAllowed go={this.goTo.bind(this)} user={this.state.user} />
              ) : (
                <div className="dashboard-boxes" style={{ paddingRight: 20 }}>
                  <Col
                    sm={{ size: 12 }}
                    md={{ size: 6 }}
                    className="margin-box mobile-no-paddings"
                  >
                    <div className="m--15 normalize-card">
                      <div className="dashboard-box box100">
                        <h3 className="finance-title title-b">A RECEBER</h3>
                        <div className="finance-row">
                          <div className="finance-box-info">
                            <div className="finance-box mt10">
                              <div className="finance-subtitle">Hoje</div>
                              <span className="finance-value reveice">
                                {' '}
                                {this.state.dash.receive_today
                                  ? this.state.dash.receive_today
                                  : ''}{' '}
                              </span>
                            </div>
                            <div className="finance-box mt10">
                              <div className="finance-subtitle">
                                Restante do mês
                              </div>
                              <span className="finance-subvalue reveice">
                                {' '}
                                {this.state.dash.receive_month
                                  ? this.state.dash.receive_month
                                  : ''}{' '}
                              </span>
                            </div>
                          </div>
                          <div
                            className="finance-box-button reveice"
                            onClick={() => {
                              this.goTo('dashboard/finances/balance/in/create');
                            }}
                          >
                            <i className="fas fa-plus"></i>
                            <p>Novo</p>
                            <p>Recebimento</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>

                  <Col
                    sm={{ size: 12 }}
                    md={{ size: 6 }}
                    className="margin-box2 mobile-no-paddings"
                  >
                    <div className="m--15 normalize-card">
                      <div className="dashboard-box box100">
                        <h3 className="finance-title title-b">A PAGAR</h3>
                        <div className="finance-row">
                          <div className="finance-box-info">
                            <div className="finance-box mt10">
                              <div className="finance-subtitle">Hoje</div>
                              <span className="finance-value paid">
                                {' '}
                                {this.state.dash.pay_today
                                  ? this.state.dash.pay_today
                                  : ''}{' '}
                              </span>
                            </div>
                            <div className="finance-box mt10">
                              <div className="finance-subtitle">
                                Restante do mês
                              </div>
                              <span className="finance-subvalue paid">
                                {' '}
                                {this.state.dash.pay_month
                                  ? this.state.dash.pay_month
                                  : ''}{' '}
                              </span>
                            </div>
                          </div>
                          <div
                            className="finance-box-button paid"
                            onClick={() => {
                              this.goTo(
                                'dashboard/finances/balance/out/create'
                              );
                            }}
                          >
                            <i className="fas fa-plus"></i>
                            <p>Novo</p>
                            <p>Despesa</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>

                  {/*chart*/}

                  <Col xs={{ size: 12 }} className="mobile-no-paddings zoom100">
                    <div className="m--15 normalize-card">
                      <div className="dashboard-box box100">
                        <h3 className="finance-btitle">
                          Fluxo de caixa diário
                        </h3>
                        <div
                          className="finance-block"
                          style={{ alignItems: 'flex-start' }}
                        >
                          {/*<span className="finance-value reveice"> R$ 340,00 </span>*/}

                          {this.state.lineChart ? this.chartLine() : null}

                          <ul>
                            <li>
                              <div className="before back-reveice"></div>
                              Recebimentos
                            </li>
                            <li>
                              <div className="before back-paid"></div>Pagamentos
                            </li>
                            <li>
                              <div className="before back-balance"></div>Saldo
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </Col>

                  <Col
                    sm={{ size: 12 }}
                    md={{ size: 6 }}
                    className="margin-box mobile-no-paddings"
                  >
                    <div className="m--15 normalize-card">
                      <div className="dashboard-box box100">
                        <div className="finance-block">
                          <h3 className="finance-title">
                            Recebimentos em atraso
                          </h3>
                          <span className="finance-value reveice">
                            {' '}
                            {this.state.dash.past_receive
                              ? this.state.dash.past_receive
                              : ''}{' '}
                          </span>
                        </div>
                      </div>
                    </div>
                  </Col>

                  <Col
                    sm={{ size: 12 }}
                    md={{ size: 6 }}
                    className="margin-box2 mobile-no-paddings"
                  >
                    <div className="m--15 normalize-card">
                      <div className="dashboard-box box100">
                        <div className="finance-block">
                          <h3 className="finance-title">
                            Pagamentos em atraso
                          </h3>
                          <span className="finance-value paid">
                            {' '}
                            {this.state.dash.past_pay
                              ? this.state.dash.past_pay
                              : ''}{' '}
                          </span>
                        </div>
                      </div>
                    </div>
                  </Col>
                </div>
              )}

              <Footer />
            </div>
          </div>
        </div>
      </div>
    );
  }

  chartLine() {
    return (
      <div className="center-chart finance-zoom-chart">
        <BarChart
          width={600}
          height={60}
          barWidth={20}
          xTickNumber={60}
          data={this.state._ins}
        />

        <BarChart
          width={600}
          height={60}
          style={{ transform: 'rotateX(180deg)', marginTop: -9 }}
          data={this.state._outs}
        />

        <div style={{ marginTop: -124, marginLeft: -54 }}>
          <HighchartsChart
            chart={{ width: 660, height: 160 }}
            legend={{
              layout: 'vertical',
              floating: true,
              backgroundColor: '#FFFFFF',
              align: 'left',
              verticalAlign: 'top',
              y: 0,
              x: 0,
            }}
            tooltip={{
              formatter: function() {
                return (
                  '<small style="text-align:center">' +
                  this.x +
                  '</small><br/>' +
                  'Saldo R$ ' +
                  this.y
                );
              },
            }}
            xAxis={this.state.xAxis}
          >
            <Chart />

            {/*<Legend layout="vertical" align="right" verticalAlign="middle" />*/}

            <XAxis>{/*<XAxis.Title>Time</XAxis.Title>*/}</XAxis>

            <YAxis>
              <LineSeries name="Saldo" data={this.state._bals} />
            </YAxis>
          </HighchartsChart>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  menu: state.simpleReducer.menu,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateImage }, dispatch);

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withHighcharts(Finances, Highcharts))
);
