import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import logo from '../../logo.svg';
import '../../styles/css/style.css';
import '../../styles/css/dashboard.css';
import PropTypes from 'prop-types';

import { Container, Row, Col, Nav, NavItem, NavLink } from 'reactstrap';

import {
  simpleAction,
  updateImage,
  updateAlert,
} from '../../actions/SimpleAction';
// import Topbar from '../topbar/Topbar';

import Sidebar from '../sidebar/Sidebar';

import Header from './../components/Header';
import PrincipalMenu from './../components/PrincipalMenu';
import Breadcrumbs from './../components/Breadcrumbs';
import PeriodiceFilter from './../components/PeriodiceFilter';
import NotAllowed from './../components/NotAllowed';

import RowStats from './../components/RowStats';

import CardPerformance from './../components/CardPerformance';
import CardFinance from './../components/CardFinance';
import CardCoachees from './../components/CardCoachees';
import CardTasks from './../components/CardTasks';
import CardWeekCalendar from './../components/CardWeekCalendar';
import CardMessages from './../components/CardMessages';
import CardLastActions from './../components/CardLastActions';

import Footer from './../components/Footer';

import ModalExport from './Create/ModalExport';

import Service from './Service.js';
import Utils from './../../libs/Utils.js';

import { AreaChart, BarChart } from 'react-easy-chart';

class Reports extends Component {
  constructor(props, context) {
    super(props);

    const initialWidth = window.innerWidth > 0 ? window.innerWidth : 500;

    this.state = {
      isVisible: false,
      sideOpen: this.props.menu,
      principalOpen: true,
      view: 'reports',
      breadcrumbs: [
        { title: 'Dashboard', link: '' },
        { title: 'Relatórios Gerenciais', link: 'reports' },
      ],
      showToolTip: false,
      windowWidth: initialWidth - (window.innerWidth * 40) / 100,

      selectedReport: '',
      showExport: false,
    };

    this.utils = new Utils();
    this.service = new Service();
    this.handleResize = this.handleResize.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.togglePrincipal = this.togglePrincipal.bind(this);
    this.setSidebarLinks = this.setSidebarLinks.bind(this);
    this.goTo = this.goTo.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.exportReport = this.exportReport.bind(this);
    this.saveExport = this.saveExport.bind(this);
  }

  toggleMenu() {
    if (this.state.sideOpen) {
      this.setState({ sideOpen: false });
    } else {
      this.setState({ sideOpen: true });
    }
  }

  togglePrincipal() {
    if (this.state.principalOpen) {
      this.setState({ principalOpen: false });
    } else {
      this.setState({ principalOpen: true });
    }
  }

  goTo(page) {
    this.props.history.push(`/${page}`);
  }

  verifyLogin() {
    if (!this.utils.isLogged()) {
      this.goTo('');
    }
  }

  componentDidMount() {
    this.verifyLogin();
    this.loadUser();
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  setSidebarLinks(view) {
    this.setState({ view });
    this.goTo(`dashboard/${view}`);
  }

  handleResize() {
    let _ww =
      window.innerWidth < 890 && window.innerWidth > 570
        ? window.innerWidth - (window.innerWidth * 30) / 100
        : window.innerWidth <= 570
        ? window.innerWidth - (window.innerWidth * 40) / 100
        : window.innerWidth - (window.innerWidth * 40) / 100;

    this.setState({
      windowWidth: _ww,
    });
  }

  logout() {
    this.utils.setLogout();
    this.goTo('');
  }

  async loadUser() {
    let result = await this.service.me();
    if (!result) {
      this.logout();
      return;
    }
    let user = {
      ...result.user,
    };
    if (user.image) {
      this.props.updateImage(user.image);
    }
    this.setState({ user });
  }

  toggleModal() {
    if (this.state.showExport) {
      this.setState({ selectedReport: '' });
    }
    this.setState({
      showExport: !this.state.showExport,
    });
  }

  exportReport(selectedReport) {
    this.setState({ selectedReport });
    this.toggleModal();
  }

  async saveExport(format, period, month, year) {
    let _pay = {
      type: this.state.selectedReport,
      format,
      period,
      month,
      year,
    };
    let result = await this.service.exportFile(_pay);
    if (result && result.success) {
      this.toggleModal();
      this.props.updateAlert('Exportado com sucesso');

      if (!result.resolved)
        window.open(
          `${this.service.getServer()}generate/${result.inserted_id}`
        );
    }
  }

  render() {
    return (
      <div className="main-content">
        <Header
          goTo={this.goTo}
          sideOpen={this.state.sideOpen}
          toggleMenu={this.toggleMenu}
        />
        <div className="content-climb user bodyClimby">
          <div
            className={this.props.menu ? 'content-menu closed' : 'content-menu'}
          >
            <Sidebar
              side={this.state.side}
              setSidebarLinks={this.setSidebarLinks}
              expanded={!this.state.sideOpen}
            />
          </div>
          <div
            className={this.props.menu ? 'content-dash closed' : 'content-dash'}
          >
            <div className="dash-content">
              <div className="dash-crumb-filter">
                <Breadcrumbs breadcrumbs={this.state.breadcrumbs} />
              </div>

              {(this.state.user &&
                this.state.user.plans &&
                !this.state.user.plans.platform) ||
              (this.state.user &&
                this.state.user.plans &&
                this.state.user.plans.platform &&
                !this.state.user.plans.platform.plan.use_reports) ? (
                <NotAllowed go={this.goTo.bind(this)} user={this.state.user} />
              ) : (
                <div className="dashboard-boxes">
                  <div className="dashboard-box  box100">
                    <Col xs={{ size: 12 }} md={{ size: 12 }}>
                      <div className="box-row-title">
                        <div className="box-title">Relatórios</div>
                      </div>
                    </Col>

                    <Col
                      xs={{ size: 12 }}
                      md={{ size: 12 }}
                      className="col-rel"
                    >
                      <Col
                        xs={{ size: 6 }}
                        md={{ size: 2 }}
                        className={
                          this.state.selectedReport == 'programs'
                            ? 'report-bt-export active'
                            : 'report-bt-export'
                        }
                        onClick={() => {
                          this.exportReport('project_status_area');
                        }}
                      >
                        <img
                          src={require('./../../assets/images/reports/rel_programas_coachee.svg')}
                          width="60"
                        />
                        <p>Relatório por área</p>
                      </Col>

                      <Col
                        xs={{ size: 6 }}
                        md={{ size: 2 }}
                        className={
                          this.state.selectedReport == 'coachees'
                            ? 'report-bt-export active'
                            : 'report-bt-export'
                        }
                        onClick={() => {
                          this.exportReport('executive_project_status');
                        }}
                      >
                        <img
                          src={require('./../../assets/images/reports/rel_relacao_coachee.svg')}
                          width="60"
                        />
                        <p>Relatório executivo</p>
                      </Col>
                    </Col>
                  </div>
                </div>
              )}

              <Footer />
            </div>
          </div>
        </div>
        {this.state.showExport && (
          <ModalExport
            toggleModal={this.toggleModal}
            show={this.state.showExport}
            save={this.saveExport}
          />
        )}
      </div>
    );
  }
}
const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  menu: state.simpleReducer.menu,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateImage, updateAlert }, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Reports)
);
