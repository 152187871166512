import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../../../styles/css/style.css';
import '../../../styles/css/profile.css';
import '../../../styles/css/marketing/stylesUploadImg.scss';
import { simpleAction, updateImage } from '../../../actions/SimpleAction';
import Utils from './../../../libs/Utils.js';
import Service from './Service.js';
import NewModalCoacheeDelete from '../../components/NewModalCoacheeDelete';

class UploadImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      new_modal_delete: false,
      displayRemove: 'displayNone',
    };
    this.utils = new Utils();
    this.service = new Service();
    this.onFileChange = this.onFileChange.bind(this);
    this.openUpload = this.openUpload.bind(this);
  }

  componentDidMount() {}

  componentWillUnmount() {}

  onFileChange(event) {
    if (event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];
      this.readFile(file);
    }
  }

  openUpload() {
    document.getElementById('fileimage-image').click();
  }

  readFile(file) {
    let reader = new FileReader();
    reader.onload = async () => {
      let source = `${reader.result}`.split(',')[1];
      let image = {
        filename: file.name,
        filetype: file.type,
        source,
      };
      let thefile = this.prepareImageFile(image);
      let result = await this.service.postImage(thefile);
      if (result.success) {
        this.props.handleImage(result.source);
      }
    };
    reader.readAsDataURL(file);
  }

  prepareImageFile(image) {
    let btc = window.atob(image.source);
    let btn = new Array(btc.length);
    for (var i = 0; i < btc.length; i++) {
      btn[i] = btc.charCodeAt(i);
    }
    var bta = new Uint8Array(btn);
    let boobs = new Blob([bta], { type: 'image/png' });
    const filenameText = image.filename.replace(/\.[^/.]+$/, '');
    return new File([boobs], `${filenameText}.png`);
  }

  resolveImage(path) {
    return this.utils.resolveURL(path, this.service.getServer());
  }

  removerImagem = async () => {
    this.toggleModalDelete();
  };

  toggleModalDelete() {
    this.setState({ new_modal_delete: !this.state.new_modal_delete });
  }

  deleteContent = async () => {
    let result = false;
    if (this.props.projectId) {
      result = await this.service.removeProjectImageById(this.props.projectId);
    } else {
      result = await this.service.removeProjectImage({
        image: this.props.image,
      });
    }
    if (result && result.success) {
      this.props.handleImage('');
    }
    this.toggleModalDelete();
  };

  displayOver = () => {
    this.setState({ displayRemove: 'displayBlock' });
  };

  displayOut = () => {
    this.setState({ displayRemove: 'displayNone' });
  };

  render() {
    return (
      <div
        className="marketing-styles-upload-img row-center"
        style={{ paddingLeft: this.props.image ? 60 : 0 }}
      >
        <div>
          <input
            type="file"
            onChange={ev => this.onFileChange(ev)}
            id="fileimage-image"
            style={{ display: 'none' }}
          />
          {this.props.image ? (
            <div
              className="container-imagem"
              onMouseOver={() => this.displayOver()}
              onMouseOut={() => this.displayOut()}
            >
              <div
                className="container-remover"
                style={{ height: 20 }}
                onClick={() => this.removerImagem()}
              >
                <span className={`btn-remover ${this.state.displayRemove}`}>
                  X
                </span>
              </div>
              <img
                src={this.resolveImage(this.props.image)}
                style={{
                  padding: 0,
                  zIndex: 10,
                  backgroundSize: 'contain, cover',
                }}
                className="hover-shadow imgPerfil"
                onClick={() => this.openUpload()}
              />
            </div>
          ) : (
            <div
              onClick={() => this.openUpload()}
              className="up-image hover-shadow group"
              style={{ marginBottom: 0, marginTop: 0 }}
            >
              <img
                src={require('./../../../assets/images/camera.svg')}
                width="70"
                style={{
                  padding: 0,
                  zIndex: this.props.zIndex ? this.props.zIndex : 10,
                  backgroundSize: 'contain, cover',
                }}
                alt="Foto"
              />
              <p>
                Arraste o arquivo .jpg ou .png
                <br />
                ou
                <br />
                Clique aqui
                <br />
                Max 10mb
              </p>
            </div>
          )}
          <NewModalCoacheeDelete
            close={() => this.setState({ new_modal_delete: false })}
            state={this.state}
            open={this.state.new_modal_delete}
            deleteContent={this.deleteContent.bind(this)}
            title="Deseja remover esta imagem"
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch => ({
  simpleAction: () => dispatch(simpleAction()),
  updateImage: () => dispatch(updateImage()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadImage);
