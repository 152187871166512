import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import '../../../styles/css/style.css';
import '../../../styles/css/dashboard.css';
import { Col } from 'reactstrap';
import { updateImage, updateAlert } from '../../../actions/SimpleAction';
import SidebarProposes from './../../sidebar/SidebarProposes';
import ReactLoading from 'react-loading';
import Header from './../../components/Header';
import PrincipalMenu from './../../components/PrincipalMenu';
import Breadcrumbs from './../../components/Breadcrumbs';
import NotAllowed from './../../components/NotAllowed';
import Footer from './../../components/Footer';
import Service from './../Service.js';
import Utils from './../../../libs/Utils.js';

class ProposalCreate extends Component {
  constructor(props, context) {
    super(props);

    const initialWidth = window.innerWidth > 0 ? window.innerWidth : 500;

    this.state = {
      isVisible: false,
      sideOpen: false,
      principalOpen: true,
      view: 'proposals',
      side: 'generate',
      breadcrumbs: [
        { title: 'Dashboard', link: '' },
        { title: 'Propostas', link: 'proposals' },
        { title: 'Gerar Proposta', link: 'proposals/proposal/register' },
      ],
      showToolTip: false,
      windowWidth: initialWidth - (window.innerWidth * 40) / 100,
      loading: false,

      loading_coachee: false,
      coachees: [],

      loading_contents: false,
      list_contents: [],

      contents: [],

      select_coachee: false,
      name: '',
      email: '',
      phone: '',
      needs_description: '',
      oportunity_id: '',
      maxLength: 3660,
      editing: false,
      editing_id: '',
    };

    this.utils = new Utils();
    this.service = new Service();
    this.handleResize = this.handleResize.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.togglePrincipal = this.togglePrincipal.bind(this);
    this.setSidebarLinks = this.setSidebarLinks.bind(this);
    this.goTo = this.goTo.bind(this);
    this.save = this.save.bind(this);
    this.changeCoachee = this.changeCoachee.bind(this);
    this.canSent = this.canSent.bind(this);
  }

  toggleMenu() {
    if (this.state.sideOpen) {
      this.setState({ sideOpen: false });
    } else {
      this.setState({ sideOpen: true });
    }
  }

  togglePrincipal() {
    if (this.state.principalOpen) {
      this.setState({ principalOpen: false });
    } else {
      this.setState({ principalOpen: true });
    }
  }

  goTo(page) {
    this.props.history.push(`/${page}`);
  }

  verifyLogin() {
    if (!this.utils.isLogged()) {
      this.goTo('');
    }
  }

  componentDidMount() {
    this.verifyLogin();
    this.loadUser();

    this.loadContents();
    this.loadOptions();
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  setSidebarLinks(view) {
    this.setState({ view });
    this.goTo(`dashboard/${view}`);
  }

  handleResize() {
    let _ww =
      window.innerWidth < 890 && window.innerWidth > 570
        ? window.innerWidth - (window.innerWidth * 30) / 100
        : window.innerWidth <= 570
        ? window.innerWidth - (window.innerWidth * 40) / 100
        : window.innerWidth - (window.innerWidth * 40) / 100;

    this.setState({
      windowWidth: _ww,
    });
  }

  logout() {
    this.utils.setLogout();
    this.goTo('');
  }

  async loadUser() {
    let result = await this.service.me();
    if (!result) {
      this.logout();
      return;
    }
    let user = {
      ...result.user,
    };
    delete user.location;
    delete user.contact;
    if (result.user.contact && result.user.contact.length > 0) {
      user['phone'] = result.user.contact[0].phone;
      user['site'] = result.user.contact[0].site;
    }
    if (result.user.location && result.user.location.length > 0) {
      user['state'] = result.user.location[0].state;
      user['city'] = result.user.location[0].city;
    }
    if (user.image) {
      this.props.updateImage(user.image);
    }

    this.setState({ user });
  }

  async loadOptions() {
    this.setState({ loading_coachee: true });
    let result = await this.service.oportunities();
    if (result && result.success) {
      this.setState({ coachees: result.oportunities });
    }
    this.setState({ loading_coachee: false });
  }

  async loadContents() {
    this.setState({ loading_contents: true });
    let result = await this.service.modelProposals();
    if (result && result.success) {
      this.setState({ list_contents: result.models_proposals });
    }
    this.setState({ loading_contents: false });
  }

  save() {
    if (this.state.oportunity_id.length > 0) {
      this._save();
    } else {
      this.props.updateAlert('Escolha uma oportunidade');
    }
  }

  async _save() {
    this.setState({ loading: true });
    let _pay = {
      models_proposal_id: this.state.contents.length
        ? this.state.contents[0]._id
        : '',
      oportunity_id: this.state.oportunity_id,
    };

    let result;
    if (this.state.editing) {
      result = await this.service.updateProposal(_pay, this.state.editing_id);
    } else {
      result = await this.service.saveProposal(_pay);
    }

    if (result && result.success) {
      this.setState({ loading: false });
      let _id = this.state.editing ? this.state.editing_id : result.inserted_id;
      this.goTo(`dashboard/proposals/proposal/register/${_id}`);
    }

    this.setState({ loading: false });
  }

  changeCoachee(e) {
    let _oportu = this.getOportuniById(e.target.value);

    if (_oportu.type == 'free') {
      this.setState({ editing: true, editing_id: _oportu.proposal_id });
    } else {
      this.setState({ editing: false });
    }

    this.setState({ oportunity_id: e.target.value });
  }

  toggleContent(item) {
    let contents = [];
    contents.push(item);
    this.setState({ contents });
  }

  hasContent(item) {
    return this.contentIndex(item) !== -1;
  }

  contentIndex(item) {
    for (let ind in this.state.contents) {
      let nitem = this.state.contents[ind];
      if (nitem._id == item._id) {
        return ind;
      }
    }
    return -1;
  }

  canSent() {
    return this.state.oportunity_id.length > 0;
  }

  getOportuniById(oportunity_id) {
    for (let _op of this.state.coachees) {
      if (_op._id == oportunity_id) {
        return _op;
      }
    }
    return -1;
  }

  render() {
    return (
      <div className="main-content">
        <Header
          goTo={this.goTo}
          sideOpen={this.state.sideOpen}
          toggleMenu={this.toggleMenu}
        />
        <div className="content-climb user bodyClimby">
          <div
            className={this.props.menu ? 'content-menu closed' : 'content-menu'}
          >
            <SidebarProposes
              goTo={this.goTo}
              side={this.state.side}
              setSidebarLinks={this.setSidebarLinks}
            />
          </div>
          <div
            className={this.props.menu ? 'content-dash closed' : 'content-dash'}
          >
            <PrincipalMenu
              principalOpen={this.state.principalOpen}
              view={this.state.view}
              setSidebarLinks={this.setSidebarLinks}
              togglePrincipal={this.togglePrincipal}
            />

            <div className="dash-content dash-fixed">
              <div className="dash-crumb-filter">
                <Breadcrumbs breadcrumbs={this.state.breadcrumbs} />
              </div>

              {(this.state.user &&
                this.state.user.plans &&
                !this.state.user.plans.platform) ||
              (this.state.user &&
                this.state.user.plans &&
                this.state.user.plans.platform &&
                !this.state.user.plans.platform.plan
                  .use_oportunities_proposals) ? (
                <NotAllowed go={this.goTo.bind(this)} user={this.state.user} />
              ) : (
                <div className="dashboard-boxes">
                  <div className="dashboard-box box100">
                    <Col xs={{ size: 12 }} md={{ size: 6, offset: 3 }}>
                      <div className="box-row-title row-center">
                        <div className="box-title">
                          Meus modelos de propostas
                        </div>
                      </div>

                      <div className="form-input input-title">
                        <label className="blue-label">Oportunidade</label>
                        {this.state.loading_coachee ? (
                          <ReactLoading type="bubbles" color="#B1CD49" />
                        ) : (
                          <select
                            value={this.state.oportunity_id}
                            className="blue-input"
                            onChange={e => {
                              this.changeCoachee(e);
                            }}
                          >
                            <option value=""> &nbsp; Oportunidade</option>
                            {this.state.coachees.map((item, key) => {
                              return (
                                <option key={key} value={item._id}>
                                  {' '}
                                  &nbsp; {item.name}
                                </option>
                              );
                            })}
                          </select>
                        )}
                      </div>
                    </Col>
                    <Col xs={{ size: 12 }} md={{ size: 10, offset: 1 }}>
                      <div className="top-textarea">
                        <table className="big-row table-session">
                          <thead>
                            <tr>
                              <td>
                                <div className="">Título</div>
                              </td>
                              <td>
                                <div className="tcenter">Formato</div>
                              </td>
                              <td>
                                <div className="tcenter">Categoria</div>
                              </td>
                              <td>
                                <div className="tcenter">Modelo</div>
                              </td>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.list_contents.map((item, key) => {
                              return (
                                <tr
                                  key={key}
                                  className={
                                    this.hasContent(item)
                                      ? 'modal-select-content active'
                                      : 'modal-select-content'
                                  }
                                  onClick={() => {
                                    this.toggleContent(item);
                                  }}
                                >
                                  <td className="">
                                    <div className="div-box-row-table">
                                      <span className="mobile-label">
                                        Título:{' '}
                                      </span>
                                      {item.title}
                                    </div>
                                  </td>
                                  <td className="tcenter">
                                    <div className="div-box-row-table">
                                      <span className="mobile-label">
                                        Formato:{' '}
                                      </span>
                                      {item.doc_format}
                                    </div>
                                  </td>
                                  <td className="tcenter">
                                    <div className="div-box-row-table">
                                      <span className="mobile-label">
                                        Categoria:{' '}
                                      </span>
                                      {item.category}
                                    </div>
                                  </td>
                                  <td className="tcenter">
                                    <div className="div-box-row-table">
                                      <span className="mobile-label">
                                        Modelo:{' '}
                                      </span>
                                      {item.tag}
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>

                      <div className="row-center mtop-50 mbottom-50">
                        <div
                          className={
                            this.state.contents.length > 0
                              ? 'bt-blue-outline'
                              : 'bt-blue grey'
                          }
                          style={{ marginRight: 20 }}
                          onClick={() => {
                            if (this.state.contents.length > 0) {
                              this.setState({ contents: [] });
                            }
                          }}
                        >
                          Cancelar
                        </div>
                        {this.state.loading ? (
                          <ReactLoading type="bubbles" color="#00838F" />
                        ) : (
                          <div
                            className={
                              this.canSent() ? 'bt-blue' : 'bt-blue bt-disabled'
                            }
                            onClick={() => {
                              this.save();
                            }}
                          >
                            {this.state.editing ? 'Salvar ' : 'Avançar '}
                          </div>
                        )}
                      </div>
                    </Col>
                  </div>
                </div>
              )}
              <Footer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  menu: state.simpleReducer.menu,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateImage, updateAlert }, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProposalCreate)
);
