import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import DatePicker from 'react-datepicker';
import { updateImage } from '../../../../actions/SimpleAction';
import Sidebar from './../../../sidebar/Sidebar';
import Header from './../../../components/Header';
import PrincipalMenu from './../../../components/PrincipalMenu';
import Breadcrumbs from './../../../components/Breadcrumbs';
import Footer from './../../../components/Footer';
import Service from './../../Service.js';
import Utils from './../../../../libs/Utils.js';
import '../../../../styles/css/style.css';
import '../../../../styles/css/dashboard.css';

class FormPreview extends Component {
  constructor(props, context) {
    super(props);
    const initialWidth = window.innerWidth > 0 ? window.innerWidth : 500;

    this.state = {
      isVisible: false,
      sideOpen: false,
      principalOpen: true,
      view: 'contents',
      side: 'my',
      breadcrumbs: [
        { title: 'Dashboard', link: '' },
        { title: 'Conteúdos', link: 'contents' },
        { title: 'Visualizador de formulário', link: 'form-preview' },
      ],
      showToolTip: false,
      windowWidth: initialWidth - (window.innerWidth * 40) / 100,

      formData: [],
      title: '',

      climbyContent: false,
      contentId: '',
    };

    this.utils = new Utils();
    this.service = new Service();
    this.handleResize = this.handleResize.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.togglePrincipal = this.togglePrincipal.bind(this);
    this.setSidebarLinks = this.setSidebarLinks.bind(this);
    this.goTo = this.goTo.bind(this);
  }

  toggleMenu() {
    const { sideOpen } = this.state;
    this.setState({ sideOpen: !sideOpen });
  }

  togglePrincipal() {
    const { principalOpen } = this.state;
    this.setState({ principalOpen: !principalOpen });
  }

  goTo(page) {
    this.props.history.push(`/${page}`);
  }

  verifyLogin() {
    if (!this.utils.isLogged()) {
      this.goTo('');
    }
  }

  componentDidMount() {
    this.verifyLogin();
    this.loadUser();
    this.resolveParams();
    window.addEventListener('resize', this.handleResize);
  }

  resolveParams() {
    const {
      match: { params },
      location,
    } = this.props;
    const climbyContent = location.state && location.state.climbyContent;
    if (params.id) {
      const contentId = params.id;
      this.setState(
        {
          contentId,
          climbyContent,
          side: climbyContent ? 'climb' : 'my',
        },
        () => this.load(contentId)
      );
    }
  }

  async load(_id) {
    const { climbyContent } = this.state;
    let result = climbyContent
      ? await this.service.contentsClimbOne(_id)
      : await this.service.content(_id);

    if (result && result.success) {
      if (result.content) {
        this.setState({
          title: result.content.title,
          formData: [...result.content.data],
        });
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  setSidebarLinks(view) {
    this.setState({ view });
    this.goTo(`dashboard/${view}`);
  }

  handleResize() {
    let _ww =
      window.innerWidth < 890 && window.innerWidth > 570
        ? window.innerWidth - (window.innerWidth * 30) / 100
        : window.innerWidth <= 570
        ? window.innerWidth - (window.innerWidth * 40) / 100
        : window.innerWidth - (window.innerWidth * 40) / 100;

    this.setState({
      windowWidth: _ww,
    });
  }

  logout() {
    this.utils.setLogout();
    this.goTo('');
  }

  async loadUser() {
    let result = await this.service.me();
    if (!result) {
      this.logout();
      return;
    }
    let user = {
      ...result.user,
    };
    if (user.image) {
      this.props.updateImage(user.image);
    }
  }

  renderEvaluationLevels = evaluationLevels => {
    const levels = [];
    for (let i = 1; i <= parseInt(evaluationLevels); i++) {
      levels.push(i);
    }
    return levels;
  };

  renderResponseTypes(question) {
    switch (question.type) {
      case 'text': {
        return (
          <Col xs={{ size: 12 }} md={{ size: 12 }}>
            <div
              className="form-textarea clear-textarea"
              style={{ maxWidth: 'none', paddingLeft: '0px' }}
            >
              <label className="blue-label">Resposta</label>
              <textarea
                value=""
                className="blue-input"
                placeholder="Resposta"
                readOnly={true}
              />
            </div>
          </Col>
        );
      }
      case 'date': {
        return (
          <Col xs={{ size: 12 }} md={{ size: 6 }}>
            <div className="form-input date-picker">
              <label className="blue-label">Resposta</label>
              <DatePicker
                selected=""
                readOnly={true}
                timeIntervals={30}
                dateFormat="dd/MM/yyyy"
                timeCaption="time"
                className="blue-input"
              />
            </div>
          </Col>
        );
      }
      case 'multiple-choice':
      case 'checkboxes': {
        return (
          <Col xs={{ size: 12 }} md={{ size: 12 }}>
            {question.options.map((item, optionIndex) => (
              <div key={optionIndex} className="row-center">
                <div className="form-option-div">
                  <input
                    className="form-option cursor-auto"
                    type={
                      question.type === 'multiple-choice' ? 'radio' : 'checkbox'
                    }
                    name="choices"
                    value={false}
                    checked={false}
                    readOnly={true}
                  />
                </div>
                <div className="form-input">
                  <label className="blue-label">{`Opção ${optionIndex +
                    1}`}</label>
                  <input
                    type="text"
                    value={item.option}
                    className="blue-input"
                    readOnly={true}
                  />
                </div>
              </div>
            ))}
            {question.others && question.others.actived && (
              <div className="row-center">
                <div className="form-option-div">
                  <input
                    className="form-option cursor-auto"
                    type={
                      question.type === 'multiple-choice' ? 'radio' : 'checkbox'
                    }
                    name="choices"
                    value={false}
                    checked={false}
                    readOnly={true}
                  />
                </div>
                <div className="form-input">
                  <label className="blue-label">Outros</label>
                  <input
                    type="text"
                    className="blue-input"
                    placeholder="Outros..."
                    value=""
                    disabled={true}
                    readOnly={true}
                  />
                </div>
              </div>
            )}
          </Col>
        );
      }
      case 'ranking': {
        return (
          <Col xs={{ size: 12 }} md={{ size: 12 }}>
            {question.options.map((item, optionIndex) => (
              <div key={optionIndex} className="row-center z-index">
                <span className="form-ranking">{`${optionIndex + 1}º`}</span>
                <div className="form-input">
                  <label className="blue-label">{`${optionIndex +
                    1}º Posição`}</label>
                  <input
                    type="text"
                    value={item.option}
                    className="blue-input"
                    readOnly={true}
                  />
                </div>
              </div>
            ))}
          </Col>
        );
      }
      case 'evaluation': {
        return (
          <Col xs={{ size: 12 }} md={{ size: 12 }}>
            <div className="row-center flex-wrap mb-15">
              {this.renderEvaluationLevels(question.evaluationLevels).map(
                item => (
                  <div key={item} className="form-evaluation-levels-div">
                    <span className="form-evaluation-levels-span">{item}</span>
                    <input
                      className="form-option cursor-auto"
                      type="radio"
                      name="evaluation"
                      value={false}
                      checked={false}
                      readOnly={true}
                    />
                  </div>
                )
              )}
            </div>
          </Col>
        );
      }
      case 'likert': {
        return (
          <Col xs={{ size: 12 }} md={{ size: 12 }} style={{ margin: '10px 0' }}>
            <div className="form-likert-columns">
              {question.likert.columns.map((column, columnIndex) => {
                return (
                  <span
                    key={columnIndex}
                    className="column-text likert-vertical-text"
                  >
                    {column.text || `Coluna ${columnIndex + 1}`}
                  </span>
                );
              })}
            </div>
            {question.likert.rows.map((row, rowIndex) => {
              return (
                <div
                  key={rowIndex}
                  className={`form-likert-rows ${
                    rowIndex % 2 ? 'bc-gray' : 'bc-light-gray'
                  }`}
                >
                  <span className="row-text likert-vertical-text">
                    {row.text || `Linha ${rowIndex + 1}`}
                  </span>
                  <div className="likert-rows-circle">
                    {question.likert.columns.map((column, columnIndex) => (
                      <input
                        key={`${rowIndex}-${columnIndex}`}
                        className="form-option cursor-auto"
                        type="radio"
                        name={row.text}
                        value={false}
                        checked={false}
                        readOnly={true}
                      />
                    ))}
                  </div>
                </div>
              );
            })}
          </Col>
        );
      }
      default:
        return null;
    }
  }

  render() {
    const {
      sideOpen,
      side,
      principalOpen,
      view,
      breadcrumbs,
      subview,
      title,
      formData,
    } = this.state;
    return (
      <div className="main-content">
        <Header
          goTo={this.goTo}
          sideOpen={sideOpen}
          toggleMenu={this.toggleMenu}
        />
        <div className="content-climb user bodyClimby">
          <div
            className={this.props.menu ? 'content-menu closed' : 'content-menu'}
          >
            <Sidebar
              side={this.state.side}
              setSidebarLinks={this.setSidebarLinks}
              expanded={!this.state.sideOpen}
            />
          </div>
          <div
            className={this.props.menu ? 'content-dash closed' : 'content-dash'}
          >
            <div className="dash-content">
              <div className="dash-crumb-filter">
                <Breadcrumbs breadcrumbs={breadcrumbs} />
              </div>
              <div className="dashboard-boxes">
                <div className="dashboard-box box100">
                  <Col xs={{ size: 12 }} md={{ size: 12 }}>
                    <div className="box-row-title row-center">
                      <div className="box-title">
                        Visualizador de Formulário
                      </div>
                    </div>

                    <div className="form-div">
                      <div className="form-input">
                        <label className="blue-label">Título</label>
                        <input
                          type="text"
                          value={title}
                          className="blue-input"
                          placeholder="Título"
                          readOnly={true}
                        />
                      </div>

                      {formData.map((item, index) => (
                        <div key={index} className="form-new-question">
                          <label className="form-question-title">
                            {`${index + 1}. Questão `}
                            {item.mandatory && (
                              <span style={{ color: '#AE1515' }}>*</span>
                            )}
                          </label>
                          <Row>
                            <Col xs={{ size: 12 }} md={{ size: 12 }}>
                              <div className="form-input">
                                <label className="blue-label">Pergunta</label>
                                <input
                                  type="text"
                                  value={item.question}
                                  className="blue-input"
                                  placeholder="Pergunta"
                                  readOnly={true}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>{this.renderResponseTypes(item)}</Row>
                        </div>
                      ))}
                    </div>
                  </Col>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  menu: state.simpleReducer.menu,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateImage }, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FormPreview)
);
