import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Col } from 'reactstrap';
import DatePicker from 'react-datepicker';
import Utils from '../../../libs/Utils.js';
import Service from '../Service.js';
import '../../../styles/css/dashboard.css';

import '../../../styles/css/datePicker/style.scss';
import { AdequacyStatus } from '../../../consts/adequacy.js';

class ModalFramework extends Component {
  constructor(props, context) {
    super(props);
    this.state = {
      idFramework: '',
      id: '',
      process_description: '',
      law_article: '',
      comments: '',
      process_status: '',
      maxLength: 1000,
      optionsStatus: [],
    };
    this.utils = new Utils();
    this.updateFramework = this.updateFramework.bind(this);
    this.service = new Service();
  }

  componentDidMount() {
    this.getStatus();

    this.setState({
      idFramework: this.props.objEditFramework.idFramework,
      id: this.props.objEditFramework.id,
      process_description: this.props.objEditFramework.process_description,
      law_article: this.props.objEditFramework.law_article,
      comments: this.props.objEditFramework.comments,
      process_status: this.props.objEditFramework.process_status,
    });
  }
  componentWillUnmount() {}

  async updateFramework() {
    const framework = {
      idFramework: this.state.idFramework,
      id: this.state.id,
      process_description: this.state.process_description,
      law_article: this.state.law_article,
      comments: this.state.comments,
      process_status: this.state.process_status,
    };

    this.props.updateFramework(framework);
  }

  async getStatus() {
    const _status = await this.service.getStatusFramework();

    if (_status && _status.length > 0) {
      this.setState({
        optionsStatus: _status,
      });
    }
  }

  render() {
    return (
      <div
        className={`datepicker-style modal-content ${
          this.props.modal_framework ? ' active' : ''
        }`}
      >
        <div className="modal-box">
          <div className="modal-row-between">
            <div className="box-title">Atualizar Status</div>
            <i
              className="fas fa-times"
              onClick={() => {
                this.props.toggleModalFramework();
              }}
            ></i>
          </div>

          <div className="top-textarea modal-table-box">
            <Col xs={{ size: 12 }} md={{ size: 8, offset: 2 }}>
              <div className="modal-row-triple gap10">
                <div className="form-input disabled">
                  <label className="blue-label">Descrição do processo</label>
                  <input
                    disabled
                    type="text"
                    value={this.state.process_description}
                    className="blue-input"
                    placeholder="Descrição do processo"
                    onChange={e => {
                      this.setState({ process_description: e.target.value });
                    }}
                  />
                </div>
              </div>

              <div className="modal-row-triple bm00 gap10">
                <div className="form-input disabled w33">
                  <label className="blue-label">Artigo da Lei</label>
                  <input
                    disabled
                    type="text"
                    value={this.state.law_article}
                    className="blue-input"
                    placeholder="Artigo da Lei"
                    onChange={e => {
                      this.setState({ law_article: e.target.value });
                    }}
                  />
                </div>
                <div className="form-input w33">
                  <label className="blue-label">Status</label>
                  <select
                    value={this.state.process_status}
                    className="blue-input margin-bottom-unset"
                    onChange={e =>
                      this.setState({ process_status: e.target.value })
                    }
                    disabled={
                      this.state.process_status === AdequacyStatus.NAO_APLICAVEL
                    }
                  >
                    {this.state.optionsStatus
                      .filter(item =>
                        this.state.process_status ===
                        AdequacyStatus.NAO_APLICAVEL
                          ? item === AdequacyStatus.NAO_APLICAVEL
                          : item !== AdequacyStatus.NAO_APLICAVEL
                      )
                      .map((item, key) => {
                        return (
                          <option key={key} value={item}>
                            &nbsp; {item}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>

              <div className="row-form">
                <div className="form-textarea disabled clear-textarea">
                  <label className="blue-label">Comentários</label>
                  <textarea
                    disabled
                    value={this.state.comments}
                    className="blue-input h55"
                    placeholder="Comentários"
                    maxLength={this.state.maxLength}
                    onChange={e => {
                      this.setState({ comments: e.target.value });
                    }}
                  ></textarea>
                  <span>
                    {this.state.comments.length}/{this.state.maxLength}
                  </span>
                </div>
              </div>
            </Col>
          </div>

          <div className="modal-row-actions">
            <div
              className="bt-blue"
              onClick={() => {
                this.updateFramework();
              }}
            >
              <img
                src={require('./../../../assets/images/plus.svg')}
                style={{ marginTop: -3, marginRight: 14 }}
              />
              Atualizar
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  image: state.simpleReducer.image,
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ModalFramework);
