import ApiService from '../../../services/ApiService';

class Service extends ApiService {
  path = 'admin/projects';

  async read() {
    try {
      let response = await fetch(`${this.endPoint}${this.path}`, {
        method: 'GET',
        headers: {
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async readOne(id) {
    try {
      let response = await fetch(`${this.endPoint}${this.path}/${id}`, {
        method: 'GET',
        headers: {
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async create(_payload) {
    try {
      let response = await fetch(`${this.endPoint}${this.path}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
        body: JSON.stringify(_payload),
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async update(_payload, _id) {
    try {
      let response = await fetch(`${this.endPoint}${this.path}/${_id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
        body: JSON.stringify(_payload),
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async delete(_id) {
    try {
      let response = await fetch(`${this.endPoint}${this.path}/${_id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async users() {
    try {
      let response = await fetch(`${this.endPoint}admin/coachs`, {
        method: 'GET',
        headers: {
          Authorization: `JWT ${this.getToken()}`,
        },
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async postImage(fileToUpload) {
    const formData = new FormData();
    formData.append('image', fileToUpload, fileToUpload.name);
    try {
      let response = await fetch(`${this.endPoint}admins/upload/image`, {
        method: 'POST',
        headers: {
          Authorization: `JWT ${this.getToken()}`,
        },
        body: formData,
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async removeProjectImageById(_id) {
    try {
      let response = await fetch(
        `${this.endPoint}${this.path}/removeimagebyid/${_id}`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
            Authorization: `JWT ${this.getToken()}`,
          },
        }
      );
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }

  async removeProjectImage(_payload) {
    try {
      let response = await fetch(`${this.endPoint}${this.path}/removeimage`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Authorization: `JWT ${this.getToken()}`,
        },
        body: this.parsePayload(_payload),
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      console.error(error);
    }
  }
}

export default Service;
