import React, { Component } from 'react';
import ReactLoading from 'react-loading';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { updateImage, updateSession } from '../../../actions/SimpleAction';
import Utils from '../../../libs/Utils.js';
import '../../../styles/css/dashboard.css';
import '../../../styles/css/style.css';
import Breadcrumbs from '../../components/Breadcrumbs';
import CardGenericTableWithGroup from '../../components/CardGenericTableWithGroup';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Sidebar from '../../sidebar/Sidebar';
import Service from '../Service.js';
import ModalFramework from './ModalFramework';
import { sortArticles } from '../../../consts/adequacy.js';

class AdequacyStatus extends Component {
  constructor(props, context) {
    super(props);

    this.state = {
      sideOpen: this.props.menu,
      view: 'adequacy-status',
      subview: '',
      breadcrumbs: [
        { title: 'Dashboard', link: '' },
        {
          title: 'Status da Adequação',
          link: 'adequacy-status',
        },
      ],
      titleRows: [
        { title: 'Processo', key: 'process_description' },
        { title: 'Artigo da Lei', key: 'law_article' },
        { title: 'Comentário', key: 'comments' },
        { title: 'Status', key: 'process_status' },
        { title: 'Opções', key: 'options' },
      ],
      tableActions: [
        {
          title: 'Editar',
          icon: 'fas fa-pencil-alt',
          action: this.editFramework.bind(this),
        },
      ],
      allFrameworks: [],
      isLoading: false,
      showModalFramework: false,
      objEditFramework: {},
    };

    this.utils = new Utils();
    this.service = new Service();
    this.toggleMenu = this.toggleMenu.bind(this);
    this.setSidebarLinks = this.setSidebarLinks.bind(this);
    this.goTo = this.goTo.bind(this);
    this.updateFramework = this.updateFramework.bind(this);
  }

  toggleMenu() {
    const { sideOpen } = this.state;
    this.setState({ sideOpen: !sideOpen });
  }

  goTo(page) {
    this.props.history.push(`/${page}`);
  }

  verifyLogin() {
    if (!this.utils.isLogged()) {
      this.goTo('');
    }
  }

  async updateFramework(framework) {
    if (framework) {
      await this.service.updateStatusFramework(framework.idFramework, {
        id: framework.id,
        status: framework.process_status,
      });

      await this.getAllFrameworks();

      this.toggleModalFramework();
    }
  }

  toggleModalFramework = () => {
    const { showModalFramework } = this.state;
    this.setState({
      showModalFramework: !showModalFramework,
    });
  };

  componentDidMount() {
    this.verifyLogin();

    this.setState({ isLoading: true }, () => {
      Promise.all([this.loadUser(), this.getAllFrameworks()]).finally(() => {
        this.setBreadcrumbs();
        this.setState({ isLoading: false });
      });
    });
  }

  componentWillUnmount() {}

  async getAllFrameworks() {
    const result = await this.service.getAllFrameworks();

    if (result && result.length > 0) {
      this.setState({
        allFrameworks: result,
      });
    }
  }

  editFramework(event) {
    this.setState(
      {
        objEditFramework: event,
      },
      () => this.toggleModalFramework()
    );
  }

  setBreadcrumbs = () => {
    this.setState({
      breadcrumbs: [
        { title: 'Dashboard', link: '' },
        {
          title: 'Status da Adequação',
          link: 'programs/adequacy-status',
        },
      ],
    });
  };

  setSidebarLinks(view) {
    this.setState({ view });
    this.goTo(`dashboard/${view}`);
  }

  async loadUser() {
    let result = await this.service.me();
    if (!result) {
      this.logout();
      return;
    }
    let user = {
      ...result.user,
    };
    if (user.image) {
      this.props.updateImage(user.image);
    }

    this.setState({ user });
  }

  render() {
    const {
      sideOpen,
      side,
      breadcrumbs,
      tableActions,
      titleRows,
      isLoading,
      allFrameworks,
      showModalFramework,
      objEditFramework,
    } = this.state;

    return (
      <div className="main-content">
        <Header
          goTo={this.goTo}
          sideOpen={sideOpen}
          toggleMenu={this.toggleMenu}
        />
        <div className="content-climb user bodyClimby">
          <div
            className={this.props.menu ? 'content-menu closed' : 'content-menu'}
          >
            <Sidebar
              side={this.state.side}
              setSidebarLinks={this.setSidebarLinks}
              expanded={!this.state.sideOpen}
            />
          </div>
          <div
            className={this.props.menu ? 'content-dash closed' : 'content-dash'}
          >
            <div className="dash-content">
              <div className="dash-crumb-filter">
                <Breadcrumbs breadcrumbs={breadcrumbs} />
              </div>
              {isLoading ? (
                <ReactLoading type="bubbles" color="#B1CD49" />
              ) : (
                <div className="dashboard-boxes">
                  <div className="dashboard-box box100 align-select-group">
                    <div style={{ display: 'flex' }}>
                      <span
                        className="box-title row-center"
                        style={{
                          alignItems: 'center',
                          width: '100%',
                        }}
                      >
                        Status da Adequação
                      </span>
                    </div>

                    {allFrameworks &&
                      allFrameworks.length > 0 &&
                      allFrameworks.map(el => (
                        <CardGenericTableWithGroup
                          key={el.framework}
                          noBox
                          id={`framework_${el._id}`}
                          title=""
                          actions={tableActions}
                          title_rows={titleRows}
                          groupBy={'groupFramework'}
                          rows={
                            el.table_info && el.table_info.length > 0
                              ? el.table_info.sort(sortArticles).map(info => ({
                                  idFramework: el._id,
                                  id: info._id,
                                  process_description: info.process_description,
                                  law_article: info.law_article,
                                  comments: info.comments,
                                  process_status: info.status,
                                  groupFramework: el.framework,
                                }))
                              : []
                          }
                        />
                      ))}
                  </div>
                </div>
              )}

              <Footer />
            </div>
          </div>
        </div>

        {showModalFramework && (
          <ModalFramework
            toggleModalFramework={this.toggleModalFramework}
            updateFramework={this.updateFramework}
            modal_framework={showModalFramework}
            objEditFramework={objEditFramework}
          />
        )}
      </div>
    );
  }
}
const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  sessionId: state.simpleReducer.sessionId,
  menu: state.simpleReducer.menu,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateImage, updateSession }, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AdequacyStatus)
);
