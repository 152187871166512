import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import '../../styles/css/style.css';
import '../../styles/css/dashboard.css';
import { updateImage, updateAlert } from '../../actions/SimpleAction';
import Sidebar from '../sidebar/Sidebar';
import CardGenericTable from './../components/CardGenericTable';
import Header from './../components/Header';
import PrincipalMenu from './../components/PrincipalMenu';
import Breadcrumbs from './../components/Breadcrumbs';
import Footer from './../components/Footer';
import Service from './Service.js';
import Utils from './../../libs/Utils.js';

class Contents extends Component {
  constructor(props, context) {
    super(props);

    const initialWidth = window.innerWidth > 0 ? window.innerWidth : 500;

    this.state = {
      isVisible: false,
      sideOpen: this.props.menu,
      principalOpen: true,
      view: 'contents',
      side: 'climb',
      breadcrumbs: [
        { title: 'Dashboard', link: '' },
        { title: 'Conteúdo', link: 'contents' },
        { title: 'Conteúdo Personalizado', link: 'contents/custom' },
      ],
      showToolTip: false,
      windowWidth: initialWidth - (window.innerWidth * 40) / 100,
      rows: [],
      title_rows: [
        { title: 'Título', key: 'title' },
        { title: 'Formato', key: 'doc_format' },
        { title: 'Categoria', key: 'category' },
        { title: 'Modelo', key: 'tag' },
        { title: 'Ação', key: 'options' },
      ],
      actions: [
        {
          title: 'Ver',
          svg: {
            icon: require('./../../assets/images/tables/eye.svg'),
            width: 22,
          },
          action: this.see.bind(this),
        },
        {
          title: 'Importar',
          svg: {
            icon: require('./../../assets/images/tables/share.svg'),
            width: 18,
          },
          action: this.saveToMe.bind(this),
        },
      ],
      sort1: '',
    };

    this.utils = new Utils();
    this.service = new Service();
    this.handleResize = this.handleResize.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.togglePrincipal = this.togglePrincipal.bind(this);
    this.setSidebarLinks = this.setSidebarLinks.bind(this);
    this.goTo = this.goTo.bind(this);
  }

  toggleMenu() {
    if (this.state.sideOpen) {
      this.setState({ sideOpen: false });
    } else {
      this.setState({ sideOpen: true });
    }
  }

  togglePrincipal() {
    if (this.state.principalOpen) {
      this.setState({ principalOpen: false });
    } else {
      this.setState({ principalOpen: true });
    }
  }

  goTo(page, state) {
    this.props.history.push({
      pathname: `/${page}`,
      state,
    });
  }

  verifyLogin() {
    if (!this.utils.isLogged()) {
      this.goTo('');
    }
  }

  componentDidMount() {
    this.verifyLogin();
    this.loadUser();
    this.loadContents();
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  setSidebarLinks(view, state) {
    this.setState({ view });
    this.goTo(`dashboard/${view}`, state);
  }

  handleResize() {
    let _ww =
      window.innerWidth < 890 && window.innerWidth > 570
        ? window.innerWidth - (window.innerWidth * 30) / 100
        : window.innerWidth <= 570
        ? window.innerWidth - (window.innerWidth * 40) / 100
        : window.innerWidth - (window.innerWidth * 40) / 100;

    this.setState({
      windowWidth: _ww,
    });
  }

  logout() {
    this.utils.setLogout();
    this.goTo('');
  }

  async loadUser() {
    let result = await this.service.me();
    if (!result) {
      this.logout();
      return;
    }
    let user = {
      ...result.user,
    };
    delete user.location;
    delete user.contact;
    if (result.user.contact && result.user.contact.length > 0) {
      user['phone'] = result.user.contact[0].phone;
      user['site'] = result.user.contact[0].site;
    }
    if (result.user.location && result.user.location.length > 0) {
      user['state'] = result.user.location[0].state;
      user['city'] = result.user.location[0].city;
    }
    if (user.image) {
      this.props.updateImage(user.image);
    }
  }

  async loadContents() {
    let result = await this.service.contentsClimb();
    if (result && result.success) {
      this.setState({ rows: result.contents });
    }
  }

  async removeContent(id) {
    let result = await this.service.removeContent(id);
    if (result && result.success) {
      this.loadCoachees();
    }
  }

  /*
  Alterado por: Natanael Diego
  Data: 13/04/2020
  Hora: 17:51
  Funcao: Alterado a forma de obter o pdf, antes passava o caminho do arquivo 
  que ja tinha sido salvo, agora e passado o html e toda vez gera um novo pdf em tela.
  */
  see(item) {
    if (!item.doc_format) {
      this.setSidebarLinks(`proposals/proposal/register/${item._id}`);
    } else {
      let url;
      if (item.doc_format.toLowerCase() === 'link') {
        url = item.doc_source;
      } else if (item.doc_format.toLowerCase() === 'wheel') {
        this.setSidebarLinks(`wheel-preview/${item._id}`, {
          climbyContent: true,
        });
        return;
      } else if (item.doc_format.toLowerCase() == 'form') {
        this.setSidebarLinks(`form-preview/${item._id}`, {
          climbyContent: true,
        });
        return;
      } else if (item.doc_format.toLowerCase() == 'rel') {
        this.setSidebarLinks(`programs/assessments/relatory/${item._id}`);
        return;
      } else {
        url = `${this.service.getServer()}generate/session/${item._id}`;
      }

      window.open(url);
    }
  }

  async saveToMe(item) {
    let result = await this.service.saveContentToMe({ content_id: item._id });
    if (result && result.success) {
      this.props.updateAlert('Salvo com sucesso');
    }
  }

  sort1(key) {
    let order =
      this.state.sort1 == '' || this.state.sort1 == key ? '-down' : '';

    let rows = this.state.rows.sort((a, b) => {
      if (a[key] > b[key]) {
        return order.indexOf('down') !== -1 ? 1 : -1;
      }
      if (a[key] < b[key]) {
        return order.indexOf('down') !== -1 ? -1 : 1;
      }
      return 0;
    });
    this.setState({ sort1: `${key}${order}`, rows });
  }

  render() {
    return (
      <div className="main-content">
        <Header
          goTo={this.goTo}
          sideOpen={this.state.sideOpen}
          toggleMenu={this.toggleMenu}
        />
        <div className="content-climb user bodyClimby">
          <div
            className={this.props.menu ? 'content-menu closed' : 'content-menu'}
          >
            <Sidebar
              side={this.state.side}
              setSidebarLinks={this.setSidebarLinks}
              expanded={!this.state.sideOpen}
            />
          </div>
          <div
            className={this.props.menu ? 'content-dash closed' : 'content-dash'}
          >
            <div className="dash-content">
              <div className="dash-crumb-filter">
                <Breadcrumbs breadcrumbs={this.state.breadcrumbs} />
              </div>

              <CardGenericTable
                id="content-climb usery-1"
                title="Conteúdo Personalizado"
                sort={this.sort1.bind(this)}
                _sort={this.state.sort1}
                actions={this.state.actions}
                title_rows={this.state.title_rows}
                rows={this.state.rows}
              />

              <Footer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  menu: state.simpleReducer.menu,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateImage, updateAlert }, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Contents)
);
