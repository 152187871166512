import React, { Component } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import ReactLoading from 'react-loading';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { bindActionCreators } from 'redux';
import { updateAlert, updateImage } from '../../../actions/SimpleAction';
import Utils from '../../../libs/Utils.js';
import '../../../styles/css/dashboard.css';
import '../../../styles/css/datePicker/style.scss';
import '../../../styles/css/style.css';
import Breadcrumbs from '../../components/Breadcrumbs';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Sidebar from '../../sidebar/Sidebar';
import UploadImage from '../Groups/UploadImage';
import Service from '../Service.js';
const Typeahead = require('react-typeahead').Typeahead;

class GroupCreate extends Component {
  constructor(props, context) {
    super(props);
    this._typeahead = React.createRef();

    const initialWidth = window.innerWidth > 0 ? window.innerWidth : 500;

    this.state = {
      isVisible: false,
      sideOpen: false,
      principalOpen: true,
      view: '',
      side: 'groups',
      breadcrumbs: [
        { title: 'Dashboard', link: '' },
        { title: 'Áreas', link: 'groups' },
        { title: 'Cadastrar', link: 'groups/create' },
      ],
      showToolTip: false,
      windowWidth: initialWidth - (window.innerWidth * 40) / 100,
      loading_coachees: false,
      loading: false,
      coachees: [],

      name: '',
      description: '',
      selectedCoachees: [],
      image: '',
      online: false,
      presential: true,

      maxLength: 3660,
      editing: false,
      editing_id: '',
      openPop: null,
      openPopInsert: false,
      coacheeSelected: '',
    };

    this.utils = new Utils();
    this.service = new Service();
    this.handleResize = this.handleResize.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.togglePrincipal = this.togglePrincipal.bind(this);
    this.setSidebarLinks = this.setSidebarLinks.bind(this);
    this.goTo = this.goTo.bind(this);
    this.save = this.save.bind(this);
    this.clearForm = this.clearForm.bind(this);
  }

  toggleMenu() {
    if (this.state.sideOpen) {
      this.setState({ sideOpen: false });
    } else {
      this.setState({ sideOpen: true });
    }
  }

  togglePrincipal() {
    if (this.state.principalOpen) {
      this.setState({ principalOpen: false });
    } else {
      this.setState({ principalOpen: true });
    }
  }

  goTo(page) {
    this.props.history.push(`/${page}`);
  }

  verifyLogin() {
    if (!this.utils.isLogged()) {
      this.goTo('');
    }
  }

  componentDidMount() {
    this.verifyLogin();
    this.loadUser();
    this.loadCoachees();
    this.resolveParams();
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  resolveParams() {
    const {
      match: { params },
    } = this.props;
    if (params.id) {
      const editing_id = params.id;
      this.setState({
        editing: true,
        editing_id,
      });
      this.load(editing_id);
    }
  }

  async load(_id) {
    this.setState({ loading: true });
    const result = await this.service.group(_id);
    if (result && result.success) {
      const { group } = result;
      this.setState({
        name: group.name,
        description: group.description,
        selectedCoachees: group.coachees,
        image: group.image,
        online: group.online,
        presential: group.presential,
      });
    }
    this.setState({ loading: false });
  }

  loadCoachees = async () => {
    this.setState({ loading_coachees: true });
    const result = await this.service.coachees();
    if (result && result.success) {
      this.setState({ coachees: result.coachees });
    }
    this.setState({ loading_coachees: false });
  };

  setSidebarLinks(view) {
    this.setState({ view });
    this.goTo(`dashboard/${view}`);
  }

  handleResize() {
    let _ww =
      window.innerWidth < 890 && window.innerWidth > 570
        ? window.innerWidth - (window.innerWidth * 30) / 100
        : window.innerWidth <= 570
        ? window.innerWidth - (window.innerWidth * 40) / 100
        : window.innerWidth - (window.innerWidth * 40) / 100;
    this.setState({
      windowWidth: _ww,
    });
  }

  logout() {
    this.utils.setLogout();
    this.goTo('');
  }

  async loadUser() {
    const result = await this.service.me();
    if (!result) {
      this.logout();
      return;
    }
    const user = result.user;
    if (user.image) {
      this.props.updateImage(user.image);
    }
    this.setState({ user });
  }

  async save() {
    let _pay = {
      name: this.state.name,
      description: this.state.description,
      image: this.state.image,
      online: this.state.online,
      presential: this.state.presential,
      coachees: JSON.stringify(this.state.selectedCoachees),
    };
    if (!_pay.name) {
      this.props.updateAlert('O nome da área é obrigatório');
      return;
    }
    if (!_pay.online && !_pay.presential) {
      this.props.updateAlert('Informe um tipo de atendimento');
      return;
    }
    if (this.state.selectedCoachees.length === 0) {
      this.props.updateAlert('Informe pelo menos um membro para gravar.');
      return;
    }

    this.setState({ loading: true });
    let result = !this.state.editing
      ? await this.service.saveGroup(_pay)
      : await this.service.updateGroup(_pay, this.state.editing_id);
    if (result && result.success) {
      if (!this.state.editing) {
        this.setState({
          editing: true,
          editing_id: result.inserted_id,
        });
      }
      this.goTo('dashboard/groups');
    }
    if (result && !result.success) {
      if (result.name_unavailable) {
        this.props.updateAlert(
          'Área já cadastrada com o mesmo nome. Por favor, informe outro.'
        );
      } else {
        this.props.updateAlert(
          `Ocorreu um erro ao ${
            this.state.editing ? 'salvar' : 'cadastrar'
          } a área.`
        );
      }
    }
    this.setState({ loading: false });
  }

  removeSelectedCoachee = coacheeId => {
    const selectedCoachees = this.state.selectedCoachees.filter(
      item => item._id !== coacheeId
    );
    this.setState({ selectedCoachees });
  };

  handleSelectedCoachees = () => {
    const { selectedCoachees, coacheeSelected } = this.state;
    if (
      coacheeSelected &&
      !selectedCoachees.find(item => item._id === coacheeSelected._id)
    ) {
      selectedCoachees.push(coacheeSelected);
      this.setState({ selectedCoachees, coacheeSelected: '' });
    }
    if (this._typeahead && this._typeahead.current)
      this._typeahead.current.setEntryText('');
  };

  handleCoacheeSelected = coachee => {
    this.setState({ coacheeSelected: coachee });
  };

  handleImage = image => this.setState({ image });

  getSearchCoachees = coachees => `${coachees.name} - ${coachees.email}`;

  clearForm() {
    this.setState({
      active_status: false,
      name: '',
      email: '',
      phone: '',
      needs_description: '',
      coachee_id: '',
    });
  }

  handleOpenPop(openPop) {
    this.setState({ isOpenPop: openPop });
  }

  render() {
    return (
      <div className="datepicker-style main-content">
        <Header
          goTo={this.goTo}
          sideOpen={this.state.sideOpen}
          toggleMenu={this.toggleMenu}
        />
        <div className="content-climb user bodyClimby">
          <div
            className={this.props.menu ? 'content-menu closed' : 'content-menu'}
          >
            <Sidebar
              goTo={this.goTo}
              side={this.state.side}
              setSidebarLinks={this.setSidebarLinks}
            />
          </div>
          <div
            className={this.props.menu ? 'content-dash closed' : 'content-dash'}
          >
            {/* <PrincipalMenu
              principalOpen={this.state.principalOpen}
              view={this.state.view}
              setSidebarLinks={this.setSidebarLinks}
              togglePrincipal={this.togglePrincipal}
            /> */}

            <div className="dash-content">
              <div className="dash-crumb-filter">
                <Breadcrumbs breadcrumbs={this.state.breadcrumbs} />
              </div>
              <div className="dashboard-boxes">
                <div className="dashboard-box box100">
                  <Col xs={{ size: 12 }} md={{ size: 6, offset: 3 }}>
                    <div className="box-row-title row-center">
                      <div className="box-title">
                        {this.state.editing ? 'Editar ' : 'Cadastrar '} Área
                      </div>
                    </div>
                    <div className="form-input">
                      <label className="blue-label">Nome</label>
                      <input
                        type="text"
                        value={this.state.name}
                        className="blue-input"
                        placeholder="Nome"
                        onChange={e => {
                          this.setState({ name: e.target.value });
                        }}
                      />
                    </div>
                    <div
                      className="form-textarea"
                      style={{ maxWidth: 'none', paddingLeft: '0px' }}
                    >
                      <label className="blue-label">Descrição</label>
                      <textarea
                        value={this.state.description}
                        className="blue-input"
                        placeholder="Descrição"
                        onChange={e =>
                          this.setState({ description: e.target.value })
                        }
                      />
                      <span>
                        {this.state.description.length}/{this.state.maxLength}
                      </span>
                    </div>
                    <div>
                      <div className="row-center form-title">
                        Imagem da área
                      </div>
                      <UploadImage
                        image={this.state.image}
                        idGroup={this.state.editing_id}
                        handleImage={this.handleImage}
                        upref="photo"
                        zIndex={7}
                      />
                    </div>
                    {/* <div className="row-center">
                      <div>
                        <div className="form-title mt-20px text-center">
                          Tipo de Atendimento
                        </div>
                        <div
                          className={
                            !this.state.presential
                              ? 'option-type'
                              : 'option-type active'
                          }
                          onClick={() =>
                            this.setState({
                              presential: !this.state.presential,
                            })
                          }
                        >
                          <img
                            src={require('./../../../assets/images/menu/personal/icon-5.svg')}
                            width="22"
                            alt="Presencial"
                          />
                          <img
                            src={require('./../../../assets/images/menu/personal/icon-5b.svg')}
                            width="22"
                            alt="Presencial"
                          />
                          Presenciais
                        </div>
                        <div
                          className={
                            !this.state.online
                              ? 'option-type'
                              : 'option-type active'
                          }
                          onClick={() =>
                            this.setState({ online: !this.state.online })
                          }
                        >
                          <img
                            src={require('./../../../assets/images/menu/personal/icon-6.svg')}
                            width="55"
                            alt="Online"
                          />
                          <img
                            src={require('./../../../assets/images/menu/personal/icon-6b.svg')}
                            width="55"
                            alt="Online"
                          />
                          Online
                        </div>
                      </div>
                    </div> */}
                    <div className="form-input">
                      <label className="blue-label">Membro</label>
                      <div className="search-content" style={{ width: '100%' }}>
                        <i className="fas fa-search message-search-icon"></i>
                        <Typeahead
                          ref={this._typeahead}
                          maxVisible={10}
                          filterOption="name"
                          defaultValue=""
                          displayOption={this.getSearchCoachees}
                          placeholder="Membro"
                          options={this.state.coachees}
                          onOptionSelected={this.handleCoacheeSelected}
                          customClasses={{
                            input: 'messages-search',
                            results: 'list-messages listahead',
                          }}
                        />
                        <div className="button-save">
                          {this.state.openPopInsert && (
                            <div className="insert-hint">Inserir</div>
                          )}

                          <img
                            onMouseOver={() =>
                              this.setState({ openPopInsert: true })
                            }
                            onMouseOut={() =>
                              this.setState({ openPopInsert: false })
                            }
                            onClick={this.handleSelectedCoachees}
                            className="table-icon disable svg-image"
                            src={require('./../../../assets/images/tables/add_coachee.svg')}
                            style={{ width: 20 }}
                            alt="Inserir"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="selected-coachees-div">
                      {this.state.selectedCoachees.length ? (
                        this.state.selectedCoachees.map(item => (
                          <Row
                            key={item._id}
                            className="selected-coachees-rows"
                          >
                            <div className="list-group-name">
                              <span>{item.name}</span>
                            </div>
                            <div className="list-group-email">
                              <span>{item.email}</span>
                            </div>

                            <div>
                              {this.state.openPop === item._id && (
                                <div className="delete-hint">Deletar</div>
                              )}
                              <img
                                onMouseOver={() =>
                                  this.setState({ openPop: item._id })
                                }
                                onMouseOut={() =>
                                  this.setState({ openPop: null })
                                }
                                onClick={() =>
                                  this.removeSelectedCoachee(item._id)
                                }
                                className="table-icon svg-image"
                                src={require('./../../../assets/images/tables/trash.svg')}
                                style={{ width: 10 }}
                                alt="Excluir"
                              />
                            </div>
                          </Row>
                        ))
                      ) : (
                        <div className="selected-coachees-empty">
                          <i
                            className="fas fa-exclamation-triangle"
                            style={{ marginRight: 5 }}
                          ></i>
                          Nenhum membro selecionado
                        </div>
                      )}
                    </div>
                    {this.state.loading ? (
                      <div className="display-flex-center">
                        <ReactLoading type="bubbles" color="#00838F" />
                      </div>
                    ) : (
                      <div
                        className="row-between mtop-50 mbottom-50"
                        style={{ justifyContent: 'center' }}
                      >
                        <div
                          className="bt-blue-outline"
                          onClick={() => {
                            this.setSidebarLinks('groups');
                          }}
                        >
                          Cancelar
                        </div>
                        <div
                          className="bt-blue"
                          onClick={() => {
                            this.save();
                          }}
                        >
                          {this.state.editing ? 'Salvar' : 'Cadastrar'}
                        </div>

                        <div className="icon-information-container">
                          <div
                            style={{ cursor: 'pointer' }}
                            onClick={() => this.handleOpenPop(true)}
                            onMouseOver={() => this.handleOpenPop(true)}
                            onMouseLeave={() => this.handleOpenPop(false)}
                          >
                            <div
                              className="popover-container-information"
                              style={{
                                display: this.state.isOpenPop
                                  ? 'block'
                                  : 'none',
                              }}
                            >
                              <div className="popover-content-information-grupo">
                                Permitido até 20 membros por área.
                              </div>
                            </div>
                            <i className="fas fa-info-circle table-icon padding-grupo"></i>
                          </div>
                        </div>
                      </div>
                    )}
                  </Col>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  menu: state.simpleReducer.menu,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateImage, updateAlert }, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GroupCreate)
);
