import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import '../../../styles/css/style.css';
import '../../../styles/css/profile.css';
import ReactLoading from 'react-loading';
import { Col, Row } from 'reactstrap';
import Service from './Service.js';
import {
  updateImage,
  updateUser,
  updateAlert,
} from '../../../actions/SimpleAction';
import Sidebar from '../../sidebar/Sidebar';
import Header from './../../components/Header';
import Breadcrumbs from './../../components/Breadcrumbs';
import Footer from './../../components/Footer';
import IForm from './../Create/Form';
import Utils from './../../../libs/Utils.js';
import UploadImage from './UploadImage';
const Typeahead = require('react-typeahead').Typeahead;

class Form extends Component {
  constructor(props, context) {
    super(props);
    this._typeahead = React.createRef();

    const initialWidth = window.innerWidth > 0 ? window.innerWidth : 500;

    this.state = {
      isVisible: false,
      sideOpen: false,
      principalOpen: true,
      loading: false,
      view: 'project',
      breadcrumbs: [
        { title: 'Dashboard', link: '' },
        { title: 'Projetos', link: 'projects' },
        { title: 'Cadastrar Projeto', link: 'projects/form' },
      ],
      showToolTip: false,
      windowWidth: initialWidth - (window.innerWidth * 40) / 100,
      content: {},
      editing: false,
      editing_id: '',
      items: [
        {
          label: 'Nome',
          key: 'name',
          type: 'text',
        },
        {
          label: 'Ativado',
          offlabel: 'Desativado',
          key: 'active',
          type: 'checkbox',
        },
      ],

      loading_users: false,
      loading: false,
      users: [],
      selectedUsers: [],
      openPop: null,
      openPopInsert: false,
      userSelected: '',
    };

    this.utils = new Utils();
    this.service = new Service();
    this.handleResize = this.handleResize.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.togglePrincipal = this.togglePrincipal.bind(this);
    this.setSidebarLinks = this.setSidebarLinks.bind(this);
    this.goTo = this.goTo.bind(this);
    this.logout = this.logout.bind(this);
  }

  toggleMenu() {
    if (this.state.sideOpen) {
      this.setState({ sideOpen: false });
    } else {
      this.setState({ sideOpen: true });
    }
  }

  togglePrincipal() {
    if (this.state.principalOpen) {
      this.setState({ principalOpen: false });
    } else {
      this.setState({ principalOpen: true });
    }
  }

  goTo(page) {
    this.props.history.push(`/${page}`);
  }

  verifyLogin() {
    if (!this.utils.isLogged()) {
      this.goTo('');
    }
  }

  resolveParams() {
    const {
      match: { params },
    } = this.props;
    if (params.id) {
      let editing_id = params.id;
      let breadcrumbs = this.state.breadcrumbs;
      breadcrumbs.pop();
      breadcrumbs.push({
        title: 'Atualizar Projeto',
        link: `${this.state.view}s/form/${editing_id}`,
      });
      this.setState({
        editing: true,
        editing_id,
      });
      this.load(editing_id);
    }
  }

  logout() {
    this.utils.setLogout();
    this.props.updateImage('');
    this.goTo('admin');
  }

  componentDidMount() {
    this.verifyLogin();
    let user = this.utils.getUser();
    this.setState({ user });
    this.loadUser();
    this.loadUsers().then(() => {
      this.resolveParams();
    });
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  setSidebarLinks(view) {
    this.setState({ view });
    this.goTo(`admin/dashboard/${view}`);
  }

  handleResize() {
    let _ww =
      window.innerWidth < 890 && window.innerWidth > 570
        ? window.innerWidth - (window.innerWidth * 30) / 100
        : window.innerWidth <= 570
        ? window.innerWidth - (window.innerWidth * 40) / 100
        : window.innerWidth - (window.innerWidth * 40) / 100;

    this.setState({
      windowWidth: _ww,
    });
  }

  async loadUser() {
    this.setState({ loading: true });
    let result = await this.service.meAdmin();
    if (!result) {
      this.logout();
      return;
    }
    this.props.updateUser(result.user);
    let user = {
      ...result.user,
    };
    this.setState({ loading: false, user });
    if (user.image) {
      this.props.updateImage(user.image);
    }
  }

  async load(_id) {
    this.setState({ loading_contents: true });
    let result = await this.service.readOne(_id);
    if (result && result.success) {
      this.setState({
        content: result[this.state.view],
        selectedUsers: result[this.state.view].users?.map(userId =>
          this.state.users.find(user => user._id === userId)
        ),
      });
    }
    this.setState({ loading_contents: false });
  }

  loadUsers = async () => {
    this.setState({ loading_users: true });
    const result = await this.service.users();
    if (result && result.success) {
      this.setState({ users: result.coachs });
    }
    this.setState({ loading_users: false });
  };

  async save() {
    this.setState({ loading: true });

    try {
      let result = {};
      if (this.state.selectedUsers.length === 0) {
        this.props.updateAlert('Informe pelo menos um usuário para gravar.');
        return;
      }
      let _pay = this.state.content;
      _pay.users = this.state.selectedUsers.map(item => item._id);
      result = this.state.editing
        ? await this.service.update(_pay, this.state.editing_id)
        : await this.service.create(_pay);

      if (result && result.success) {
        this.setSidebarLinks(this.state.breadcrumbs[1].link);
      }
    } finally {
      this.setState({ loading: false });
    }
  }

  removeSelectedUser = userId => {
    const selectedUsers = this.state.selectedUsers.filter(
      item => item._id !== userId
    );
    this.setState({ selectedUsers });
  };

  handleSelectedUsers = () => {
    const { selectedUsers, userSelected } = this.state;
    if (
      userSelected &&
      !selectedUsers.find(item => item._id === userSelected._id)
    ) {
      selectedUsers.push(userSelected);
      this.setState({ selectedUsers, userSelected: '' });
    }
    if (this._typeahead && this._typeahead.current)
      this._typeahead.current.setEntryText('');
  };

  handleUserSelected = user => {
    this.setState({ userSelected: user });
  };

  getSearchUsers = user => `${user.name} - ${user.email}`;

  handleImage = image =>
    this.setState({ content: { ...this.state.content, image } });

  render() {
    return (
      <div className="main-content">
        <Header
          goTo={this.goTo}
          sideOpen={this.state.sideOpen}
          toggleMenu={this.toggleMenu}
        />
        <div className="content-climb user bodyClimby">
          <div
            className={this.props.menu ? 'content-menu closed' : 'content-menu'}
          >
            <Sidebar
              goTo={this.goTo}
              side={this.state.view}
              logout={this.logout}
              setSidebarLinks={this.setSidebarLinks}
            />
          </div>
          <div
            className={this.props.menu ? 'content-dash closed' : 'content-dash'}
          >
            <div className="dash-content">
              <div className="dash-crumb-filter">
                <Breadcrumbs breadcrumbs={this.state.breadcrumbs} />
              </div>

              <div className="dashboard-boxes">
                <div className="dashboard-box box100">
                  <Col md={{ size: 12 }} lg={{ size: 4, offset: 4 }}>
                    <div className="box-row-title">
                      <div
                        className="box-title"
                        style={{ textAlign: 'center', width: '100%' }}
                      >
                        {this.state.breadcrumbs[2].title}
                      </div>
                    </div>

                    <IForm
                      state={this.state}
                      setState={this.setState.bind(this)}
                      content={this.state.content}
                      items={this.state.items}
                    />

                    <div>
                      <div className="row-center form-title">
                        Imagem do projeto
                      </div>
                      <UploadImage
                        image={this.state.content.image}
                        projectId={this.state.editing_id}
                        handleImage={this.handleImage}
                        upref="photo"
                        zIndex={7}
                      />
                    </div>

                    <div className="form-input">
                      <label className="blue-label">Usuário</label>
                      <div className="search-content" style={{ width: '100%' }}>
                        <i className="fas fa-search message-search-icon"></i>
                        <Typeahead
                          ref={this._typeahead}
                          maxVisible={10}
                          filterOption="name"
                          defaultValue=""
                          displayOption={this.getSearchUsers}
                          placeholder="Usuário"
                          options={this.state.users}
                          onOptionSelected={this.handleUserSelected}
                          customClasses={{
                            input: 'messages-search',
                            results: 'list-messages listahead',
                          }}
                        />
                        <div className="button-save">
                          {this.state.openPopInsert && (
                            <div className="insert-hint">Inserir</div>
                          )}

                          <img
                            onMouseOver={() =>
                              this.setState({ openPopInsert: true })
                            }
                            onMouseOut={() =>
                              this.setState({ openPopInsert: false })
                            }
                            onClick={this.handleSelectedUsers}
                            className="table-icon disable svg-image"
                            src={require('./../../../assets/images/tables/add_coachee.svg')}
                            style={{ width: 20 }}
                            alt="Inserir"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="selected-coachees-div">
                      {this.state.selectedUsers.length ? (
                        this.state.selectedUsers.map(item => (
                          <Row
                            key={item._id}
                            className="selected-coachees-rows"
                          >
                            <div className="list-group-name">
                              <span>{item.name}</span>
                            </div>
                            <div className="list-group-email">
                              <span>{item.email}</span>
                            </div>

                            <div>
                              {this.state.openPop === item._id && (
                                <div className="delete-hint">Deletar</div>
                              )}
                              <img
                                onMouseOver={() =>
                                  this.setState({ openPop: item._id })
                                }
                                onMouseOut={() =>
                                  this.setState({ openPop: null })
                                }
                                onClick={() =>
                                  this.removeSelectedUser(item._id)
                                }
                                className="table-icon svg-image"
                                src={require('./../../../assets/images/tables/trash.svg')}
                                style={{ width: 10 }}
                                alt="Excluir"
                              />
                            </div>
                          </Row>
                        ))
                      ) : (
                        <div className="selected-coachees-empty">
                          <i
                            className="fas fa-exclamation-triangle"
                            style={{ marginRight: 5 }}
                          ></i>
                          Nenhum usuário selecionado
                        </div>
                      )}
                    </div>

                    <div className="row-between mtop-50 mbottom-50">
                      <div
                        className="bt-blue-outline"
                        onClick={() => {
                          this.setSidebarLinks(this.state.breadcrumbs[1].link);
                        }}
                      >
                        Cancelar
                      </div>
                      {this.state.loading ? (
                        <ReactLoading type="bubbles" color="#00838F" />
                      ) : (
                        <div
                          className="bt-blue"
                          onClick={() => {
                            this.save();
                          }}
                        >
                          Salvar
                        </div>
                      )}
                    </div>
                  </Col>
                </div>
              </div>

              <Footer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  menu: state.simpleReducer.menu,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateImage, updateUser, updateAlert }, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Form));
