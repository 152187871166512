import React from 'react';
import ReactLoading from "react-loading";
import logo from '../../assets/images/logo-facebook.jpg';
import '../../styles/css/style.css';
import '../../styles/css/dashboard.css';
 

const ModalEventAcao = (props) => {
  
  return (
    <div>
      {
        props.open ?
        <div className="modal-alert" style={{ zIndex:1000 }}>
          <div className="modal-alert-box">
            <i className="fas fa-times icon-close" onClick={() => { props.close() }}></i>
            <div className="row-modal-alert">
              <img src={ logo } width={70} /> 
              <div>
                <div className="modal-alert-text">
                  <div>{ props.title }</div>
                  <div style={{ display:'flex', flexDirection:"row" }}>
                    
                    {
                      props.loadingModal  ?
                        <div className="row-loading">
                          <ReactLoading type="bubbles" color="#00838F" />
                        </div>
                    : 
                      <>
                        <div className="bt-blue" style={{backgroundColor:"white", color:"#00838F", margin:'20px auto', borderRadius:30 }} onClick={ () => { props.acaoModalNegativo() } }>
                          <img src={ require('./../../assets/images/tables/follow.svg') } style={{ marginRight:10 }} /> 
                          {
                            'Não'
                          } 
                        </div>
                        <div className="bt-blue" style={{backgroundColor:"white", color:"#00838F", margin:'20px 10px', borderRadius:30 }} onClick={ () => { props.acaoModalPositivo() } }>
                          <img src={ require('./../../assets/images/tables/trash.svg') } style={{ marginRight:10 }} /> 
                          Sim
                        </div>
                      </>
                    }

                    {/* <div className="bt-blue" style={{backgroundColor:"white", color:"#00838F", margin:'20px auto', borderRadius:30 }} onClick={ () => { props.acaoModalNegativo() } }>
                      <img src={ require('./../../assets/images/tables/follow.svg') } style={{ marginRight:10 }} /> 
                      {
                        'Não'
                      } 
                    </div>
                    {
                      <div className="bt-blue" style={{backgroundColor:"white", color:"#00838F", margin:'20px 10px', borderRadius:30 }} onClick={ () => { props.acaoModalPositivo() } }>
                        <img src={ require('./../../assets/images/tables/trash.svg') } style={{ marginRight:10 }} /> 
                        Sim
                      </div>
                    }  */}
                  </div>
                </div>
              </div> 
            </div>
          </div>
        </div>
        : null
      }
    </div>
  );
}

export default ModalEventAcao;