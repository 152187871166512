import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import ReactLoading from 'react-loading';
import '../../styles/css/style.css';
import '../../styles/css/dashboard.css';
import videoRoomTypes, {
  roles as videoRoomRoles,
} from '../../consts/videoRoomTypes';
import userTypes from '../../consts/userTypes';
import { Col } from 'reactstrap';
import {
  updateImage,
  updateVideoSession,
  updateSession,
} from '../../actions/SimpleAction';
import Sidebar from '../sidebar/Sidebar';
import Header from './../components/Header';
import Breadcrumbs from './../components/Breadcrumbs';
import Footer from './../components/Footer';
import InputMask from 'react-input-mask';
import Submenu from './Session/SubmenuSession';
import Service from './Service.js';
import Utils from './../../libs/Utils.js';
import ModalAlertCustom from './../components/ModalAlertCustom';
import RichText from './../../components/RichText/';

const sessionTypes = {
  INDIVIDUAL: 'individual',
  GROUP: 'group',
};

class Programs extends Component {
  constructor(props, context) {
    super(props);

    const initialWidth = window.innerWidth > 0 ? window.innerWidth : 500;

    this.state = {
      isVisible: false,
      sideOpen: false,
      principalOpen: true,
      view: 'programs',
      subview: 'register',
      side: 'painel',
      breadcrumbs: [
        { title: 'Dashboard', link: '' },
        { title: 'Programas', link: 'programs' },
      ],
      showToolTip: false,
      windowWidth: initialWidth - (window.innerWidth * 40) / 100,
      user: {},
      sessions: [],
      session: {
        id: null,
        its_group: false,
        coachee_objectives: '',
        success_evidence: '',
        motivators: '',
        saboteurs: '',
        values: '',
        strategies: '',
        necessary_resources: '',
        coachee_objectives_active: false,
        success_evidence_active: false,
        motivators_active: false,
        saboteurs_active: false,
        values_active: false,
        strategies_active: false,
        necessary_resources_active: false,
      },
      activeMeeting: 'master',
      meetings: {},
      maxLength: 3660,
      isLoading: false,
      isLoadingSession: false,
      isLoadingSessions: false,
      hasNoMeetings: false,
      alert: '',
    };

    this.utils = new Utils();
    this.service = new Service();
    this.handleResize = this.handleResize.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.togglePrincipal = this.togglePrincipal.bind(this);
    this.setSidebarLinks = this.setSidebarLinks.bind(this);
    this.goTo = this.goTo.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  toggleMenu() {
    const { sideOpen } = this.state;
    this.setState({ sideOpen: !sideOpen });
  }

  togglePrincipal() {
    const { principalOpen } = this.state;
    this.setState({ principalOpen: !principalOpen });
  }

  goTo(page) {
    this.props.history.push(`/${page}`);
  }

  verifyLogin() {
    if (!this.utils.isLogged()) {
      this.goTo('');
    }
  }

  componentDidMount() {
    this.verifyLogin();

    const {
      sessionId,
      match: { params },
    } = this.props;

    this.setState({ isLoading: true }, () => {
      Promise.all([
        this.loadUser(),
        this.loadSessions().then(() => {
          const { sessions } = this.state;
          const individualSession = sessions.find(
            session => session.type === sessionTypes.INDIVIDUAL
          );

          this.loadSession(
            params.sessionId ||
              sessionId ||
              (individualSession && individualSession.id)
          );
        }),
      ]).finally(() => {
        this.setState({ isLoading: false });
      });
    });
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.match.params.sessionId !== this.props.match.params.sessionId
    ) {
      this.loadSession(this.props.match.params.sessionId);
    }
  }

  setSidebarLinks(view) {
    this.setState({ view });
    this.goTo(`users/dashboard/${view}`);
  }

  handleResize() {
    let _ww =
      window.innerWidth < 890 && window.innerWidth > 570
        ? window.innerWidth - (window.innerWidth * 30) / 100
        : window.innerWidth <= 570
        ? window.innerWidth - (window.innerWidth * 40) / 100
        : window.innerWidth - (window.innerWidth * 40) / 100;

    this.setState({
      windowWidth: _ww,
    });
  }

  logout() {
    this.utils.setLogout();
    this.goTo('');
  }

  async loadUser() {
    let result = await this.service.meCoachee();
    if (!result) {
      this.logout();
      return;
    }

    let user = {
      ...result.user,
    };
    if (user.image) {
      this.props.updateImage(user.image);
    }
    this.setState({ user });
  }

  mapRequestSessionToState = result => {
    const { session } = result;
    const {
      id,
      its_group,
      coachee_objectives,
      success_evidence,
      motivators,
      saboteurs,
      values,
      strategies,
      necessary_resources,
      coachee_objectives_active,
      success_evidence_active,
      motivators_active,
      saboteurs_active,
      values_active,
      strategies_active,
      necessary_resources_active,
      meetings,
    } = session;

    this.setState({
      session: {
        id,
        its_group,
        coachee_objectives,
        success_evidence,
        motivators,
        saboteurs,
        values,
        strategies,
        necessary_resources,
        coachee_objectives_active,
        success_evidence_active,
        motivators_active,
        saboteurs_active,
        values_active,
        strategies_active,
        necessary_resources_active,
      },
      meetings,
      activeMeeting: Object.values(meetings).length,
    });

    this.props.updateSession(id);
  };

  async loadSession(sessionId) {
    if (!sessionId) return;
    this.setState({ isLoadingSession: true });
    let result = await this.service.sessionS(sessionId);
    if (result && result.success) {
      this.mapRequestSessionToState(result);
    }
    this.setState({ isLoadingSession: false });
  }

  async loadSessions() {
    this.setState({ isLoadingSessions: true });
    let result = await this.service.sessions();
    if (result && result.success) {
      this.setState({ sessions: result.sessions });
      if (result.sessions && result.sessions.length === 0) {
        this.setState({ hasNoMeetings: true, alert: this.handleHtmlAlert() });
      }
    }
    this.setState({ isLoadingSessions: false });
  }

  changeMeeting(meetingNumber) {
    this.setState({ activeMeeting: meetingNumber });
  }

  getActiveMeetingValue = (fieldname, isDate = false) => {
    const { meetings, activeMeeting } = this.state;
    const value = meetings[activeMeeting][fieldname];
    if (isDate) {
      if (!value) return '';
      return this.utils.parseDateUSA(value);
    }

    return value;
  };

  setActiveMeetingValue = payload => {
    const { meetings, activeMeeting } = this.state;

    this.setState({
      meetings: {
        ...meetings,
        [activeMeeting]: {
          ...meetings[activeMeeting],
          ...payload,
        },
      },
    });
  };

  changeSession = e => {
    this.loadSession(e.target.value);
  };

  sortMeetings = (a, b) => {
    if (a.number === 'master') return -1;
    if (b.number === 'master') return 1;
    return a.number - b.number;
  };

  getSessionTitle = meetingNumber => {
    if (meetingNumber === 'master') return 'Reunião Inaugural';
    return `Sessão ${meetingNumber}`;
  };

  handleHtmlAlert() {
    return (
      <div>
        <p>
          Ainda não existem sessões disponíveis no programa selecionado. Aguarde
          até que seu coach compartilhe as sessões.
        </p>
        <br />

        <div className="btn-footer-modal">
          <div
            className="bt-blue"
            style={{ margin: '0 5px' }}
            onClick={this.handleCloseModal}
          >
            OK
          </div>
        </div>
      </div>
    );
  }

  handleNoMeeting() {
    return (
      <ModalAlertCustom
        showCloseIcon={true}
        alert={this.state.alert}
        close={this.handleCloseModal}
      />
    );
  }

  handleCloseModal() {
    this.goTo(`users/dashboard/`);
    this.setState({ alert: '', hasNoMeetings: false });
  }

  render() {
    const {
      sideOpen,
      side,
      principalOpen,
      view,
      breadcrumbs,
      subview,
      activeMeeting,
      meetings,
      session,
      sessions,
      maxLength,
      isLoadingSession,
      isLoadingSessions,
      isLoading,
      hasNoMeetings,
    } = this.state;
    return (
      <div className="main-content">
        <Header
          style={hasNoMeetings ? { zIndex: 0 } : {}}
          goTo={this.goTo}
          sideOpen={sideOpen}
          toggleMenu={this.toggleMenu}
        />
        <div className="content-climb user bodyClimby">
          <div
            style={hasNoMeetings ? { zIndex: 0 } : {}}
            className={this.props.menu ? 'content-menu closed' : 'content-menu'}
          >
            <Sidebar
              side={this.state.side}
              setSidebarLinks={this.setSidebarLinks}
              expanded={!this.state.sideOpen}
            />
          </div>
          <div
            className={this.props.menu ? 'content-dash closed' : 'content-dash'}
          >
            <div className="dash-content">
              <div className="dash-crumb-filter">
                <Breadcrumbs breadcrumbs={breadcrumbs} />
              </div>
              <div className="dashboard-boxes">
                {isLoading ? (
                  <ReactLoading type="bubbles" color="#B1CD49" />
                ) : (
                  <div className="dashboard-box box100-menu">
                    <div className="box-row-title row-center">
                      <div className="box-title">Registro de Sessões</div>
                    </div>
                    {isLoadingSessions ? (
                      <ReactLoading type="bubbles" color="#B1CD49" />
                    ) : sessions.length > 0 ? (
                      <Col xs={{ size: 12 }} md={{ size: 6, offset: 3 }}>
                        <div className="form-input">
                          <label className="blue-label">Programas</label>
                          <select
                            value={session.id}
                            className="blue-input"
                            onChange={e => {
                              this.changeSession(e);
                            }}
                          >
                            <option value=""> &nbsp; Programa</option>
                            {sessions.map((session, key) => {
                              return (
                                <option key={key} value={session.id}>
                                  {' '}
                                  &nbsp; {session.group}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </Col>
                    ) : (
                      this.handleNoMeeting()
                    )}
                    {isLoadingSession ? (
                      <ReactLoading type="bubbles" color="#B1CD49" />
                    ) : (
                      session.id && (
                        <>
                          <div>
                            <ul
                              className="list-sessions"
                              style={{ clear: 'both' }}
                            >
                              {Object.values(meetings)
                                .sort(this.sortMeetings)
                                .map((meeting, index) => {
                                  return (
                                    <span
                                      key={String(meeting.number)}
                                      className={
                                        String(meeting.number) ===
                                        String(activeMeeting)
                                          ? 'active'
                                          : ''
                                      }
                                    >
                                      {index !== 0 && (
                                        <li className="line-sessions"></li>
                                      )}
                                      <li
                                        onClick={() => {
                                          this.changeMeeting(meeting.number);
                                        }}
                                      >
                                        {this.getSessionTitle(meeting.number)}
                                      </li>
                                    </span>
                                  );
                                })}
                            </ul>
                          </div>

                          <Col xs={{ size: 12 }} md={{ size: 6, offset: 3 }}>
                            <div>
                              <div
                                className="check-content"
                                style={{ justifyContent: 'space-between' }}
                              >
                                <div className="form-input w57">
                                  <label className="blue-label">
                                    Tema da sessão
                                  </label>
                                  <input
                                    type="text"
                                    value={this.getActiveMeetingValue(
                                      'session_theme'
                                    )}
                                    className="blue-input"
                                    placeholder="Tema da sessão"
                                  />
                                </div>
                                <div
                                  className="form-input"
                                  style={{ width: 'auto', flex: 'flex' }}
                                >
                                  <label className="blue-label">
                                    {'master' == activeMeeting
                                      ? 'Data prevista para o sucesso'
                                      : 'Data'}
                                  </label>
                                  <InputMask
                                    {...this.props}
                                    mask="99/99/9999"
                                    value={this.getActiveMeetingValue(
                                      'date',
                                      true
                                    )}
                                    className="blue-input"
                                    placeholder="Data"
                                  />
                                </div>
                                {/* TODO: Add video session */}
                                {/* {session.id && (
                                  <div
                                    className="bt-blueicon container-pop bt-blueicon-video-session"
                                    onClick={() =>
                                      this.props.updateVideoSession({
                                        isActive: true,
                                        type: videoRoomTypes.SESSION,
                                        roomName: session.id,
                                        role: videoRoomRoles.lobby,
                                        userType: userTypes.coachee,
                                        lobbyMessage:
                                          'Aguarde um DPO iniciar a vídeo-chamada',
                                      })
                                    }
                                  >
                                    <i className="fas fa-video" />
                                    <div className="popover-container">
                                      <div className="popover-content">
                                        Vídeo-chamada
                                      </div>
                                    </div>
                                  </div>
                                )} */}
                              </div>

                              {activeMeeting === 'master' && (
                                <div>
                                  {session.coachee_objectives_active && (
                                    <div className="row-form top-textarea">
                                      <div className="form-textarea clear-textarea">
                                        <label className="blue-label">{`Objetivo do ${
                                          session.its_group ? 'Área' : 'Membro'
                                        }`}</label>
                                        <div className="textarea">
                                          <RichText
                                            setState={this.setState.bind(this)}
                                            state={this.state}
                                            value={session.coachee_objectives}
                                            readonly={true}
                                            showToolbar={false}
                                            minHeight={130}
                                            height={130}
                                            placeholder={`Objetivo do ${
                                              session.its_group
                                                ? 'Área'
                                                : 'Membro'
                                            }`}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                  {session.success_evidence_active && (
                                    <div className="row-form top-textarea">
                                      <div className="form-textarea clear-textarea">
                                        <label className="blue-label">
                                          Evidência de Sucesso
                                        </label>
                                        <div className="textarea">
                                          <RichText
                                            setState={this.setState.bind(this)}
                                            state={this.state}
                                            value={session.success_evidence}
                                            readonly={true}
                                            showToolbar={false}
                                            minHeight={130}
                                            height={130}
                                            placeholder="Evidência de Sucesso"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                  {session.motivators_active && (
                                    <div className="row-form top-textarea">
                                      <div className="form-textarea clear-textarea">
                                        <label className="blue-label">
                                          Motivadores
                                        </label>
                                        <div className="textarea">
                                          <RichText
                                            setState={this.setState.bind(this)}
                                            state={this.state}
                                            value={session.motivators}
                                            readonly={true}
                                            showToolbar={false}
                                            minHeight={130}
                                            height={130}
                                            placeholder="Motivadores"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                  {session.saboteurs_active && (
                                    <div className="row-form top-textarea">
                                      <div className="form-textarea clear-textarea">
                                        <label className="blue-label">
                                          Sabotadores
                                        </label>
                                        <div className="textarea">
                                          <RichText
                                            setState={this.setState.bind(this)}
                                            state={this.state}
                                            value={session.saboteurs}
                                            readonly={true}
                                            showToolbar={false}
                                            minHeight={130}
                                            height={130}
                                            placeholder="Sabotadores"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                  {session.values_active && (
                                    <div className="row-form top-textarea">
                                      <div className="form-textarea clear-textarea">
                                        <label className="blue-label">
                                          Valores
                                        </label>
                                        <div className="textarea">
                                          <RichText
                                            setState={this.setState.bind(this)}
                                            state={this.state}
                                            value={session.values}
                                            readonly={true}
                                            showToolbar={false}
                                            minHeight={130}
                                            height={130}
                                            placeholder="Valores"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                  {session.strategies_active && (
                                    <div className="row-form top-textarea">
                                      <div className="form-textarea clear-textarea">
                                        <label className="blue-label">
                                          Estratégias
                                        </label>
                                        <div className="textarea">
                                          <RichText
                                            setState={this.setState.bind(this)}
                                            state={this.state}
                                            value={session.strategies}
                                            readonly={true}
                                            showToolbar={false}
                                            minHeight={130}
                                            height={130}
                                            placeholder="Estratégias"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                  {session.necessary_resources_active && (
                                    <div className="row-form top-textarea">
                                      <div className="form-textarea clear-textarea">
                                        <label className="blue-label">
                                          Recursos necessários
                                        </label>
                                        <div className="textarea">
                                          <RichText
                                            setState={this.setState.bind(this)}
                                            state={this.state}
                                            value={session.necessary_resources}
                                            readonly={true}
                                            showToolbar={false}
                                            minHeight={130}
                                            height={130}
                                            placeholder="Recursos necessários"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              )}

                              <div className="row-form top-textarea">
                                <div className="form-textarea clear-textarea">
                                  <label className="blue-label">
                                    Registro de Sessão
                                  </label>
                                  <div className="textarea">
                                    <RichText
                                      setState={this.setState.bind(this)}
                                      state={this.state}
                                      value={this.getActiveMeetingValue(
                                        'comment_private'
                                      )}
                                      showToolbar={false}
                                      readonly={true}
                                      minHeight={130}
                                      height={130}
                                      placeholder="Registro de Sessão"
                                    />
                                  </div>
                                </div>
                              </div>
                              {this.getActiveMeetingValue(
                                'comment_coachee_active'
                              ) ? (
                                <div className="row-form top-textarea">
                                  <div className="form-textarea clear-textarea">
                                    <label className="blue-label">{`Comentário para ${
                                      session.its_group ? 'a Área' : 'o Membro'
                                    }`}</label>
                                    <div className="textarea">
                                      <RichText
                                        setState={this.setState.bind(this)}
                                        state={this.state}
                                        value={this.getActiveMeetingValue(
                                          'comment_coachee'
                                        )}
                                        showToolbar={false}
                                        readonly={true}
                                        minHeight={130}
                                        height={130}
                                        placeholder={`Comentário para ${
                                          session.its_group
                                            ? 'a Área'
                                            : 'o Membro'
                                        }`}
                                        onChange={e => {
                                          this.setActiveMeetingValue({
                                            comment_coachee: e,
                                          });
                                        }}
                                      />
                                    </div>
                                    <span>
                                      {this.utils.getLengthStringOfHtml(
                                        this.getActiveMeetingValue(
                                          'comment_coachee'
                                        )
                                      )}
                                      /{maxLength}
                                    </span>
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          </Col>
                        </>
                      )
                    )}
                  </div>
                )}
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  sessionId: state.simpleReducer.sessionId,
  menu: state.simpleReducer.menu,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { updateImage, updateVideoSession, updateSession },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Programs)
);
