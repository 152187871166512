import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import '../../../styles/css/style.css';
import '../../../styles/css/dashboard.css';
import '../../../styles/css/sessaoMasterDate/style.scss';
import { Col, Row } from 'reactstrap';
import Switch from 'react-switch';
import {
  updateImage,
  updateAlert,
  updateSession,
  updateVideoSession,
  updateChronometer,
  updateChronometerSessions,
  updateActiveMeetingChronometer,
} from '../../../actions/SimpleAction';
import Sidebar from './../../sidebar/Sidebar';
import ReactLoading from 'react-loading';
import Header from './../../components/Header';
import PrincipalMenu from './../../components/PrincipalMenu';
import Breadcrumbs from './../../components/Breadcrumbs';
import NotAllowed from './../../components/NotAllowed';
import Footer from './../../components/Footer';
import Submenu from './SubmenuSession';
import TableAdd from './TableAdd';
import ModalActions from './ModalActions';
import ModalContents from './ModalContents';
import ModalContentUpload from './ModalContentUpload';
import ContentUploadConfirmation from './ContentUploadConfirmation';
import Service from './../Service.js';
import Utils from './../../../libs/Utils.js';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Chronometer, {
  chronometerInitialState,
  normalizeChronometerFromApi,
} from './../../components/Chronometer';
import videoRoomTypes, {
  roles as videoRoomRoles,
} from '../../../consts/videoRoomTypes';
import userTypes from '../../../consts/userTypes';
import sessionParamTypes from '../../../consts/sessionParamTypes';
import RichText from './../../../components/RichText';
import ReactTooltip from 'react-tooltip';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomCheckbox from '../../../components/Checkbox/indexCheckbox';
import ModalProcess from './ModalProcess';

const Typeahead = require('react-typeahead').Typeahead;

const getSessionInitialState = () => ({
  _id: '',
  oportunity_id: '',
  coachee_id: '',
  group_id: '',
  its_free: false,
  its_group: true,
  project: '',
  expected_date: new Date(),
  coachee_objectives: '',
  coachee_objectives_active: false,
  success_evidence: '',
  success_evidence_active: false,
  motivators: '',
  motivators_active: false,
  saboteurs: '',
  saboteurs_active: false,
  values: '',
  values_active: false,
  strategies: '',
  strategies_active: false,
  necessary_resources: '',
  necessary_resources_active: false,
  editorState: '',
  FrameworksName: [],
});

const getMasterInitialState = () => ({
  chronometer: chronometerInitialState(),
  contents: [],
  actions: [],
  coachees: [],
});

class SessionMaster extends Component {
  constructor(props, context) {
    super(props);
    this._typeahead = React.createRef();

    const initialWidth = window.innerWidth > 0 ? window.innerWidth : 500;
    this.state = {
      isVisible: false,
      sideOpen: this.props.menu,
      principalOpen: true,
      view: 'programs',
      subview: 'master',
      side: 'assessments',
      breadcrumbs: [
        { title: 'Dashboard', link: '' },
        { title: 'Programas', link: 'programs' },
        { title: 'Reunião Inaugural', link: 'programs/assessments' },
      ],
      showToolTip: false,
      windowWidth: initialWidth - (window.innerWidth * 40) / 100,
      isLoading: false,
      loading: false,
      loading_oportunities: false,
      oportunities: {},
      loading_coachees: false,
      coachees: [],
      loading_groups: false,
      groups: [],
      loading_contents: false,
      contentList: [],
      session: getSessionInitialState(),
      master: getMasterInitialState(),
      showModalContents: false,
      showModalActions: false,
      showModalProcess: false,
      showModalContentUpload: false,
      maxLength: 3660,
      isEditing: false,
      sortField: '',
      selectedContents: [],
      selectedCoachees: [],
      coacheeSelected: '',
      loadingSendMinute: false,
    };
    this.props.updateChronometer(chronometerInitialState());
    this.utils = new Utils();
    this.service = new Service();
    this.handleResize = this.handleResize.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.togglePrincipal = this.togglePrincipal.bind(this);
    this.setSidebarLinks = this.setSidebarLinks.bind(this);

    this.toggleModalContent = this.toggleModalContent.bind(this);
    this.toggleContent = this.toggleContent.bind(this);
    this.removeContent = this.removeContent.bind(this);

    this.toggleModalAction = this.toggleModalAction.bind(this);
    this.saveAction = this.saveAction.bind(this);
    this.removeAction = this.removeAction.bind(this);
  }

  notify = async () => {
    const { session } = this.state;

    if (session && session._id) {
      const obj = {
        meetingType: 'master',
      };

      this.setState(
        {
          loadingSendMinute: true,
        },
        () => {
          this.service
            .sendMinutes(session._id, obj)
            .then(result => {
              if (result && result.success) {
                this.props.updateAlert('Compartilhado com sucesso');
              }
            })
            .finally(() => {
              this.setState({
                loadingSendMinute: false,
              });
            });
        }
      );
    }
  };

  toggleContent = item => {
    let selectedContents = this.state.selectedContents;
    const isContentSelected = selectedContents.find(
      content => content._id === item._id
    );
    if (isContentSelected) {
      selectedContents = selectedContents.filter(
        content => content._id !== item._id
      );
    } else {
      selectedContents.push(item);
    }
    this.setState({ selectedContents });
  };

  toggleCoachee = item => {
    let selectedCoachees = [...this.state.selectedCoachees];
    const isCoacheeSelected = selectedCoachees.find(
      coachee => coachee._id === item._id
    );
    if (isCoacheeSelected) {
      selectedCoachees = selectedCoachees.filter(
        coachee => coachee._id !== item._id
      );
    } else {
      selectedCoachees.push(item);
    }
    this.setState({ selectedCoachees });
  };

  removeContent = async item => {
    const { session } = this.state;
    if (session._id) {
      let result;
      if (session.its_free) {
        result = await this.service.destroySessionContent(
          session._id,
          'master',
          item.oportunity_id,
          item._id
        );
      } else {
        result = await this.service.destroySessionContent(
          session._id,
          'master',
          item.coachee_id,
          item._id
        );
      }

      if (result && result.success) {
        const { session } = result;

        this.setState({
          session: this.parseSession(session),
          master: this.parseMaster({
            ...session.planning.master,
            procedures: session.procedures.filter(p => p.meeting === 'master'),
            actions: session.actions.filter(a => a.meeting === 'master'),
          }),
        });
      }
    }
  };

  toggleModalContent = async (saving = false, content = []) => {
    const {
      selectedContents,
      selectedCoachees,
      session,
      contentList,
    } = this.state;

    const hasContent = contentList.length > 0;
    if (!hasContent) {
      this.props.updateAlert(
        <ContentUploadConfirmation
          cancel={() => this.props.updateAlert('')}
          upload={() => {
            this.props.updateAlert('');
            this.setState({ showModalContentUpload: true });
          }}
        />
      );
      return;
    }

    const contentIds = selectedContents.map(content => content._id);

    if (saving && contentIds.length > 0 && session._id) {
      const sendTo = [];
      if (session.its_free && session.oportunity_id) {
        sendTo.push(session.oportunity_id);
      } else if (session.its_group) {
        selectedCoachees.forEach(coachee => sendTo.push(coachee._id));
      } else if (session.coachee_id) {
        sendTo.push(session.coachee_id);
      }

      if (sendTo.length > 0) {
        const result = await this.service.storeSessionContents(
          session._id,
          'master',
          sendTo,
          contentIds
        );

        if (result && result.success) {
          const { session } = result;
          this.setState({
            session: this.parseSession(session),
            master: this.parseMaster({
              ...session.planning.master,
              procedures: session.procedures.filter(
                p => p.meeting === 'master'
              ),
              actions: session.actions.filter(a => a.meeting === 'master'),
            }),
          });
        }
      }
    }
    this.setState({
      showModalContents: !this.state.showModalContents,
      selectedContents: [...content],
      selectedCoachees: [],
    });
  };

  toggleModalContentUpload = async isSaved => {
    const { showModalContentUpload } = this.state;
    this.setState({ showModalContentUpload: !showModalContentUpload });
    if (isSaved) await this.loadContents(true);
  };

  toggleModalAction = () => {
    const { showModalActions } = this.state;
    this.setState({
      showModalActions: !showModalActions,
      selectedCoachees: [],
    });
  };

  toggleModalProcess = () => {
    const { showModalProcess } = this.state;
    this.setState({
      showModalProcess: !showModalProcess,
    });
  };

  saveAction = async action => {
    const { session, selectedCoachees } = this.state;
    if (session._id) {
      const sendTo = [];
      if (session.its_free && session.oportunity_id) {
        sendTo.push(session.oportunity_id);
      } else if (session.its_group) {
        selectedCoachees.forEach(coachee => sendTo.push(coachee._id));
      } else if (session.coachee_id) {
        sendTo.push(session.coachee_id);
      }

      if (sendTo.length > 0) {
        const result = await this.service.storeSessionActions(
          session._id,
          'master',
          sendTo,
          action
        );

        if (result && result.success) {
          const { session } = result;
          this.setState({
            session: this.parseSession(session),
            master: this.parseMaster({
              ...session.planning.master,
              procedures: session.procedures.filter(
                p => p.meeting === 'master'
              ),
              actions: session.actions.filter(a => a.meeting === 'master'),
            }),
          });
        }
      }
    }
    this.toggleModalAction();
  };

  saveProcess = async process => {
    const { session } = this.state;
    const body = {
      procedure: {
        ...process,
      },
    };

    if (session) {
      const result = await this.service.saveProceduresSessionMasterFree(
        session._id,
        body
      );

      if (result && result.success) {
        this.setState(
          {
            session: this.parseSession(result.session),
            master: this.parseMaster({
              ...result.session.planning.master,
              procedures: result.session.procedures.filter(
                p => p.meeting === 'master'
              ),
              actions: result.session.actions.filter(
                a => a.meeting === 'master'
              ),
            }),
          },
          () => this.toggleModalProcess()
        );
      }
    }
  };

  removeAction = async action => {
    const { session } = this.state;
    if (session._id) {
      let result;
      if (session.its_free) {
        result = await this.service.destroySessionAction(
          session._id,
          action.oportunity_id,
          action.action
        );
      } else {
        result = await this.service.destroySessionAction(
          session._id,
          action.coachee_id,
          action.action
        );
      }
      if (result && result.success) {
        const { session } = result;
        this.setState({
          session: this.parseSession(session),
          master: this.parseMaster({
            ...session.planning.master,
            procedures: session.procedures.filter(p => p.meeting === 'master'),
            actions: session.actions.filter(a => a.meeting === 'master'),
          }),
        });
      }
    }
  };

  removeProcess = async process => {
    const { session } = this.state;

    if (session._id) {
      const result = await this.service.destroySessionProceduresMasterFree(
        session._id,
        process.procedure
      );
      if (result && result.success) {
        const { session } = result;
        this.setState({
          session: this.parseSession(session),
          master: this.parseMaster({
            ...session.planning.master,
            procedures: session.procedures.filter(p => p.meeting === 'master'),
            actions: session.actions.filter(a => a.meeting === 'master'),
          }),
        });
      }
    }
  };

  toggleMenu = async () => {
    const { sideOpen } = this.state;
    await this.setState({ sideOpen: !sideOpen });
  };

  togglePrincipal = async () => {
    const { principalOpen } = this.state;
    await this.setState({ principalOpen: !principalOpen });
  };

  goTo = page => {
    this.props.history.push(`/${page}`);
  };

  verifyLogin = () => {
    if (!this.utils.isLogged()) {
      this.goTo('');
    }
  };

  componentDidMount() {
    this.verifyLogin();

    this.setState({ isLoading: true }, () => {
      Promise.all([
        this.loadUser().then(() => this.loadGroups()),
        this.loadContents(),
        this.loadFrameworksName(),
        this.loadOportunities(),
        this.loadCoachees(),
      ])
        .then(() => this.resolveParams())
        .finally(() => this.setState({ isLoading: false }));
    });

    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  resolveParams = () => {
    const {
      match: { params },
    } = this.props;

    const { session, user } = this.state;

    this.setState(
      {
        session: {
          ...session,
          its_free: params.session_type === sessionParamTypes.MASTER,
        },
      },
      async () => {
        this.setBreadcrumbs();

        if (params.sessionId) {
          await this.loadSessionById(params.sessionId);

          if (params.session && params.session !== 'master') {
            this.goTo(
              `dashboard/programs/assessments/register/${params.session}`
            );
          }
        }

        if (!this.state.session._id && this.props.sessionId) {
          const result = await this.service.sessionS(this.props.sessionId);
          if (result && result.success && result.session) {
            const isSessionTypeOk =
              result.session.its_free ===
              (params.session_type === sessionParamTypes.MASTER);

            if (
              (result.session.its_group &&
                !this.userHasGroupSessionRight(user)) ||
              !isSessionTypeOk
            ) {
              this.props.updateSession('');
            } else {
              this.resolveSessionRequest(result.session);
            }
          }
        }
      }
    );
  };

  getBreadcrumbDetail = () => {
    const { session } = this.state;
    if (session.coachee_id) return this.getCoacheeName(session.coachee_id);
    if (session.group_id) return this.getGroupName(session.group_id);
  };

  setBreadcrumbs = () => {
    const { session } = this.state;
    const breadcrumbDetail = this.getBreadcrumbDetail();

    if (session.its_free) {
      this.setState({
        side: 'master',
        breadcrumbs: [
          { title: 'Dashboard', link: '' },
          { title: 'Programas', link: 'programs' },
          { title: 'Reunião Inaugural', link: 'programs/master' },
          // ...(breadcrumbDetail ? [{ title: breadcrumbDetail }] : []),
        ],
      });
    } else {
      this.setState({
        subview: 'master',
        side: 'assessments',
        breadcrumbs: [
          { title: 'Dashboard', link: '' },
          { title: 'Programas', link: 'programs' },
          { title: 'Registro de Sessões', link: 'programs/assessments' },
          ...(breadcrumbDetail ? [{ title: breadcrumbDetail }] : []),
        ],
      });
    }
  };

  setSidebarLinks = view => {
    if (view == 'programs/master' || view == 'programs/assessments') {
      setTimeout(() => {
        let its_free = view == 'programs/master';
        if (this.state.session.its_free !== its_free) {
          this.clearForm();
        }
        this.resolveParams();
      }, 1);
    }
    this.setState({ view });
    this.goTo(`dashboard/${view}`);
  };

  handleResize = () => {
    let _ww =
      window.innerWidth < 890 && window.innerWidth > 570
        ? window.innerWidth - (window.innerWidth * 30) / 100
        : window.innerWidth <= 570
        ? window.innerWidth - (window.innerWidth * 40) / 100
        : window.innerWidth - (window.innerWidth * 40) / 100;

    this.setState({
      windowWidth: _ww,
    });
  };

  logout = () => {
    this.utils.setLogout();
    this.goTo('');
  };

  loadUser = async () => {
    let result = await this.service.me();
    if (!result) {
      this.logout();
      return;
    }
    let user = {
      ...result.user,
    };
    if (user.image) {
      this.props.updateImage(user.image);
    }
    this.setState({ user });
  };

  loadFrameworksName = async () => {
    let result = await this.service.getFrameworksName();

    this.setState({
      FrameworksName: result,
    });
  };

  loadOportunities = async () => {
    this.setState({ loading_oportunities: true });
    let result = await this.service.oportunitiesByProject();

    if (result && result.success && result.oportunities) {
      await this.loadSessionByOportunityId(result.oportunities._id);
      this.setState({ oportunities: result.oportunities });
    }
    this.setState({ loading_oportunities: false });
  };

  loadCoachees = async () => {
    this.setState({ loading_coachees: true });
    let result = await this.service.coachees();
    if (result && result.success) {
      let coachees = result.coachees.filter(item => {
        return item.status == 'true';
      });
      this.setState({ coachees });
    }
    this.setState({ loading_coachees: false });
  };

  loadGroups = async () => {
    const { user } = this.state;
    if (!this.userHasGroupSessionRight(user)) return;

    this.setState({ loading_groups: true });
    let result = await this.service.groups();
    if (result && result.success) {
      const { groups } = result;
      this.setState({ groups });
    }
    this.setState({ loading_groups: false });
  };

  loadContents = async (openModalAfterLoad = false) => {
    this.setState({ loading_contents: true });
    let result = await this.service.contents();
    if (result && result.success) {
      this.setState({ contentList: result.contents }, () => {
        if (openModalAfterLoad) {
          const { contentList } = this.state;
          const [newContent] = contentList;
          if (newContent) this.toggleModalContent(false, [newContent]);
        }
      });
    }
    this.setState({ loading_contents: false });
  };

  parseSession = session => {
    const {
      _id,
      oportunity_id,
      coachee_id,
      group_id,
      its_free,
      its_group,
      project,
      expected_date,
      coachee_objectives,
      coachee_objectives_active,
      success_evidence,
      success_evidence_active,
      motivators,
      motivators_active,
      saboteurs,
      saboteurs_active,
      values,
      values_active,
      strategies,
      strategies_active,
      necessary_resources,
      necessary_resources_active,
    } = session;

    return {
      _id,
      oportunity_id,
      coachee_id,
      group_id,
      its_free,
      its_group,
      project,
      expected_date: expected_date ? new Date(expected_date) : undefined,
      coachee_objectives,
      coachee_objectives_active,
      success_evidence,
      success_evidence_active,
      motivators,
      motivators_active,
      saboteurs,
      saboteurs_active,
      values,
      values_active,
      strategies,
      strategies_active,
      necessary_resources,
      necessary_resources_active,
    };
  };

  parseMaster = master => {
    const { chronometer, contents, actions, coachees, procedures } = master;
    const auxChronometer = normalizeChronometerFromApi(chronometer);
    this.props.updateChronometer(auxChronometer);
    return {
      contents,
      actions,
      chronometer: this.props.chronometer,
      coachees,
      procedures,
    };
  };

  saveSession = () => {
    if (this.state.isEditing) {
      this.updateSession();
    } else {
      this.createSession();
    }
  };

  createSession = async () => {
    this.setState({ loading: true });
    const {
      coachee_id,
      oportunity_id,
      group_id,
      its_group,
    } = this.state.session;
    const result = await this.service.createSession({
      coachee_id,
      oportunity_id,
      its_free: true,
      group_id,
      its_group,
    });
    if (result && result.success) {
      const { session } = result;
      this.setState(
        {
          session: this.parseSession(session),
          master: this.parseMaster({
            ...session.planning.master,
            procedures: session.procedures.filter(p => p.meeting === 'master'),
            actions: session.actions.filter(a => a.meeting === 'master'),
          }),
          isEditing: true,
        },
        () => {
          this.setBreadcrumbs();
        }
      );
      this.props.updateSession(session._id);
    }
    this.setState({ loading: false });
  };

  updateSession = async () => {
    this.setState({ loading: true });
    const { session } = this.state;
    const {
      _id: sessionId,
      project,
      expected_date,
      coachee_objectives,
      coachee_objectives_active,
      success_evidence,
      success_evidence_active,
      motivators,
      motivators_active,
      saboteurs,
      saboteurs_active,
      values,
      values_active,
      strategies,
      strategies_active,
      necessary_resources,
      necessary_resources_active,
    } = session;

    const { coachees } = this.state.master;

    const chronometer = this.props.chronometer;

    let result = await this.service.updateSession(sessionId, {
      session: {
        project,
        expected_date,
        coachee_objectives,
        coachee_objectives_active,
        success_evidence,
        success_evidence_active,
        motivators,
        motivators_active,
        saboteurs,
        saboteurs_active,
        values,
        values_active,
        strategies,
        strategies_active,
        necessary_resources,
        necessary_resources_active,
      },
      master: { chronometer, coachees },
    });
    if (result && result.success) {
      this.setState({
        session: this.parseSession(result.session),
        master: this.parseMaster({
          ...result.session.planning.master,
          procedures: result.session.procedures.filter(
            p => p.meeting === 'master'
          ),
          actions: result.session.actions.filter(a => a.meeting === 'master'),
        }),
      });
    }
    this.setState({ loading: false });
  };

  clearForm = () => {
    this.setState({
      session: getSessionInitialState(),
      master: getMasterInitialState(),
      isEditing: false,
    });
  };

  changeCoachee = e => {
    this.loadSessionByCoacheeId(e.target.value);
    //zerar cronometros globais para evitar problemas
    this.props.updateActiveMeetingChronometer(null);
    this.props.updateChronometerSessions({});
  };

  changeGroup = e => {
    this.loadSessionByGroupId(e.target.value);
  };

  resolveSessionRequest = session => {
    this.setState(
      {
        session: this.parseSession(session),
        master: this.parseMaster({
          ...session.planning.master,
          procedures: session.procedures.filter(p => p.meeting === 'master'),
          actions: session.actions.filter(a => a.meeting === 'master'),
        }),
        isEditing: true,
      },
      () => {
        this.setBreadcrumbs();
      }
    );

    this.props.updateSession(session._id);
  };

  loadSessionById = async sessionId => {
    if (!sessionId) return;
    const result = await this.service.sessionS(sessionId);
    if (result && result.success) {
      const { session } = result;

      this.resolveSessionRequest(session);
    }
  };

  loadSessionByOportunityId = async oportunity_id => {
    if (!oportunity_id) return;
    const result = await this.service.session(oportunity_id);

    if (result && result.success) {
      const { session } = result;

      if (session) {
        this.resolveSessionRequest(session);
      } else if (oportunity_id) {
        const updateState = {
          group_id: '',
          coachee_id: '',
          oportunity_id,
        };
        this.setState(
          {
            isEditing: false,
            session: { ...this.state.session, ...updateState },
          },
          () => {
            this.saveSession();
          }
        );
      }
    }
  };

  loadSessionByCoacheeId = async coachee_id => {
    if (!coachee_id) return;
    const result = await this.service.sessionC(coachee_id);
    if (result && result.success) {
      const { session } = result;
      if (session) {
        this.resolveSessionRequest(session);
      } else if (coachee_id) {
        const updateState = {
          group_id: '',
          coachee_id,
          oportunity_id: '',
        };
        this.setState(
          {
            isEditing: false,
            session: { ...this.state.session, ...updateState },
          },
          () => {
            this.saveSession();
          }
        );
      }
    }
  };

  loadSessionByGroupId = async group_id => {
    if (!group_id) return;
    const result = await this.service.sessionG(group_id);
    if (result && result.success) {
      const { session } = result;
      if (session) {
        this.resolveSessionRequest(session);
      } else if (group_id) {
        const updateState = {
          group_id,
          coachee_id: '',
          oportunity_id: '',
        };
        this.setState(
          {
            isEditing: false,
            session: { ...this.state.session, ...updateState },
          },
          () => {
            this.saveSession();
          }
        );
      }
    }
  };

  saveForm = () => {
    const { isEditing, session } = this.state;
    if (
      isEditing &&
      (session.coachee_id || session.oportunity_id || session.group_id)
    ) {
      this.saveSession();
    }
  };

  sort = key => {
    let order =
      this.state.sortField === '' || this.state.sortField === key
        ? '-down'
        : '';

    let contentList = this.state.contentList.sort((a, b) => {
      if (a[key] > b[key]) {
        return order.indexOf('down') !== -1 ? 1 : -1;
      }
      if (a[key] < b[key]) {
        return order.indexOf('down') !== -1 ? -1 : 1;
      }
      return 0;
    });
    this.setState({ sortField: `${key}${order}`, contentList });
  };

  handleCoacheeSelected = coachee => {
    this.setState({ coacheeSelected: coachee });
  };

  handleSelectedCoachees = () => {
    const { master, coacheeSelected } = this.state;

    if (
      coacheeSelected &&
      !master.coachees.find(item => item._id === coacheeSelected._id)
    ) {
      master.coachees.push({
        ...coacheeSelected,
      });
      this.setState(
        {
          master: {
            ...this.state.master,
            coachees: master.coachees,
          },
          coacheeSelected: '',
        },
        () => this.saveForm()
      );
    }

    if (this._typeahead && this._typeahead.current)
      this._typeahead.current.setEntryText('');
  };

  removeSelectedCoachee = coacheeId => {
    const selectedCoachees = this.state.master.coachees.filter(
      item => item._id !== coacheeId
    );
    this.setState(
      {
        master: {
          ...this.state.master,
          coachees: selectedCoachees,
        },
      },
      () => this.saveForm()
    );
  };

  handleChange = checked => {
    this.setState({ checked });
  };

  handleChangeCheckBoxFrameworks = async frameworks => {
    await this.service.updateFrameworkActive(frameworks.id);
    await this.loadFrameworksName();
  };

  handleChronometer = chronometer => {
    const { master } = this.state;
    this.props.updateChronometer(chronometer);
    this.setState({ master: { ...master, chronometer } });
  };

  getGroupCoachees = () => {
    const { session, groups } = this.state;
    const group =
      session.group_id && groups.find(group => group._id === session.group_id);
    return group ? group.coachees : [];
  };

  getAllCoachees = () => {
    const { coachees } = this.state;

    return coachees;
  };

  userHasGroupSessionRight = user => {
    return (
      user &&
      user.plans &&
      user.plans.platform &&
      user.plans.platform.plan.group_sessions
    );
  };

  userHasProgramRegisterRight = user => {
    return (
      user &&
      user.plans &&
      user.plans.platform &&
      user.plans.platform.plan.use_program_register
    );
  };

  getSearchCoachees = coachees => `${coachees.name} - ${coachees.email}`;

  userHasRights = user => {
    const { session } = this.state;
    return (
      this.userHasProgramRegisterRight(user) &&
      (!session.its_group ||
        (session.its_group && this.userHasGroupSessionRight(user)))
    );
  };

  showSessionFields = () => {
    return !!this.props.sessionId;
  };

  getCoacheeName = coacheeId => {
    const coachee = this.state.coachees.find(
      coachee => coachee._id === coacheeId
    );
    return coachee && coachee.name;
  };

  getGroupName = groupId => {
    const group = this.state.groups.find(group => group._id === groupId);
    return group && group.name;
  };

  copyToClipboard = async text => {
    try {
      await navigator.clipboard.writeText(text);
      this.notifyToast('Link copiado para a área de transferência', 'success');
    } catch (err) {
      console.error('Failed to copy: ', err);
      this.notifyToast('Falha ao copiar link', 'error');
    }
  };

  notifyToast = (text, type) => {
    const options = {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      toastId: 'url_copied',
      progressClassName: 'fancy-progress-bar',
      className: 'toast-zindex',
    };

    switch (type) {
      case 'success':
        toast(text, options);
        break;
      case 'error':
        toast.error(text, options);
        break;
      default:
    }
  };

  sleep(ms) {
    return new Promise(resolve => {
      setTimeout(resolve, ms);
    });
  }

  render() {
    const {
      principalOpen,
      sideOpen,
      side,
      view,
      subview,
      breadcrumbs,
      loading_oportunity,
      oportunities,
      loading_coachees,
      coachees,
      loading_groups,
      groups,
      user,
      session,
      master,
      maxLength,
      loading,
      sortField,
      showModalContents,
      showModalContentUpload,
      contentList,
      selectedContents,
      showModalActions,
      showModalProcess,
      selectedCoachees,
      isLoading,
      FrameworksName,
      loadingSendMinute,
    } = this.state;

    return (
      <div className="sessao-master-date-style main-content">
        <ToastContainer
          position="bottom-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Header
          goTo={this.goTo}
          sideOpen={sideOpen}
          toggleMenu={this.toggleMenu}
        />
        <div className="content-climb zIndex user bodyClimby">
          <div
            className={this.props.menu ? 'content-menu closed' : 'content-menu'}
          >
            <Sidebar
              side={this.state.side}
              setSidebarLinks={this.setSidebarLinks}
              expanded={!this.state.sideOpen}
            />
          </div>
          <div
            className={this.props.menu ? 'content-dash closed' : 'content-dash'}
          >
            <div className="dash-content zIndex">
              <div className="dash-crumb-filter">
                <Breadcrumbs breadcrumbs={breadcrumbs} />
              </div>
              {loading ? (
                <ReactLoading type="bubbles" color="#B1CD49" />
              ) : !this.userHasRights(user) ? (
                <NotAllowed go={this.goTo.bind(this)} user={user} />
              ) : (
                <div className="dashboard-boxes">
                  <div
                    className={'dashboard-box box100'}
                    style={session.its_free ? { minHeight: '73vh' } : null}
                  >
                    {(session.coachee_id ||
                      session.oportunity_id ||
                      session.group_id) &&
                    !loading ? (
                      <Chronometer
                        chronometer={this.props.chronometer}
                        handleChronometer={this.handleChronometer}
                        onSave={this.saveForm}
                      />
                    ) : null}
                    <Col xs={{ size: 12 }} md={{ size: 8, offset: 2 }}>
                      <div className="box-row-title row-center">
                        <div className="box-title">
                          {session.its_free
                            ? 'Reunião Inaugural'
                            : 'Registro de Sessões'}{' '}
                        </div>
                      </div>

                      {this.showSessionFields() && !loading && (
                        <>
                          <div className="check-content">
                            <div className="form-input w57">
                              <label className="blue-label">Projeto</label>
                              <input
                                type="text"
                                value={session.project}
                                onBlur={this.saveForm}
                                className="blue-input"
                                placeholder="Projeto"
                                onChange={e => {
                                  this.setState({
                                    session: {
                                      ...session,
                                      project: e.target.value,
                                    },
                                  });
                                }}
                              />
                            </div>
                            <div className="form-input">
                              <label className="blue-label special">
                                Data prevista para sucesso
                              </label>

                              <DatePicker
                                selected={session.expected_date}
                                onChange={e =>
                                  this.setState(
                                    {
                                      session: { ...session, expected_date: e },
                                    },
                                    () => this.saveForm()
                                  )
                                }
                                timeIntervals={30}
                                dateFormat="dd/MM/yyyy"
                                timeCaption="time"
                                className="blue-input tamanhoOldData"
                              />
                            </div>
                            {/* TODO: Habilitar video chamada */}
                            {/* {session.coachee_id ||
                            session.group_id ||
                            session.its_free ? (
                              <>
                                <div
                                  className="bt-blueicon container-pop bt-blueicon-video-session"
                                  onClick={() =>
                                    this.props.updateVideoSession({
                                      isActive: true,
                                      roomName: session._id,
                                      type: videoRoomTypes.SESSION,
                                      role: videoRoomRoles.moderator,
                                      subject: session.project,
                                      userType: userTypes.coach,
                                    })
                                  }
                                >
                                  <i className="fas fa-video" />
                                  <div className="popover-container">
                                    <div className="popover-content">
                                      Vídeo-chamada
                                    </div>
                                  </div>
                                </div>
                                {session.its_free ? (
                                  <div
                                    className={
                                      this.props.urlVideoSession
                                        ? 'bt-blueicon container-pop bt-blueicon-copy-video-session'
                                        : 'bt-blueicon container-pop bt-blueicon-copy-video-session disabled'
                                    }
                                    data-tip
                                    data-for="tooltip"
                                    onClick={() =>
                                      this.copyToClipboard(
                                        this.props.urlVideoSession
                                      )
                                    }
                                  >
                                    <i className="fas fa-link" />
                                    <ReactTooltip
                                      id="tooltip"
                                      type="light"
                                      effect="solid"
                                      textColor="#757575"
                                      borderColor="#B1B1B1"
                                      border={true}
                                    >
                                      <p style={{ maxWidth: 200 }}>
                                        Copiar link da chamada
                                      </p>
                                    </ReactTooltip>
                                  </div>
                                ) : null}
                              </>
                            ) : null} */}
                          </div>

                          <div className="form-input">
                            <label className="blue-label">Membro</label>
                            <div
                              className="search-content"
                              style={{ width: '100%' }}
                            >
                              <i className="fas fa-search message-search-icon"></i>
                              <Typeahead
                                ref={this._typeahead}
                                maxVisible={10}
                                filterOption="name"
                                defaultValue=""
                                displayOption={this.getSearchCoachees}
                                placeholder="Membro"
                                options={this.state.coachees}
                                onOptionSelected={this.handleCoacheeSelected}
                                customClasses={{
                                  input: 'messages-search',
                                  results: 'list-messages listahead',
                                }}
                              />
                              <div className="button-save">
                                {this.state.openPopInsert && (
                                  <div className="insert-hint">Inserir</div>
                                )}

                                <img
                                  onMouseOver={() =>
                                    this.setState({ openPopInsert: true })
                                  }
                                  onMouseOut={() =>
                                    this.setState({ openPopInsert: false })
                                  }
                                  onClick={this.handleSelectedCoachees}
                                  className="table-icon disable svg-image"
                                  src={require('./../../../assets/images/tables/add_coachee.svg')}
                                  style={{ width: 20 }}
                                  alt="Inserir"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="selected-coachees-div">
                            {master &&
                            master.coachees &&
                            master.coachees.length > 0 ? (
                              master.coachees.map(item => (
                                <Row
                                  key={item._id}
                                  className="selected-coachees-rows"
                                >
                                  <div className="list-group-name">
                                    <span>{item.name}</span>
                                  </div>
                                  <div className="list-group-email">
                                    <span>{item.email}</span>
                                  </div>

                                  <div>
                                    {this.state.openPop === item._id && (
                                      <div className="delete-hint">Deletar</div>
                                    )}
                                    <img
                                      onMouseOver={() =>
                                        this.setState({ openPop: item._id })
                                      }
                                      onMouseOut={() =>
                                        this.setState({ openPop: null })
                                      }
                                      onClick={() =>
                                        this.removeSelectedCoachee(item._id)
                                      }
                                      className="table-icon svg-image"
                                      src={require('./../../../assets/images/tables/trash.svg')}
                                      style={{ width: 10 }}
                                      alt="Excluir"
                                    />
                                  </div>
                                </Row>
                              ))
                            ) : (
                              <div className="selected-coachees-empty">
                                <i
                                  className="fas fa-exclamation-triangle"
                                  style={{ marginRight: 5 }}
                                ></i>
                                Nenhum membro selecionado
                              </div>
                            )}
                          </div>

                          <div className="form-textarea maxWidth clear-textarea top-textarea">
                            <label className="blue-label">
                              Comitê Executivo - Estratégico
                            </label>
                            <div className="textarea">
                              <RichText
                                setState={this.setState.bind(this)}
                                state={this.state}
                                value={session.success_evidence}
                                showToolbar={true}
                                minHeight={260}
                                height={260}
                                maxLength={maxLength}
                                placeholder={`Comitê Executivo - Estratégico`}
                                onBlur={this.saveForm}
                                onChange={e => {
                                  this.setState({
                                    session: {
                                      ...session,
                                      success_evidence: e,
                                    },
                                  });
                                }}
                              />
                            </div>
                            <span>
                              {this.utils.getLengthStringOfHtml(
                                session.success_evidence
                              )}
                              /{maxLength}
                            </span>
                          </div>

                          <div className="form-textarea maxWidth clear-textarea top-textarea">
                            <label className="blue-label">
                              Comitê de Privacidade de Dados - Tático
                            </label>
                            <div className="textarea">
                              <RichText
                                setState={this.setState.bind(this)}
                                state={this.state}
                                value={session.motivators}
                                showToolbar={true}
                                minHeight={260}
                                height={260}
                                maxLength={maxLength}
                                placeholder="Comitê de Privacidade de Dados - Tático"
                                onBlur={this.saveForm}
                                onChange={e => {
                                  this.setState({
                                    session: {
                                      ...session,
                                      motivators: e,
                                    },
                                  });
                                }}
                              />
                            </div>
                            <span>
                              {this.utils.getLengthStringOfHtml(
                                session.motivators
                              )}
                              /{maxLength}
                            </span>
                          </div>

                          <div className="form-textarea maxWidth clear-textarea top-textarea">
                            <label className="blue-label">
                              Comitê Operativo do Projeto - Operativo
                            </label>
                            <div className="textarea">
                              <RichText
                                setState={this.setState.bind(this)}
                                state={this.state}
                                value={session.saboteurs}
                                showToolbar={true}
                                minHeight={260}
                                height={260}
                                maxLength={maxLength}
                                placeholder="Comitê Operativo do Projeto - Operativo"
                                onBlur={this.saveForm}
                                onChange={e => {
                                  this.setState({
                                    session: {
                                      ...session,
                                      saboteurs: e,
                                    },
                                  });
                                }}
                              />
                            </div>
                            <span>
                              {this.utils.getLengthStringOfHtml(
                                session.saboteurs
                              )}
                              /{maxLength}
                            </span>
                          </div>

                          <div className="form-textarea maxWidth clear-textarea top-textarea">
                            <label className="blue-label">
                              Registro da Reunião
                            </label>
                            <div className="textarea">
                              <RichText
                                setState={this.setState.bind(this)}
                                state={this.state}
                                value={session.values}
                                showToolbar={true}
                                minHeight={260}
                                height={260}
                                maxLength={maxLength}
                                placeholder="Registro da Reunião"
                                onBlur={this.saveForm}
                                onChange={e => {
                                  this.setState({
                                    session: {
                                      ...session,
                                      values: e,
                                    },
                                  });
                                }}
                              />
                            </div>
                            <span>
                              {this.utils.getLengthStringOfHtml(session.values)}
                              /{maxLength}
                            </span>
                          </div>
                        </>
                      )}
                    </Col>

                    {this.showSessionFields() && !loading && (
                      <Col
                        className={'mtop-20'}
                        xs={{ size: 12 }}
                        md={{ size: 10, offset: 1 }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            width: '100%',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <span
                            style={{
                              fontSize: '18px',
                              color: '#003343',
                              fontWeight: '400',
                            }}
                          >
                            GESTÃO DA ADEQUAÇÃO
                          </span>

                          <div
                            style={{
                              display: 'flex',
                              flex: 1,
                              flexWrap: 'wrap',
                              padding: '10px',
                              marginLeft: '20px',
                              gap: '40px',
                            }}
                          >
                            {FrameworksName &&
                              FrameworksName.length > 0 &&
                              FrameworksName.map(el => (
                                <CustomCheckbox
                                  key={el.name}
                                  label={el.name}
                                  checked={el.actived}
                                  onChange={() =>
                                    this.handleChangeCheckBoxFrameworks(el)
                                  }
                                />
                              ))}
                          </div>
                        </div>
                      </Col>
                    )}

                    {this.showSessionFields() && !loading && (
                      <>
                        {/* <Col
                          className="mtop-20 mb20"
                          xs={{ size: 12 }}
                          md={{ size: 10, offset: 1 }}
                        >
                          <TableAdd
                            title="Tabela de conteúdos"
                            linked={'content'}
                            rows={[
                              ...(session.its_group
                                ? [
                                    {
                                      title: 'Membro',
                                      key: 'coacheeName',
                                    },
                                  ]
                                : []),
                              {
                                title: 'Título',
                                key: 'title',
                              },
                              {
                                title: 'Formato',
                                key: 'doc_format',
                              },
                              {
                                title: 'Categoria',
                                key: 'category',
                              },
                              {
                                title: 'Modelo',
                                key: 'tag',
                              },
                              {
                                title: 'Remover',
                                key: 'remove',
                              },
                            ]}
                            remove={this.removeContent}
                            toggle={this.toggleModalContent}
                            button_label="Adicionar Conteúdo"
                            button_icon="fas fa-paperclip"
                            values={master.contents.map(content => ({
                              ...content,
                              coacheeName: content.coachee
                                ? content.coachee.name
                                : '',
                            }))}
                          />
                        </Col> */}

                        <Col
                          className="mtop-20 mb20"
                          xs={{ size: 12 }}
                          md={{ size: 10, offset: 1 }}
                        >
                          <TableAdd
                            title="Tabela de ações"
                            rows={[
                              ...(session.its_group
                                ? [
                                    {
                                      title: 'Membro',
                                      key: 'coacheeName',
                                    },
                                  ]
                                : []),
                              {
                                title: 'Ação',
                                key: 'action',
                              },
                              {
                                title: 'Descrição',
                                key: 'description',
                              },
                              {
                                title: 'Responsável',
                                key: 'responsible',
                              },
                              {
                                title: 'Data para conclusão',
                                key: 'date',
                              },
                              {
                                title: 'Remover',
                                key: 'remove',
                              },
                            ]}
                            remove={this.removeAction}
                            toggle={this.toggleModalAction}
                            button_label="Adicionar uma Ação"
                            button_icon="fas fa-plus"
                            values={master.actions.map(action => ({
                              ...action,
                              coacheeName: action.coachee
                                ? action.coachee.name
                                : '',
                            }))}
                          />
                        </Col>

                        <Col
                          className="mtop-20 mb20"
                          xs={{ size: 12 }}
                          md={{ size: 10, offset: 1 }}
                        >
                          {/* <TableAdd
                            title="Definições de Processos"
                            rows={[
                              {
                                title: 'Título do Processo',
                                key: 'process_description',
                              },
                              {
                                title: 'Artigo da Lei',
                                key: 'law_article',
                              },
                              {
                                title: 'Status',
                                key: 'status',
                              },
                              {
                                title: 'Remover',
                                key: 'remove',
                              },
                            ]}
                            remove={this.removeProcess}
                            toggle={this.toggleModalProcess}
                            button_label="Adicionar Processo"
                            button_icon="fas fa-plus"
                            values={master.procedures}
                          /> */}

                          <div
                            className="row-between mtop-50 mbottom-50"
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            <div
                              className="bt-blue"
                              onClick={() => {
                                this.notify();
                              }}
                            >
                              {loadingSendMinute ? (
                                <ReactLoading type="bubbles" color="#B1CD49" />
                              ) : (
                                'Enviar ATA'
                              )}
                            </div>
                          </div>
                        </Col>
                      </>
                    )}
                  </div>
                </div>
              )}
              <Footer />
            </div>
          </div>
        </div>

        <ModalContents
          sort={this.sort.bind(this)}
          sortField={sortField}
          show={showModalContents}
          contentList={contentList}
          selectedContents={selectedContents}
          toggleContent={this.toggleContent}
          toggleModalContent={this.toggleModalContent}
          coachees={this.getGroupCoachees()}
          selectedCoachees={selectedCoachees}
          toggleCoachee={this.toggleCoachee}
          selectAllCoachee={() =>
            this.setState({ selectedCoachees: [...this.getGroupCoachees()] })
          }
          selectNoneCoachee={() => this.setState({ selectedCoachees: [] })}
        />

        {showModalActions && (
          <ModalActions
            toggleModalAction={this.toggleModalAction}
            saveAction={this.saveAction}
            modal_actions={showModalActions}
            coachees={this.getAllCoachees()}
            selectedCoachees={selectedCoachees}
            toggleCoachee={this.toggleCoachee}
            selectAllCoachee={() =>
              this.setState({ selectedCoachees: [...this.getAllCoachees()] })
            }
            selectNoneCoachee={() => this.setState({ selectedCoachees: [] })}
          />
        )}

        {showModalProcess && (
          <ModalProcess
            toggleModalProcess={this.toggleModalProcess}
            saveProcess={this.saveProcess}
            modal_process={showModalProcess}
          />
        )}

        <ModalContentUpload
          show={showModalContentUpload}
          toggle={this.toggleModalContentUpload}
        />
      </div>
    );
  }
}
const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  sessionId: state.simpleReducer.sessionId,
  menu: state.simpleReducer.menu,
  chronometer: state.simpleReducer.chronometer,
  urlVideoSession: state.simpleReducer.urlVideoSession,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateImage,
      updateAlert,
      updateSession,
      updateVideoSession,
      updateChronometer,
      updateChronometerSessions,
      updateActiveMeetingChronometer,
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SessionMaster)
);
