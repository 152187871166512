import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Col } from 'reactstrap';
import DatePicker from 'react-datepicker';
import Utils from '../../../libs/Utils.js';
import Service from './../Service.js';

import '../../../styles/css/datePicker/style.scss';
import {
  DataProcessingHypotheses,
  ProcedureStatus,
  getDataProcessingHypothesesText,
} from '../../../consts/procedure.js';

class ModalProcess extends Component {
  constructor(props, context) {
    super(props);
    this.state = {
      id: '',
      process_date: '',
      process_description: '',
      process_data_processing_hypotheses: '',
      process_comments: '',
      process_status: '',
      process_sensitive_data: false,
      process_children_or_adolescents_data: false,
      process_international_data_transfer: false,
      process_associated_operator: false,
      maxLength: 1000,
      isEditing: false,
      optionsStatus: [],
    };
    this.utils = new Utils();
    this.saveProcess = this.saveProcess.bind(this);
    this.service = new Service();
  }

  componentDidMount() {
    const { objEditProcess } = this.props;

    const isEdit = objEditProcess && Object.values(objEditProcess).length > 0;

    this.setState({
      process_date: isEdit
        ? objEditProcess.date
        : this.utils.parseDate(new Date()),
      id: isEdit ? objEditProcess.procedure : '',
      process_description: isEdit ? objEditProcess.process_description : '',
      process_data_processing_hypotheses: isEdit
        ? objEditProcess.data_processing_hypotheses
        : '',
      process_comments: isEdit ? objEditProcess.comments : '',
      process_status: isEdit
        ? objEditProcess.status
        : ProcedureStatus.NAO_INICIADO,
      process_sensitive_data: isEdit ? objEditProcess.sensitive_data : false,
      process_children_or_adolescents_data: isEdit
        ? objEditProcess.children_or_adolescents_data
        : false,
      process_international_data_transfer: isEdit
        ? objEditProcess.international_data_transfer
        : false,
      process_associated_operator: isEdit
        ? objEditProcess.associated_operator
        : false,
      isEditing: isEdit,
      optionsStatus: Object.values(ProcedureStatus),
    });
  }

  componentWillUnmount() {}

  saveProcess() {
    if (!this.validateStatus()) return;

    const process = {
      date: this.state.process_date,
      process_description: this.state.process_description,
      data_processing_hypotheses: this.state.process_data_processing_hypotheses,
      comments: this.state.process_comments,
      status: this.state.process_status,
      sensitive_data: this.state.process_sensitive_data,
      children_or_adolescents_data: this.state
        .process_children_or_adolescents_data,
      international_data_transfer: this.state
        .process_international_data_transfer,
      associated_operator: this.state.process_associated_operator,
    };

    this.props.saveProcess(process);
  }

  validateStatus() {
    const { process_status, process_data_processing_hypotheses } = this.state;

    return !(
      process_status === ProcedureStatus.ADEQUADO &&
      !process_data_processing_hypotheses
    );
  }

  updateProcess() {
    if (!this.validateStatus()) return;

    const process = {
      id: this.state.id,
      date: this.state.process_date,
      process_description: this.state.process_description,
      data_processing_hypotheses: this.state.process_data_processing_hypotheses,
      comments: this.state.process_comments,
      status: this.state.process_status,
      sensitive_data: this.state.process_sensitive_data,
      children_or_adolescents_data: this.state
        .process_children_or_adolescents_data,
      international_data_transfer: this.state
        .process_international_data_transfer,
      associated_operator: this.state.process_associated_operator,
    };

    this.props.updateProcess(process);
  }

  render() {
    return (
      <div
        className={`datepicker-style modal-content ${
          this.props.modal_process ? ' active' : ''
        }`}
      >
        <div className="modal-box">
          <div className="modal-row-between">
            <div className="box-title">
              {this.state.isEditing
                ? 'Editar o processo'
                : 'Preencha o processo'}
            </div>
            <i
              className="fas fa-times"
              onClick={() => {
                this.props.toggleModalProcess();
              }}
            ></i>
          </div>

          <div className="top-textarea modal-table-box">
            <Col xs={{ size: 12 }} md={{ size: 8, offset: 2 }}>
              <div className="modal-row-triple gap10">
                <div className="form-input">
                  <label className="blue-label">Descrição do processo</label>
                  <input
                    type="text"
                    value={this.state.process_description}
                    className="blue-input"
                    placeholder="Descrição do processo"
                    onChange={e => {
                      this.setState({ process_description: e.target.value });
                    }}
                  />
                </div>
                <div className="form-input w25">
                  <label className="blue-label">Data</label>

                  <DatePicker
                    selected={this.utils.resolveSessionDate(
                      this.state.process_date
                    )}
                    onChange={e => {
                      this.setState({
                        process_date: this.utils.parseDate(new Date(e)),
                      });
                    }}
                    timeFormat="HH:mm"
                    timeIntervals={30}
                    dateFormat="dd/MM/yyyy"
                    timeCaption="time"
                    className="blue-input"
                  />
                </div>
              </div>

              <div className="modal-row-triple bm00 gap10">
                <div className="form-input">
                  <label className="blue-label">
                    Hipótese de Tratamento de Dados
                  </label>
                  <select
                    value={this.state.process_data_processing_hypotheses}
                    className="blue-input margin-bottom-unset"
                    onChange={e =>
                      this.setState({
                        process_data_processing_hypotheses: e.target.value,
                      })
                    }
                  >
                    {['', ...Object.values(DataProcessingHypotheses)].map(
                      item => {
                        return (
                          <option key={item} value={item}>
                            &nbsp; {getDataProcessingHypothesesText(item) || ''}
                          </option>
                        );
                      }
                    )}
                  </select>
                  {!this.validateStatus() && (
                    <span style={{ color: '#fa1e1e', alignSelf: 'flex-start' }}>
                      "Hipótese de tratamento de dados" deve ser informada
                    </span>
                  )}
                </div>
                <div className="form-input w33">
                  <label className="blue-label">Status</label>
                  <select
                    value={this.state.process_status}
                    className="blue-input margin-bottom-unset"
                    onChange={e =>
                      this.setState({ process_status: e.target.value })
                    }
                  >
                    {this.state.optionsStatus.map((item, key) => {
                      return (
                        <option key={key} value={item}>
                          &nbsp; {item}
                        </option>
                      );
                    })}
                  </select>
                </div>
                {/* <div className="form-input w33">
                  <label className="blue-label">Status</label>

                  <input
                    type="text"
                    value={this.state.process_status}
                    className="blue-input"
                    placeholder="Status"
                    onChange={e => {
                      this.setState({ process_status: e.target.value });
                    }}
                  />
                </div> */}
              </div>

              <div className="row-form">
                <div className="form-textarea clear-textarea">
                  <label className="blue-label">Mapeamento</label>
                  <textarea
                    value={this.state.process_comments}
                    className="blue-input h55"
                    placeholder="Mapeamento"
                    maxLength={this.state.maxLength}
                    onChange={e => {
                      this.setState({ process_comments: e.target.value });
                    }}
                  ></textarea>
                  <span>
                    {this.state.process_comments.length}/{this.state.maxLength}
                  </span>
                </div>
              </div>
              <div
                style={{
                  paddingBottom: 30,
                }}
              >
                <label className="container-ckb">
                  <input
                    type="checkbox"
                    checked={this.state.process_sensitive_data}
                    onChange={ev => {
                      this.setState({
                        process_sensitive_data: !this.state
                          .process_sensitive_data,
                      });
                    }}
                  />
                  <span className="chklbl">
                    Dados Sensíveis
                    {this.state.process_sensitive_data && (
                      <span
                        style={{
                          color: '#fa1e1e',
                          alignSelf: 'flex-start',
                          marginLeft: 10,
                        }}
                      >
                        Garantir o cumprimento do capítulo II, seção II da Lei
                        13.709/2018
                      </span>
                    )}
                  </span>
                  <span className="checkmark"></span>
                </label>
                <label className="container-ckb">
                  <input
                    type="checkbox"
                    checked={this.state.process_children_or_adolescents_data}
                    onChange={ev => {
                      this.setState({
                        process_children_or_adolescents_data: !this.state
                          .process_children_or_adolescents_data,
                      });
                    }}
                  />
                  <span className="chklbl">
                    Dados de crianças e/ou adolescentes
                    {this.state.process_children_or_adolescents_data && (
                      <span
                        style={{
                          color: '#fa1e1e',
                          alignSelf: 'flex-start',
                          marginLeft: 10,
                        }}
                      >
                        Garantir o cumprimento do capítulo III, seção III da Lei
                        13.709/2018
                      </span>
                    )}
                  </span>
                  <span className="checkmark"></span>
                </label>

                <label className="container-ckb">
                  <input
                    type="checkbox"
                    checked={this.state.process_international_data_transfer}
                    onChange={ev => {
                      this.setState({
                        process_international_data_transfer: !this.state
                          .process_international_data_transfer,
                      });
                    }}
                  />
                  <span className="chklbl">
                    Transferência Internacional de Dados
                    {this.state.process_international_data_transfer && (
                      <span
                        style={{
                          color: '#fa1e1e',
                          alignSelf: 'flex-start',
                          marginLeft: 10,
                        }}
                      >
                        Garantir o cumprimento do capítulo V da Lei 13.709/2018
                      </span>
                    )}
                  </span>
                  <span className="checkmark"></span>
                </label>
                <label className="container-ckb">
                  <input
                    type="checkbox"
                    checked={this.state.process_associated_operator}
                    onChange={ev => {
                      this.setState({
                        process_associated_operator: !this.state
                          .process_associated_operator,
                      });
                    }}
                  />
                  <span className="chklbl">
                    Operador Associado
                    {this.state.process_associated_operator && (
                      <span
                        style={{
                          color: '#fa1e1e',
                          alignSelf: 'flex-start',
                          marginLeft: 10,
                        }}
                      >
                        Garantir o cumprimento do capítulo VI da Lei 13.709/2018
                      </span>
                    )}
                  </span>
                  <span className="checkmark"></span>
                </label>
              </div>
            </Col>
          </div>

          <div className="modal-row-actions">
            <div
              className="bt-blue"
              onClick={() => {
                {
                  this.state.isEditing
                    ? this.updateProcess()
                    : this.saveProcess();
                }
              }}
            >
              <img
                src={require('./../../../assets/images/plus.svg')}
                style={{ marginTop: -3, marginRight: 14 }}
              />

              {this.state.isEditing ? 'Salvar processo' : 'Adicionar processo'}
            </div>
            <div
              className="bt-blue"
              onClick={() => this.props.toggleModalAction()}
            >
              <img
                src={require('./../../../assets/images/plus.svg')}
                style={{ marginTop: -3, marginRight: 14 }}
              />
              Adicionar ação
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  image: state.simpleReducer.image,
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ModalProcess);
