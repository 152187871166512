import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import defaultImage from '../../assets/images/header-user.png';
import groupImage from '../../assets/images/group.png';
import '../../styles/css/style.css';
import '../../styles/css/dashboard.css';
import '../../styles/css/coachee/mensagens.scss';
import { Col } from 'reactstrap';
import { updateImage } from '../../actions/SimpleAction';
import Sidebar from '../sidebar/Sidebar';
import Header from './../components/Header';
import PrincipalMenu from './../components/PrincipalMenu';
import Breadcrumbs from './../components/Breadcrumbs';
import Footer from './../components/Footer';
import Service from './Service.js';
import Utils from './../../libs/Utils.js';
import BigCalendar from 'react-big-calendar';
import moment from 'moment/min/moment-with-locales';
import Avatar from 'react-avatar';

const localizer = BigCalendar.momentLocalizer(moment);

class Messages extends Component {
  constructor(props, context) {
    super(props);

    const initialWidth = window.innerWidth > 0 ? window.innerWidth : 500;

    this.state = {
      isVisible: false,
      sideOpen: false,
      principalOpen: true,
      view: '',
      side: 'messages',
      breadcrumbs: [
        { title: 'Dashboard', link: '' },
        { title: 'Mensagens', link: 'dashboard/messages' },
      ],
      showToolTip: false,
      windowWidth: initialWidth - (window.innerWidth * 40) / 100,
      coacheesAndGroups: [],
      openedChat: false,
      openedMessages: [],
      textMessage: '',
      chatStarted: [],
    };

    this.utils = new Utils();
    this.service = new Service();

    this.sockets = [];

    this.handleResize = this.handleResize.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.togglePrincipal = this.togglePrincipal.bind(this);
    this.setSidebarLinks = this.setSidebarLinks.bind(this);
    this.goTo = this.goTo.bind(this);
    this.sentMessage = this.sentMessage.bind(this);
  }

  toggleMenu() {
    if (this.state.sideOpen) {
      this.setState({ sideOpen: false });
    } else {
      this.setState({ sideOpen: true });
    }
  }

  togglePrincipal() {
    if (this.state.principalOpen) {
      this.setState({ principalOpen: false });
    } else {
      this.setState({ principalOpen: true });
    }
  }

  goTo(page) {
    this.props.history.push(`/${page}`);
  }

  verifyLogin() {
    if (!this.utils.isLogged()) {
      this.goTo('');
    }
  }

  componentDidMount() {
    this.verifyLogin();
    this.loadUser();
    this.loadChats(true);
    moment.locale('pt-BR');
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    for (let k in this.sockets) {
      this.sockets[k].disconnect();
    }
    window.removeEventListener('resize', this.handleResize);
  }

  setSidebarLinks(view) {
    this.setState({ view });
    this.goTo(`users/dashboard/${view}`);
  }

  handleResize() {
    let _ww =
      window.innerWidth < 890 && window.innerWidth > 570
        ? window.innerWidth - (window.innerWidth * 30) / 100
        : window.innerWidth <= 570
        ? window.innerWidth - (window.innerWidth * 40) / 100
        : window.innerWidth - (window.innerWidth * 40) / 100;

    this.setState({
      windowWidth: _ww,
    });
  }

  logout() {
    this.utils.setLogout();
    this.goTo('');
  }

  async initSocket(chatId) {
    const socketParams = {
      channel: chatId,
      type: 'chat',
    };
    this.sockets[socketParams.channel] = this.service.createSocket(
      this.service.parsePayload(socketParams)
    );

    this.sockets[socketParams.channel].off(
      `chat.message.${socketParams.channel}`
    );
    setTimeout(() => {
      this.sockets[socketParams.channel].on(
        `chat.message.${socketParams.channel}`,
        async chatId => {
          await this.loadChats();
          if (chatId === this.state.openedChat._id) {
            const openChat = this.state.chatStarted.find(
              chat => chat._id === chatId
            );
            if (openChat) {
              this.openChat(openChat);
              this.loadMessages();
            }
          }
        }
      );
    }, 1000);
  }

  async loadUser() {
    let result = await this.service.meCoachee();
    if (!result) {
      this.logout();
      return;
    }
    let user = {
      ...result.user,
    };
    if (user.image) {
      this.props.updateImage(user.image);
    }
    this.setState({ user });
  }

  async loadMessages() {
    setTimeout(() => {
      if (this.messagesContainer)
        this.messagesContainer.scrollIntoView({ behavior: 'smooth' });
    }, 500);
  }

  async loadChats(open = false) {
    const result = await this.service.getCoacheeChats();
    if (result.success) {
      result.chats.forEach(chat => {
        if (chat._id) {
          if (!this.sockets[chat._id]) {
            this.initSocket(chat._id);
          }
        }
      });
      this.setState({ chatStarted: result.chats });
      const {
        match: { params },
      } = this.props;
      if (params.chat_id && open) {
        const openChat = result.chats.find(chat => chat._id === params.chat_id);
        if (openChat) {
          setTimeout(() => {
            this.openChat(openChat);
            this.loadMessages();
          }, 100);
        }
      }
    }
  }

  async sentMessage() {
    const { textMessage, openedChat } = this.state;
    if (textMessage) {
      if (openedChat._id) {
        const payload = { text: textMessage, coachWhoSends: false };
        const result = await this.service.updateChat(payload, openedChat._id);
        if (result.success) {
          const { updatedChat } = result;
          this.loadChats();
          this.loadMessages();
          const channel = openedChat._id;
          this.sockets[channel].emit(`chat.message.${channel}`, openedChat._id);
          this.setState({
            openedChat,
            openedMessages: updatedChat.messages,
            textMessage: '',
          });
        }
      } else {
        const payload = {
          coach: openedChat.coachId,
          group: openedChat.groupId,
          private_coachee: openedChat.privateCoacheeId,
          text: textMessage,
        };
        const result = await this.service.createNewChatByCoachee(payload);
        if (result.success) {
          const { newChat } = result;
          this.loadChats();
          this.loadMessages();
          this.setState({
            openedChat: { ...this.state.openedChat, _id: newChat._id },
            openedMessages: newChat.messages,
            textMessage: '',
          });
        }
      }
    }
  }

  parseDate(lastMessageDate) {
    return lastMessageDate ? moment(lastMessageDate).fromNow() : '';
  }

  async newChat(coacheeId) {
    const {
      chatStarted,
      user,
      openedChat: { groupId },
    } = this.state;
    const newChatStarted = [...chatStarted];
    const chat = chatStarted.find(chat => chat.group._id === groupId);
    const coachee = chat.group.coachees.find(
      coachee => coachee._id === coacheeId
    );
    let openedMessages = [];
    const openedChat = {};
    openedChat._id = null;
    openedChat.coachId = '';
    openedChat.coacheeId = user._id;
    openedChat.privateCoacheeId = coachee._id;
    openedChat.groupId = '';
    openedChat.name = coachee.name;
    openedChat.surname = coachee.surname ? coachee.surname : '';
    openedChat.image = coachee.image
      ? this.resolveImage(coachee.image)
      : defaultImage;

    chatStarted.forEach(item => {
      if (item.private_coachee._id) {
        if (
          item.private_coachee._id == coachee._id ||
          item.coachee._id === coachee._id
        ) {
          openedMessages = item.messages;
          openedChat._id = item._id;
        }
      }
    });

    if (!openedChat._id) {
      const newData = {};
      newData._id = null;
      newData.coach = '';
      newData.coachee = {
        _id: user._id,
        name: user.name,
        image: user.image || '',
      };
      newData.private_coachee = {
        _id: coachee._id,
        name: coachee.name,
        image: coachee.image || '',
      };
      newData.group = '';
      newData.messages = [];
      newData.unreaded = { lastMessageDate: '', total: 0 };
      let privateCoacheeAddInChatStarted = false;
      chatStarted.forEach(item => {
        if (item.private_coachee._id) {
          if (
            item.private_coachee._id === coachee._id ||
            item.coachee._id === coachee._id
          ) {
            privateCoacheeAddInChatStarted = true;
          }
        }
      });
      if (privateCoacheeAddInChatStarted) {
        return;
      }
      newChatStarted.push(newData);
      this.setState({ chatStarted: newChatStarted });
    }
    this.loadMessages();
    this.setState({ openedChat, openedMessages });
  }

  resolveImage(path) {
    return this.utils.resolveURL(path, this.service.getServer());
  }

  async openChat(item) {
    const openedMessages = item.messages;
    const openedChat = {};
    openedChat._id = item._id;
    if (item.group._id) {
      openedChat.coachId = item.coach._id;
      openedChat.coacheeId = '';
      openedChat.privateCoacheeId = '';
      openedChat.groupId = item.group._id;
      openedChat.name = item.group.name;
      openedChat.image = item.group.image
        ? this.resolveImage(item.group.image)
        : groupImage;
    }
    if (item.coachee._id && item.coach._id) {
      openedChat.coachId = item.coach._id;
      openedChat.coacheeId = this.state.user._id;
      openedChat.privateCoacheeId = '';
      openedChat.groupId = '';
      openedChat.name = item.coach.name;
      openedChat.surname = item.coach.surname ? item.coach.surname : '';
      openedChat.image = item.coach.image
        ? this.resolveImage(item.coach.image)
        : defaultImage;
    }
    if (item.private_coachee._id) {
      openedChat.coachId = '';
      openedChat.coacheeId = this.state.user._id;
      openedChat.privateCoacheeId = item.private_coachee._id;
      openedChat.groupId = '';
      openedChat.name = item.private_coachee.name;
      openedChat.surname = item.private_coachee.surname
        ? item.private_coachee.surname
        : '';
      openedChat.image = item.private_coachee.image
        ? this.resolveImage(item.private_coachee.image)
        : defaultImage;
    }
    if (item._id) {
      if (item.unreaded.total) {
        const result = await this.service.updateReadedChat(openedChat._id);
        if (result.success) {
          this.loadChats();
        }
      }
    }
    this.loadMessages();
    this.setState({ openedChat, openedMessages });
  }

  getNameIssuer(issuer) {
    const { openedChat, chatStarted } = this.state;
    let name = '';
    if (openedChat.groupId) {
      chatStarted.forEach(chat => {
        if (chat.group) {
          if (issuer === chat.coach._id) {
            name = chat.coach.name;
          }
          chat.group.coachees.forEach(coachee => {
            if (coachee._id === issuer) {
              name = coachee.name;
            }
          });
        }
      });
    }
    return name;
  }

  getArrayMembers() {
    const { user, chatStarted, openedChat } = this.state;
    const chat = chatStarted.find(
      item => item.group._id === openedChat.groupId
    );
    const coachess = chat
      ? chat.group.coachees.filter(item => item._id !== user._id)
      : [];
    return coachess;
  }

  render() {
    return (
      <div className="coachee-mensagens main-content">
        <Header
          goTo={this.goTo}
          sideOpen={this.state.sideOpen}
          toggleMenu={this.toggleMenu}
        />
        <div className="content-climb user bodyClimby">
          <div
            className={this.props.menu ? 'content-menu closed' : 'content-menu'}
          >
            <Sidebar
              goTo={this.goTo}
              side={this.state.side}
              setSidebarLinks={this.setSidebarLinks}
            />
          </div>
          <div
            className={this.props.menu ? 'content-dash closed' : 'content-dash'}
          >
            <PrincipalMenu
              principalOpen={this.state.principalOpen}
              view={this.state.view}
              setSidebarLinks={this.setSidebarLinks}
              togglePrincipal={this.togglePrincipal}
            />

            <div className="dash-content dash-fixed">
              <div className="dash-crumb-filter">
                <Breadcrumbs breadcrumbs={this.state.breadcrumbs} />
              </div>
              <div className="dashboard-boxes">
                <Col xs={{ size: 12 }} sm={{ size: 12 }} md={{ size: 3 }}>
                  <div className="dashboard-box box100 bm0 fbm0">
                    <ul className="list-messages">
                      {this.state.chatStarted.map((item, key) => {
                        const itemInfo = {
                          lastMessageDate: item.unreaded.lastMessageDate,
                          total: item.unreaded.total,
                        };
                        if (item.group._id) {
                          itemInfo._id = item.group._id;
                          itemInfo.image = item.group.image
                            ? this.resolveImage(item.group.image)
                            : groupImage;
                          itemInfo.name = item.group.name;
                        } else if (item.coach._id && item.coachee._id) {
                          itemInfo._id = item.coachee._id;
                          itemInfo.image = item.coach.image
                            ? this.resolveImage(item.coach.image)
                            : defaultImage;
                          itemInfo.name = item.coach.name;
                          itemInfo.surname = item.coach.surname
                            ? item.coach.surname
                            : '';
                        } else if (item.private_coachee) {
                          itemInfo._id = item.private_coachee._id;
                          itemInfo.image = item.private_coachee.image
                            ? this.resolveImage(item.private_coachee.image)
                            : defaultImage;
                          itemInfo.name = item.private_coachee.name;
                          itemInfo.surname = item.private_coachee.surname
                            ? item.private_coachee.surname
                            : '';
                        }
                        return (
                          <li
                            key={key}
                            onClick={() => this.openChat(item)}
                            className={
                              itemInfo.name === this.state.openedChat.name
                                ? 'active'
                                : ''
                            }
                          >
                            <div className="list-message-content">
                              {!itemInfo.image.includes('image/png') ? (
                                <div
                                  className="message-user-icon"
                                  style={{
                                    background: `url(${itemInfo.image}) no-repeat center center / cover`,
                                    width: 35,
                                    height: 35,
                                  }}
                                ></div>
                              ) : (
                                <Avatar
                                  name={this.utils.resolveCompleteName(
                                    itemInfo.name,
                                    itemInfo.surname
                                  )}
                                  size="35"
                                  round={true}
                                  color={Avatar.getRandomColor(
                                    this.utils.resolveCompleteName(
                                      itemInfo.name,
                                      itemInfo.surname
                                    ),
                                    [
                                      '#a6ce39',
                                      '#2c8440',
                                      '#303440',
                                      '#a4cc3c',
                                      '#043448',
                                    ]
                                  )}
                                  fgColor="#FFFFFF"
                                  maxInitials={2}
                                />
                              )}
                              <p className="message-user-name">
                                {itemInfo.name}
                              </p>
                            </div>
                            <div className="list-message-content">
                              {itemInfo.lastMessageDate ? (
                                <div className="last-message">
                                  {this.parseDate(itemInfo.lastMessageDate)}
                                </div>
                              ) : null}
                              {itemInfo.total ? (
                                <span className="last-count">
                                  {' '}
                                  {itemInfo.total}{' '}
                                </span>
                              ) : null}
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </Col>
                <Col
                  className="p5"
                  xs={{ size: 12 }}
                  sm={{ size: 12 }}
                  md={{ size: 9 }}
                >
                  <div
                    className="dashboard-box box100 bm0"
                    style={{
                      background: this.state.openedChat ? '#fff' : '#f2f2f2',
                      paddingBottom: 0,
                    }}
                  >
                    {this.state.openedChat ? (
                      <div className="message-header">
                        <div className="list-message-content">
                          {!this.state.openedChat.image.includes(
                            'image/png'
                          ) ? (
                            <div
                              className="message-user-icon"
                              style={{
                                background: `url(${this.state.openedChat.image}) no-repeat center center / cover`,
                              }}
                            ></div>
                          ) : (
                            <Avatar
                              name={this.utils.resolveCompleteName(
                                this.state.openedChat.name,
                                this.state.openedChat.surname
                              )}
                              size="50"
                              round={true}
                              color={Avatar.getRandomColor(
                                this.utils.resolveCompleteName(
                                  this.state.openedChat.name,
                                  this.state.openedChat.surname
                                ),
                                [
                                  '#a6ce39',
                                  '#2c8440',
                                  '#303440',
                                  '#a4cc3c',
                                  '#043448',
                                ]
                              )}
                              fgColor="#FFFFFF"
                              maxInitials={2}
                            />
                          )}
                          <p className="message-user-name">
                            {' '}
                            {this.state.openedChat.name}{' '}
                          </p>
                          {this.state.openedChat.groupId && (
                            <div className="form-input select-chat-members">
                              <label className="blue-label">Membros</label>
                              <select
                                className="blue-input margin-bottom-unset"
                                onChange={e => this.newChat(e.target.value)}
                              >
                                <option value=""> &nbsp; Coachee</option>
                                {this.getArrayMembers().map((item, key) => {
                                  return (
                                    <option key={key} value={item._id}>
                                      {' '}
                                      &nbsp; {item.name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          )}
                        </div>
                      </div>
                    ) : null}
                    <div className="content-message">
                      {this.state.openedMessages.map((item, key) => {
                        const iAmIssuer = this.state.user._id === item.issuer;
                        return (
                          <Col
                            key={key}
                            md={{ size: 12 }}
                            lg={{
                              size: 10,
                              offset: iAmIssuer ? 2 : 0,
                            }}
                            className={iAmIssuer ? 'row-baloon-sent' : ''}
                          >
                            <div
                              key={key}
                              className={
                                iAmIssuer
                                  ? 'message-baloon baloon-sent'
                                  : 'message-baloon baloon-received'
                              }
                            >
                              <div> {this.getNameIssuer(item.issuer)} </div>
                              <div> {item.text} </div>
                              <span className="baloon-datetime">
                                {moment(item.created_at).format(
                                  'DD/MM/YYYY - HH:mm'
                                )}
                              </span>
                            </div>
                          </Col>
                        );
                      })}
                      <div
                        style={{ float: 'left', clear: 'both' }}
                        ref={e => {
                          this.messagesContainer = e;
                        }}
                      ></div>
                    </div>
                    {this.state.openedChat ? (
                      <div className="content-write-message">
                        <input
                          className="message-input"
                          style={{ color: '#333131' }}
                          value={this.state.textMessage}
                          onChange={e =>
                            this.setState({ textMessage: e.target.value })
                          }
                          onKeyPress={e => {
                            if (e.key === 'Enter') {
                              this.sentMessage();
                            }
                          }}
                          type="text"
                        />
                        <i
                          className="fas fa-paper-plane icon-sent"
                          onClick={() => this.sentMessage()}
                        ></i>
                      </div>
                    ) : null}
                  </div>
                </Col>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  menu: state.simpleReducer.menu,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateImage }, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Messages)
);
