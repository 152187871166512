import React, { Component } from 'react';
import './checkbox.css';

class CustomCheckbox extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { checked, onChange, label } = this.props;

    return (
      <label className="custom-checkbox-container">
        <input
          className="custom-input-checkbox"
          checked={checked}
          type="checkbox"
          onChange={onChange}
        />
        <span className="custom-label-checkbox">{label}</span>
        <span className="custom-checkmark" />
      </label>
    );
  }
}

export default CustomCheckbox;
