import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import '../../../styles/css/style.css';
import '../../../styles/css/dashboard.css';
import { Col } from 'reactstrap';
import { updateImage, updateAlert } from '../../../actions/SimpleAction';
import Sidebar from './../../sidebar/Sidebar';
import ReactLoading from 'react-loading';
import Header from './../../components/Header';
import PrincipalMenu from './../../components/PrincipalMenu';
import Breadcrumbs from './../../components/Breadcrumbs';
import Footer from './../../components/Footer';
import UploadFile from './UploadFile';
import Service from './../Service.js';
import Utils from './../../../libs/Utils.js';

class CoacheeCreate extends Component {
  constructor(props, context) {
    super(props);

    const initialWidth = window.innerWidth > 0 ? window.innerWidth : 500;

    this.state = {
      isVisible: false,
      sideOpen: false,
      principalOpen: true,
      view: '',
      side: 'my',
      breadcrumbs: [
        { title: 'Dashboard', link: '' },
        { title: 'Conteúdo', link: 'contents' },
        { title: 'Minha Área', link: 'contents' },
        { title: 'Upload de Conteúdo', link: 'contents/create' },
      ],
      showToolTip: false,
      windowWidth: initialWidth - (window.innerWidth * 40) / 100,
      loading_category: false,
      loading: false,
      categories: [],
      islink: false,
      tag: '',
      title: '',
      link: 'https://',
      source: '',
      doc_source: '',
      doc_format: '',
      html: '',
      original_name: '',

      maxLength: 3660,
      editing: false,
      editing_id: '',
      notConvert: false,

      statusSave: false,
    };

    this.utils = new Utils();
    this.service = new Service();
    this.handleResize = this.handleResize.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.togglePrincipal = this.togglePrincipal.bind(this);
    this.setSidebarLinks = this.setSidebarLinks.bind(this);
    this.goTo = this.goTo.bind(this);
    this.selectCategory = this.selectCategory.bind(this);
    this.save = this.save.bind(this);
    this.clearForm = this.clearForm.bind(this);
    this.openUpload = this.openUpload.bind(this);
    this.removeAttachment = this.removeAttachment.bind(this);
    this.showUpload = this.showUpload.bind(this);
  }

  toggleMenu() {
    if (this.state.sideOpen) {
      this.setState({ sideOpen: false });
    } else {
      this.setState({ sideOpen: true });
    }
  }

  togglePrincipal() {
    if (this.state.principalOpen) {
      this.setState({ principalOpen: false });
    } else {
      this.setState({ principalOpen: true });
    }
  }

  goTo(page) {
    this.props.history.push(`/${page}`);
  }

  verifyLogin() {
    if (!this.utils.isLogged()) {
      this.goTo('');
    }
  }

  componentDidMount() {
    this.verifyLogin();
    this.loadUser();

    this.resolveParams();

    this.loadCategory();
    window.addEventListener('resize', this.handleResize);
  }

  resolveParams() {
    const {
      match: { params },
    } = this.props;
    if (params.id) {
      let editing_id = params.id;
      this.setState({
        editing: true,
        editing_id,
      });
      this.load(editing_id);
    }
  }

  async load(_id) {
    this.setState({ loading: true });
    let result = await this.service.content(_id);
    if (result && result.success) {
      this.setState({
        title: result.content.title,
        category: result.content.category,
        tag: result.content.tag,
        personal: result.content.personal,
        original_name: result.content.original_name,
        doc_source:
          result.content.doc_format == 'LINK' ? '' : result.content.doc_source,
        doc_format: result.content.doc_format,
        status: result.content.status != 'false',
        link:
          result.content.doc_format != 'LINK' ? '' : result.content.doc_source,
        islink: result.content.doc_format == 'LINK',
      });
    }
    this.setState({ loading: false });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  setSidebarLinks(view) {
    this.setState({ view });
    this.goTo(`dashboard/${view}`);
  }

  handleResize() {
    let _ww =
      window.innerWidth < 890 && window.innerWidth > 570
        ? window.innerWidth - (window.innerWidth * 30) / 100
        : window.innerWidth <= 570
        ? window.innerWidth - (window.innerWidth * 40) / 100
        : window.innerWidth - (window.innerWidth * 40) / 100;

    this.setState({
      windowWidth: _ww,
    });
  }

  logout() {
    this.utils.setLogout();
    this.goTo('');
  }

  async loadUser() {
    let result = await this.service.me();
    if (!result) {
      this.logout();
      return;
    }
    let user = {
      ...result.user,
    };
    if (user.image) {
      this.props.updateImage(user.image);
    }
    this.setState({ user });
  }

  selectCategory(category) {
    this.setState({ category });
  }

  formValid() {
    if (
      this.state.islink &&
      this.state.link !== '' &&
      this.state.link !== 'https://' &&
      this.isValidUrl(this.state.link)
    ) {
      return true;
    }

    if (
      this.state.doc_source !== '' &&
      this.state.doc_format !== '' &&
      this.state.original_name !== ''
    ) {
      return true;
    }
    return false;
  }

  isValidUrl(string) {
    try {
      new URL(string);
    } catch (_) {
      return false;
    }
    return true;
  }

  async save() {
    if (!this.formValid()) {
      alert(
        'Por favor selecione um arquivo para upload ou informe um link válido.'
      );
      return;
    }
    if (
      !this.state.editing &&
      this.state.user &&
      !this.state.user.limits.storage.canUpload
    ) {
      this.props.updateAlert(
        <div>
          <p>
            Limite de upload do plano atingido, aumente seu plano ou apague
            conteúdos para continuar
          </p>
          <br />{' '}
          <div
            className="bt-blue"
            style={{ margin: '0 auto' }}
            onClick={() => {
              this.goTo('dashboard/profile/plans');
            }}
          >
            Alterar plano
          </div>
        </div>
      );
    } else {
      this.setState({ loading: true });
      if (this.state.islink) {
        this.saveContent();
      } else {
        this.setState(() => ({
          statusSave: true,
        }));
      }
    }
  }

  saveContent = async () => {
    let _pay = {
      title: this.state.title !== '' ? this.state.title : 'Título',
      category:
        this.state.category === undefined ? 'Ferramentas' : this.state.category,
      original_name: this.state.original_name,
      tag: this.state.tag !== '' ? this.state.tag : 'Modelo 1',
      personal: true,
      doc_source: this.state.islink ? this.state.link : this.state.doc_source,
      doc_format: this.state.islink ? 'LINK' : this.state.doc_format,
      html: this.state.html,
      status: true,
    };

    let result;
    if (this.state.editing) {
      result = await this.service.updateContent(_pay, this.state.editing_id);
    } else {
      result = await this.service.saveContent(_pay);
    }
    if (result && result.success) {
      this.goTo('dashboard/contents');
      this.clearForm();
    }
    this.setState({ loading: false });
  };

  errorContent = async () => {
    this.setState({ loading: false });
    this.setState(() => ({
      statusSave: false,
    }));
    this.removeAttachment();
  };

  clearForm() {
    this.setState({
      tag: '',
      title: '',
      doc_source: '',
      doc_format: '',
      link: '',
      islink: false,
    });
  }

  async loadCategory() {
    this.setState({ loading_category: true });
    let result = await this.service.categories();
    if (result && result.success) {
      this.setState({ categories: result.categories });
    }
    this.setState({ loading_category: false });
  }

  resolveFile(path) {
    return this.utils.resolveURL(path, this.service.getServer());
  }

  openUpload() {
    document.getElementById('filefile').click();
  }

  removeAttachment() {
    this.setState({ doc_source: '', doc_format: '' });
  }

  showUpload() {
    if (this.state.doc_source.length == 0) {
      return true;
    }
    return false;
  }

  showMessageConvert() {
    let fileExtension = this.state.doc_format.split('.');
    fileExtension = fileExtension[fileExtension.length - 1];
    if (fileExtension === 'docx' && !this.state.notConvert) {
      return (
        <div>
          <label className="blue-label">Aguarde convertendo arquivo...</label>
        </div>
      );
    }
  }

  render() {
    return (
      <div className="main-content">
        <Header
          goTo={this.goTo}
          sideOpen={this.state.sideOpen}
          toggleMenu={this.toggleMenu}
        />
        <div className="content-climb user bodyClimby">
          <div
            className={this.props.menu ? 'content-menu closed' : 'content-menu'}
          >
            <Sidebar
              side={this.state.side}
              setSidebarLinks={this.setSidebarLinks}
              expanded={!this.state.sideOpen}
            />
          </div>
          <div
            className={this.props.menu ? 'content-dash closed' : 'content-dash'}
          >
            {/* <PrincipalMenu
              principalOpen={this.state.principalOpen}
              view={this.state.view}
              setSidebarLinks={this.setSidebarLinks}
              togglePrincipal={this.togglePrincipal}
            /> */}

            <div className="dash-content">
              <div className="dash-crumb-filter">
                <Breadcrumbs breadcrumbs={this.state.breadcrumbs} />
              </div>
              <div className="dashboard-boxes">
                <div className="dashboard-box box100">
                  <Col xs={{ size: 12 }} md={{ size: 6, offset: 3 }}>
                    <div className="box-row-title row-center">
                      <div className="box-title">Upload de Conteúdo</div>
                    </div>

                    <div className="form-input">
                      <label className="blue-label">Título</label>
                      <input
                        type="text"
                        value={this.state.title}
                        className="blue-input"
                        placeholder="Título"
                        onChange={e => {
                          this.setState({ title: e.target.value });
                        }}
                      />
                    </div>

                    <label className="container-ckb ">
                      <input
                        type="checkbox"
                        checked={this.state.islink}
                        onChange={ev => {
                          this.setState({ islink: !this.state.islink });
                        }}
                      />
                      <span className="chklbl">É um link</span>
                      <span className="checkmark"></span>
                    </label>

                    <label
                      className="container-ckb"
                      style={{ paddingBottom: 30 }}
                    >
                      <input
                        type="checkbox"
                        checked={this.state.notConvert}
                        onChange={ev => {
                          this.setState({ notConvert: !this.state.notConvert });
                        }}
                      />
                      <div className="container-pop">
                        <span className="chklbl">Não converter</span>
                        <div className="popover-container">
                          <div className="popover-content">
                            Ao clicar neste Box seu arquivo será carregado em
                            seu formato original sem converter para edicao.
                          </div>
                        </div>
                      </div>
                      <span className="checkmark"></span>
                    </label>

                    {this.state.islink ? (
                      <div className="form-input">
                        <label className="blue-label">Link</label>
                        <input
                          type="text"
                          value={this.state.link}
                          className="blue-input"
                          placeholder="Link"
                          onChange={e => {
                            this.setState({ link: e.target.value });
                          }}
                        />
                      </div>
                    ) : (
                      <UploadFile
                        notConvert={this.state.notConvert}
                        setState={this.setState.bind(this)}
                        doc_format={this.state.doc_format}
                        doc_source={this.state.doc_source}
                        state={this.state}
                        saveContent={this.saveContent}
                        errorContent={this.errorContent}
                      />
                    )}

                    <div className="form-input">
                      {this.state.loading_category ? (
                        <ReactLoading type="bubbles" color="#B1CD49" />
                      ) : (
                        <div className="form-input">
                          <label className="blue-label">Categoria</label>
                          <select
                            value={this.state.category}
                            className="blue-input"
                            onChange={e => {
                              this.selectCategory(e.target.value);
                            }}
                          >
                            {/* <option value=""> &nbsp; Categoria</option> */}
                            {this.state.categories.map((item, key) => {
                              return (
                                <option key={key} value={item.name}>
                                  {' '}
                                  &nbsp; {item.name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      )}
                    </div>

                    <div className="form-input">
                      <label className="blue-label">Modelo</label>
                      <input
                        type="text"
                        value={this.state.tag}
                        className="blue-input"
                        placeholder="Modelo"
                        onChange={e => {
                          this.setState({ tag: e.target.value });
                        }}
                      />
                    </div>

                    <div className="row-between mtop-50 mbottom-50">
                      <div
                        className="bt-blue-outline"
                        onClick={() => {
                          this.goTo('dashboard/contents');
                        }}
                      >
                        Cancelar
                      </div>
                      {this.state.loading ? (
                        <>
                          <ReactLoading type="bubbles" color="#00838F" />
                          {this.showMessageConvert()}
                        </>
                      ) : (
                        <div
                          className="bt-blue"
                          onClick={() => {
                            this.save();
                          }}
                        >
                          Enviar
                        </div>
                      )}
                    </div>
                  </Col>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  menu: state.simpleReducer.menu,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateImage, updateAlert }, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CoacheeCreate)
);
