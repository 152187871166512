import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import '../../../../styles/css/style.css';
import '../../../../styles/css/dashboard.css';

import { Col } from 'reactstrap';

import {
  updateImage,
  updateAlert,
  updateSession,
} from '../../../../actions/SimpleAction';

import Sidebar from './../../../sidebar/Sidebar';

import Wheel from '../../../../components/Wheel';
import Header from './../../../components/Header';
import PrincipalMenu from './../../../components/PrincipalMenu';
import Breadcrumbs from './../../../components/Breadcrumbs';

import Footer from './../../../components/Footer';

import Service from './../../Service.js';
import Utils from './../../../../libs/Utils.js';

class WheelPreview extends Component {
  constructor(props, context) {
    super(props);
    const initialWidth = window.innerWidth > 0 ? window.innerWidth : 500;

    this.state = {
      isVisible: false,
      sideOpen: false,
      principalOpen: true,
      view: 'contents',
      side: 'my',
      breadcrumbs: [
        { title: 'Dashboard', link: '' },
        { title: 'Conteúdos', link: 'contents' },
        { title: 'Visualizador de rodas', link: 'wheel-preview' },
      ],
      showToolTip: false,
      windowWidth: initialWidth - (window.innerWidth * 40) / 100,
      loading: false,

      title: '',
      description: '',
      areas: [],
      maximumScore: 10,
      comment: '',

      sessionId: '',
      meetingType: '',
      contentId: '',

      climbyContent: false,
    };

    this.utils = new Utils();
    this.service = new Service();
    this.handleResize = this.handleResize.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.togglePrincipal = this.togglePrincipal.bind(this);
    this.setSidebarLinks = this.setSidebarLinks.bind(this);
    this.goTo = this.goTo.bind(this);
  }

  toggleMenu() {
    const { sideOpen } = this.state;
    this.setState({ sideOpen: !sideOpen });
  }

  togglePrincipal() {
    const { principalOpen } = this.state;
    this.setState({ principalOpen: !principalOpen });
  }

  goTo(page) {
    this.props.history.push(`/${page}`);
  }

  verifyLogin() {
    if (!this.utils.isLogged()) {
      this.goTo('');
    }
  }

  componentDidMount() {
    this.verifyLogin();
    this.loadUser();
    this.resolveParams();
    window.addEventListener('resize', this.handleResize);
  }

  resolveParams() {
    const {
      match: { params },
      location,
    } = this.props;
    const climbyContent = location.state && location.state.climbyContent;
    if (params.id) {
      const contentId = params.id;
      this.setState(
        {
          contentId,
          climbyContent,
          side: climbyContent ? 'climb' : 'my',
        },
        () => this.load(contentId)
      );
    }
  }

  async load(_id) {
    const { climbyContent } = this.state;
    this.setState({ loading: true });
    let result = climbyContent
      ? await this.service.contentsClimbOne(_id)
      : await this.service.content(_id);

    if (result && result.success) {
      if (result.content) {
        this.setState({
          title: result.content.title,
          description: result.content.data.description,
          areas: result.content.data.areas,
          maximumScore: result.content.data.maximumScore,
          comment: result.content.data.comment,
        });
      }
    }
    this.setState({ loading: false });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  setSidebarLinks(view) {
    this.setState({ view });
    this.goTo(`dashboard/${view}`);
  }

  handleResize() {
    let _ww =
      window.innerWidth < 890 && window.innerWidth > 570
        ? window.innerWidth - (window.innerWidth * 30) / 100
        : window.innerWidth <= 570
        ? window.innerWidth - (window.innerWidth * 40) / 100
        : window.innerWidth - (window.innerWidth * 40) / 100;

    this.setState({
      windowWidth: _ww,
    });
  }

  logout() {
    this.utils.setLogout();
    this.goTo('');
  }

  async loadUser() {
    let result = await this.service.me();
    if (!result) {
      this.logout();
      return;
    }
    let user = {
      ...result.user,
    };
    if (user.image) {
      this.props.updateImage(user.image);
    }
  }

  render() {
    const {
      sideOpen,
      side,
      principalOpen,
      view,
      breadcrumbs,
      title,
      description,
      areas,
      maximumScore,
      comment,
    } = this.state;
    return (
      <div className="main-content">
        <Header
          goTo={this.goTo}
          sideOpen={sideOpen}
          toggleMenu={this.toggleMenu}
        />
        <div className="content-climb user bodyClimby">
          <div
            className={this.props.menu ? 'content-menu closed' : 'content-menu'}
          >
            <Sidebar
              side={this.state.side}
              setSidebarLinks={this.setSidebarLinks}
              expanded={!this.state.sideOpen}
            />
          </div>
          <div
            className={this.props.menu ? 'content-dash closed' : 'content-dash'}
          >
            <div className="dash-content">
              <div className="dash-crumb-filter">
                <Breadcrumbs breadcrumbs={breadcrumbs} />
              </div>
              <div className="dashboard-boxes">
                <div
                  className="dashboard-box box100"
                  style={{ paddingBottom: 30 }}
                >
                  <Col xs={{ size: 12 }} md={{ size: 6, offset: 3 }}>
                    <div className="box-row-title row-center">
                      <div className="box-title">Visualizador de roda</div>
                    </div>

                    <div className="form-input">
                      <label className="blue-label">Título</label>
                      <input
                        type="text"
                        value={title}
                        className="blue-input"
                        placeholder="Título"
                        readOnly={true}
                      />
                    </div>
                    <div
                      className="form-textarea clear-textarea"
                      style={{ maxWidth: 'none', paddingLeft: '0px' }}
                    >
                      <label className="blue-label">Descrição</label>
                      <textarea
                        value={description}
                        className="blue-input"
                        placeholder="Descrição"
                        readOnly={true}
                      ></textarea>
                    </div>
                    <Wheel areas={areas} maxScore={maximumScore} />
                    <div
                      className="form-textarea clear-textarea"
                      style={{ maxWidth: 'none', paddingLeft: '0px' }}
                    >
                      <label className="blue-label">Comentário</label>
                      <textarea
                        value={comment}
                        className="blue-input"
                        placeholder="Deixe seu comentário sobre sua interpretação dos dados. Por exemplo, porque você identificou com maior ou menor valor determinada área? Qual a área que causaria maior impacto no seu objetivo se fosse melhorada? Por que?"
                        readOnly={true}
                      ></textarea>
                    </div>
                  </Col>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  menu: state.simpleReducer.menu,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateImage, updateAlert, updateSession }, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(WheelPreview)
);
