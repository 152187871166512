import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import '../../../../styles/css/style.css';
import '../../../../styles/css/dashboard.css';

import { Col } from 'reactstrap';

import { updateImage, updateAlert } from '../../../../actions/SimpleAction';

import Sidebar from './../../../sidebar/Sidebar';
import Submenu from '../../Session/SubmenuSession';

import ReactLoading from 'react-loading';

import Wheel from '../../../../components/Wheel';
import Header from './../../../components/Header';
import Breadcrumbs from './../../../components/Breadcrumbs';

import Footer from './../../../components/Footer';

import Service from './../../Service.js';
import Utils from './../../../../libs/Utils.js';

import './styles.css';

class WheelEditor extends Component {
  constructor(props, context) {
    super(props);
    const initialWidth = window.innerWidth > 0 ? window.innerWidth : 500;

    this.state = {
      isVisible: false,
      sideOpen: false,
      principalOpen: true,
      view: 'programs',
      subview: 'contents',
      side: 'assessments',
      breadcrumbs: [
        { title: 'Dashboard', link: '' },
        { title: 'Conteúdos', link: 'contents' },
        { title: 'Editor de rodas', link: 'wheel-editor' },
      ],
      showToolTip: false,
      windowWidth: initialWidth - (window.innerWidth * 40) / 100,
      loading: false,

      title: '',
      description: '',
      areas: [],
      maximumScore: 10,
      comment: '',

      sessionId: '',
      meetingType: '',
      contentId: '',
    };

    this.utils = new Utils();
    this.service = new Service();
    this.handleResize = this.handleResize.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.togglePrincipal = this.togglePrincipal.bind(this);
    this.setSidebarLinks = this.setSidebarLinks.bind(this);
    this.goTo = this.goTo.bind(this);
  }

  toggleMenu() {
    const { sideOpen } = this.state;
    this.setState({ sideOpen: !sideOpen });
  }

  togglePrincipal() {
    const { principalOpen } = this.state;
    this.setState({ principalOpen: !principalOpen });
  }

  goTo(page) {
    this.props.history.push(`/${page}`);
  }

  verifyLogin() {
    if (!this.utils.isLogged()) {
      this.goTo('');
    }
  }

  componentDidMount() {
    this.verifyLogin();
    this.loadUser();
    this.resolveParams();
    window.addEventListener('resize', this.handleResize);
  }

  resolveParams() {
    const {
      match: { params },
    } = this.props;
    const { sessionId, meetingType, contentId } = params;

    if (sessionId && meetingType && contentId) {
      this.loadContent(sessionId, meetingType, contentId);
    } else {
      window.history.back();
    }
  }

  async loadContent(sessionId, meetingType, contentId) {
    this.setState({ loading: true });
    const result = await this.service.sessionContent(
      sessionId,
      meetingType,
      contentId
    );
    if (result && result.success) {
      const { description, areas, maximumScore, comment } = result.content.data;
      this.setState({
        sessionId,
        meetingType,
        contentId,

        title: result.content.title || '',
        description,
        areas,
        maximumScore,
        comment,
      });
    }
    this.setState({ loading: false });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  setSidebarLinks(view) {
    this.setState({ view });
    this.goTo(`users/dashboard/${view}`);
  }

  handleResize() {
    let _ww =
      window.innerWidth < 890 && window.innerWidth > 570
        ? window.innerWidth - (window.innerWidth * 30) / 100
        : window.innerWidth <= 570
        ? window.innerWidth - (window.innerWidth * 40) / 100
        : window.innerWidth - (window.innerWidth * 40) / 100;

    this.setState({
      windowWidth: _ww,
    });
  }

  logout() {
    this.utils.setLogout();
    this.goTo('');
  }

  async loadUser() {
    let result = await this.service.meCoachee();
    if (!result) {
      this.logout();
      return;
    }
    let user = {
      ...result.user,
    };

    if (user.image) {
      this.props.updateImage(user.image);
    }
  }

  save = async () => {
    const {
      sessionId,
      meetingType,
      contentId,

      title,
      description,
      maximumScore,
      comment,
      areas,
    } = this.state;
    if (!sessionId) return;

    this.setState({ loading: true });
    const result = await this.service.updateSessionContent(
      sessionId,
      meetingType,
      contentId,
      {
        title,
        data: {
          description,
          areas,
          maximumScore,
          comment,
        },
      }
    );

    if (result && result.success) {
      const { description, areas, maximumScore, comment } = result.content.data;

      this.setState({
        title: result.content.title || '',
        description,
        areas,
        maximumScore,
        comment,
      });
      this.props.updateAlert('Salvo com sucesso');
    }

    this.setState({ loading: false });
  };

  render() {
    const {
      sideOpen,
      side,
      principalOpen,
      view,
      breadcrumbs,
      subview,
      title,
      description,
      areas,
      maximumScore,
      comment,
      loading,
    } = this.state;
    return (
      <div className="main-content">
        <Header
          goTo={this.goTo}
          sideOpen={sideOpen}
          toggleMenu={this.toggleMenu}
        />
        <div className="content-climb user bodyClimby">
          <div
            className={this.props.menu ? 'content-menu closed' : 'content-menu'}
          >
            <Sidebar
              side={this.state.side}
              setSidebarLinks={this.setSidebarLinks}
              expanded={!this.state.sideOpen}
            />
          </div>
          <div
            className={this.props.menu ? 'content-dash closed' : 'content-dash'}
          >
            <div className="dash-content">
              <div className="dash-crumb-filter">
                <Breadcrumbs breadcrumbs={breadcrumbs} />
              </div>
              <div className="dashboard-boxes">
                <div className="dashboard-box box100-menu">
                  <Col xs={{ size: 12 }} md={{ size: 6, offset: 3 }}>
                    <div className="box-row-title row-center">
                      <div className="box-title">Editor de roda</div>
                    </div>

                    <div className="form-input">
                      <label className="blue-label">Título</label>
                      <input
                        type="text"
                        value={title}
                        className="blue-input"
                        placeholder="Título"
                        readOnly={true}
                      />
                    </div>
                    <div
                      className="form-textarea clear-textarea"
                      style={{ maxWidth: 'none', paddingLeft: '0px' }}
                    >
                      <label className="blue-label">Descrição</label>
                      <textarea
                        value={description}
                        className="blue-input"
                        placeholder="Descrição"
                        readOnly={true}
                      ></textarea>
                    </div>
                    <span className="wheel-info">
                      Clique e arraste para avaliar cada atributo na roda
                    </span>
                    <Wheel
                      areas={areas}
                      maxScore={maximumScore}
                      onChange={values => this.setState({ areas: values })}
                    />
                    <div
                      className="form-textarea clear-textarea"
                      style={{ maxWidth: 'none', paddingLeft: '0px' }}
                    >
                      <label className="blue-label">Comentário</label>
                      <textarea
                        value={comment}
                        className="blue-input"
                        placeholder="Deixe seu comentário sobre sua interpretação dos dados. Por exemplo, porque você identificou com maior ou menor valor determinada área? Qual a área que causaria maior impacto no seu objetivo se fosse melhorada? Por que?"
                        onChange={e =>
                          this.setState({
                            comment: e.target.value,
                          })
                        }
                      ></textarea>
                    </div>
                  </Col>
                  <Col xs={{ size: 12 }} md={{ size: 10, offset: 1 }}>
                    <div className="row-between mtop-50 mbottom-50">
                      {loading ? (
                        <ReactLoading type="bubbles" color="#00838F" />
                      ) : (
                        <div
                          className="bt-blue"
                          onClick={() => {
                            this.save();
                          }}
                        >
                          Salvar
                        </div>
                      )}
                    </div>
                  </Col>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  menu: state.simpleReducer.menu,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateImage, updateAlert }, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(WheelEditor)
);
