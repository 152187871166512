import React, { Component } from 'react';
import { connect } from 'react-redux';

import '../../styles/css/dashboard.css';
import '../../styles/css/style.css';

import { simpleAction } from '../../actions/SimpleAction';

import { Icon } from 'rsuite';
import Utils from './../../libs/Utils.js';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.utils = new Utils();
  }

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    const {
      areas = 0,
      completeActions = 0,
      completeAdequation = 0,
      sessions = 0,
      stakeHolders = 0,
    } = this.props.stats;

    return (
      <div className="dash-stats-content">
        <div className="dash-stats">
          <div className="stats-title">Sessões realizadas</div>
          <div className="stats-info">
            <div>{sessions}</div>
            <Icon
              style={{
                fontSize: 30,
                color: '#003343',
              }}
              icon={'detail'}
            />
          </div>
        </div>

        <div className="dash-stats">
          <div className="stats-title">Membros Envolvidos</div>
          <div className="stats-info">
            <div>{stakeHolders}</div>
            <Icon
              style={{
                fontSize: 30,
                color: '#003343',
              }}
              icon={'peoples'}
            />
          </div>
        </div>

        <div className="dash-stats">
          <div className="stats-title">Área envolvidas</div>
          <div className="stats-info">
            <div>{areas}</div>
            <Icon
              style={{
                fontSize: 30,
                color: '#003343',
              }}
              icon={'database'}
            />
          </div>
        </div>

        <div className="dash-stats">
          <div className="stats-title">% Ações concluídas</div>
          <div className="stats-info">
            <div>{completeActions}%</div>
            <Icon
              style={{
                fontSize: 30,
                color: '#003343',
              }}
              icon={'check-circle'}
            />
          </div>
        </div>

        <div className="dash-stats">
          <div className="stats-title">% Adequação</div>
          <div className="stats-info">
            <div>{completeAdequation}%</div>
            <Icon
              style={{
                fontSize: 30,
                color: '#003343',
              }}
              icon={'realtime'}
            />
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch => ({
  simpleAction: () => dispatch(simpleAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
