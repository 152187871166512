import React, { Component } from 'react';
import { connect } from 'react-redux';
import './../styles/css/header.css';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import logo from '../assets/images/logo.png';

import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Button,
} from 'reactstrap';

import './styles.css';

class Header extends Component {
  constructor(props, context) {
    super(props, context);
    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
    };
  }

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  render() {
    return (
      <div className="header">
        <div className="header-left">
          <img
            src={require('./../assets/images/header/logo.png')}
            alt="climby"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              window.location = process.env.REACT_APP_URL_WORDPRESS;
            }}
          />
          {/* <span className="label-header">CompassDPO</span> */}
        </div>
        <div
          className={!this.state.open ? 'header-right' : 'header-right active'}
        ></div>
      </div>
    );
  }
}

export default connect()(Header);
