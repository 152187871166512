import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import '../../styles/css/style.css';
import '../../styles/css/dashboard.css';

import { updateImage, updateMenu } from '../../actions/SimpleAction';

import Utils from './../../libs/Utils.js';
import Service from './../../services/ApiService.js';

class ProjectsMenu extends Component {
  constructor(props) {
    super(props);
    this.utils = new Utils();
    this.service = new Service();

    this.state = {};
  }

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {}

  componentWillUnmount() {}

  render() {
    return (
      <div className="box-notifications">
        {this.props.projects.map((item, key) => {
          return (
            <div
              key={key}
              className="notification-item"
              style={{ textAlign: 'right', cursor: 'pointer' }}
            >
              <div className="row-notification">
                <div
                  className="text-notification"
                  onClick={() => {
                    this.props.selectProject(this.props.user, item);
                  }}
                >
                  {item.name}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}
const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  menu: state.simpleReducer.menu,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateImage, updateMenu }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsMenu);
