import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import logo from '../../logo.svg';
import '../../styles/css/style.css';
import '../../styles/css/dashboard.css';
import PropTypes from 'prop-types';

import { Container, Row, Col, Nav, NavItem, NavLink } from 'reactstrap';

import { simpleAction, updateImage } from '../../actions/SimpleAction';
// import Topbar from '../topbar/Topbar';

import SidebarProposes from '../sidebar/SidebarProposes';

import Header from './../components/Header';
import PrincipalMenu from './../components/PrincipalMenu';
import Breadcrumbs from './../components/Breadcrumbs';
import PeriodiceFilter from './../components/PeriodiceFilter';

import CardGenericTable from './../../screens_dashboard/components/CardGenericTable';

import Footer from './../components/Footer';

import Service from './Service.js';
import Utils from './../../libs/Utils.js';

import { AreaChart, BarChart } from 'react-easy-chart';

class Proposals extends Component {
  constructor(props, context) {
    super(props);

    const initialWidth = window.innerWidth > 0 ? window.innerWidth : 500;

    this.state = {
      isVisible: false,
      sideOpen: false,
      principalOpen: true,
      view: 'proposals',
      side: 'painel',
      breadcrumbs: [
        { title: 'Dashboard', link: '' },
        { title: 'Propostas', link: 'proposals' },
        { title: 'Contratos em andamento', link: 'proposals/contracts' },
        { title: 'Propostas aceitas', link: 'proposals/contracts/accepted' },
      ],
      showToolTip: false,
      windowWidth: initialWidth - (window.innerWidth * 40) / 100,
      rows3: [],
      title_rows3: [
        { title: 'Data', key: 'date' },
        { title: 'Nome', key: 'name' },
        // { title: "Email", key: 'email' },
        // { title: "Telefone", key: 'phone' },
        { title: 'Descrição', key: 'title' },
        { title: 'Valor', key: 'value' },
        // { title: "Origem", key: 'origin' },
        { title: 'Resposta', key: '__response' },
        { title: 'Ações', key: 'options' },
      ],
      actions3: [
        // { title:"Visualizar", icon:"fas fa-eye", action: this.see.bind(this) },
        {
          title: 'Gerar PDF',
          icon: 'fas fa-file-pdf',
          action: this.generatePDF.bind(this),
        },
        // { title:"Imprimir", icon:"fas fa-print", action: this.print.bind(this) },
      ],
      response_actions2: [
        { action: this.proposalYes.bind(this) },
        { action: this.proposalNo.bind(this) },
      ],
    };

    this.utils = new Utils();
    this.service = new Service();
    this.handleResize = this.handleResize.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.togglePrincipal = this.togglePrincipal.bind(this);
    this.setSidebarLinks = this.setSidebarLinks.bind(this);
    this.goTo = this.goTo.bind(this);
  }

  toggleMenu() {
    if (this.state.sideOpen) {
      this.setState({ sideOpen: false });
    } else {
      this.setState({ sideOpen: true });
    }
  }

  togglePrincipal() {
    if (this.state.principalOpen) {
      this.setState({ principalOpen: false });
    } else {
      this.setState({ principalOpen: true });
    }
  }

  goTo(page) {
    this.props.history.push(`/${page}`);
  }

  verifyLogin() {
    if (!this.utils.isLogged()) {
      this.goTo('');
    }
  }

  componentDidMount() {
    this.verifyLogin();
    this.loadUser();
    this.loadProposals();
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  see(item) {
    this.goTo(`users/dashboard/proposals/proposal/${item._id}`);
  }

  generatePDF(item) {
    window.open(`${this.service.getServer()}pdf/proposals/${item._id}`);
  }

  print(item) {}

  edit(id) {
    this.setSidebarLinks(`proposals/oportunity/register/${id}`);
  }

  editProposal(id) {
    this.setSidebarLinks(`proposals/proposal/register/${id}`);
  }

  setSidebarLinks(view) {
    this.setState({ view });
    this.goTo(`users/dashboard/${view}`);
  }

  handleResize() {
    let _ww =
      window.innerWidth < 890 && window.innerWidth > 570
        ? window.innerWidth - (window.innerWidth * 30) / 100
        : window.innerWidth <= 570
        ? window.innerWidth - (window.innerWidth * 40) / 100
        : window.innerWidth - (window.innerWidth * 40) / 100;

    this.setState({
      windowWidth: _ww,
    });
  }

  logout() {
    this.utils.setLogout();
    this.goTo('');
  }

  async loadUser() {
    let result = await this.service.meCoachee();
    if (!result) {
      this.logout();
      return;
    }
    let user = {
      ...result.user,
    };
    if (user.image) {
      this.props.updateImage(user.image);
    }
    this.setState({ user });
  }

  async loadProposals() {
    let result = await this.service.proposals();
    if (result && result.success) {
      let generated = result.proposals;
      generated = generated.filter(item => {
        return item.status == 'emitted' && item.response == '';
      });
      let owns = result.proposals;
      owns = owns.filter(item => {
        return item.status == 'emitted';
      });
      this.setState({ rows2: generated, rows3: owns });
    }
  }

  async removeOportunity(id) {
    let result = await this.service.removeOportunity(id);
    if (result && result.success) {
      this.loadOportunities();
    }
  }

  async removeProposal(id) {
    let result = await this.service.removeProposal(id);
    if (result && result.success) {
      this.loadProposals();
    }
  }

  async removeSession(item) {
    let result = await this.service.removeSession(item._id);
    if (result && result.success) {
      this.loadSessions();
    }
  }

  async proposalYes(id) {
    let _pay = {
      response: 'yes',
    };
    let result = await this.service.updateProposal(_pay, id);
    if (result && result.success) {
      this.loadProposals();
    }
  }

  async proposalNo(id) {
    let _pay = {
      response: 'no',
    };
    let result = await this.service.updateProposal(_pay, id);
    if (result && result.success) {
      this.loadProposals();
    }
  }

  render() {
    return (
      <div className="main-content">
        <Header
          goTo={this.goTo}
          sideOpen={this.state.sideOpen}
          toggleMenu={this.toggleMenu}
        />
        <div className="content-climb user bodyClimby">
          <div
            className={this.props.menu ? 'content-menu closed' : 'content-menu'}
          >
            <SidebarProposes
              goTo={this.goTo}
              side={this.state.side}
              setSidebarLinks={this.setSidebarLinks}
            />
          </div>
          <div
            className={this.props.menu ? 'content-dash closed' : 'content-dash'}
          >
            <PrincipalMenu
              principalOpen={this.state.principalOpen}
              view={this.state.view}
              setSidebarLinks={this.setSidebarLinks}
              togglePrincipal={this.togglePrincipal}
            />

            <div className="dash-content dash-fixed">
              <div className="dash-crumb-filter">
                <Breadcrumbs breadcrumbs={this.state.breadcrumbs} />
                {/*<PeriodiceFilter filtred={() => {  }} filter={ "Semestral" } />*/}
              </div>

              <div className="dashboard-boxes">
                {/* Other Cards */}
                <CardGenericTable
                  id="coachees-dashboard-proposals-1"
                  title="Propostas aceitas"
                  actions={this.state.actions3}
                  title_rows={this.state.title_rows3}
                  response_actions={this.state.response_actions2}
                  rows={this.state.rows3}
                />
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  menu: state.simpleReducer.menu,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateImage }, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Proposals)
);
