import React, { Component } from 'react';
import { connect } from 'react-redux';
import { simpleAction } from '../../actions/SimpleAction';
import { Dropdown, Icon, Nav, Sidenav, Toggle } from 'rsuite';
// import '../../styles/css/tutorial/styles.scss';
import sideBarItems from './ItemsSidebar';
import '../../styles/css/sideBar.css';
import Utils from '../../libs/Utils';

class Sidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeKey: '1',
      windowWidth: 0,
    };

    this.handleResize = this.handleResize.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  handleSelect(eventKey) {
    if (eventKey === 'logout') {
      const utils = new Utils();
      utils.setLogout();
      window.location.reload();
    } else {
      this.setState({
        activeKey: eventKey,
      });

      this.props.setSidebarLinks(eventKey);
    }
  }

  checkCurrentLink(_menuLink, mainMenu) {
    const { pathname } = window.location;
    if (_menuLink || _menuLink === '') {
      if (mainMenu) {
        const menu = sideBarItems.find(el => el.menuLink === _menuLink);

        console.log(mainMenu, menu.menuName === mainMenu);
      }

      const currentPageLocation = pathname.replace('/dashboard/', '');
      return currentPageLocation === _menuLink;
    }

    return false;
  }

  checkCurrentLinkDropdown(mainMenu) {
    const { pathname } = window.location;

    if (mainMenu) {
      const currentPageLocation = pathname.replace('/dashboard/', '');

      const menu = sideBarItems.find(el => {
        if (el.menuSubItems) {
          return el.menuSubItems.find(
            el => el.menuLink === currentPageLocation
          );
        }
      });

      return menu ? menu.menuName === mainMenu : false;
    }

    return false;
  }

  handleResize() {
    this.setState({
      windowWidth: window.innerWidth,
    });
  }

  componentDidMount() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  render() {
    const { expanded } = this.props;
    const { windowWidth } = this.state;

    return (
      <div className="containerSideMenu">
        <Sidenav
          expanded={windowWidth < 942 ? false : expanded}
          activeKey={this.state.activeKey}
          onSelect={this.handleSelect}
        >
          <Sidenav.Body>
            <Nav>
              {sideBarItems &&
                sideBarItems.length > 0 &&
                sideBarItems.map((el, idx) => {
                  return el.menuSubItems && el.menuSubItems.length > 0 ? (
                    <Dropdown
                      key={idx}
                      className={
                        this.checkCurrentLinkDropdown(el.menuName)
                          ? 'sideBarItemsDropdownActive'
                          : ''
                      }
                      placement="rightStart"
                      eventKey={el.menuLink}
                      title={el.menuName}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                      icon={
                        <Icon
                          className={
                            this.checkCurrentLinkDropdown(el.menuName)
                              ? 'sideBarItemsDropdownActive-icon'
                              : ''
                          }
                          style={{
                            fontSize: 24,
                            color: '#003343',
                            top: '10px',
                          }}
                          icon={el.icon}
                        />
                      }
                    >
                      {el.menuSubItems.map((subMenu, idxSub) => (
                        <Dropdown.Item
                          className={
                            this.checkCurrentLink(subMenu.menuLink)
                              ? 'sideBarItemsActive'
                              : ''
                          }
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                          key={idxSub}
                          eventKey={subMenu.menuLink}
                        >
                          {subMenu.menuName}
                        </Dropdown.Item>
                      ))}
                    </Dropdown>
                  ) : (
                    <Nav.Item
                      className={
                        this.checkCurrentLink(el.menuLink)
                          ? 'sideBarItemsActive'
                          : ''
                      }
                      key={idx}
                      eventKey={el.menuLink}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                      icon={
                        <Icon
                          className={
                            this.checkCurrentLink(el.menuLink)
                              ? 'sideBarItemsActive-icon'
                              : ''
                          }
                          icon={el.icon}
                          style={{
                            fontSize: 24,
                            color: '#003343',
                            top: '10px',
                          }}
                        />
                      }
                    >
                      {el.menuName}
                    </Nav.Item>
                  );
                })}
            </Nav>
          </Sidenav.Body>
        </Sidenav>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch => ({
  simpleAction: () => dispatch(simpleAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
