import React, { Component } from 'react';
import { connect } from 'react-redux';

import '../../../styles/css/style.css';
import '../../../styles/css/profile.css';

import { simpleAction } from '../../../actions/SimpleAction';

import Utils from './../../../libs/Utils.js';

class SubmenuSession extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.utils = new Utils();
  }

  componentDidMount() {}

  componentWillUnmount() {}

  isSessionSelected = () => {
    return !!this.props.sessionId;
  };

  render() {
    return (
      <div className="submenu">
        <ul className="side-menu">
          <li
            className={this.props.subview == 'master' ? 'active' : ''}
            onClick={() => {
              this.props.goTo('dashboard/programs/coaching');
            }}
          >
            <img
              src={require('./../../../assets/images/session/icon-0.svg')}
              width="28"
            />
            <img
              src={require('./../../../assets/images/session/icon-0w.svg')}
              width="28"
              className="image-over"
            />
            <div className="popover-container">
              <div className="triangle-border"></div>
              <div className="popover-content">Sessão Master</div>
            </div>
          </li>
          {this.isSessionSelected() && (
            <>
              <li
                className={this.props.subview == 'planning' ? 'active' : ''}
                onClick={() => {
                  this.props.goTo('dashboard/programs/coaching/planning');
                }}
              >
                <img
                  src={require('./../../../assets/images/session/icon-1.svg')}
                  width="28"
                />
                <img
                  src={require('./../../../assets/images/session/icon-1w.svg')}
                  width="28"
                  className="image-over"
                />
                <div className="popover-container">
                  <div className="triangle-border"></div>
                  <div className="popover-content">Planejamento de Sessões</div>
                </div>
              </li>
              <li
                className={this.props.subview == 'register' ? 'active' : ''}
                onClick={() => {
                  this.props.goTo('dashboard/programs/coaching/register');
                }}
              >
                <img
                  src={require('./../../../assets/images/session/icon-2.svg')}
                  width="28"
                />
                <img
                  src={require('./../../../assets/images/session/icon-2w.svg')}
                  width="28"
                  className="image-over"
                />
                <div className="popover-container">
                  <div className="triangle-border"></div>
                  <div className="popover-content">Sessões de Coaching</div>
                </div>
              </li>
              <li
                className={this.props.subview == 'actions' ? 'active' : ''}
                onClick={() => {
                  this.props.goTo('dashboard/programs/assessments/actions');
                }}
              >
                <img
                  src={require('./../../../assets/images/session/icon-3.svg')}
                  width="28"
                />
                <img
                  src={require('./../../../assets/images/session/icon-3w.svg')}
                  width="28"
                  className="image-over"
                />
                <div className="popover-container">
                  <div className="triangle-border"></div>
                  <div className="popover-content">Lista de Ações</div>
                </div>
              </li>
              <li
                className={this.props.subview == 'contents' ? 'active' : ''}
                onClick={() => {
                  this.props.goTo('dashboard/programs/coaching/contents');
                }}
              >
                <img
                  src={require('./../../../assets/images/session/icon-4.svg')}
                  width="28"
                />
                <img
                  src={require('./../../../assets/images/session/icon-4w.svg')}
                  width="28"
                  className="image-over"
                />
                <div className="popover-container">
                  <div className="triangle-border"></div>
                  <div className="popover-content">
                    Conteúdos Compartilhados
                  </div>
                </div>
              </li>
              <li
                className={this.props.subview == 'relatory' ? 'active' : ''}
                onClick={() => {
                  this.props.goTo('dashboard/programs/coaching/relatory');
                }}
              >
                <img
                  src={require('./../../../assets/images/session/icon-5.svg')}
                  width="28"
                />
                <img
                  src={require('./../../../assets/images/session/icon-5w.svg')}
                  width="28"
                  className="image-over"
                />
                <div className="popover-container">
                  <div className="triangle-border"></div>
                  <div className="popover-content">Relatórios do Programa</div>
                </div>
              </li>
            </>
          )}
        </ul>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  sessionId: state.simpleReducer.sessionId,
});

const mapDispatchToProps = dispatch => ({
  simpleAction: () => dispatch(simpleAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubmenuSession);
