import React, { Component } from 'react';
import ReactLoading from 'react-loading';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { updateAlert, updateImage } from '../../../actions/SimpleAction';
import '../../../styles/css/dashboard.css';
import '../../../styles/css/style.css';
import CardGenericTableWithGroup from '../../components/CardGenericTableWithGroup';
import Utils from './../../../libs/Utils.js';
import Breadcrumbs from './../../components/Breadcrumbs';
import Footer from './../../components/Footer';
import Header from './../../components/Header';
import ModalContentDelete from './../../components/NewModalCoacheeDelete';
import NotAllowed from './../../components/NotAllowed';
import Sidebar from './../../sidebar/Sidebar';
import Service from './../Service.js';
import ContentUploadConfirmation from './ContentUploadConfirmation';
import ModalContentUpload from './ModalContentUpload';
import ModalContents from './ModalContents';

class ContentsRegister extends Component {
  constructor(props, context) {
    super(props);

    const initialWidth = window.innerWidth > 0 ? window.innerWidth : 500;

    this.state = {
      isVisible: false,
      sideOpen: this.props.menu,
      principalOpen: true,
      view: 'programs',
      subview: 'contents',
      side: 'assessments',
      breadcrumbs: [
        { title: 'Dashboard', link: '' },
        { title: 'Programas', link: 'programs' },
        {
          title: 'Registro de conteúdo',
          link: 'programs/assessments/contents',
        },
      ],
      showToolTip: false,
      windowWidth: initialWidth - (window.innerWidth * 40) / 100,
      isLoading: false,
      loading: false,

      loading_coachee: false,
      coachees: [],

      loading_groups: false,
      groups: [],

      loading_contents: false,
      contentList: [],

      rows: [],
      tableTitles: [
        { title: 'Título', key: 'title' },
        { title: 'Formato', key: 'doc_format' },
        { title: 'Categoria', key: 'category' },
        { title: 'Modelo', key: 'tag' },
        { title: 'Opções', key: 'options' },
        { title: 'Concluir', key: '_response_status' },
      ],
      tableActions: [
        {
          title: 'Ver Resposta',
          icon: 'fas fa-eye',
          action: this.see.bind(this),
        },
        {
          title: 'Deletar',
          icon: 'fas fa-trash',
          action: this.removeContent,
        },
      ],
      responseActions: [{ action: this.taskYes.bind(this) }],

      sortField: '',

      session: {
        project: '',
        coachee_id: '',
        group_id: '',
        its_group: false,
        _id: '',
      },
      contents: [],
      selectedContents: [],
      sessionContents: [],

      selectedCoachees: [],

      showModalContents: false,
      showModalContentUpload: false,

      maxLength: 3660,

      showModalContentDelete: false,
      contentToDelete: null,
    };

    this.utils = new Utils();
    this.service = new Service();
    this.handleResize = this.handleResize.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.togglePrincipal = this.togglePrincipal.bind(this);
    this.setSidebarLinks = this.setSidebarLinks.bind(this);
    this.goTo = this.goTo.bind(this);
  }

  toggleContent = selectedItem => {
    let selectedContents = [...this.state.selectedContents];
    const isContentSelected = selectedContents.find(
      content => content._id === selectedItem._id
    );
    if (isContentSelected) {
      selectedContents = selectedContents.filter(
        content => content._id !== selectedItem._id
      );
    } else {
      selectedContents.push(selectedItem);
    }
    this.setState({ selectedContents });
  };

  removeContent = contentToDelete => {
    this.setState({ contentToDelete }, () => {
      this.toggleModalDelete();
    });
  };

  deleteContent = async () => {
    const { session, contentToDelete } = this.state;
    const { sessionId } = this.props;

    const __sessionID = session._id || sessionId;

    if (__sessionID) {
      const {
        meeting,
        coachee_id: coacheeId,
        _id: contentId,
      } = contentToDelete;

      const result = await this.service.destroySessionContent(
        __sessionID,
        meeting,
        coacheeId,
        contentId
      );
      if (result && result.success) {
        await this.loadSessionContents();
      }
    }
    this.toggleModalDelete();
  };

  toggleModalDelete() {
    this.setState({
      showModalContentDelete: !this.state.showModalContentDelete,
    });
  }

  toggleCoachee = item => {
    let selectedCoachees = [...this.state.selectedCoachees];
    const isCoacheeSelected = selectedCoachees.find(
      coachee => coachee._id === item._id
    );
    if (isCoacheeSelected) {
      selectedCoachees = selectedCoachees.filter(
        coachee => coachee._id !== item._id
      );
    } else {
      selectedCoachees.push(item);
    }
    this.setState({ selectedCoachees });
  };

  async see(content) {
    let url;
    if (
      content.html ||
      content.doc_format === 'wheel' ||
      content.doc_format === 'form'
    ) {
      this.templateEditor(content);
    } else {
      if (content.doc_format.toLowerCase() === 'link') {
        url = content.doc_source;
      } else {
        url = this.utils.resolveURL(
          content.doc_source,
          this.service.getServer()
        );
      }
      window.open(url);
    }
  }

  toggleModalContent = async (saving = false, content = []) => {
    const { sessionId } = this.props;

    const {
      selectedCoachees,
      selectedContents,
      session,
      contentList,
    } = this.state;

    const __sessionID = session._id || sessionId;

    const hasContent = contentList.length > 0;
    if (!hasContent) {
      this.props.updateAlert(
        <ContentUploadConfirmation
          cancel={() => this.props.updateAlert('')}
          upload={() => {
            this.props.updateAlert('');
            this.setState({ showModalContentUpload: true });
          }}
        />
      );
      return;
    }

    const contentIds = selectedContents.map(content => content._id);
    if (saving && contentIds.length > 0 && __sessionID) {
      const sendTo = [];
      if (session.its_group) {
        selectedCoachees.forEach(coachee => sendTo.push(coachee._id));
      } else if (session.coachee_id) {
        sendTo.push(session.coachee_id);
      }
      if (sendTo.length > 0) {
        const result = await this.service.storeSessionContents(
          __sessionID,
          'session',
          sendTo,
          contentIds
        );

        if (result && result.success) {
          await this.loadSessionContents();
        }
      }
    }
    this.setState({
      showModalContents: !this.state.showModalContents,
      selectedContents: [...content],
      selectedCoachees: [],
    });
  };

  toggleMenu() {
    const { sideOpen } = this.state;
    this.setState({ sideOpen: !sideOpen });
  }

  togglePrincipal() {
    const { principalOpen } = this.state;
    this.setState({ principalOpen: !principalOpen });
  }

  goTo(page) {
    this.props.history.push(`/${page}`);
  }

  verifyLogin() {
    if (!this.utils.isLogged()) {
      this.goTo('');
    }
  }

  async componentDidMount() {
    this.verifyLogin();
    this.setState({ isLoading: true }, () => {
      Promise.all([
        this.loadUser().then(() => this.loadGroups()),
        this.loadCoachees(),
        this.loadContents(),
        this.loadSession(),
        this.loadSessionContents(),
      ]).finally(() => {
        this.setBreadcrumbs();
        this.setState({ isLoading: false });
      });
    });
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  setBreadcrumbs = () => {
    const breadcrumbDetail = this.getBreadcrumbDetail();

    this.setState({
      breadcrumbs: [
        { title: 'Dashboard', link: '' },
        { title: 'Programas', link: 'programs' },
        {
          title: 'Registro de conteúdo',
          link: 'programs/assessments/contents',
        },
        ...(breadcrumbDetail ? [{ title: breadcrumbDetail }] : []),
      ],
    });
  };

  getCoacheeName = coacheeId => {
    const coachee = this.state.coachees.find(
      coachee => coachee._id === coacheeId
    );
    return coachee && coachee.name;
  };

  getGroupName = groupId => {
    const group = this.state.groups.find(group => group._id === groupId);
    return group && group.name;
  };

  getBreadcrumbDetail = () => {
    const { session } = this.state;

    if (session.coachee_id) return this.getCoacheeName(session.coachee_id);
    if (session.group_id) return this.getGroupName(session.group_id);
  };

  setSidebarLinks(view) {
    this.setState({ view });
    this.goTo(`dashboard/${view}`);
  }

  handleResize() {
    let _ww =
      window.innerWidth < 890 && window.innerWidth > 570
        ? window.innerWidth - (window.innerWidth * 30) / 100
        : window.innerWidth <= 570
        ? window.innerWidth - (window.innerWidth * 40) / 100
        : window.innerWidth - (window.innerWidth * 40) / 100;

    this.setState({
      windowWidth: _ww,
    });
  }

  logout() {
    this.utils.setLogout();
    this.goTo('');
  }

  parseSession = session => {
    const { coachee_id, project, group_id, its_group, _id } = session;
    return { coachee_id, project, group_id, its_group, _id };
  };

  loadSession = async () => {
    const { session } = this.state;
    const { sessionId } = this.props;

    const __sessionID = session._id || sessionId;

    this.setState({ loading: true });

    if (__sessionID) {
      const result = await this.service.sessionG(__sessionID);

      if (result && result.success && result.session) {
        this.setState({
          session: this.parseSession(result.session),
        });
      }
    }
    this.setState({ loading: false });
  };

  async loadUser() {
    let result = await this.service.me();
    if (!result) {
      this.logout();
      return;
    }
    let user = {
      ...result.user,
    };
    delete user.location;
    delete user.contact;
    if (result.user.contact && result.user.contact.length > 0) {
      user['phone'] = result.user.contact[0].phone;
      user['site'] = result.user.contact[0].site;
    }
    if (result.user.location && result.user.location.length > 0) {
      user['state'] = result.user.location[0].state;
      user['city'] = result.user.location[0].city;
    }
    if (user.image) {
      this.props.updateImage(user.image);
    }

    this.setState({ user });
  }

  async loadCoachees() {
    this.setState({ loading_coachee: true });
    let result = await this.service.coachees();
    if (result && result.success) {
      this.setState({ coachees: result.coachees });
    }
    this.setState({ loading_coachee: false });
  }

  loadGroups = async () => {
    const { user } = this.state;
    if (!this.userHasGroupSessionRight(user)) return;

    this.setState({ loading_groups: true });
    let result = await this.service.groups();
    if (result && result.success) {
      const { groups } = result;
      this.setState({ groups });
    }
    this.setState({ loading_groups: false });
  };

  async loadContents(openModalAfterLoad = false) {
    this.setState({ loading_contents: true });
    let result = await this.service.contents();
    if (result && result.success) {
      this.setState({ contentList: result.contents }, () => {
        if (openModalAfterLoad) {
          const { contentList } = this.state;
          const [newContent] = contentList;
          if (newContent) this.toggleModalContent(false, [newContent]);
        }
      });
    }
    this.setState({ loading_contents: false });
  }

  async loadSessionContents() {
    const { session } = this.state;
    const { sessionId } = this.props;

    const __sessionID = session._id || sessionId;

    if (__sessionID) {
      this.setState({ loading_contents: true });
      const result = await this.service.sessionContents(__sessionID);
      if (result && result.success) {
        this.setState({ sessionContents: result.contents });
      }
      this.setState({ loading_contents: false });
    }
  }

  sort(key) {
    let order =
      this.state.sortField == '' || this.state.sortField == key ? '-down' : '';

    let contentList = this.state.contentList.sort((a, b) => {
      if (a[key] > b[key]) {
        return order.indexOf('down') !== -1 ? 1 : -1;
      }
      if (a[key] < b[key]) {
        return order.indexOf('down') !== -1 ? -1 : 1;
      }
      return 0;
    });
    this.setState({ sortField: `${key}${order}`, contentList });
  }

  templateEditor(content) {
    const { session } = this.state;
    const { sessionId } = this.props;

    const __sessionID = session._id || sessionId;

    if (__sessionID) {
      const {
        meeting,
        coachee_id: coacheeId,
        _id: contentId,
        doc_format,
      } = content;
      if (doc_format === 'wheel') {
        this.goTo(
          `dashboard/wheel-viewer/${__sessionID}/${meeting}/${coacheeId}/${contentId}`
        );
      } else if (doc_format === 'form') {
        this.goTo(
          `dashboard/form-viewer/${__sessionID}/${meeting}/${coacheeId}/${contentId}`
        );
      } else {
        this.goTo(
          `dashboard/response-viewer/${__sessionID}/${meeting}/${coacheeId}/${contentId}`
        );
      }
    }
  }

  async taskYes(content) {
    const { session } = this.state;
    const { sessionId } = this.props;

    const __sessionID = session._id || sessionId;

    if (__sessionID) {
      const { meeting, coachee_id: coacheeId, _id: contentId } = content;

      const result = await this.service.completeSessionTask(
        __sessionID,
        meeting,
        coacheeId,
        contentId
      );

      if (result && result.success) {
        this.loadSessionContents();
      }
    }
  }

  getGroupCoachees = () => {
    const { session, groups } = this.state;
    const group =
      session.group_id && groups.find(group => group._id === session.group_id);
    return group ? group.coachees : [];
  };

  userHasGroupSessionRight = user => {
    return (
      user &&
      user.plans &&
      user.plans.platform &&
      user.plans.platform.plan.group_sessions
    );
  };

  userHasRights = user => {
    const { session } = this.state;
    return (
      !session.its_group ||
      (session.its_group && this.userHasGroupSessionRight(user))
    );
  };

  toggleModalContentUpload = async isSaved => {
    const { showModalContentUpload } = this.state;
    this.setState({ showModalContentUpload: !showModalContentUpload });
    if (isSaved) await this.loadContents(true);
  };

  loadSessionByGroupId = async group_id => {
    if (!group_id) return;
    const result = await this.service.sessionG(group_id);
    if (result && result.success) {
      const { session } = result;

      if (session) {
        this.setState({
          session: this.parseSession(result.session),
        });

        this.setState({ isLoading: true }, () => {
          Promise.all([this.loadSession(), this.loadSessionContents()]).finally(
            () => {
              this.setBreadcrumbs();
              this.setState({ isLoading: false });
            }
          );
        });
      } else if (group_id) {
        const updateState = {
          group_id: group_id,
          coachee_id: '',
          oportunity_id: '',
        };
        this.setState(
          {
            session: { ...this.state.session, ...updateState },
          },
          () => {
            this.createSession();
          }
        );
      }
    }
  };

  createSession = async () => {
    this.setState({ loading: true });
    const {
      coachee_id,
      oportunity_id,
      group_id,
      its_group,
    } = this.state.session;

    const result = await this.service.createSession({
      coachee_id,
      oportunity_id,
      its_free: false,
      group_id,
      its_group,
    });

    if (result && result.success) {
      const { session } = result;

      this.setState(
        {
          session: this.parseSession(session),
        },
        async () => {
          this.setBreadcrumbs();
        }
      );
    }
    this.setState({ loading: false });
  };

  changeGroup = e => {
    this.loadSessionByGroupId(e.target.value);
  };

  render() {
    const {
      sideOpen,
      side,
      principalOpen,
      view,
      breadcrumbs,
      subview,
      session,
      loading,
      coachees,
      responseActions,
      tableTitles,
      tableActions,
      sessionContents,
      sortField,
      showModalContents,
      showModalContentUpload,
      contentList,
      selectedContents,
      showModalContentDelete,
      groups,
      selectedCoachees,
      user,
      isLoading,
    } = this.state;
    return (
      <div className="main-content">
        <Header
          goTo={this.goTo}
          sideOpen={sideOpen}
          toggleMenu={this.toggleMenu}
        />
        <div className="content-climb user bodyClimby">
          <div
            className={this.props.menu ? 'content-menu closed' : 'content-menu'}
          >
            <Sidebar
              side={this.state.side}
              setSidebarLinks={this.setSidebarLinks}
              expanded={!this.state.sideOpen}
            />
          </div>
          <div
            className={this.props.menu ? 'content-dash closed' : 'content-dash'}
          >
            <div className="dash-content">
              <div className="dash-crumb-filter">
                <Breadcrumbs breadcrumbs={breadcrumbs} />
              </div>
              {isLoading ? (
                <ReactLoading type="bubbles" color="#B1CD49" />
              ) : !this.userHasRights(user) ? (
                <NotAllowed go={this.goTo.bind(this)} user={user} />
              ) : (
                <div className="dashboard-boxes">
                  <div className="dashboard-box box100 align-select-group">
                    <div style={{ display: 'flex' }}>
                      <span
                        className="box-title row-center"
                        style={{
                          alignItems: 'center',
                          width: '100%',
                        }}
                      >
                        Registro de Conteúdo
                      </span>

                      <div
                        className="form-input"
                        style={{
                          width: '45%',
                          padding: '0 20px',
                        }}
                      >
                        <label className="blue-label">Área</label>
                        {this.state.loading_groups ? (
                          <ReactLoading type="bubbles" color="#B1CD49" />
                        ) : (
                          <select
                            value={session.group_id}
                            className="blue-input"
                            onChange={e => {
                              this.changeGroup(e);
                            }}
                          >
                            <option value=""> &nbsp; Área</option>
                            {groups.map((group, key) => {
                              return (
                                <option key={group._id} value={group._id}>
                                  {' '}
                                  &nbsp; {group.name}
                                </option>
                              );
                            })}
                          </select>
                        )}
                      </div>
                    </div>

                    {session._id && (
                      <CardGenericTableWithGroup
                        noBox
                        id="session-contents-1"
                        title="Registro de Conteúdos"
                        response_actions={responseActions}
                        actions={tableActions}
                        title_rows={[
                          ...(session.its_group
                            ? [
                                {
                                  title: 'Membro',
                                  key: 'coacheeName',
                                },
                              ]
                            : []),
                          ...tableTitles,
                        ]}
                        groupBy="meetingTitle"
                        rows={sessionContents.map(content => {
                          const { meeting } = content;
                          let meetingTitle = '';
                          if (meeting === 'master') {
                            meetingTitle = 'Reunião Inaugural';
                          } else if (meeting === 'session') {
                            meetingTitle = 'Conteúdo Adicional';
                          } else {
                            meetingTitle = `Sessão ${meeting}`;
                          }
                          return {
                            ...content,
                            meetingTitle,
                            coacheeName: content.coachee.name,
                          };
                        })}
                      />
                    )}
                  </div>

                  {session._id && (
                    <div className="row-button" style={{ padding: '7px 16px' }}>
                      {loading ? (
                        <ReactLoading type="bubbles" color="#00838F" />
                      ) : (
                        <div
                          className="bt-blue noMargin"
                          onClick={this.toggleModalContent}
                        >
                          <img
                            src={require('./../../../assets/images/attach_file.svg')}
                            style={{ marginTop: -3, marginRight: 14 }}
                          />
                          Adicionar Conteúdo
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}

              <Footer />
            </div>
          </div>
        </div>

        <ModalContents
          sort={this.sort.bind(this)}
          sortField={sortField}
          show={showModalContents}
          contentList={contentList}
          selectedContents={selectedContents}
          toggleContent={this.toggleContent}
          toggleModalContent={this.toggleModalContent}
          coachees={this.getGroupCoachees()}
          selectedCoachees={selectedCoachees}
          toggleCoachee={this.toggleCoachee}
          selectAllCoachee={() =>
            this.setState({ selectedCoachees: [...this.getGroupCoachees()] })
          }
          selectNoneCoachee={() => this.setState({ selectedCoachees: [] })}
        />

        <ModalContentDelete
          close={() => {
            this.setState({ showModalContentDelete: false });
          }}
          open={showModalContentDelete}
          deleteContent={this.deleteContent.bind(this)}
          title={`Confirmar deleção?`}
        />

        <ModalContentUpload
          show={showModalContentUpload}
          toggle={this.toggleModalContentUpload}
        />
      </div>
    );
  }
}
const mapStateToProps = state => ({
  image: state.simpleReducer.image,
  sessionId: state.simpleReducer.sessionId,
  menu: state.simpleReducer.menu,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateImage, updateAlert }, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ContentsRegister)
);
